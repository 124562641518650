<button
  mat-icon-button
  [matMenuTriggerFor]="downloadMenu"
  *ngIf="downloadVideos?.length + liveVideos?.length > 0"
>
  <mat-icon
    class="button-icon cmv-dim-gray-icon open-download-menu"
    svgIcon="cmv-download"
  ></mat-icon>
</button>
<mat-menu class="cmv-menu-container" #downloadMenu="matMenu" xPosition="before">
  <div (keydown)="$event.stopPropagation()">
    <button
      *ngFor="let video of liveVideos"
      class="cmv-menu-item"
      (click)="onRenderVideo(video)"
      mat-menu-item
    >
      <span class="cmv-h4 source-name">
        {{
          getVideoRemuxTranslateString(video)
            | translate: { videoType: video.name }
        }}
      </span>
      <ng-container [ngSwitch]="video.status">
        <div
          *ngSwitchCase="VideoStatus.FAILED"
          class="download-menu-icon"
          [matTooltip]="
            'components.playedMatchTagging.renderFailedInfo' | translate
          "
        >
          <mat-icon
            class="download-menu-icon button-icon cmv-dim-gray-icon"
            svgIcon="cmv-retry"
          ></mat-icon>
        </div>

        <div
          *ngSwitchCase="VideoStatus.REMUXING"
          class="download-menu-icon"
          [matTooltip]="
            'components.playedMatchTagging.renderingInfo' | translate
          "
        >
          <mat-icon
            class="download-menu-icon button-icon cmv-dim-gray-icon rotating"
            svgIcon="cmv-loading"
          ></mat-icon>
        </div>

        <mat-icon
          *ngSwitchDefault
          class="button-icon cmv-dim-gray-icon"
          svgIcon="cmv-download"
        ></mat-icon>
      </ng-container>
    </button>

    <button
      *ngFor="let video of downloadVideos"
      class="cmv-menu-item"
      (click)="downloadVideo(video.download, video.id, video.recordingId)"
      mat-menu-item
    >
      <span class="cmv-h4 source-name">
        {{
          'components.playedMatchTagging.downloadFeed'
            | translate: { videoType: video.name }
        }}
      </span>
      <div class="download-menu-icon">
        <mat-icon
          class="button-icon cmv-dim-gray-icon"
          svgIcon="cmv-download"
        ></mat-icon>
      </div>
    </button>
  </div>
</mat-menu>
