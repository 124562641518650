<div tabindex="0" #elementToFocus class="cmv-modal-outer-card logout-mobile">
  <div class="cmv-modal-inner-card cmv-modal-remove">
    <div class="cmv-modal-close-button" (click)="closeModal(false)">
      <mat-icon
        class="modal-icon-closing cmv-dim-gray"
        svgIcon="cmv-closing"
      ></mat-icon>
    </div>

    <div class="main-info">
      <div class="cmv-h1">
        {{ 'components.modals.cookiesModal.title' | translate }}
      </div>
      <div class="cmv-body cookie-subtitle">
        {{ 'components.modals.cookiesModal.textBefore' | translate }}
        <a href="/privacy" target="_blank" class="cookie-link">
          {{ 'components.modals.cookiesModal.textLink' | translate }}
        </a>
        {{ 'components.modals.cookiesModal.textAfter' | translate }}
      </div>
    </div>
    <div class="buttons">
      <div class="cmv-accept-button" (click)="closeModal(true)">
        <div class="cmv-h4">{{ 'basic.accept' | translate }}</div>
      </div>
      <div class="cmv-decline-button" (click)="closeModal(false)">
        <div class="cmv-h4">
          {{ 'basic.decline' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
