import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cmv-cookies-modal',
  templateUrl: './cookies-modal.component.html',
  styleUrls: ['./cookies-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiesModalComponent implements AfterViewInit {
  @ViewChild('elementToFocus')
  elementToFocus: ElementRef;

  constructor(
    private readonly dialogRef: MatDialogRef<CookiesModalComponent>,
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elementToFocus.nativeElement.focus();
    }, 0);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
  }

  closeModal(output: boolean): void {
    this.dialogRef.close(output);
  }
}
