import { SharingTypes } from '../components/tree/tree.component';
import { Sharing, SharingUserTeam } from '../../../api/models';

export enum ShareableEntityType {
  LIST = 'LIST',
  ANNO = 'ANNOTATION',
}

export interface ShareableEntityModel {
  id: string;
  type: ShareableEntityType;
  sharings: Sharing[] | null;
}

export interface UpdateShareableModel {
  id: string;
  sharings: Sharing[];
}

export interface SharingStateEntityModel {
  tree: SharingTeamIndexedModel[];
  expanded: SharingExpandIndex[];
  shareable: ShareableEntityModel;
  sharingTeams: SharingUserTeam[];
}

export interface SharingTeamIndexedModel {
  [id: string]: SharingTeamModel;
}

export interface SharingTeamModel {
  id: string;
  label: string;
  sharingMode: SharingTypes;
  users: SharingTreeUserModel[];
}

export interface SharingTreeUserModel {
  id: string;
  label: string;
  selected: boolean;
}

export interface SharingExpandIndex {
  [id: string]: {
    expanded: boolean;
  };
}
