import {
  AnnotationState,
  AnnotationTypesState,
  AppState,
  PlaylistListState,
} from '../../models/app.state';
import { createSelector } from '@ngrx/store';

export const $annotationTypes = (state: AppState) => state.annotationTypes;
export const $annotations = (state: AppState) => state.annotations;
export const $recording = (state: AppState) => state.recording;
export const $playlist = (state: AppState) => state.playlistList;

export const $videoTaggingViewLoading = createSelector(
  $annotationTypes,
  $recording,
  (annotationTypes: AnnotationTypesState, recording): boolean =>
    !(annotationTypes.loaded || recording.loaded) &&
    !(annotationTypes.errors.length || recording.errors.length),
);

export const $liveMatchViewLoading = createSelector(
  $annotationTypes,
  $recording,
  (annotationTypes: AnnotationTypesState, recording): boolean =>
    !(annotationTypes.loaded || recording.loaded) &&
    !(annotationTypes.errors.length || recording.errors.length),
);

export const $videoDetailViewLoading = createSelector(
  $annotations,
  $recording,
  (annotations: AnnotationState, recording): boolean =>
    !(annotations.loaded || recording.loaded) &&
    !(annotations.errors.length || recording.errors.length),
);

export const $playlistCollectionViewLoading = createSelector(
  $playlist,
  (playlistList: PlaylistListState): boolean =>
    !playlistList.loaded && !playlistList.errors.length,
);

export const $playlistDetailViewLoading = createSelector(
  $playlist,
  (playlistDetail: PlaylistListState): boolean =>
    !playlistDetail.loaded && !playlistDetail.errors.length,
);
