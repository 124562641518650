import { RecordingState } from '../../models/app.state'; // eslint-disable-line no-redeclare
import {
  getRecordingFailureAction,
  getRecordingRequestAction,
  getRecordingSuccessAction,
  remuxRecordingVideoSuccessAction,
} from '../actions/recording.actions';
import {
  getAnnotationsRecordingFailureAction,
  getAnnotationsRecordingRequestAction,
  getAnnotationsRecordingSuccessAction,
} from '../actions/annotation.actions';
import { Action, createReducer, on } from '@ngrx/store';
import {
  getPlaylistsRecordingsFailureAction,
  getPlaylistsRecordingsRequestAction,
  getPlaylistsRecordingsSuccessAction,
} from '../actions/video-detail.actions';
import {
  refreshSingleRecordingFailureAction,
  refreshSingleRecordingRequestAction,
  refreshSingleRecordingSuccessAction,
} from '../actions/recording-list.actions';

const initialState: RecordingState = {
  entities: {},
  errors: [],
  loading: false,
  loaded: false,
};

const reducer = createReducer(
  initialState,
  on(
    getRecordingRequestAction,
    getAnnotationsRecordingRequestAction,
    getPlaylistsRecordingsRequestAction,
    refreshSingleRecordingRequestAction,
    state => ({ ...state, loading: true }),
  ),
  on(
    getRecordingSuccessAction,
    getAnnotationsRecordingSuccessAction,
    refreshSingleRecordingSuccessAction,
    (state, { recording }) => ({
      ...state,
      entities: { ...state.entities, [recording.id]: recording },
      loading: false,
      loaded: true,
      errors: [],
    }),
  ),
  on(
    getPlaylistsRecordingsSuccessAction,
    (state, { response: { recordings } }) => ({
      ...state,
      entities: recordings.reduce(
        (acc, recording) => ({ ...acc, [recording.id]: recording }),
        { ...state.entities },
      ),
      loading: false,
      loaded: true,
      errors: [],
    }),
  ),
  on(
    getRecordingFailureAction,
    getAnnotationsRecordingFailureAction,
    getPlaylistsRecordingsFailureAction,
    refreshSingleRecordingFailureAction,
    (state, { errors }) => ({
      ...state,
      loading: false,
      errors,
    }),
  ),
  on(remuxRecordingVideoSuccessAction, (state, { recording }) => ({
    ...state,
    entities: {
      ...state.entities,
      [recording.id]: { ...recording },
    },
  })),
);

export function recordingReducer(
  state: RecordingState,
  action: Action,
): RecordingState {
  return reducer(state, action);
}
