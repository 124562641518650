import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AppState } from '../../../models/app.state';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { $sharingTeamsStateMappedExpand } from '../../../store/selectors/sharing.selectors';
import { ShareableEntityType } from '../../../models/sharing.model';

const BASIC_PATH = 'components.modals.shareModal';
const TITLE_ANNOTATION = 'titleAnnotation';
const SUBTITLE_ANNOTATION = 'subtitleAnnotation';
const TITLE_LIST = 'titleList';
const SUBTITLE_LIST = 'subtitleList';

@Component({
  selector: 'cmv-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareModalComponent implements OnDestroy, AfterViewInit {
  readonly unsubscribe$ = new Subject<void>();

  readonly sharingData$ = this.store.pipe(
    select($sharingTeamsStateMappedExpand),
  );

  @ViewChild('elementToFocus')
  elementToFocus: ElementRef;

  titleKey = '';
  subtitleKey = '';

  constructor(
    private readonly dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { entityType: ShareableEntityType },
    private readonly store: Store<AppState>,
  ) {
    this.titleKey = `${BASIC_PATH}.${
      this.data.entityType === ShareableEntityType.ANNO
        ? TITLE_ANNOTATION
        : TITLE_LIST
    }`;
    this.subtitleKey = `${BASIC_PATH}.${
      this.data.entityType === ShareableEntityType.ANNO
        ? SUBTITLE_ANNOTATION
        : SUBTITLE_LIST
    }`;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elementToFocus.nativeElement.focus();
    }, 0);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
  }

  closeModal(): void {
    this.dialog.closeAll();
  }
}
