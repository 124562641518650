import { ErrorModel } from '../../models/app.state';
import { Recorder } from '../../../../api/models';
import { createAction, props } from '@ngrx/store';

export const getRecordersRequestAction = createAction('GetRecordersRequest');
export const refreshRecordersRequestAction = createAction(
  'RefreshRecordersRequest',
);
export const getRecordersSuccessAction = createAction(
  'GetRecordersSuccess',
  props<{ recorders: Recorder[] }>(),
);
export const getRecordersFailureAction = createAction(
  'GetRecordersFailure',
  props<{ errors: ErrorModel[] }>(),
);
export const refreshRecordersByIdsAction = createAction(
  'RefreshRecordersByIds',
  props<{ ids: string[] }>(),
);
