export interface WsStateModel {
  nextPingPong: number;
  currentlySubscribed?: WsCurrentlySubscribedModel | undefined;
}

export interface WsCurrentlySubscribedModel {
  id: string;
  subscribedAs: WsSubscribeAs;
}

export enum WsSubscribeAs {
  REC = 'recording',
  ANN = 'annotation',
}

export interface WsMessageModel {
  action: WsAction;
  entity?: string;
  msg?: string;
  id?: string;
  ids?: string[];
  recordingId?: string;
  status?: string;
  error?: string;
  userId?: string;
  recordersIds?: string[];
  playlistId?: string;
  url?: string;
}

export enum WsAction {
  PING = 'ping',
  SUB = 'sub',
  UNSUB = 'unsub',
}
