import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  takeUntil,
  tap,
  map,
  switchMap,
  withLatestFrom,
  take,
} from 'rxjs/operators';
import { Annotation } from 'src/api/models/annotation.model';
import { AppState } from '../../models/app.state';
import {
  changeCurrentVideoDurationAction,
  clearVideoUrlAction,
} from '../../store/actions/current-selections.actions';
import {
  closeIOSPWAFullscreenWarningAction,
  setDrawingOverlayVisibilityAction,
  setVideoVisibleAction,
} from '../../store/actions/ui-flags.actions';
import {
  adjustVideoVolumeAction,
  fullscreenVideoAction,
  closeFullscreenAction,
  pauseVideoAction,
  playingVideoAction,
  playVideoAction,
  scrubInVideoAction,
  waitingVideoAction,
  setVideoActualTimeAction,
  setFullscreenAction,
} from '../../store/actions/video.actions';
import {
  $drawingOverlayVisible,
  $isPWA,
  $showIOSPWAWarning,
  $videoTaggingPanelRolledUp,
  $videoTaggingEnabled,
} from '../../store/selectors/ui-flags.selectors';
import { $premiumUser } from '../../store/selectors/auth.selectors';
import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  OffsetModel,
  VgApiService,
  VgStates,
  VgUtilsService,
} from '@videogular/ngx-videogular/core';
import {
  CHANGE_CURRENT_VIDEO_DURATION_IOS_DELAY,
  GoogleAnalyticsEvent,
  SpeedRateOptions,
} from 'src/app/app.constants';
import { ChangeSrcModel } from '../../models/change-src.model';
import { CurrentVideoModel } from '../../models/current-video.model';
import { Video } from 'src/api/models';
import { reportToGA } from 'src/app/app.utils';
import { showPtzRequestAction } from '../../store/actions/ptz.actions';
import {
  $isPtzEnabled,
  $ptzOverlayVisible,
} from '../../store/selectors/ptz.selectors';
import { showSnackbarAction } from '../../store/actions/snackbar.actions';
import {
  $isEnabledNativeFullscreen,
  $isFakeFullscreenActive,
  $videoFullScreen,
} from '../../store/selectors/video.selectors';

const MAX_DATA_RETRY = 10;
const DEBOUNCE_TIME = 200;
const VIDEO_ELEMENT_ID = 'singleVideo';
const FPS = 25; // FPS should be consistent for all uploaded videos

enum TIME_SLOTS {
  TEN_SECONDS = 10,
  FIVE_MINUTES = 300, // eslint-disable-line no-magic-numbers
  FRAME = 0,
}

/* if animation states are defined as enum, this enum needs to be exported */
export enum VIDEO_CONTROLS_ANIMATION_STATES {
  ROLLED_UP = 'rolledUp',
  ROLLED_DOWN = 'rolledDown',
  DEFAULT = 'default',
}

@Component({
  selector: 'cmv-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  animations: [
    trigger('rollIt', [
      state(
        VIDEO_CONTROLS_ANIMATION_STATES.ROLLED_UP,
        style({
          transform: 'translateY(-165px)',
        }),
      ),
      state(
        VIDEO_CONTROLS_ANIMATION_STATES.ROLLED_DOWN,
        style({
          transform: 'translateY(0px)',
        }),
      ),
      state(
        VIDEO_CONTROLS_ANIMATION_STATES.DEFAULT,
        style({
          transform: 'translateY(0)',
        }),
      ),
      transition(
        `* => ${VIDEO_CONTROLS_ANIMATION_STATES.DEFAULT}`,
        animate(
          0,
          style({
            transform: 'translateY(0)',
          }),
        ),
      ),
      transition(
        `${VIDEO_CONTROLS_ANIMATION_STATES.ROLLED_UP} => ${VIDEO_CONTROLS_ANIMATION_STATES.ROLLED_DOWN}`,
        animate(
          '700ms ease',
          keyframes([
            style({
              transform: 'translateY(-165px)',
            }),
            style({
              transform: 'translateY(-165px)',
            }),
            style({
              transform: 'translateY(0)',
            }),
          ]),
        ),
      ),
      transition(
        `${VIDEO_CONTROLS_ANIMATION_STATES.ROLLED_DOWN} => ${VIDEO_CONTROLS_ANIMATION_STATES.ROLLED_UP}`,
        animate(
          '700ms ease',
          keyframes([
            style({
              transform: 'translateY(0)',
            }),
            style({
              transform: 'translateY(-165px)',
            }),
            style({
              transform: 'translateY(-165px)',
            }),
          ]),
        ),
      ),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoPlayerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('media') media: ElementRef;
  @ViewChild('vgPlayerEl') vgPlayerRef: any;

  @Input() offset: OffsetModel;
  @Input() videoUrl: string;
  @Input() loading: boolean = false;
  @Input() autoplay: boolean;
  @Input() createTag: (tag: Partial<Annotation>, inFullscreen: boolean) => void;
  @Input() enableChangeVideoSource: boolean = false;
  @Input() currentVideo: CurrentVideoModel;
  @Input() videoSources: Video[];

  @Output() clipEnded = new EventEmitter<boolean>();
  @Output() videoCurrentTime = new EventEmitter<number>();
  @Output() changeSrc = new EventEmitter<ChangeSrcModel>();
  @Output() seeked = new EventEmitter<number>();
  @Output() playerReady = new EventEmitter<void>();

  constructor(private readonly store: Store<AppState>) {}

  api: VgApiService;
  dataRetry = 0;
  TIME_SLOTS = TIME_SLOTS;
  playbackRateShown = false;
  changeVideoSourceShown = false;
  seeking$: Observable<boolean>;
  videoElementId = VIDEO_ELEMENT_ID;
  videoControlsAnimationStates = VIDEO_CONTROLS_ANIMATION_STATES;
  SpeedRateOptions = SpeedRateOptions;

  readonly videoTaggingEnabled$ = this.store.pipe(select($videoTaggingEnabled));
  readonly premiumUser$ = this.store.pipe(select($premiumUser));
  readonly isEnabledNativeFullscreen$ = this.store.pipe(
    select($isEnabledNativeFullscreen),
  );
  readonly isFakeFullscreenActive$ = this.store.pipe(
    select($isFakeFullscreenActive),
  );
  readonly isFullscreen$ = this.store.pipe(select($videoFullScreen));
  readonly taggingFullscreen$ = this.isFullscreen$;

  readonly drawingOverlayVisible$ = this.store.pipe(
    select($drawingOverlayVisible),
  );

  readonly ptzEnabled$ = this.store.pipe(select($isPtzEnabled));
  readonly ptzOverlayVisible$ = this.store.pipe(select($ptzOverlayVisible));

  readonly ptzOverlayVisibleSubsrciption = this.ptzOverlayVisible$.subscribe(
    visible => {
      if (!this.api) {
        return;
      }

      if (visible) {
        this.api.shortcutsDisabled = true;
      } else {
        this.api.shortcutsDisabled = false;
      }
    },
  );

  readonly taggingPanelRolledUp$ = this.store.pipe(
    select($videoTaggingPanelRolledUp),
  );

  readonly taggingFullscreenAndFakeFullscreen$ = this.videoTaggingEnabled$.pipe(
    switchMap(taggingEnabled =>
      this.isFullscreen$.pipe(
        switchMap(isFullscreen =>
          this.isFakeFullscreenActive$.pipe(
            map(isFakeFullscreen => isFakeFullscreen || isFullscreen),
            map(fullscreen => fullscreen && taggingEnabled && !!this.createTag),
          ),
        ),
      ),
    ),
  );

  readonly showIOSPWAWarning$ = this.store.pipe(
    select($showIOSPWAWarning),
    withLatestFrom(this.store.pipe(select($isPWA))),
    map(([show, isPWA]) => show && !isPWA && this.isIOS),
  );

  private readonly unsubscribe$ = new Subject<void>();

  readonly isPWAInstalled = (): boolean =>
    !window.matchMedia('(display-mode: browser)').matches;

  get isPlayerReady(): boolean {
    return this.api != null && this.api.isPlayerReady;
  }

  get playbackRate(): number {
    return (this.api && this.api.playbackRate) || 1;
  }

  onPlayerReady(api: VgApiService): void {
    this.api = api;
    this.playerReady.emit();
  }

  readonly isFullscreen = (): boolean =>
    this.api && this.api.fsAPI.isFullscreen;

  readonly isPlaying = (): boolean => this.api && this.api.state === 'playing';

  readonly getCurrentTime = (): number =>
    this.api ? this.api.getDefaultMedia().currentTime : 0;

  setCurrentTime(time: number): void {
    this.api
      .getDefaultMedia()
      .subscriptions.canPlay.pipe(take(1))
      .subscribe(() => {
        this.api.getDefaultMedia().currentTime = time;
      });
  }

  readonly isIOS = (): boolean => VgUtilsService.isiOSDevice();

  readonly getVideoDuration = (): number =>
    this.api ? Math.floor(this.api.duration) : 0;

  videoError(): void {
    if (this.dataRetry < MAX_DATA_RETRY) {
      if (this.videoUrl != null && this.media != null) {
        this.dataRetry++;

        if (!this.videoUrl) {
          return;
        }

        const videoUrl = new URL(this.videoUrl);
        videoUrl.searchParams.set('retry', `${this.dataRetry}`);
        this.media.nativeElement.src = `${videoUrl}`;
      }
    }
  }

  play(): void {
    if (this.api) {
      this.api.play();
    }
  }

  togglePlay(): void {
    if (this.api) {
      if (this.api.state === 'playing') {
        this.api.pause();
      } else {
        this.api.play();
      }
    }
  }

  computeJumpTime(seconds: TIME_SLOTS, forward: boolean): number {
    const jumpTime = seconds === TIME_SLOTS.FRAME ? 1 / FPS : seconds;
    return forward ? jumpTime : -jumpTime;
  }

  canJump(seconds: TIME_SLOTS, forward: boolean): boolean {
    if (this.api == null) {
      return false;
    }
    const jumpTime = this.computeJumpTime(seconds, forward);
    const newTime = this.api.getDefaultMedia().time.current / 1000 + jumpTime;
    return (
      newTime <= this.api.getDefaultMedia().time.total / 1000 && newTime > 0
    );
  }

  jumpTo(seconds: number, forward: boolean): void {
    const jumpTime = this.computeJumpTime(seconds, forward);
    this.api.seekTime(this.getCurrentTime() + jumpTime);
  }

  setPlaybackRate(rate: number): void {
    if (this.api) {
      this.api.playbackRate = rate;
      reportToGA(GoogleAnalyticsEvent.CHANGE_VIDEO_SPEED, { rate });
    }
  }

  setVideoSource(video: Video) {
    this.changeSrc.emit({ video, currentVideo: this.currentVideo });
  }

  toggleChangeVideoSource(event: MouseEvent): void {
    event.stopPropagation();
    this.changeVideoSourceShown = !this.changeVideoSourceShown;
    this.playbackRateShown = false;
  }

  toggleOptions(event: MouseEvent): void {
    event.stopPropagation();
    this.playbackRateShown = !this.playbackRateShown;
    this.changeVideoSourceShown = false;
  }

  hideOptions(): void {
    this.playbackRateShown = false;
    this.changeVideoSourceShown = false;
  }

  openPtz(): void {
    if (this.api.followsLive && this.api.state === VgStates.VG_PLAYING) {
      this.store.dispatch(
        showPtzRequestAction({
          recordingId: this.currentVideo.recordingId,
          source: this.currentVideo.name,
        }),
      );
    } else {
      this.store.dispatch(
        showSnackbarAction({
          infoMessage: 'error.ptz.noLive',
          icon: 'closing',
        }),
      );
    }
  }

  openDrawing(): void {
    this.store.dispatch(setDrawingOverlayVisibilityAction({ visible: true }));
    reportToGA(GoogleAnalyticsEvent.OPEN_DRAWING);
  }

  closeIOSPWAFullscreenWarning(): void {
    this.store.dispatch(closeIOSPWAFullscreenWarningAction());
  }

  ngAfterViewInit(): void {
    this.store.dispatch(setVideoVisibleAction({ videoVisible: true }));

    const video = this.media.nativeElement;
    const vgPlayer = this.vgPlayerRef.elem;

    if (video == null || vgPlayer == null) {
      return;
    }

    video.addEventListener('loadedmetadata', () => {
      const timeout = this.isIOS()
        ? CHANGE_CURRENT_VIDEO_DURATION_IOS_DELAY
        : 0;
      setTimeout(() => {
        this.store.dispatch(
          changeCurrentVideoDurationAction({
            duration: this.getVideoDuration(),
          }),
        );
      }, timeout);
    });

    if (this.api) {
      fromEvent(video, 'timeupdate')
        .pipe(
          tap(() => {
            const actualTime = this.api.time.current;

            this.store.dispatch(setVideoActualTimeAction({ actualTime }));
            this.videoCurrentTime.emit(actualTime);
          }),
          takeUntil(this.unsubscribe$),
          filter(() => {
            const currentTime = Math.floor(
              this.api.getDefaultMedia().currentTime,
            );
            const duration = Math.floor(this.api.getDefaultMedia().duration);
            return (
              (this.api.offset?.end >= 0 &&
                currentTime === Math.floor(this.api.offset.end)) ||
              (!isNaN(duration) && currentTime === duration)
            );
          }),
          debounceTime(DEBOUNCE_TIME),
        )
        .subscribe(() => {
          this.clipEnded.emit(true);
        });

      this.api.fsAPI.onChangeFullscreen.subscribe(value => {
        this.store.dispatch(setFullscreenAction({ videoFullscreen: value }));
      });
    }

    fromEvent(video, 'play')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.store.dispatch(playVideoAction());
      });

    fromEvent(video, 'volumechange')
      .pipe(takeUntil(this.unsubscribe$), debounceTime(DEBOUNCE_TIME))
      .subscribe(() => {
        this.api.muted = this.api.volume === 0;
        this.store.dispatch(adjustVideoVolumeAction());
      });

    fromEvent(video, 'seeked')
      .pipe(takeUntil(this.unsubscribe$), debounceTime(DEBOUNCE_TIME))
      .subscribe(() => {
        const actualTime = this.api.time.current;
        this.seeked.emit(actualTime);
        this.store.dispatch(scrubInVideoAction({ actualTime }));
      });

    this.seeking$ = merge(
      fromEvent(video, 'seeking'),
      fromEvent(video, 'seeked'),
    ).pipe(map(({ type }) => type === 'seeking'));

    fromEvent(video, 'pause')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.store.dispatch(
          pauseVideoAction({ actualTime: this.api.time.current }),
        );
      });

    fromEvent(video, 'playing')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.store.dispatch(playingVideoAction());
      });

    fromEvent(video, 'waiting')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.store.dispatch(
          waitingVideoAction({ actualTime: this.api.time.current }),
        );
      });

    fromEvent(vgPlayer, 'fullscreenchange')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if ((document as any).fullscreenElement === vgPlayer) {
          this.store.dispatch(fullscreenVideoAction());
        } else {
          this.store.dispatch(closeFullscreenAction());
        }
      });

    this.api.fsAPI.onChangeFullscreen
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value =>
        value
          ? this.store.dispatch(fullscreenVideoAction())
          : this.store.dispatch(closeFullscreenAction()),
      );
  }

  ngOnDestroy(): void {
    this.store.dispatch(closeFullscreenAction());
    this.store.dispatch(setVideoVisibleAction({ videoVisible: false }));
    this.store.dispatch(clearVideoUrlAction());
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.ptzOverlayVisibleSubsrciption.unsubscribe();
  }
}
