import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { filter, map, pairwise, startWith, switchMap } from 'rxjs/operators';
import { QueryParamNames, RoutePath } from '../../../app.constants';
import { getAnnotationTypesRequestAction } from '../actions/annotation-types.actions';
import { getAnnotationsRequestAction } from '../actions/annotation.actions';
import { getRecordingsAnnotationListsRequestAction } from '../actions/annotations-lists.actions';
import { getRecordingRequestAction } from '../actions/recording.actions';
import { storeRouteAction } from '../actions/route.actions';
import { getPlaylistsRecordingsRequestAction } from '../actions/video-detail.actions';

@Injectable()
export class RouteEffects {
  navigate$ = createEffect(() =>
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(({ urlAfterRedirects }: NavigationEnd) => {
        const route = this.router.parseUrl(urlAfterRedirects);
        const paths = route.root.children['primary'].segments.map(
          ({ path }) => path,
        );
        return storeRouteAction({
          route: {
            path: paths.join('/'),
            params: { ...route.queryParams },
            pathSegments: [...paths],
          },
        });
      }),
    ),
  );

  getRecordingResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(storeRouteAction),
      map(({ route }) => route),
      startWith(undefined),
      pairwise(),
      filter(
        ([, current]) => current!.pathSegments[1] === RoutePath.Recordings,
      ),
      // eslint-disable-next-line complexity
      switchMap(([_, current]) => {
        const [, , currentSubSection] = current!.pathSegments;
        const params = current!.params;
        const id = params[QueryParamNames.RecordingId];
        switch (currentSubSection) {
          case RoutePath.PlayedMatch:
            return of(
              getAnnotationTypesRequestAction(),
              getRecordingRequestAction({ id }),
              getAnnotationsRequestAction({ id }),
              getRecordingsAnnotationListsRequestAction({ recordingId: id }),
            );
          case RoutePath.LiveMatch:
            return of(
              getAnnotationTypesRequestAction(),
              getRecordingRequestAction({ id }),
              getAnnotationsRequestAction({ id }),
              getRecordingsAnnotationListsRequestAction({ recordingId: id }),
            );
          default:
            return EMPTY;
        }
      }),
    ),
  );

  getPlaylistResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(storeRouteAction),
      filter(
        ({
          route: {
            pathSegments: [, section],
          },
        }) => section === RoutePath.Playlists,
      ),
      switchMap(
        ({
          route: {
            pathSegments: [, , subsection],
            params,
          },
        }) => {
          switch (subsection) {
            case RoutePath.Clip:
              return of(
                getAnnotationsRequestAction({
                  id: params.annotationId,
                }),
                getPlaylistsRecordingsRequestAction({
                  playlistId: params.playlistId,
                }),
              );
            default:
              return EMPTY;
          }
        },
      ),
    ),
  );

  constructor(
    private readonly router: Router,
    private readonly actions$: Actions,
  ) {}
}
