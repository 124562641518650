<div class="change-src-menu" *ngIf="currentVideo">
  <button
    mat-icon-button
    *ngIf="videoSources && videoSources.length > 0"
    [matMenuTriggerFor]="broadcastMenu"
  >
    <mat-icon
      class="button-icon cmv-dim-gray-icon"
      svgIcon="cmv-camera"
    ></mat-icon>
  </button>
  <mat-menu
    class="cmv-menu-container"
    #broadcastMenu="matMenu"
    xPosition="before"
  >
    <div (keydown)="$event.stopPropagation()">
      <button
        *ngFor="let video of videoSources"
        class="cmv-menu-item"
        (click)="videoClick(video)"
        mat-menu-item
      >
        <span class="cmv-h4 source-name">
          {{
            ('components.playedMatchTagging.feed.' + video.name | translate) !==
            'components.playedMatchTagging.feed.' + video.name
              ? ('components.playedMatchTagging.feed.' + video.name | translate)
              : ('components.playedMatchTagging.feed.watchFeed'
                | translate: { videoType: video.name })
          }}
        </span>
        <span class="source-checkmark-icon-wrapper">
          <mat-icon
            class="modal-icon cmv-gray-icon source-check-icon"
            *ngIf="video.id === currentVideo.id"
            svgIcon="cmv-checkmark"
          ></mat-icon>
        </span>
      </button>
    </div>
  </mat-menu>
</div>
