<div class="version-info">
  <h2 class="version-info--title">
    {{ 'components.versionInfo.version' | translate }} {{ version }}
  </h2>
  <markdown
    [line]="'6, 10-16'"
    [lineOffset]="5"
    [src]="url"
    (load)="onLoad()"
  ></markdown>
</div>
