import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { AnnotationList, RenderStatus } from 'src/api/models';
import { AppState } from '../../models/app.state';
import { ShareableEntityType } from '../../models/sharing.model';
import { getSharingTeamsRequestAction } from '../../store/actions/sharing.actions';
import { ShareModalComponent } from '../modal/share-modal/share-modal.component';
import { downloadAnnotationsListRequestAction } from '../../store/actions/annotations-lists.actions';
import { $currentRecordingHasVideo } from '../../store/selectors/current-selections.selectors';

@Component({
  selector: 'cmv-created-tags-more',
  templateUrl: './created-tags-more.component.html',
  styleUrls: ['./created-tags-more.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatedTagsMoreComponent {
  @Input() shareableList: AnnotationList;
  @Input() annotationsEmpty: Boolean = true;
  @Input() isDownloadPrepared: Boolean = false;

  renderStatus = RenderStatus;

  readonly currentRecordingHasVideo$ = this.store.pipe(
    select($currentRecordingHasVideo),
  );

  constructor(
    public readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
  ) {}

  shareAnnotationList(annotationList: AnnotationList): void {
    const shareable = {
      id: annotationList.id,
      sharings: annotationList.sharings,
      type: ShareableEntityType.LIST,
    };
    this.store.dispatch(getSharingTeamsRequestAction({ shareable }));
    this.dialog.open(ShareModalComponent, {
      data: {
        entityType: ShareableEntityType.LIST,
      },
    });
  }

  downloadAnnotationList(annotationList: AnnotationList): void {
    this.store.dispatch(
      downloadAnnotationsListRequestAction({ id: annotationList.id }),
    );
  }
}
