import { createEntityAdapter } from '@ngrx/entity';
import { INITIAL_ENTITY_STATE, StateStatus } from '../../models/app.state';
import { Recorder } from '../../../../api/models';
import {
  getRecordersFailureAction,
  getRecordersRequestAction,
  getRecordersSuccessAction,
} from '../actions/recorders.actions';
import { Action, createReducer, on } from '@ngrx/store';

export const recordersAdapter = createEntityAdapter<Recorder>();

const recorderState = sessionStorage.getItem('recordersState');
let initState = recorderState
  ? JSON.parse(recorderState)
  : INITIAL_ENTITY_STATE;

const reducer = createReducer(
  initState,
  on(getRecordersRequestAction, state => ({
    ...state,
    status: StateStatus.LOADING,
  })),
  on(getRecordersSuccessAction, (state, { recorders }) => ({
    ...state,
    ...recordersAdapter.setAll(recorders, state),
    status: StateStatus.LOADED,
    errors: [],
  })),
  on(getRecordersFailureAction, (state, { errors }) => ({
    ...state,
    status: StateStatus.ERROR,
    errors,
  })),
);

export function recordersReducer(
  state: typeof INITIAL_ENTITY_STATE,
  action: Action,
): typeof INITIAL_ENTITY_STATE {
  return reducer(state, action);
}
