import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { VideoPlayerWrapperComponent } from 'src/app/shared/components/video-player-wrapper/video-player-wrapper.component';
import { OffsetModel } from 'src/app/shared/models/offset.model';
import { Annotation, Playlist, Video } from '../../../api/models';
import { QueryParamNames, RoutePath } from '../../app.constants';
import { TAGS_ANIMATION } from '../../app.utils';
import { SEEK_TO } from '../../shared/components/trimming-panel/trimming-panel.component';
import { AppState } from '../../shared/models/app.state';
import { CurrentVideoModel } from '../../shared/models/current-video.model';
import { TrimmingInputModel } from '../../shared/models/trimming-input.model';
import { changeCurrentVideoAction } from '../../shared/store/actions/current-selections.actions';
import {
  deletePlaylistRequestAction,
  editAnnotationNameInPlaylistRequestAction,
  editAnnotationTrimmingInPlaylistAction,
  putPlaylistRequestAction,
  removeFromPlaylistRequestAction,
  renderPlaylistRequestAction,
} from '../../shared/store/actions/playlist-list.actions';
import {
  $currentPlaylist,
  $currentPlaylistAnnotationOffsets,
  $currentPlaylistAnnotationValue,
  $currentPlaylistAnotations,
  $currentPlaylistsAnnotation,
  $currentPlaylistsRecordingVideos,
  $currentSignedUrl,
  $currentVideo,
  $currentVideoLoading,
} from '../../shared/store/selectors/current-selections.selectors';
import { $videoDetailViewLoading } from '../../shared/store/selectors/loading-components.selectors';
import { $nextPlayablePlaylistAnnotationId } from '../../shared/store/selectors/playlist-list.selectors';
import { $isTrimming } from '../../shared/store/selectors/route.selectors';

@Component({
  selector: 'cmv-video-detail-playlist',
  templateUrl: './video-detail-playlist.component.html',
  styleUrls: ['./video-detail-playlist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: TAGS_ANIMATION,
})
export class VideoDetailPlaylistComponent implements OnDestroy {
  @ViewChild('videoPlayer')
  videoPlayer: VideoPlayerWrapperComponent;
  loadingResources$ = this.store.pipe(select($videoDetailViewLoading));
  isTrimming$ = this.store.pipe(select($isTrimming));
  calcAnnotationValue: TrimmingInputModel | undefined;
  shouldAutoplay = false;
  jumpToStart = true;
  jumpToEnd = false;

  newCurrentOffset$: BehaviorSubject<OffsetModel> = new BehaviorSubject({
    jumpToStart: this.jumpToStart,
    jumpToEnd: this.jumpToEnd,
  });

  currentOffset$: Observable<OffsetModel | null> = this.store.pipe(
    select($currentPlaylistAnnotationOffsets),
    map(currVidOffset =>
      currVidOffset === undefined
        ? null
        : {
            ...currVidOffset,
            jumpToStart: this.jumpToStart,
            jumpToEnd: this.jumpToEnd,
          },
    ),
  );

  playerOffset$: Observable<OffsetModel | null> = this.currentOffset$.pipe(
    switchMap(currentOffset =>
      this.newCurrentOffset$
        .asObservable()
        .pipe(
          switchMap(newCurrentOffset =>
            this.isTrimming$.pipe(
              map(isTrimming =>
                isTrimming &&
                newCurrentOffset.start != null &&
                newCurrentOffset.end != null
                  ? newCurrentOffset
                  : currentOffset,
              ),
            ),
          ),
        ),
    ),
  );

  playerOffsetIsValid$: Observable<boolean> = this.playerOffset$.pipe(
    switchMap(offset =>
      this.store.select($currentVideo).pipe(
        map(video => {
          if (offset?.start === undefined || offset?.end === undefined) {
            return false;
          }

          const start = Number(offset.start);
          const end = Number(offset.end);

          if (end < 0 || start > video.trueDuration || end - start <= 0) {
            return false;
          }

          return true;
        }),
      ),
    ),
  );

  playlist$: Observable<Playlist | null> = this.store.pipe(
    select($currentPlaylist),
  );
  annotations$: Observable<Annotation[]> = this.store.pipe(
    select($currentPlaylistAnotations),
  );
  currentVideo$: Observable<CurrentVideoModel> = this.store.pipe(
    select($currentVideo),
  );
  currentVideoStream$ = this.store.pipe(select($currentSignedUrl));
  currentVideoLoading$ = this.store.pipe(select($currentVideoLoading));

  currentAnnotation$: Observable<Annotation> = this.store.pipe(
    select($currentPlaylistsAnnotation),
    filter((annotation): annotation is Annotation => annotation !== null),
  );

  recordingVideos$: Observable<Video[]> = this.store.pipe(
    select($currentPlaylistsRecordingVideos),
  );
  calcAnnotationValue$ = this.store.pipe(
    select($currentPlaylistAnnotationValue),
  );
  trimmingOffset$: Observable<TrimmingInputModel> = this.newCurrentOffset$.pipe(
    switchMap((newCurrentOffset: OffsetModel) =>
      this.currentOffset$.pipe(
        map((currentOffset: OffsetModel) => [newCurrentOffset, currentOffset]),
      ),
    ),
    map(([newCurrentOffset, currentOffset]: [OffsetModel, OffsetModel]) =>
      newCurrentOffset.start != null && newCurrentOffset.end != null
        ? newCurrentOffset
        : currentOffset,
    ),
    switchMap(offset =>
      this.calcAnnotationValue$.pipe(
        filter(
          (calcAnnotationValue): calcAnnotationValue is TrimmingInputModel =>
            !!calcAnnotationValue,
        ),
        map(
          (calcAnnotationValue): TrimmingInputModel => ({
            ...calcAnnotationValue,
            tagStart: offset.start!,
            tagEnd: offset.end!,
          }),
        ),
      ),
    ),
  );

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly store: Store<AppState>,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  deleteTag(tag: Annotation): void {
    this.playlist$.pipe(take(1)).subscribe(currentPlaylist => {
      if (currentPlaylist) {
        this.store.dispatch(
          removeFromPlaylistRequestAction({
            annotationId: tag.id,
            annotationName: tag.name,
            playlistId: currentPlaylist.id,
            playlistName: currentPlaylist.name,
          }),
        );
      }
      this.currentAnnotation$
        .pipe(
          take(1),
          filter(annotation => annotation.id === tag.id),
        )
        .subscribe(() => {
          const annotations = (currentPlaylist?.annotations ?? []).filter(
            a => a.id != tag.id,
          );
          if (annotations.length > 0) {
            this.router.navigate(
              [RoutePath.Platform, RoutePath.Playlists, RoutePath.Clip],
              {
                replaceUrl: true,
                queryParams: {
                  [QueryParamNames.PlaylistId]: currentPlaylist?.id,
                  [QueryParamNames.AnnotationId]: annotations[0].id,
                },
              },
            );
          } else {
            this.router.navigate([RoutePath.Platform, RoutePath.Playlists], {
              replaceUrl: true,
            });
          }
        });
    });
  }

  selectTag(id: string): void {
    this.currentAnnotation$
      .pipe(
        take(1),
        filter(annotation => annotation?.id !== id),
      )
      .subscribe(() => {
        if (this.videoPlayer) {
          this.shouldAutoplay = this.videoPlayer.player.api
            .getDefaultMedia()
            .state.includes('playing');
          this.jumpToStart = true;
        }

        this.calcAnnotationValue = undefined;
        this.router.navigate(
          [RoutePath.Platform, RoutePath.Playlists, RoutePath.Clip],
          {
            queryParams: {
              [QueryParamNames.PlaylistId]:
                this.route.snapshot.queryParams.playlistId,
              [QueryParamNames.AnnotationId]: id,
            },
          },
        );
      });
  }

  cancelTrim(): void {
    this.currentAnnotation$.pipe(take(1)).subscribe(annotation => {
      this.router.navigate(
        [RoutePath.Platform, RoutePath.Playlists, RoutePath.Clip],
        {
          queryParams: {
            [QueryParamNames.AnnotationId]: annotation.id,
            [QueryParamNames.PlaylistId]:
              this.route.snapshot.queryParams.playlistId,
          },
        },
      );
    });
  }

  changeSrc(
    {
      currentVideo,
      video,
    }: {
      currentVideo: CurrentVideoModel;
      video: Video;
    },
    inFullscreen = false,
  ): void {
    if (currentVideo.id === video.id) {
      return;
    }

    const prevVideoTime = this.videoPlayer
      ? this.videoPlayer.player.getCurrentTime()
      : 0;

    if (this.videoPlayer) {
      this.shouldAutoplay = this.videoPlayer.player.api
        .getDefaultMedia()
        .state.includes('playing');
      this.jumpToStart = false;
    }

    this.store.dispatch(changeCurrentVideoAction({ video, inFullscreen }));

    if (this.videoPlayer) {
      this.videoPlayer.player.setCurrentTime(
        prevVideoTime + currentVideo.startAt - video.startAt,
      );
    }
  }

  editAnnotation(annotation: Annotation): void {
    this.playlist$
      .pipe(
        take(1),
        filter(currPlaylist => !!currPlaylist && !!currPlaylist.id),
      )
      .subscribe(currPlaylist => {
        this.store.dispatch(
          editAnnotationNameInPlaylistRequestAction({
            playlistId: currPlaylist!.id,
            annotation,
          }),
        );
      });
  }

  removePlaylist(playlist: Playlist): void {
    this.store.dispatch(deletePlaylistRequestAction({ playlist }));
    this.router.navigate([RoutePath.Platform, RoutePath.Playlists]);
  }

  renderPlaylist(id: string): void {
    this.store.dispatch(renderPlaylistRequestAction({ id }));
  }

  editPlaylist(playlist: Playlist): void {
    this.store.dispatch(
      putPlaylistRequestAction({
        playlist,
      }),
    );
  }

  redirectToTrimming(id: string): void {
    this.router.navigate(
      [RoutePath.Platform, RoutePath.Playlists, RoutePath.Clip],
      {
        queryParams: {
          [QueryParamNames.AnnotationId]: id,
          [QueryParamNames.Trimming]: true,
          [QueryParamNames.PlaylistId]:
            this.route.snapshot.queryParams.playlistId,
        },
      },
    );
    this.newCurrentOffset$.next({
      jumpToStart: this.jumpToStart,
      jumpToEnd: this.jumpToEnd,
    });
  }

  trimAnnotation(newValues: { from: number; to: number }): void {
    this.playlist$
      .pipe(
        withLatestFrom(
          this.store.pipe(select($currentVideo)),
          this.currentAnnotation$,
        ),
        take(1),
      )
      .subscribe(
        ([currPlaylist, currentVideo, currentAnnotation]: [
          Playlist | null,
          CurrentVideoModel,
          Annotation,
        ]) => {
          if (currPlaylist && !!currentAnnotation && !!currentVideo) {
            const tagCreated =
              currentAnnotation.timeStamp - currentVideo.startAt;
            const tagStart = tagCreated - currentAnnotation.from;

            const timestamp =
              currentAnnotation.timeStamp +
              (newValues.from - tagStart) -
              currentAnnotation.from;

            const to = newValues.to - newValues.from;

            this.store.dispatch(
              editAnnotationTrimmingInPlaylistAction({
                id: currPlaylist.id,
                body: {
                  id: currentAnnotation.id,
                  name: currentAnnotation.name,
                  from: 0,
                  to,
                  timestamp,
                },
              }),
            );

            this.router.navigate(
              [RoutePath.Platform, RoutePath.Playlists, RoutePath.Clip],
              {
                queryParams: {
                  [QueryParamNames.AnnotationId]: currentAnnotation.id,
                  [QueryParamNames.PlaylistId]:
                    this.route.snapshot.queryParams.playlistId,
                },
              },
            );
          }
        },
      );
  }

  recalculateTag(start: number, end: number): void {
    if (this.newCurrentOffset$.value) {
      this.newCurrentOffset$.next({
        ...this.newCurrentOffset$.value,
        end,
        start,
      });
    }
  }

  seekWithHandleMove(seekTo: SEEK_TO): void {
    if (seekTo === SEEK_TO.START) {
      this.jumpToStart = true;
      this.jumpToEnd = !this.jumpToStart;
    } else {
      this.jumpToEnd = true;
      this.jumpToStart = !this.jumpToEnd;
    }
  }

  shouldPlayNext(shouldPlayNext: boolean): void {
    if (shouldPlayNext) {
      this.store
        .pipe(
          select($nextPlayablePlaylistAnnotationId),
          takeUntil(this.unsubscribe$),
          take(1),
          filter(playableId => !!playableId),
        )
        .subscribe(playableId => {
          this.router
            .navigate(
              [RoutePath.Platform, RoutePath.Playlists, RoutePath.Clip],
              {
                queryParams: {
                  ...this.route.snapshot.queryParams,
                  [QueryParamNames.AnnotationId]: playableId,
                },
              },
            )
            .then(() => {
              this.shouldAutoplay = true;
              this.jumpToStart = true;
              this.videoPlayer.player.play();
            })
            .catch(() => console.error('UNNABLE TO PLAY'));
        });
    }
  }
}
