import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const TRANSLATE_PATH = 'components.manualScheduling.cameraOptions.';

@Pipe({
  name: 'cameraTranslate',
})
export class CameraTranslatePipe implements PipeTransform {
  constructor(private readonly translate: TranslateService) {}

  transform(value: string): string {
    const translateString = `${TRANSLATE_PATH}${this.getTranslateString(
      value,
    )}`;
    const translated = this.translate.instant(translateString);
    return translated !== translateString ? translated : value;
  }

  getTranslateString(value: string): string {
    return value
      .split(' ')
      .reduce(
        (acc, curr, i) =>
          !i ? curr.toLowerCase() : acc + this.capitalizeFirstLetter(curr),
        '',
      );
  }

  capitalizeFirstLetter(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
