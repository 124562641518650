import { Component, ChangeDetectionStrategy } from '@angular/core';
import { RoutePath } from '../app.constants';

@Component({
  selector: 'cmv-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Error404Component {
  constructor() {}

  get routerLink() {
    return ['/', RoutePath.Landing];
  }
}
