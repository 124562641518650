import {
  Component,
  Input,
  ElementRef,
  HostListener,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
} from "@angular/core";
import { Subscription } from "rxjs";
import { VgApiService, VgUtilsService } from "@videogular/ngx-videogular/core";
import { Key } from "ts-key-enum";

@Component({
  selector: "vg-mute",
  encapsulation: ViewEncapsulation.None,
  template: ` <div
    class="icon"
    [class.vg-icon-volume_up]="getVolume() >= 0.75 && !muted()"
    [class.vg-icon-volume_down]="getVolume() >= 0.25 && getVolume() < 0.75"
    [class.vg-icon-volume_mute]="getVolume() > 0 && getVolume() < 0.25"
    [class.vg-icon-volume_off]="getVolume() === 0 || muted()"
    tabindex="0"
    role="button"
    aria-label="mute button"
    [attr.aria-valuetext]="ariaValue"
  ></div>`,
  styles: [
    `
      vg-mute {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: flex;
        justify-content: center;
        height: 50px;
        width: 50px;
        cursor: pointer;
        color: white;
        line-height: 50px;
      }
      vg-mute .icon {
        pointer-events: none;
      }
    `,
  ],
})
export class VgMuteComponent implements OnInit, OnDestroy {
  @Input() vgFor: string;
  elem: HTMLElement;
  target: any;

  currentVolume: number;

  subscriptions: Subscription[] = [];

  ariaValue = "unmuted";

  constructor(ref: ElementRef, public API: VgApiService) {
    this.elem = ref.nativeElement;
  }

  ngOnInit() {
    if (this.API.isPlayerReady) {
      this.onPlayerReady();
    } else {
      this.subscriptions.push(
        this.API.playerReadyEvent.subscribe(() => this.onPlayerReady())
      );
    }
  }

  onPlayerReady() {
    this.target = this.API.getMediaById(this.vgFor);
    this.currentVolume = this.target.volume;
  }

  @HostListener("click")
  onClick() {
    this.changeMuteState();
  }

  @HostListener("document:keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    if (this.API.shortcutsDisabled) {
      return;
    }

    if (event.key.toLowerCase() === "m") {
      event.preventDefault();
      this.changeMuteState();
    }
  }

  changeMuteState() {
    const volume = this.getVolume();

    // iOS doesn't allow to change volume of media via HTML controls
    if (this.target && VgUtilsService.isiOSDevice()) {
      this.target.muted = !this.target.muted;
      return;
    }

    if (volume === 0) {
      if (this.target.volume === 0 && this.currentVolume === 0) {
        this.currentVolume = 1;
      }

      this.target.volume = this.currentVolume;
      if (this.target) {
        this.target.muted = false;
      }
    } else {
      this.currentVolume = volume;
      this.target.volume = 0;
      if (this.target) {
        this.target.muted = true;
      }
    }
  }

  getVolume() {
    const volume = this.target ? this.target.volume : 0;
    this.ariaValue = volume ? "unmuted" : "muted";
    return volume;
  }

  muted(): boolean {
    return this.target ? this.target.muted : false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
