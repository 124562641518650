import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { APIClient } from '../../../../api';
import { AnnotationType } from '../../../../api/models';
import { AppState } from '../../models/app.state';
import {
  changeATPageAction,
  changeATPageLeftAction,
  changeATPageRightAction,
  deleteAnnotationTypeFailureAction,
  deleteAnnotationTypeRequestAction,
  deleteAnnotationTypeSuccessAction,
  getAnnotationTypesFailureAction,
  getAnnotationTypesRequestAction,
  getAnnotationTypesSuccessAction,
  nextATPageAction,
  nextATPageLeftAction,
  nextATPageRightAction,
  patchAnnotationTypeFailureAction,
  patchAnnotationTypeOrderFailureAction,
  patchAnnotationTypeOrderRequestAction,
  patchAnnotationTypeOrderSuccessAction,
  patchAnnotationTypeRequestAction,
  patchAnnotationTypeSuccessAction,
  postAnnotationTypeFailureAction,
  postAnnotationTypeRequestAction,
  postAnnotationTypeSuccessAction,
} from '../actions/annotation-types.actions';
import { handleErrorResponseAction } from '../actions/error.actions';
import { showSnackbarAction } from '../actions/snackbar.actions';
import {
  $annotationTypeById,
  $currentTaggingPage,
  $currentTaggingPageLeft,
  $currentTaggingPageRight,
  $totalTaggingPages,
  $totalVideoTaggingPages,
} from '../selectors/annotation-types.selectors';
import { reportToGA } from 'src/app/app.utils';
import { GoogleAnalyticsEvent } from 'src/app/app.constants';
import { setCurrentTaggingTeamAction } from '../actions/current-selections.actions';

@Injectable()
export class AnnotationTypesEffects {
  getAnnotationTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAnnotationTypesRequestAction),
      switchMap(() =>
        this.apiService
          .call(() => this.apiClient.getAnnotationTypes({}))
          .pipe(
            map(annotationTypes =>
              getAnnotationTypesSuccessAction({ annotationTypes }),
            ),
            catchError(error => {
              const parsedError = {
                type: error.status.toString(),
                message: error.error.msg,
              };

              return of(
                getAnnotationTypesFailureAction({
                  errors: [parsedError],
                }),
                handleErrorResponseAction({
                  errorType: parsedError.type,
                }),
              );
            }),
          ),
      ),
    ),
  );

  deleteAnnotationType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAnnotationTypeRequestAction),
      switchMap(({ id }) =>
        this.apiService
          .call(() => this.apiClient.deleteAnnotationTypesId({ id }))
          .pipe(
            switchMap(() =>
              of(
                deleteAnnotationTypeSuccessAction({ id }),
                showSnackbarAction({
                  infoMessage: 'success.tagButton.delete',
                  icon: 'delete',
                }),
              ),
            ),
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.TAG_PANEL_DELETE);
            }),
            catchError(error =>
              of(
                deleteAnnotationTypeFailureAction({
                  errors: [
                    {
                      type: error.status.toString(),
                      message: error.error.msg,
                    },
                  ],
                }),
                showSnackbarAction({
                  infoMessage: 'error.tagButton.delete',
                  icon: 'delete',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  postAnnotationType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postAnnotationTypeRequestAction),
      switchMap(({ request }) =>
        this.apiService
          .call(() => this.apiClient.postAnnotationTypes({ body: request }))
          .pipe(
            switchMap(annotationType =>
              of(
                postAnnotationTypeSuccessAction({ annotationType }),
                showSnackbarAction({
                  infoMessage: 'success.tagButton.create',
                  icon: 'checkmark',
                }),
              ),
            ),
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.TAG_PANEL_ADD);
            }),
            catchError(error =>
              of(
                postAnnotationTypeFailureAction({
                  errors: [
                    {
                      type: error.status.toString(),
                      message: error.error.msg,
                    },
                  ],
                }),
                showSnackbarAction({
                  infoMessage: 'error.tagButton.create',
                  icon: 'closing',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  patchAnnotationType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patchAnnotationTypeRequestAction),
      switchMap(({ request }) =>
        this.apiService
          .call(() => this.apiClient.patchAnnotationTypes({ body: request }))
          .pipe(
            switchMap(annotationType =>
              of(
                patchAnnotationTypeSuccessAction({ annotationType }),
                showSnackbarAction({
                  infoMessage: 'success.tagButton.edit',
                  icon: 'edit',
                }),
              ),
            ),
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.TAG_PANEL_EDIT);
            }),
            catchError(error =>
              of(
                patchAnnotationTypeFailureAction({
                  errors: [
                    {
                      type: error.status.toString(),
                      message: error.error.msg,
                    },
                  ],
                }),
                showSnackbarAction({
                  infoMessage: 'error.tagButton.edit',
                  icon: 'closing',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  patchAnnotationTypeOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patchAnnotationTypeOrderRequestAction),
      switchMap(({ id, newOrder, oldOrder }) =>
        this.store.pipe(
          select($annotationTypeById(id)),
          take(1),
          map((annotationType: AnnotationType) => ({
            body: {
              id: annotationType.id,
              color: annotationType.color || '',
              preroll: annotationType.preroll,
              duration: annotationType.duration,
              name: annotationType.name,
              order: newOrder,
            },
            newOrder,
            oldOrder,
          })),
        ),
      ),
      switchMap(combined =>
        this.apiService
          .call(() =>
            this.apiClient.patchAnnotationTypes({ body: combined.body }),
          )
          .pipe(
            switchMap(response =>
              of(
                patchAnnotationTypeOrderSuccessAction({
                  response,
                  newOrder: combined.newOrder,
                  oldOrder: combined.oldOrder,
                }),
                showSnackbarAction({
                  infoMessage: 'success.tagButton.edit',
                  icon: 'edit',
                }),
              ),
            ),
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.TAG_PANEL_MOVE);
            }),
            catchError(error =>
              of(
                patchAnnotationTypeOrderFailureAction({
                  errors: [
                    {
                      type: error.status.toString(),
                      message: error.error.msg,
                    },
                  ],
                }),
                showSnackbarAction({
                  infoMessage: 'error.tagButton.edit',
                  icon: 'closing',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  nextATPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(nextATPageAction),
      withLatestFrom(
        this.store.select($totalTaggingPages),
        this.store.select($currentTaggingPage),
      ),
      switchMap(([_, totalPages, currentPage]) => {
        const newPage =
          currentPage + 1 < totalPages ? currentPage + 1 : totalPages - 1;
        return of(changeATPageAction({ page: newPage }));
      }),
    ),
  );

  nextATPageLeftAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(nextATPageLeftAction),
      withLatestFrom(
        this.store.select($totalVideoTaggingPages),
        this.store.select($currentTaggingPageLeft),
      ),
      switchMap(([_, totalPages, currentPage]) => {
        const newPage =
          currentPage + 1 < totalPages ? currentPage + 1 : totalPages - 1;
        return of(changeATPageLeftAction({ page: newPage }));
      }),
    ),
  );

  nextATPageRightAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(nextATPageRightAction),
      withLatestFrom(
        this.store.select($totalVideoTaggingPages),
        this.store.select($currentTaggingPageRight),
      ),
      switchMap(([_, totalPages, currentPage]) => {
        const newPage =
          currentPage + 1 < totalPages ? currentPage + 1 : totalPages - 1;
        return of(changeATPageRightAction({ page: newPage }));
      }),
    ),
  );

  handleTaggingTeamChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCurrentTaggingTeamAction),
      withLatestFrom(
        this.store.select($currentTaggingPage),
        this.store.select($totalTaggingPages),
      ),
      switchMap(([_, currentPage, totalPages]) => {
        const newPage = currentPage < totalPages ? currentPage : totalPages - 1;
        return of(changeATPageAction({ page: newPage }));
      }),
    ),
  );

  handleTaggingTeamChangeLeft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCurrentTaggingTeamAction),
      withLatestFrom(
        this.store.select($currentTaggingPageLeft),
        this.store.select($totalVideoTaggingPages),
      ),
      switchMap(([_, currentPage, totalPages]) => {
        const newPage = currentPage < totalPages ? currentPage : totalPages - 1;
        return of(changeATPageLeftAction({ page: newPage }));
      }),
    ),
  );

  handleTaggingTeamChangeRight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCurrentTaggingTeamAction),
      withLatestFrom(
        this.store.select($currentTaggingPageRight),
        this.store.select($totalTaggingPages),
      ),
      switchMap(([_, currentPage, totalPages]) => {
        const newPage = currentPage < totalPages ? currentPage : totalPages - 1;
        return of(changeATPageRightAction({ page: newPage }));
      }),
    ),
  );

  // TODO Check current pages when changing tagging team

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly apiClient: APIClient,
    private readonly apiService: ApiService,
  ) {}
}
