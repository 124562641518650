<div class="cmv-checkbox-container" (click)="toggleCheckbox()">
  <div class="cmv-h2 checkbox-label">{{ label }}</div>
  <div class="checkbox">
    <mat-icon
      class="cmv-dim-gray-icon"
      svgIcon="cmv-checkmark"
      *ngIf="checked"
    ></mat-icon>
  </div>
</div>
