<div class="ptz-wrapper">
  <div class="ptz-controls-buttons">
    <button class="ptz-controls-button cmv-h5" (click)="close()">
      <mat-icon
        class="cmv-white-icon cmv-drawing-button-icon"
        svgIcon="cmv-closing"
      ></mat-icon>
    </button>
  </div>

  <div class="ptz-grid" *ngIf="ptzActiveView$ | async as ptzActiveView">
    <div
      id="ptz-selection-automatic"
      class="ptz-selection"
      [class.selected]="ptzActiveView.manual === false"
      (click)="setView(false)"
    >
      {{ 'components.ptz.automatic' | translate }}
    </div>
    <div
      id="ptz-selection-left-far-corner"
      class="ptz-selection"
      [class.selected]="
        ptzActiveView.manual === true &&
        ptzActiveView.view === ptzView.LeftFarCorner
      "
      (click)="setView(true, ptzView.LeftFarCorner)"
    >
      {{ 'components.ptz.corner' | translate }}
    </div>
    <div
      id="ptz-selection-left-goal"
      class="ptz-selection"
      [class.selected]="
        ptzActiveView.manual === true && ptzActiveView.view === ptzView.LeftGoal
      "
      (click)="setView(true, ptzView.LeftGoal)"
    >
      {{ 'components.ptz.goal' | translate }}
    </div>
    <div
      id="ptz-selection-left-near-corner"
      class="ptz-selection"
      [class.selected]="
        ptzActiveView.manual === true &&
        ptzActiveView.view === ptzView.LeftNearCorner
      "
      (click)="setView(true, ptzView.LeftNearCorner)"
    >
      {{ 'components.ptz.corner' | translate }}
    </div>
    <div
      id="ptz-selection-left-side"
      class="ptz-selection"
      [class.selected]="
        ptzActiveView.manual === true && ptzActiveView.view === ptzView.LeftSide
      "
      (click)="setView(true, ptzView.LeftSide)"
    >
      {{ 'components.ptz.side' | translate }}
    </div>
    <div
      id="ptz-selection-far-center"
      class="ptz-selection"
      [class.selected]="
        ptzActiveView.manual === true &&
        ptzActiveView.view === ptzView.FarCenter
      "
      (click)="setView(true, ptzView.FarCenter)"
    >
      {{ 'components.ptz.center' | translate }}
    </div>
    <div
      id="ptz-selection-goal-to-goal"
      class="ptz-selection"
      [class.selected]="
        ptzActiveView.manual === true && ptzActiveView.view === ptzView.Home
      "
      (click)="setView(true, ptzView.Home)"
    >
      {{ 'components.ptz.goalToGoal' | translate }}
    </div>
    <div
      id="ptz-selection-near-center"
      class="ptz-selection"
      [class.selected]="
        ptzActiveView.manual === true &&
        ptzActiveView.view === ptzView.NearCenter
      "
      (click)="setView(true, ptzView.NearCenter)"
    >
      {{ 'components.ptz.center' | translate }}
    </div>
    <div
      id="ptz-selection-right-side"
      class="ptz-selection"
      [class.selected]="
        ptzActiveView.manual === true &&
        ptzActiveView.view === ptzView.RightSide
      "
      (click)="setView(true, ptzView.RightSide)"
    >
      {{ 'components.ptz.side' | translate }}
    </div>
    <div
      id="ptz-selection-right-far-corner"
      class="ptz-selection"
      [class.selected]="
        ptzActiveView.manual === true &&
        ptzActiveView.view === ptzView.RightFarCorner
      "
      (click)="setView(true, ptzView.RightFarCorner)"
    >
      {{ 'components.ptz.corner' | translate }}
    </div>
    <div
      id="ptz-selection-right-goal"
      class="ptz-selection"
      [class.selected]="
        ptzActiveView.manual === true &&
        ptzActiveView.view === ptzView.RightGoal
      "
      (click)="setView(true, ptzView.RightGoal)"
    >
      {{ 'components.ptz.goal' | translate }}
    </div>
    <div
      id="ptz-selection-right-near-corner"
      class="ptz-selection"
      [class.selected]="
        ptzActiveView.manual === true &&
        ptzActiveView.view === ptzView.RightNearCorner
      "
      (click)="setView(true, ptzView.RightNearCorner)"
    >
      {{ 'components.ptz.corner' | translate }}
    </div>
    <div
      *ngIf="ptzSetStatusbarValue$ | async as value"
      class="ptz-progressInfo"
    >
      <mat-progress-bar mode="determinate" [value]="value"></mat-progress-bar>
      <span>
        {{ 'components.ptz.progressInfo' | translate }}
      </span>
    </div>
  </div>
</div>
