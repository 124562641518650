<div class="privacy-wrap">
  <div [routerLink]="'/'" class="privacy-logo"></div>
  <cmv-default-privacy-cookie-policy></cmv-default-privacy-cookie-policy>
</div>

<div class="cmv-body privacy-footer">
  <a class="first-footer-link" [routerLink]="termsRouterLink">
    {{ 'components.landingPage.termsOfUse' | translate }}
  </a>
  <a [routerLink]="privacyRouterLink">
    {{ 'components.landingPage.privacy' | translate }}
  </a>
</div>
