<vg-player
  class="vg-player"
  id="vgPlayer"
  (click)="hideOptions()"
  (onPlayerReady)="onPlayerReady($event)"
  #vgPlayerEl
>
  <cmv-video-controls
    *ngIf="!loading"
    class="tagging-panel-controls"
    [vgAutohide]="(taggingPanelRolledUp$ | async) === false"
  >
    <cmv-video-tagging-panel
      *ngIf="taggingFullscreenAndFakeFullscreen$ | async"
      class="tagging-panel"
      [ngClass]="{
        'tagging-panel-controls-hidden': (taggingPanelRolledUp$ | async),
      }"
      (tagCreated)="createTag($event, true)"
    ></cmv-video-tagging-panel>
  </cmv-video-controls>
  <cmv-snackbar
    *ngIf="isFullscreen() || (isFakeFullscreenActive$ | async)"
  ></cmv-snackbar>
  <vg-buffering *ngIf="seeking$ | async"></vg-buffering>

  <vg-controls
    *ngIf="!loading"
    [vgAutohide]="true"
    class="controls scrub-control"
  >
    <vg-scrub-bar class="scrub-bar">
      <vg-scrub-bar-current-time
        class="current-time"
        [vgSlider]="true"
      ></vg-scrub-bar-current-time>
    </vg-scrub-bar>
  </vg-controls>
  <vg-controls
    class="player-jump-container"
    [ngClass]="{
      'player-jump-container-tagging':
        taggingFullscreenAndFakeFullscreen$ | async,
    }"
    [@rollIt]="
      (taggingFullscreenAndFakeFullscreen$ | async)
        ? (taggingPanelRolledUp$ | async)
          ? videoControlsAnimationStates.ROLLED_UP
          : videoControlsAnimationStates.ROLLED_DOWN
        : videoControlsAnimationStates.DEFAULT
    "
    [vgAutohide]="true"
    *ngIf="
      !loading &&
      isPlayerReady &&
      (drawingOverlayVisible$ | async) === false &&
      (ptzOverlayVisible$ | async) === false
    "
  >
    <div class="player-jump">
      <div class="player-jump-left">
        <div class="player-jump-button-container">
          <div
            class="player-jump-button player-jump-button-double"
            (click)="
              jumpTo(TIME_SLOTS.FIVE_MINUTES, false); $event.stopPropagation()
            "
            [ngClass]="{ active: canJump(TIME_SLOTS.FIVE_MINUTES, false) }"
            title="{{ 'components.videoPlayer.jumpFiveMinutes' | translate }}"
          >
            <mat-icon
              class="cmv-white-icon"
              svgIcon="cmv-arrow-left"
            ></mat-icon>
            <mat-icon
              class="cmv-white-icon"
              svgIcon="cmv-arrow-left"
            ></mat-icon>
            <mat-icon
              class="cmv-white-icon"
              svgIcon="cmv-arrow-left"
            ></mat-icon>
          </div>
          <div class="player-jump-button-label">
            {{ 'components.videoPlayer.jumpFiveMinutes' | translate }}
          </div>
        </div>

        <div class="player-jump-button-container">
          <div
            class="player-jump-button player-jump-button-double"
            (click)="
              jumpTo(TIME_SLOTS.TEN_SECONDS, false); $event.stopPropagation()
            "
            [ngClass]="{ active: canJump(TIME_SLOTS.TEN_SECONDS, false) }"
            title="{{ 'components.videoPlayer.jumpTenSeconds' | translate }}"
          >
            <mat-icon
              class="cmv-white-icon"
              svgIcon="cmv-arrow-left"
            ></mat-icon>
            <mat-icon
              class="cmv-white-icon"
              svgIcon="cmv-arrow-left"
            ></mat-icon>
          </div>
          <div class="player-jump-button-label">
            {{ 'components.videoPlayer.jumpTenSeconds' | translate }}
          </div>
        </div>

        <div class="player-jump-button-container">
          <div
            class="player-jump-button player-jump-button-left"
            (click)="jumpTo(TIME_SLOTS.FRAME, false); $event.stopPropagation()"
            [ngClass]="{ active: canJump(TIME_SLOTS.FRAME, false) }"
            title="{{ 'components.videoPlayer.jumpOneFrame' | translate }}"
          >
            <mat-icon
              class="cmv-white-icon"
              svgIcon="cmv-arrow-left"
            ></mat-icon>
          </div>
          <div class="player-jump-button-label">
            {{ 'components.videoPlayer.jumpOneFrame' | translate }}
          </div>
        </div>
      </div>

      <div
        class="player-jump-button play-button-container"
        (click)="togglePlay()"
      >
        <mat-icon
          class="cmv-white-icon play-button-icon"
          [svgIcon]="isPlaying() ? 'cmv-pause' : 'cmv-play'"
        ></mat-icon>
      </div>

      <div class="player-jump-right">
        <div class="player-jump-button-container">
          <div
            class="player-jump-button"
            (click)="jumpTo(TIME_SLOTS.FRAME, true); $event.stopPropagation()"
            [ngClass]="{ active: canJump(TIME_SLOTS.FRAME, true) }"
            title="{{ 'components.videoPlayer.jumpOneFrame' | translate }}"
          >
            <mat-icon
              class="cmv-white-icon"
              svgIcon="cmv-arrow-right"
            ></mat-icon>
          </div>
          <div class="player-jump-button-label">
            {{ 'components.videoPlayer.jumpOneFrame' | translate }}
          </div>
        </div>

        <div class="player-jump-button-container">
          <div
            class="player-jump-button player-jump-button-double"
            (click)="
              jumpTo(TIME_SLOTS.TEN_SECONDS, true); $event.stopPropagation()
            "
            [ngClass]="{ active: canJump(TIME_SLOTS.TEN_SECONDS, true) }"
            title="{{ 'components.videoPlayer.jumpTenSeconds' | translate }}"
          >
            <mat-icon
              class="cmv-white-icon"
              svgIcon="cmv-arrow-right"
            ></mat-icon>
            <mat-icon
              class="cmv-white-icon"
              svgIcon="cmv-arrow-right"
            ></mat-icon>
          </div>
          <div class="player-jump-button-label">
            {{ 'components.videoPlayer.jumpTenSeconds' | translate }}
          </div>
        </div>

        <div class="player-jump-button-container">
          <div
            class="player-jump-button player-jump-button-double"
            (click)="
              jumpTo(TIME_SLOTS.FIVE_MINUTES, true); $event.stopPropagation()
            "
            [ngClass]="{ active: canJump(TIME_SLOTS.FIVE_MINUTES, true) }"
            title="{{ 'components.videoPlayer.jumpFiveMinutes' | translate }}"
          >
            <mat-icon
              class="cmv-white-icon"
              svgIcon="cmv-arrow-right"
            ></mat-icon>
            <mat-icon
              class="cmv-white-icon"
              svgIcon="cmv-arrow-right"
            ></mat-icon>
            <mat-icon
              class="cmv-white-icon"
              svgIcon="cmv-arrow-right"
            ></mat-icon>
          </div>
          <div class="player-jump-button-label">
            {{ 'components.videoPlayer.jumpFiveMinutes' | translate }}
          </div>
        </div>
      </div>
    </div>
  </vg-controls>

  <video
    [vgMedia]="media"
    [vgHls]="videoUrl"
    #media
    playsinline
    [autoplay]="autoplay"
    [id]="videoElementId"
    class="video"
    id="video"
    preload="auto"
    crossorigin="use-credentials"
    [vgOffset]="offset"
    (error)="videoError()"
  ></video>

  <vg-controls
    *ngIf="!loading"
    class="controls player-controls player-panel"
    [vgAutohide]="true"
  >
    <div class="media-control">
      <vg-play-pause class="player-play"></vg-play-pause>
      <vg-mute class="mute" *ngIf="!isIOS()"></vg-mute>
      <div class="volume-wrap" *ngIf="!isIOS()">
        <vg-volume class="volume"></vg-volume>
      </div>
      <div class="player-time" [class.is-ios]="isIOS()">
        <vg-time-display
          class="time-display"
          vgFormat="mmm:ss"
          vgProperty="current"
        ></vg-time-display>
        <div class="separator">/</div>
        <vg-time-display
          class="time-display"
          vgFormat="mmm:ss"
          vgProperty="total"
        ></vg-time-display>
      </div>
    </div>
    <div class="player-controls--right">
      <div
        class="fullscreen speed-container"
        *ngIf="
          enableChangeVideoSource &&
          ((isFullscreen$ | async) || (isFakeFullscreen$ | async))
        "
      >
        <div
          class="source-button"
          [ngClass]="{ shown: changeVideoSourceShown }"
          (click)="toggleChangeVideoSource($event)"
        >
          <div
            class="source-button-options-container"
            [ngClass]="{ shown: changeVideoSourceShown }"
          >
            <div
              class="source-button-option"
              *ngFor="let video of videoSources"
              (click)="setVideoSource(video)"
            >
              <div class="source-button-option--text">
                {{
                  ('components.playedMatchTagging.feed.' + video.name
                    | translate) !==
                  'components.playedMatchTagging.feed.' + video.name
                    ? ('components.playedMatchTagging.feed.' + video.name
                      | translate)
                    : ('components.playedMatchTagging.feed.watchFeed'
                      | translate: { videoType: video.name })
                }}
              </div>
              <div class="source-button-option--checkmark-wrapper">
                <mat-icon
                  *ngIf="video.id === currentVideo.id"
                  class="source-button-option--checkmark"
                  svgIcon="cmv-checkmark"
                ></mat-icon>
              </div>
            </div>
          </div>
          <mat-icon class="source-button-icon" svgIcon="cmv-camera"></mat-icon>
        </div>
      </div>
      <div class="fullscreen speed-container">
        <div
          class="speed-button"
          [ngClass]="{ shown: playbackRateShown }"
          (click)="toggleOptions($event)"
        >
          <div
            class="speed-button-options-container"
            [ngClass]="{ shown: playbackRateShown }"
          >
            <div
              *ngFor="let option of SpeedRateOptions"
              class="speed-button-option"
              (click)="setPlaybackRate(option)"
            >
              {{ option }}x
              <mat-icon
                *ngIf="playbackRate === option"
                class="speed-rate-icon"
                svgIcon="cmv-checkmark"
              ></mat-icon>
            </div>
          </div>
          <mat-icon
            class="speed-button-icon"
            svgIcon="cmv-stopwatch"
          ></mat-icon>
          <span class="speed-button-label">x{{ playbackRate }}</span>
        </div>
      </div>
      <vg-fullscreen
        class="fullscreen"
        [customFullscreen]="(isEnabledNativeFullscreen$ | async) === false"
      ></vg-fullscreen>
    </div>
  </vg-controls>
  <vg-controls
    class="cmv-video-overlay"
    [vgAutohide]="true"
    *ngIf="
      !loading &&
      (drawingOverlayVisible$ | async) === false &&
      (ptzOverlayVisible$ | async) === false
    "
  >
    <div class="overlay-controls-buttons" *ngIf="premiumUser$ | async">
      <button
        class="overlay-controls-button"
        (click)="openDrawing(); $event.stopPropagation()"
      >
        <mat-icon
          class="cmv-white-icon overlay-button-icon"
          svgIcon="cmv-edit"
        ></mat-icon>
      </button>
      <button
        *ngIf="ptzEnabled$ | async"
        class="overlay-controls-button"
        (click)="openPtz(); $event.stopPropagation()"
      >
        <mat-icon
          class="cmv-white-icon overlay-button-icon"
          svgIcon="cmv-field"
        ></mat-icon>
      </button>
    </div>
  </vg-controls>
  <cmv-drawing
    class="drawing"
    [videoElementId]="videoElementId"
    *ngIf="!loading && drawingOverlayVisible$ | async"
  ></cmv-drawing>
  <cmv-ptz
    class="ptz"
    *ngIf="!loading && ptzOverlayVisible$ | async"
    [recordingId]="currentVideo.recordingId"
    [source]="currentVideo.name"
  ></cmv-ptz>
  <div
    class="ios-pwa-fullscreen-warning-wrap"
    *ngIf="!loading && showIOSPWAWarning$ | async"
  >
    <div class="ios-pwa-fullscreen-warning">
      {{ 'components.videoPlayer.iOSPWAFullscreenWarning' | translate }}
      <span
        class="ios-pwa-fullscreen-warning-close"
        (click)="closeIOSPWAFullscreenWarning()"
      >
        <mat-icon svgIcon="cmv-closing"></mat-icon>
      </span>
    </div>
  </div>
</vg-player>
