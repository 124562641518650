<div class="header-wrapper">
  <cmv-header
    [title]="'components.moreView.trash.tabTitle' | translate"
    [actionButtonEnabled]="true"
    [actionButtonIcon]="'cmv-recycle'"
    (actionButtonClicked)="emptyTrash()"
  ></cmv-header>
</div>

<div
  *ngIf="(trashLoaded$ | async) && (recordersLoaded$ | async); else loading"
  class="more-content-html-elements-override"
>
  <div class="cmv-body description">
    {{ 'components.moreView.trash.description' | translate }}
  </div>

  <div *ngIf="recordingsInTrash$ | async as recordingsInTrash">
    <div *ngFor="let recorder of recorders$ | async">
      <div class="recorder">
        <div class="recorder--header">
          <div class="cmv-h1 recorder--name">
            {{ recorder.name }}
          </div>
          <div class="recorder--visualizer">
            <cmv-free-space-visualizer
              [used]="recorder.duration"
              [limit]="recorder.durationLimit"
            ></cmv-free-space-visualizer>
          </div>
        </div>

        <div class="recorder--matches" *ngIf="recordingsInTrash[recorder.id]">
          <cmv-match-day
            *ngFor="
              let matchDay of sortDates(recordingsInTrash[recorder.id])
                | keyvalue
            "
            class="match-day"
            [matchDay]="matchDay.value"
            [isInUserTrash]="true"
            [isNavigationEnabled]="false"
          ></cmv-match-day>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <cmv-loading-state></cmv-loading-state>
</ng-template>
