<div class="trimming-panel-wrap">
  <div class="trimming-bar">
    <div class="value-filter-wrapper">
      <div class="slider-content">
        <div class="static-baseline" (tap)="onBaselineTap($event)" #baseline>
          <div
            [style.width]="rangeWidth"
            [style.left]="leftHandlePosition"
            class="dynamic-range"
            (panstart)="onDragStart($event)"
            (panmove)="onDrag($event)"
            #range
          ></div>
          <div class="handle-wrapper" [style.left]="leftHandlePosition">
            <div class="handle-spacer"></div>
            <div
              [style.left]="leftHandlePosition"
              (panstart)="onHandlePanStart('left')"
              (panmove)="onHandlePanMove($event)"
              (dragstart)="$event.preventDefault()"
              class="handle left"
              #handleLeft
            >
              <mat-icon
                class="cmv-white-icon trimming-arrow"
                svgIcon="cmv-arrow-left"
              ></mat-icon>
            </div>
            <div class="cmv-h5 time-value left">
              {{ getFormatedTimeFromVideoPosition(handlePositions.leftHandle) }}
            </div>
          </div>
          <div
            class="handle-wrapper"
            [ngStyle]="{ left: 'calc(' + rightHandlePosition + ' - 2px)' }"
          >
            <div class="handle-spacer"></div>
            <div
              (panstart)="onHandlePanStart('right')"
              (panmove)="onHandlePanMove($event)"
              (dragstart)="$event.preventDefault()"
              class="handle right"
              #handleRight
            >
              <mat-icon
                class="cmv-white-icon trimming-arrow"
                svgIcon="cmv-arrow-right"
              ></mat-icon>
            </div>
            <div class="cmv-h5 time-value right">
              {{
                getFormatedTimeFromVideoPosition(handlePositions.rightHandle)
              }}
            </div>
          </div>
          <div class="baseline-separator-wrapper">
            <div
              class="vertical-separator"
              *ngFor="let separator of baselineSeparatorCount"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="trimming-controls">
    <div class="trimming-buttons">
      <div class="trimming-button-container">
        <div class="trimming-button" (click)="changeFromValue(-skipDuration)">
          <mat-icon class="cmv-white-icon" svgIcon="cmv-plus"></mat-icon>
        </div>
        <div class="cmv-h5 trimming-button-label">
          {{ skipDuration }}
          {{ 'components.trimPage.trimming.seconds' | translate }}
        </div>
      </div>
      <div class="trimming-button-container">
        <div class="trimming-button" (click)="changeFromValue(skipDuration)">
          <mat-icon class="cmv-white-icon" svgIcon="cmv-minus"></mat-icon>
        </div>
        <div class="cmv-h5 trimming-button-label">
          {{ skipDuration }}
          {{ 'components.trimPage.trimming.seconds' | translate }}
        </div>
      </div>
    </div>
    <div class="trimming-info">
      <div class="cmv-h5 description">
        {{ 'components.trimPage.trimming.tagLength' | translate }}
      </div>
      <div class="cmv-h5 value">
        {{
          getDuration(handlePositions.rightHandle - handlePositions.leftHandle)
        }}
      </div>
    </div>
    <div class="trimming-buttons">
      <div class="trimming-button-container">
        <div class="trimming-button" (click)="changeToValue(-skipDuration)">
          <mat-icon class="cmv-white-icon" svgIcon="cmv-minus"></mat-icon>
        </div>
        <div class="cmv-h5 trimming-button-label">
          {{ skipDuration }}
          {{ 'components.trimPage.trimming.seconds' | translate }}
        </div>
      </div>
      <div class="trimming-button-container">
        <div class="trimming-button" (click)="changeToValue(skipDuration)">
          <mat-icon class="cmv-white-icon" svgIcon="cmv-plus"></mat-icon>
        </div>
        <div class="cmv-h5 trimming-button-label">
          {{ skipDuration }}
          {{ 'components.trimPage.trimming.seconds' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="action-buttons">
    <div class="cmv-accept-button" (click)="onSaveChanges()">
      <span class="cmv-h4">
        {{ 'components.trimPage.trimming.saveChanges' | translate }}
      </span>
    </div>
    <div class="cmv-decline-button" (click)="onCancel()">
      <span class="cmv-h4">
        {{ 'components.trimPage.trimming.cancel' | translate }}
      </span>
    </div>
  </div>
</div>
