<div
  tabindex="0"
  #elementToFocus
  class="cmv-modal-outer-card empty-trash-modal-mobile"
>
  <div class="cmv-modal-inner-card cmv-modal-remove">
    <div class="cmv-modal-close-button" (click)="closeModal(false)">
      <mat-icon
        class="modal-icon-closing cmv-dim-gray"
        svgIcon="cmv-closing"
      ></mat-icon>
    </div>

    <div class="main-info">
      <div class="icon-wrapper">
        <mat-icon
          class="modal-icon cmv-gray-icon"
          svgIcon="cmv-recycle"
        ></mat-icon>
      </div>
      <div class="cmv-h1">
        {{ 'components.modals.emptyTrashModal.title' | translate }}
      </div>
      <div class="cmv-body subtitle">
        {{ 'components.modals.emptyTrashModal.subtitle' | translate }}
      </div>
    </div>
    <div class="buttons">
      <div class="cmv-accept-button" (click)="closeModal(true)">
        <div class="cmv-h4">{{ 'basic.ok' | translate }}</div>
      </div>
      <div class="cmv-decline-button" (click)="closeModal(false)">
        <div class="cmv-h4">
          {{ 'basic.cancel' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
