<cmv-page-layout
  [title]="'basic.playlists' | translate"
  [addButtonEnabled]="true"
  (addButtonClicked)="createNewPlaylist()"
  [loadingResources]="loadingResources$ | async"
>
  <div class="playlist-wrapper" *ngIf="playlists$ | async as playlists">
    <div class="cmv-section-header">
      <div class="cmv-h4">
        {{ playlists.length }}
        {{
          playlists.length === 1
            ? ('basic.playlist' | translate)
            : ('basic.playlists' | translate)
        }}
      </div>
    </div>
    <div class="playlists">
      <div class="empty-playlists" *ngIf="!playlists.length">
        <div class="empty-playlist-image">
          <img src="assets/images/empty_state.svg" />
        </div>
        <div class="empty-playlist-title cmv-h1">
          {{ 'components.playlistCollection.emptyPlaylistTitle' | translate }}
        </div>
        <div class="empty-playlist-subtitle cmv-body">
          {{
            'components.playlistCollection.emptyPlaylistSubtitle' | translate
          }}
        </div>
        <div
          class="cmv-dashed-button empty-playlist-button"
          (click)="createNewPlaylist()"
        >
          <div class="cmv-h4 text">
            {{ 'basic.createNewPlaylist' | translate }}
          </div>
          <mat-icon
            class="button-icon cmv-dim-gray"
            svgIcon="cmv-add"
          ></mat-icon>
        </div>
      </div>
      <div class="playlist" *ngFor="let playlist of playlists">
        <cmv-playlist-title
          (editPlaylist)="editPlaylist($event)"
          (removePlaylist)="removePlaylist($event)"
          (renderPlaylist)="renderPlaylist($event)"
          [playlist]="playlist"
        ></cmv-playlist-title>
      </div>
    </div>
  </div>
</cmv-page-layout>
