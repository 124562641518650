import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoutePath } from './app.constants';
import { LandingComponent } from './landing/landing.component';
import { PlatformComponent } from './platform/platform.component';
import { platformRoutes } from './platform/platform.routes';
import { AuthGuard } from './shared/guards/auth.guard';
import { Error404Component } from './error404/error404.component';
import { PrivacyComponent } from './landing/privacy/privacy.component';
import { TermsComponent } from './landing/terms/terms.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: RoutePath.Landing },
  {
    path: RoutePath.Landing,
    component: LandingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: RoutePath.Privacy,
    component: PrivacyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: RoutePath.Terms,
    component: TermsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: RoutePath.Platform,
    component: PlatformComponent,
    children: [...platformRoutes],
    canActivate: [AuthGuard],
  },
  { path: 'index.html', pathMatch: 'prefix', redirectTo: RoutePath.Landing },
  { path: '**', component: Error404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
