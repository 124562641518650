<div class="header-wrapper">
  <cmv-header
    [title]="'components.moreView.howItWorks.tabTitle' | translate"
  ></cmv-header>
</div>

<div class="more-content-html-elements-override">
  <p class="cmv-h3 more-paragraph">
    {{ 'components.moreView.default.howItWorks' | translate }}
  </p>
</div>
