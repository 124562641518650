import { AnnotationList } from '../../../../api/models';
import { ErrorModel } from '../../models/app.state';
import { createAction, props } from '@ngrx/store';

export const getRecordingsAnnotationListsRequestAction = createAction(
  'GetRecordingsAnnotationListsRequest',
  props<{ recordingId: string; annListId?: string; status?: string }>(),
);
export const getRecordingsAnnotationListsSuccessAction = createAction(
  'GetRecordingsAnnotationListsSuccess',
  props<{ annotationLists: AnnotationList[] }>(),
);
export const getRecordingsAnnotationListsFailureAction = createAction(
  'GetRecordingsAnnotationListsFailure',
  props<{ errors: ErrorModel[] }>(),
);
export const refreshRecordingsAnnotationListRequestAction = createAction(
  'RefreshRecordingsAnnotationListRequestAction',
  props<{ recordingId: string; annListId?: string; status?: string }>(),
);
export const refreshAnnotationListRequestAction = createAction(
  'AnnotationListRequest',
  props<{ annotationListId: string; status: string }>(),
);
export const addAnnotationToList = createAction(
  'AddAnnotationToList',
  props<{ id: string; annotationId: string }>(),
);

export const downloadAnnotationsListRequestAction = createAction(
  'DownloadAnnotationsListRequest',
  props<{ id: string }>(),
);
export const downloadAnnotationsListSuccessAction = createAction(
  'DownloadAnnotationsListSuccess',
  props<{ annotationsList: AnnotationList }>(),
);
export const downloadAnnotationsListFailureAction = createAction(
  'DownloadAnnotationsListFailure',
);
