<div
  tabindex="0"
  #elementToFocus
  class="cmv-modal-outer-card edit-modal-wrapper"
>
  <div class="cmv-modal-inner-card cmv-modal-edit">
    <div class="cmv-modal-close-button" (click)="closeModal(false)">
      <mat-icon
        class="modal-icon-closing cmv-dim-gray"
        svgIcon="cmv-closing"
      ></mat-icon>
    </div>

    <div class="main-info">
      <div class="main-info-wrapper">
        <div class="icon-wrapper">
          <mat-icon
            class="modal-icon cmv-gray-icon"
            [svgIcon]="icon"
          ></mat-icon>
        </div>
        <div class="cmv-h1 edit-modal-title">{{ headerKey | translate }}</div>
        <div class="cmv-body subtitle subtitle-edit">
          {{ subtitle | translate }}
        </div>
      </div>
      <div class="edit-form">
        <div>
          <mat-form-field class="cmv-input modal-input">
            <mat-label>{{ 'basic.title' | translate }}</mat-label>
            <input matInput [(ngModel)]="title" type="text" maxlength="65" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field
            class="cmv-input modal-input"
            *ngIf="type === 'tagEdit'"
          >
            <mat-label>{{ 'basic.description' | translate }}</mat-label>
            <input matInput [(ngModel)]="description" type="text" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div *ngIf="sharedBy" class="cmv-body-2 share-info">
      {{
        'components.modals.editModal.sharedBy'
          | translate: { userEmail: sharedBy }
      }}
    </div>
    <div class="buttons">
      <div class="cmv-accept-button" (click)="closeModal(true)">
        <div class="cmv-h4">{{ 'basic.ok' | translate }}</div>
      </div>
      <div class="cmv-decline-button" (click)="closeModal(false)">
        <div class="cmv-h4">
          {{ 'basic.cancel' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
