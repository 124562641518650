import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Annotation } from 'src/api/models';
import { TAGS_ANIMATION } from 'src/app/app.utils';
import { AppState } from '../../models/app.state';
import { $trimmingDisabled } from '../../store/selectors/current-selections.selectors';

@Component({
  selector: 'cmv-created-tags',
  templateUrl: './created-tags.component.html',
  styleUrls: ['./created-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: TAGS_ANIMATION,
})
export class CreatedTagsComponent {
  @Input() showEmptyList: boolean = false;
  @Input() annotations: Annotation[] = [];
  @Input() currentAnnotation: Annotation | null = null;
  @Input() fromPlaylistId: string | undefined;
  @Input() hideDownloadTag: boolean = false;
  @Input() trimmingDisabled: boolean = false;

  @Output() deleteTag = new EventEmitter<Annotation>();
  @Output() selectedWidgetIdChange = new EventEmitter<{
    id: string;
    recordingId: string;
    annotation: Annotation;
  }>();
  @Output() trimVideo = new EventEmitter<{ id: string; recordingId: string }>();
  @Output() editAnnotation = new EventEmitter<Annotation>();

  readonly trimmingDisabled$ = this.store.pipe(select($trimmingDisabled));

  constructor(private readonly store: Store<AppState>) {}

  idTrackFn = (_: number, item: Annotation) => item.id;
}
