import { Action, createReducer, on } from '@ngrx/store';
import { SearchType } from '../../../../api/models';
import { FilterSearchState } from '../../models/app.state';
import { FilterSearchFormModel } from '../../models/filter-search-form.model';
import {
  getFilterSearchFailureAction,
  getFilterSearchRequestAction,
  getFilterSearchSuccessAction,
  getFilterSearchTagsFailureAction,
  getFilterSearchTagsSuccessAction,
  resetSearchingAction,
  setFilterSearchOption,
} from '../actions/filter-search.actions';

export const initialSearchFilters: FilterSearchFormModel = {
  text: null,
  type: SearchType.BOTH,
  typeId: null,
  from: null,
  to: null,
};

const initialState: FilterSearchState = {
  loaded: false,
  loading: false,
  errors: [],
  entity: {
    annotations: [],
    recordings: [],
  },
  metadata: {
    tags: [],
    filterOptions: initialSearchFilters,
  },
};

const reducer = createReducer(
  initialState,
  on(getFilterSearchRequestAction, state => ({
    ...state,
    loading: true,
  })),
  on(getFilterSearchSuccessAction, (state, { response }) => ({
    ...state,
    entity: {
      annotations: response.annotations || [],
      recordings: response.recordings || [],
    },
    loading: false,
    loaded: true,
  })),
  on(setFilterSearchOption, (state, { options }) => ({
    ...state,
    metadata: {
      ...state.metadata!,
      filterOptions: {
        ...state.metadata!.filterOptions,
        ...options,
      },
    },
  })),
  on(getFilterSearchTagsSuccessAction, (state, { tags }) => ({
    ...state,
    metadata: {
      ...state.metadata!,
      tags,
    },
  })),
  on(resetSearchingAction, state => ({
    ...state,
    loaded: false,
    entity: {
      annotations: [],
      recordings: [],
    },
    metadata: {
      ...state.metadata!,
      filterOptions: initialSearchFilters,
    },
  })),
  on(
    getFilterSearchFailureAction,
    getFilterSearchTagsFailureAction,
    (state, { errors }) => ({
      ...state,
      errors,
      loaded: true,
      loading: false,
    }),
  ),
);

export function filterSearchReducer(
  state: FilterSearchState,
  action: Action,
): FilterSearchState {
  return reducer(state, action);
}
