<div
  class="page-layout"
  [class]="{ 'hide-header-on-mobile': hideHeaderOnMobile }"
>
  <div class="header-wrapper" *ngIf="(fakeFullscreenActive$ | async) === false">
    <cmv-header
      *ngIf="showHeader"
      [title]="title"
      [subtitle]="subtitle"
      [searchButtonEnabled]="searchButtonEnabled"
      [searchEnabled]="searchBarVisible$ | async"
      [actionButtonEnabled]="addButtonEnabled"
      (actionButtonClicked)="addButtonClicked.emit()"
    ></cmv-header>
  </div>
  <div class="page-content">
    <ng-content
      *ngIf="loadingResources === false; else loadingState"
    ></ng-content>

    <ng-template #loadingState>
      <!-- Loading overlay -->
      <cmv-loading-state [overlay]="true"></cmv-loading-state>
    </ng-template>
  </div>
</div>
