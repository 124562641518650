import { ErrorModel } from '../../models/app.state';
import { Club } from '../../../../api/models';
import { createAction, props } from '@ngrx/store';

export const getClubsRequestAction = createAction('GetClubsRequest');
export const getClubsSuccessAction = createAction(
  'GetClubsSuccess',
  props<{ clubs: Club[] }>(),
);
export const getClubsFailureAction = createAction(
  'GetClubsFailure',
  props<{ errors: ErrorModel[] }>(),
);
