import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppState } from '../models/app.state';
import { select, Store } from '@ngrx/store';
import { $authIdToken } from '../store/selectors/auth.selectors';
import { switchMap, take } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store<AppState>) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (/^(?:\/|.\/)?assets/.test(request.url)) {
      return next.handle(request);
    }

    return this.store.pipe(
      select($authIdToken),
      take(1),
      switchMap(idToken =>
        next.handle(
          request.url.includes('signer')
            ? request.clone({
                setHeaders: { Authorization: idToken },
                withCredentials: true,
              })
            : request.clone({
                setHeaders: { Authorization: idToken },
              }),
        ),
      ),
    );
  }
}
