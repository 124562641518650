import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import moment from 'moment';
import { Store, select } from '@ngrx/store';
import { switchMap, take, tap } from 'rxjs/operators';
import { AppState } from '../../models/app.state';
import { $customer } from '../selectors/customer.selectors';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { DEFAULT_LANG } from 'src/app/app.constants';

@Injectable()
export class CustomerEffects {
  setFavicon$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROOT_EFFECTS_INIT),
        take(1),
        switchMap(() => this.store.pipe(select($customer))),
        tap(customer => {
          document.body.classList.add(customer.themeName);

          const favicon = document.querySelector(
            'link[rel=icon]',
          )! as HTMLLinkElement;

          moment.locale(DEFAULT_LANG);
          this.translate.use(DEFAULT_LANG);
          this.adapter.setLocale(DEFAULT_LANG);
          this.translate.setDefaultLang(DEFAULT_LANG);

          const title = document.querySelector('title')! as HTMLTitleElement;
          favicon.href = customer.faviconUrl; // eslint-disable-line
          title.text = customer.pageTitle; // eslint-disable-line
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly translate: TranslateService,
    private readonly adapter: DateAdapter<moment.Moment>,
  ) {}
}
