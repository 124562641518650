import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { fromEvent, Subject, Observable } from 'rxjs';
import {
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  Annotation,
  AnnotationTeam,
  AnnotationType,
  Recording,
  RecordingType,
} from '../../../../api/models';
import {
  TAGGING_NUMBERS,
  QueryParamNames,
  VALID_EDITABLE_ELEMENTS,
} from '../../../app.constants';
import { AppState } from '../../models/app.state';
import {
  changeATPageAction,
  nextATPageAction,
  prevATPageAction,
} from '../../store/actions/annotation-types.actions';
import {
  $annotationTypesEntities,
  $annotationTypesLoaded,
  $annotationTypesPaged,
  $aTPagesCountAsArray,
  $currentTaggingPage,
  $nextPageDisabled,
  $prevPageDisabled,
  $tagsPerPage,
} from '../../store/selectors/annotation-types.selectors';
import { $annotationIsLoading } from '../../store/selectors/annotation.selectors';
import { $recording } from '../../store/selectors/recording.selectors';
import {
  $currentTaggingTeam,
  $showAwayTaggingTab,
  $showHomeTaggingTab,
} from '../../store/selectors/current-selections.selectors';
import { TaggingTeam } from '../../models/current-selections.model';

@Component({
  selector: 'cmv-tagging-panel',
  templateUrl: './tagging-panel.component.html',
  styleUrls: ['./tagging-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaggingPanelComponent implements OnDestroy {
  @Output() tagCreated = new EventEmitter<Partial<Annotation>>();

  private readonly unsubscribe$ = new Subject<void>();
  annotationTeam = AnnotationTeam;
  tags: AnnotationType[] = [];
  annotationIsLoading$ = this.store.pipe(select($annotationIsLoading));
  annotationTypesLoaded$ = this.store.pipe(select($annotationTypesLoaded));
  match$: Observable<Recording>;
  currentPage$ = this.store.pipe(select($currentTaggingPage));
  totalPages$ = this.store.pipe(select($aTPagesCountAsArray));
  annotationTypesPaged$ = this.store.pipe(select($annotationTypesPaged));
  nextPageDisabled$ = this.store.pipe(select($nextPageDisabled));
  prevPageDisabled$ = this.store.pipe(select($prevPageDisabled));
  currentTaggingTeam$ = this.store.pipe(select($currentTaggingTeam));
  showHomeTaggingTab$ = this.store.pipe(select($showHomeTaggingTab));
  showAwayTaggingTab$ = this.store.pipe(select($showAwayTaggingTab));
  tagsPerPage$ = this.store.pipe(select($tagsPerPage));

  recordingType = RecordingType;
  TaggingTeam = TaggingTeam;

  constructor(
    private readonly store: Store<AppState>,
    private readonly route: ActivatedRoute,
  ) {
    this.match$ = this.route.queryParams.pipe(
      switchMap((params: { [QueryParamNames.RecordingId]: string }) =>
        this.store.pipe(
          select($recording(params[QueryParamNames.RecordingId])),
        ),
      ),
    );
    fromEvent(window, 'keydown')
      .pipe(
        takeUntil(this.unsubscribe$.asObservable()),
        filter(() =>
          document.activeElement
            ? !VALID_EDITABLE_ELEMENTS.some(
                element => element === document.activeElement!.tagName,
              )
            : true,
        ),
        filter((event: KeyboardEvent) =>
          TAGGING_NUMBERS.some(key => key === event.key),
        ),
        tap(event => event.preventDefault()),
        map(event => ({
          index: Number(event.key) + 1,
          team: event.ctrlKey ? AnnotationTeam.AWAY : AnnotationTeam.HOME,
        })),
        withLatestFrom(this.store.pipe(select($annotationTypesEntities))),
        map(([event, annotations]) => {
          const annotation = annotations!.find(a => a.order === event.index);
          return annotation
            ? {
                annotation,
                team: event.team,
              }
            : null;
        }),
        filter(a => a !== null),
      )
      .subscribe(a => {
        this.onCreateTag(a!.annotation, a!.team);
      });
  }

  onCreateTag(selected: AnnotationType, team: AnnotationTeam): void {
    const annotation: Partial<Annotation> = {
      annotationTypeId: selected.id,
      annotationTypeName: selected.name,
      team,
    };

    this.tagCreated.emit(annotation);
  }

  setPage(page: number): void {
    this.store.dispatch(changeATPageAction({ page }));
  }

  selectPreviousPage(): void {
    this.store.dispatch(prevATPageAction());
  }

  selectNextPage(): void {
    this.store.dispatch(nextATPageAction());
  }

  trackByFn(index: number): number {
    return index;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
