import { createAction, props } from '@ngrx/store';
import { Recording } from 'src/api/models';
import { ErrorModel } from '../../models/app.state';

export const refreshTrashRequestAction = createAction('RefreshTrashRequest');
export const refreshTrashSuccessAction = createAction(
  'RefreshTrashSuccess',
  props<{ recordings: Recording[] }>(),
);
export const refreshTrashFailureAction = createAction(
  'RefreshTrashFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const emptyTrashRequestAction = createAction('EmptyTrashRequest');
export const emptyTrashSuccessAction = createAction('EmptyTrashSuccess');
export const emptyTrashFailureAction = createAction(
  'EmptyTrashFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const moveToTrashRequestAction = createAction(
  'MoveToTrashRequest',
  props<{ id: string }>(),
);
export const moveToTrashSuccessAction = createAction(
  'MoveToTrashSuccess',
  props<{ recording: Recording }>(),
);
export const moveToTrashFailureAction = createAction(
  'MoveToTrashFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const restoreFromTrashRequestAction = createAction(
  'RestoreFromTrashRequest',
  props<{ id: string }>(),
);
export const restoreFromTrashSuccessAction = createAction(
  'RestoreFromTrashSuccess',
  props<{ recording: Recording }>(),
);
export const restoreFromTrashFailureAction = createAction(
  'RestoreFromTrashFailure',
  props<{ errors: ErrorModel[] }>(),
);
