import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from '../../models/app.state';
import { Store, select } from '@ngrx/store';
import { $currentRecording } from '../../store/selectors/current-selections.selectors';

@Component({
  selector: 'cmv-recording-info',
  templateUrl: './recording-info.component.html',
  styleUrls: ['./recording-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordingInfoComponent {
  readonly recording$ = this.store.pipe(select($currentRecording));

  isOpen = false;

  constructor(
    public readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
  ) {}
}
