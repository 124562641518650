import { Component, ChangeDetectionStrategy } from '@angular/core';
@Component({
  selector: 'cmv-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsComponent {
  constructor() {
    window.scrollTo(0, 0);
  }
}
