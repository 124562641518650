<div class="cmv-video-overlay">
  <div
    class="drawing-controls"
    [ngClass]="{
      'drawing-controls-inactive': isDrawing,
    }"
  >
    <div class="cmv-drawing-controls-buttons">
      <button
        class="drawing-controls-button cmv-h5"
        (click)="closeDrawing()"
        [ngClass]="{ 'drawing-controls-button-inactive': isDrawing }"
      >
        <mat-icon
          class="cmv-white-icon cmv-drawing-button-icon"
          svgIcon="cmv-closing"
        ></mat-icon>
      </button>
      <button
        class="drawing-controls-button cmv-h5"
        (click)="undo()"
        [ngClass]="{ 'drawing-controls-button-inactive': isDrawing }"
      >
        <mat-icon
          class="cmv-white-icon cmv-drawing-button-icon"
          svgIcon="cmv-undo"
        ></mat-icon>
        <span class="drawing-button-label">
          {{ 'components.drawing.undo' | translate }}
        </span>
      </button>
      <button
        class="drawing-controls-button"
        [ngClass]="{ 'drawing-controls-button-inactive': isDrawing }"
        (click)="selectShapeType(shapeTypes.LINE)"
      >
        <mat-icon
          class="cmv-white-icon cmv-drawing-button-icon"
          [ngClass]="{
            'active-shape': currentShapeSelected === shapeTypes.LINE,
          }"
          svgIcon="cmv-line"
        ></mat-icon>
      </button>
      <button
        class="drawing-controls-button"
        [ngClass]="{ 'drawing-controls-button-inactive': isDrawing }"
        (click)="selectShapeType(shapeTypes.CIRCLE)"
      >
        <mat-icon
          class="cmv-white-icon cmv-drawing-button-icon"
          [ngClass]="{
            'active-shape': currentShapeSelected === shapeTypes.CIRCLE,
          }"
          svgIcon="cmv-oval"
        ></mat-icon>
      </button>
      <button
        class="drawing-controls-button"
        [ngClass]="{ 'drawing-controls-button-inactive': isDrawing }"
        (click)="selectShapeType(shapeTypes.FREE_HAND)"
      >
        <mat-icon
          class="cmv-white-icon cmv-drawing-button-icon"
          [ngClass]="{
            'active-shape': currentShapeSelected === shapeTypes.FREE_HAND,
          }"
          svgIcon="cmv-edit"
        ></mat-icon>
      </button>
    </div>
    <cmv-color-picker
      class="color-picker"
      [ngClass]="{ 'drawing-controls-button-inactive': isDrawing }"
      *ngIf="colorPickerDimensions$ | async as dimensions"
      [canvasHeight]="dimensions.height"
      [canvasWidth]="dimensions.width"
      [knobSize]="dimensions.knowSize"
      (colorChanges)="setColor($event)"
    ></cmv-color-picker>
  </div>
</div>

<canvas
  #canvas
  [height]="canvasDimensions.height"
  [width]="canvasDimensions.width"
  [style.top.px]="canvasDimensions.top"
  [style.left.px]="canvasDimensions.left"
  id="drawingCanvas"
  (panmove)="panMove($event)"
  (panstart)="panStart($event)"
  (panend)="panEnd($event)"
></canvas>
