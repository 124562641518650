import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  ViewChild,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditModalModel } from '../../../models/edit-modal.model';

const HEADER_BASIC_PATH = 'components.modals.editModal';
const EDIT_TAG = 'editTag';
const CREATE_PLAYLIST = 'createPlaylist';
const EDIT_PLAYLIST = 'editPlaylist';
const CREATE_TAG = 'createTag';
const SUBTITLE_EDIT_PLAYLIST = 'subtitleEditPlaylist';
const SUBTITLE_CREATE_PLAYLIST = 'subtitleCreatePlaylist';
const SUBTITLE_CREATE_TAG = 'subtitleCreateTag';
const EDIT_ICON = 'cmv-edit';
const CREATE_PLAYLIST_ICON = 'cmv-add-playlist';

export enum EditModalTypes {
  PLAYLIST_CREATE = 'playlistCreate',
  PLAYLIST_EDIT = 'playlistEdit',
  TAG_EDIT = 'tagEdit',
  TAG_CREATE = 'tagCreate',
}

@Component({
  selector: 'cmv-edit-modal',
  templateUrl: './edit-modal.component.html',
  styleUrls: ['./edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditModalComponent implements AfterViewInit {
  title = '';
  description = '';
  headerKey = '';
  subtitle = '';
  icon = EDIT_ICON;
  type: string;
  sharedBy: string | null = null;

  @ViewChild('elementToFocus')
  elementToFocus: ElementRef;

  constructor(
    private readonly dialogRef: MatDialogRef<EditModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: EditModalModel,
  ) {
    this.title = data.title;
    this.description = data.description || '';
    this.type = data.type;
    this.sharedBy = data.sharedBy || null;

    switch (data.type) {
      case EditModalTypes.PLAYLIST_CREATE:
        this.headerKey = `${HEADER_BASIC_PATH}.${CREATE_PLAYLIST}`;
        this.subtitle = `${HEADER_BASIC_PATH}.${SUBTITLE_CREATE_PLAYLIST}`;
        this.icon = CREATE_PLAYLIST_ICON;
        break;
      case EditModalTypes.TAG_CREATE:
        this.headerKey = `${HEADER_BASIC_PATH}.${CREATE_TAG}`;
        this.subtitle = `${HEADER_BASIC_PATH}.${SUBTITLE_CREATE_TAG}`;
        this.icon = CREATE_PLAYLIST_ICON;
        break;
      case EditModalTypes.TAG_EDIT:
        this.headerKey = `${HEADER_BASIC_PATH}.${EDIT_TAG}`;
        this.icon = EDIT_ICON;
        break;
      case EditModalTypes.PLAYLIST_EDIT:
        this.headerKey = `${HEADER_BASIC_PATH}.${EDIT_PLAYLIST}`;
        this.subtitle = `${HEADER_BASIC_PATH}.${SUBTITLE_EDIT_PLAYLIST}`;
        this.icon = EDIT_ICON;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elementToFocus.nativeElement.focus();
    }, 0);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
  }

  closeModal(save: boolean): void {
    if (save) {
      this.dialogRef.close({
        title: this.title,
        description: this.description,
      });
    } else {
      this.dialogRef.close();
    }
  }
}
