<div class="separator-container">
  <div class="grid-container separator-content-wrapper">
    <div class="separator-content" *ngIf="isToday; else notToday">
      <div class="cmv-h3 separator-month-year today">
        {{ 'components.recordingList.separator.recordingsToday' | translate }}
      </div>
    </div>
    <ng-template #notToday>
      <div class="separator-content">
        <div class="separator-day-container">
          <div class="cmv-h3 separator-day">
            {{ day }}
          </div>
        </div>
        <div class="cmv-h7 separator-month-year">{{ monthYear }}</div>
      </div>
    </ng-template>
  </div>
</div>
