import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { PlaylistsComponent } from './playlists.component';
import { PlaylistsCollectionViewComponent } from './playlists-collection-view/playlists-collection-view.component';

@NgModule({
  declarations: [PlaylistsComponent, PlaylistsCollectionViewComponent],
  imports: [SharedModule],
})
export class PlaylistsModule {}
