<div
  tabindex="0"
  #elementToFocus
  class="cmv-modal-outer-card pwa-guide-modal-outer"
>
  <div class="cmv-modal-inner-card pwa-guide-modal-wrapper">
    <div class="cmv-modal-close-button" (click)="closeModal()">
      <mat-icon
        class="modal-icon-closing cmv-dim-gray"
        svgIcon="cmv-closing"
      ></mat-icon>
    </div>

    <div
      class="main-info"
      *ngIf="!isTouchScreenDevice(); else touchScreenDevice"
    >
      <div class="main-info">
        <div class="cmv-h1 title">
          {{ 'components.modals.pwaInstallGuide.desktop.title' | translate }}
        </div>
        <div class="cmv-body subtitle">
          <ol *ngIf="isMac() && !isChrome(); else desktopPwaInstructions">
            <li>
              {{
                'components.modals.pwaInstallGuide.desktop.macos.supportedOnlyInChrome'
                  | translate
              }}
            </li>
          </ol>

          <ng-template #desktopPwaInstructions>
            <ol>
              <li>
                {{
                  'components.modals.pwaInstallGuide.desktop.openSettings'
                    | translate
                }}
              </li>
              <li>
                {{
                  'components.modals.pwaInstallGuide.desktop.tapAddToHomeScreen'
                    | translate
                }}
              </li>
              <li>
                {{
                  'components.modals.pwaInstallGuide.desktop.confirm'
                    | translate
                }}
              </li>
            </ol>
          </ng-template>
        </div>
      </div>
    </div>

    <ng-template #touchScreenDevice>
      <div class="main-info" *ngIf="platform.ANDROID">
        <div class="cmv-h1 title">
          {{ 'components.modals.pwaInstallGuide.android.title' | translate }}
        </div>
        <div class="cmv-body subtitle">
          <ol>
            <li>
              {{
                'components.modals.pwaInstallGuide.android.openSettings'
                  | translate
              }}
            </li>
            <li>
              {{
                'components.modals.pwaInstallGuide.android.tapAddToHomeScreen'
                  | translate
              }}
            </li>
            <li>
              {{
                'components.modals.pwaInstallGuide.android.confirm' | translate
              }}
            </li>
          </ol>
        </div>
      </div>
      <div class="main-info" *ngIf="isIPhone() || isIPad()">
        <div class="cmv-h1 title">
          {{ 'components.modals.pwaInstallGuide.ios.title' | translate }}
        </div>
        <div class="cmv-body subtitle">
          <ol *ngIf="isChrome()">
            <li>
              {{
                'components.modals.pwaInstallGuide.ios.supportedOnlyInSafari'
                  | translate
              }}
            </li>
          </ol>
          <ol *ngIf="!isChrome()">
            <li>
              {{
                'components.modals.pwaInstallGuide.ios.tapShareButton'
                  | translate
              }}
            </li>
            <li>
              {{ 'components.modals.pwaInstallGuide.ios.tapIcon' | translate }}
            </li>
            <li>
              {{ 'components.modals.pwaInstallGuide.ios.tapAdd' | translate }}
            </li>
          </ol>
        </div>
      </div>
    </ng-template>
  </div>
</div>
