<div class="annotation-controls">
  <div class="edit-delete">
    <div class="cmv-dashed-button" (click)="openModal(annotation, 'EditModal')">
      <mat-icon class="icon cmv-dim-gray-icon" svgIcon="cmv-edit"></mat-icon>
    </div>
    <div
      class="cmv-dashed-button"
      (click)="openModal(annotation, 'RemoveModal')"
    >
      <mat-icon class="icon cmv-dim-gray-icon" svgIcon="cmv-delete"></mat-icon>
    </div>
  </div>
  <div class="add" (click)="openModal(annotation, 'AddToPlaylistModal')">
    <div class="cmv-dashed-button">
      <div class="cmv-h4">{{ 'basic.addToPlaylist' | translate }}</div>
      <mat-icon
        class="button-icon cmv-dim-gray-icon"
        svgIcon="cmv-add-playlist"
      ></mat-icon>
    </div>
  </div>
</div>
