import { createSelector } from '@ngrx/store';
import { Annotation, Recording, SearchResponse } from '../../../../api/models';
import { AppState, FilterSearchState } from '../../models/app.state';
import { FilterSearchMetadataModel } from '../../models/filter-search-metadata.model';
import { FilterSearchModelType } from '../../models/filter-search-model-type.enum';
import { FilterSearchResultModel } from '../../models/filter-search-result.model';
import { FilterSearchTagModel } from '../../models/filter-search-tag.model';

export const $filterSearch = ({ filterSearch }: AppState) => filterSearch;
export const $filterSearchMetadata = createSelector(
  $filterSearch,
  ({ metadata }: FilterSearchState) => metadata,
);
export const $filterSearchOptions = createSelector(
  $filterSearchMetadata,
  ({ filterOptions }: FilterSearchMetadataModel) => filterOptions,
);
export const $filterSearchTags = createSelector(
  $filterSearchMetadata,
  ({ tags }: FilterSearchMetadataModel): FilterSearchTagModel[] =>
    tags.map(({ name, id }) => ({ name, id })),
);
export const $filterSearchEntity = createSelector(
  $filterSearch,
  ({ entity }: FilterSearchState) => entity,
);
export const $filterSearchResult = createSelector(
  $filterSearchEntity,
  ({ annotations, recordings }: SearchResponse): FilterSearchResultModel[] =>
    [
      ...annotations!.map(a => ({
        ...a,
        itemType: FilterSearchModelType.ANNOTATION,
      })),
      ...recordings!.map(m => ({
        ...m,
        itemType: FilterSearchModelType.RECORDING,
      })),
    ].sort((a, b) => sortResponse(a, b)),
);

export const $filterSearchNoResult = createSelector(
  $filterSearch,
  ({ loading, entity }: FilterSearchState) =>
    !loading &&
    entity!.annotations!.length === 0 &&
    entity!.recordings!.length === 0,
);

export const $filterSearchLoading = createSelector(
  $filterSearch,
  ({ loading }) => loading,
);

export const sortResponse = (
  a: FilterSearchResultModel,
  b: FilterSearchResultModel,
): number => {
  const getTime = (item: FilterSearchResultModel) =>
    item.itemType === FilterSearchModelType.ANNOTATION
      ? (item as Annotation).timeStamp
      : (item as Recording).dateTime;

  return (getTime(a) - getTime(b)) * -1;
};
