import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { debounceTime, filter, take, tap } from 'rxjs/operators';
import { RoutePath } from '../../../app.constants';
import { CookiesModalComponent } from '../../components/modal/cookies-modal/cookies-modal.component';
import { AppState } from '../../models/app.state';
import { storeRouteAction } from '../actions/route.actions';
import { $customerId } from '../selectors/customer.selectors';
import { environment } from 'src/environments/environment';
import { $userId } from '../selectors/user.selectors';

const COOKIE_MODAL_DEBOUNCE = 500;
const locStoragePropName = 'acceptedCookies';

@Injectable()
export class CookiesEffect {
  customerId$ = this.store.pipe(select($customerId), take(1));

  showCookieModal = createEffect(
    () =>
      this.actions$.pipe(
        ofType(storeRouteAction),
        filter(({ route: { path } }) => path !== RoutePath.Privacy),
        debounceTime(COOKIE_MODAL_DEBOUNCE),
        tap(() => {
          const acceptedCookies = localStorage.getItem(locStoragePropName);
          this.store.pipe(select($userId), take(1)).subscribe(userId => {
            if (acceptedCookies === 'true' && !(window as any).gtag) {
              this.customerId$.subscribe(() => mountGAScript(userId));
            } else if (!acceptedCookies) {
              const cookieDialog = this.dialog.open(CookiesModalComponent);

              if (cookieDialog != null) {
                cookieDialog
                  .afterClosed()
                  .pipe(
                    take(1),
                    filter(output => typeof output === 'boolean'),
                  )
                  .subscribe((userResponse: boolean): void => {
                    updateLocalStorage(userResponse.toString());
                    if (userResponse) {
                      this.customerId$.subscribe(() => mountGAScript(userId));
                    }
                  });
              }
            }
          });
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    public readonly dialog: MatDialog,
  ) {}
}

const mountGAScript = (userId: string | undefined): void => {
  if (!document.head || environment.ga.enable === false) {
    return;
  }
  const gaId = environment.ga.id;
  const gaScript = document.createElement('script');
  // eslint-disable-next-line
  gaScript.type = 'text/javascript';
  const configOptions = JSON.stringify(userId ? { userId } : {});
  // eslint-disable-next-line
  gaScript.textContent = `window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', '${gaId}', ${configOptions});`;

  document.head.appendChild(gaScript);

  const gaScript2 = document.createElement('script');
  // eslint-disable-next-line
  gaScript2.type = 'text/javascript';
  // eslint-disable-next-line
  gaScript2.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
  document.head.appendChild(gaScript2);
};

const updateLocalStorage = (value: string): void => {
  localStorage.setItem(locStoragePropName, value);
};
