import { AbstractControl, ValidationErrors } from '@angular/forms';

export class TeamValidators {
  static notSameTeam(control: AbstractControl): ValidationErrors | null {
    if (
      !control.parent ||
      !control.parent.get('team1') ||
      !control.parent.get('team2') ||
      !control.parent.get('team1')!.value ||
      !control.parent.get('team2')!.value
    ) {
      return null;
    }

    const team1 = control.parent.get('team1')!.value;
    const team2 = control.parent.get('team2')!.value;

    return team1 &&
      team2 &&
      team1.clubId === team2.clubId &&
      team1.teamId === team2.teamId
      ? {
          teamsSame: true,
        }
      : null;
  }
}
