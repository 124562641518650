import { PlaylistsRecordingsResponse } from '../../../../api/models';
import { ErrorModel } from '../../models/app.state';
import { createAction, props } from '@ngrx/store';

/*getPlaylistsRecordingsAction*/
export const getPlaylistsRecordingsRequestAction = createAction(
  'GetPlaylistsRecordingsRequest',
  props<{ playlistId: string }>(),
);
export const getPlaylistsRecordingsSuccessAction = createAction(
  'GetPlaylistsRecordingsSuccess',
  props<{ response: PlaylistsRecordingsResponse }>(),
);
export const getPlaylistsRecordingsFailureAction = createAction(
  'GetPlaylistsRecordingsFailure',
  props<{ errors: ErrorModel[] }>(),
);
