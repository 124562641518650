<mat-form-field class="cmv-input team-search" floatLabel="always">
  <mat-label>{{ label | translate }}</mat-label>
  <div class="team-search-logo" *ngIf="canShowLogo$ | async">
    <img
      class="team-search-logo-img"
      [src]="clubLogo$ | async"
      [alt]="teamName$ | async"
      (load)="logoImageLoaded()"
    />
    <div class="team-search-logo-spinner" *ngIf="!logoLoaded">
      <mat-spinner class="button-spinner"></mat-spinner>
    </div>
  </div>
  <input type="text" [formControl]="teamCtrl" hidden />
  <input
    type="text"
    class="team-search-input"
    [ngClass]="{
      'team-search-input-filled': canShowLogo$ | async,
    }"
    matInput
    [placeholder]="placeholder | translate"
    [formControl]="clubFilterCtrl"
    [matAutocomplete]="singleSelect"
  />
  <mat-icon
    class="cmv-gray-icon input-right-icon"
    svgIcon="cmv-search"
  ></mat-icon>
  <mat-autocomplete #singleSelect="matAutocomplete">
    <mat-option
      *ngFor="let club of filteredClubs$ | async; trackBy: trackBy"
      [value]="club.name"
      (mouseover)="loadTeams($event, club.id)"
    >
      <mat-expansion-panel>
        <mat-expansion-panel-header
          (click)="$event.stopPropagation()"
          [collapsedHeight]="'42px'"
          [expandedHeight]="'42px'"
        >
          <mat-panel-title>
            <img
              class="club-icon"
              [class.hidden]="!club.logo"
              src="{{ club.logo ? club.logo : '' }}"
            />
            {{ club.name }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <mat-option
            *ngFor="let team of getClubTeamsOptions(club); trackBy: trackBy"
            class="team-search-option"
            [value]="{ club: club, team: team.value }"
            [class.team-search-option-is-selected]="isTeamSelected(team.id)"
            (onSelectionChange)="setTeam($event)"
          >
            {{ team.name }}
          </mat-option>
          <div
            class="cmv-button-with-spinner"
            *ngIf="clubLoading(club.id) | async"
          >
            <mat-spinner class="button-spinner"></mat-spinner>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </mat-option>

    <span *ngIf="filteredClubs$ | async; let clubs">
      <span
        *ngIf="clubs.length >= MAX_ITEMS"
        class="cmv-paragraph clubs-overlimit"
      >
        {{ 'components.manualScheduling.moreResults' | translate }}
      </span>
    </span>
  </mat-autocomplete>

  <mat-error>
    {{ 'components.manualScheduling.errors.' + getFirstError() | translate }}
  </mat-error>
</mat-form-field>
