import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export enum INPUT_TYPE {
  PLAYLIST = 'playlist',
  ANNOTATION = 'annotation',
  TAG = 'tag',
  RECORDING = 'recording',
}

@Component({
  selector: 'cmv-empty-trash-modal',
  templateUrl: './empty-trash-modal.component.html',
  styleUrls: ['./empty-trash-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyTrashModalComponent implements AfterViewInit {
  @ViewChild('elementToFocus')
  elementToFocus: ElementRef;

  constructor(
    private readonly dialogRef: MatDialogRef<EmptyTrashModalComponent>,
  ) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
  }

  closeModal(remove: boolean): void {
    this.dialogRef.close(remove);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elementToFocus.nativeElement.focus();
    }, 0);
  }
}
