import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { RoutePath } from '../../app.constants';
import { Store } from '@ngrx/store';
import { AppState } from '../models/app.state';
import { Observable } from 'rxjs';
import { $authSessionValid } from '../store/selectors/auth.selectors';
import { map, withLatestFrom } from 'rxjs/operators';
import { $hasAnyRecorder } from '../store/selectors/recorders.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private readonly router: Router,
    private readonly store: Store<AppState>,
  ) {}

  canActivate(
    _: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.store.pipe(
      withLatestFrom(
        this.store.select($authSessionValid),
        this.store.select($hasAnyRecorder),
      ),
      map(([_, sessionValid, hasAnyRecorder]) => {
        if (
          routerState.url.startsWith(`/${RoutePath.Platform}`) &&
          !sessionValid
        ) {
          this.router.navigate([RoutePath.Landing]);
          return false;
        }

        if (
          routerState.url.startsWith(`/${RoutePath.Landing}`) &&
          sessionValid
        ) {
          this.router.navigate([RoutePath.Platform]);
          return false;
        }

        if (routerState.url.includes(RoutePath.Trash) && !hasAnyRecorder) {
          this.router.navigate([RoutePath.Platform]);
          return false;
        }

        return true;
      }),
    );
  }
}
