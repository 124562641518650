import { RecorderState, StateStatus } from '../../models/app.state';
import { recordersAdapter } from '../reducers/recorders.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const { selectAll: selectRecorders, selectEntities: selectRecorderEntities } =
  recordersAdapter.getSelectors();

export const $recordersState =
  createFeatureSelector<RecorderState>('recorders');

export const $recorders = createSelector($recordersState, selectRecorders);

export const $recorderEntities = createSelector(
  $recordersState,
  selectRecorderEntities,
);

export const $sortedRecorders = createSelector($recorderEntities, recorders =>
  Object.keys(recorders)
    .map(id => recorders[id])
    .sort((a, b) => (a?.name ?? '').localeCompare(b?.name ?? '')),
);

export const $recordersLoaded = createSelector(
  $recordersState,
  state => state.status === StateStatus.LOADED,
);

export const $hasAnyRecorder = createSelector(
  $recorders,
  recorders => recorders.length > 0,
);
