<cmv-page-layout
  [title]="'components.manualScheduling.recordingCreateTitle' | translate"
>
  <div class="cmv-recording-form-grid">
    <cmv-manual-scheduling-form></cmv-manual-scheduling-form>
  </div>
</cmv-page-layout>

<div class="header-wrapper">
  <cmv-header
    [subtitle]="
      'components.manualScheduling.recordingCreateSubtitle' | translate
    "
  ></cmv-header>
</div>
