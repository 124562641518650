<button
  mat-icon-button
  (click)="isOpen = !isOpen"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
>
  <mat-icon
    class="button-icon cmv-dim-gray-icon open-recording-Info"
    svgIcon="cmv-info"
  ></mat-icon>
</button>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayHasBackdrop]="true"
  (backdropClick)="isOpen = false"
>
  <div class="recording-info" *ngIf="recording$ | async as recording">
    <div>
      <b>{{ 'components.recordingInfo.name' | translate }}:</b>
      {{ recording.name }}
    </div>
    <div>
      <b>{{ 'components.recordingInfo.date' | translate }}:</b>
      {{ recording.dateTime | dateFormat }}
    </div>
    <div>
      <b>{{ 'components.recordingInfo.time' | translate }}:</b>
      {{ recording.dateTime | time }}
    </div>
    <div>
      <b>{{ 'components.recordingInfo.duration' | translate }}:</b>
      {{ recording.duration | duration }}
    </div>
    <div>
      <b>{{ 'components.recordingInfo.recorder' | translate }}:</b>
      {{ recording.recorderName }}
    </div>
    <div>
      <b>{{ 'components.recordingInfo.type' | translate }}:</b>
      {{ recording.type | titlecase }}
    </div>
    <div>
      <b>{{ 'components.recordingInfo.videos' | translate }}:</b>
      {{ recording.videos?.length || 0 }}
    </div>
    <div *ngIf="recording.competitionsNames?.length > 0">
      <b>{{ 'components.recordingInfo.competitions' | translate }}:</b>
      {{ recording.competitionsNames.join(', ') }}
    </div>
  </div>
</ng-template>
