import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Playlist } from '../../../../api/models';
import {
  EditModalComponent,
  EditModalTypes,
} from '../../../shared/components/modal/edit-modal/edit-modal.component';
import { AppState } from '../../../shared/models/app.state';
import { EditModalModel } from '../../../shared/models/edit-modal.model';
import {
  deletePlaylistRequestAction,
  postPlaylistRequestAction,
  putPlaylistRequestAction,
  renderPlaylistRequestAction,
} from '../../../shared/store/actions/playlist-list.actions';
import { $playlistCollectionViewLoading } from '../../../shared/store/selectors/loading-components.selectors';
import { $playlistEntities } from '../../../shared/store/selectors/playlist-list.selectors';

@Component({
  selector: 'cmv-playlists-collection-view',
  templateUrl: './playlists-collection-view.component.html',
  styleUrls: ['./playlists-collection-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaylistsCollectionViewComponent {
  playlists$: Observable<Playlist[]> = this.store.pipe(
    select($playlistEntities),
  );
  loadingResources$: Observable<boolean> = this.store.pipe(
    select($playlistCollectionViewLoading),
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialog: MatDialog,
  ) {}

  createNewPlaylist(): void {
    const dialogInstance = this.dialog.open(EditModalComponent, {
      data: {
        title: '',
        type: EditModalTypes.PLAYLIST_CREATE,
      },
    });

    if (dialogInstance != null) {
      dialogInstance
        .afterClosed()
        .pipe(take(1))
        .subscribe((value: EditModalModel) => {
          if (value != null && value.title.length > 0) {
            this.store.dispatch(
              postPlaylistRequestAction({ playlist: { name: value.title } }),
            );
          }
        });
    }
  }

  removePlaylist(playlist: Playlist): void {
    this.store.dispatch(deletePlaylistRequestAction({ playlist }));
  }

  editPlaylist(playlist: Playlist): void {
    this.store.dispatch(
      putPlaylistRequestAction({
        playlist,
      }),
    );
  }

  renderPlaylist(id: string): void {
    this.store.dispatch(renderPlaylistRequestAction({ id }));
  }
}
