<div tabindex="0" #elementToFocus class="cmv-modal-outer-card sharing-wrapper">
  <div class="cmv-modal-inner-card cmv-modal-share">
    <div class="cmv-modal-close-button" (click)="closeModal()">
      <mat-icon
        class="modal-icon-closing cmv-dim-gray"
        svgIcon="cmv-closing"
      ></mat-icon>
    </div>

    <div class="main-info main-info-share">
      <div class="icon-wrapper">
        <mat-icon
          class="modal-icon cmv-gray-icon"
          svgIcon="cmv-share"
        ></mat-icon>
      </div>
      <div class="cmv-h1">{{ titleKey | translate }}</div>
      <div class="cmv-body subtitle">
        {{ subtitleKey | translate }}
      </div>
    </div>
    <div class="sharing-tree">
      <cmv-tree [data]="sharingData$ | async"></cmv-tree>
    </div>
  </div>
</div>
