<div
  tabindex="0"
  #elementToFocus
  class="cmv-modal-outer-card remux-video-modal"
>
  <div class="cmv-modal-inner-card remux-video-modal--inner">
    <div class="cmv-modal-close-button" (click)="closeModal(false)">
      <mat-icon
        class="modal-icon-closing cmv-dim-gray"
        svgIcon="cmv-closing"
      ></mat-icon>
    </div>

    <div class="main-info">
      <div class="icon-wrapper icon-wrapper-render">
        <mat-icon
          class="modal-icon cmv-gray-icon"
          svgIcon="cmv-download"
        ></mat-icon>
      </div>
      <div class="cmv-h1">
        {{ 'components.modals.remuxVideoModal.title' | translate }}
      </div>
      <div class="cmv-body remux-video-modal--subtitle">
        {{ 'components.modals.remuxVideoModal.subtitle1' | translate }}
        <br />
        {{ 'components.modals.remuxVideoModal.subtitle2' | translate }}
      </div>
    </div>
    <div class="buttons">
      <div class="cmv-accept-button" (click)="remuxVideo()">
        <div class="cmv-h4">
          {{ 'basic.ok' | translate }}
        </div>
      </div>
      <div class="cmv-decline-button" (click)="closeModal(false)">
        <div class="cmv-h4">
          {{ 'basic.cancel' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
