import moment from 'moment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { APIClient } from '../../../../api';
import {
  ALMOST_ONE_DAY_HOURS,
  ALMOST_ONE_HOUR_MINUTES,
  GoogleAnalyticsEvent,
  RoutePath,
  TEMPLATE_NULL,
} from '../../../app.constants';
import { AppState } from '../../models/app.state';
import { FILTER_FORM_CONTROLS } from '../../models/filter-form-controls.enum';
import { FilterSearchFormModel } from '../../models/filter-search-form.model';
import { FilterSearchRequestModel } from '../../models/filter-search-request.model';
import {
  closeSearchingAfterRedirectAction,
  getFilterSearchFailureAction,
  getFilterSearchRequestAction,
  getFilterSearchSuccessAction,
  getFilterSearchTagsFailureAction,
  getFilterSearchTagsRequestAction,
  getFilterSearchTagsSuccessAction,
  resetSearchingAction,
  setFilterSearchOption,
} from '../actions/filter-search.actions';
import { storeRouteAction } from '../actions/route.actions';
import { $filterSearchOptions } from '../selectors/filter-search.selectors';
import { $searchBarVisible } from '../selectors/ui-flags.selectors';
import { reportToGA } from 'src/app/app.utils';

@Injectable()
export class FilterSearchEffects {
  getFilterSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFilterSearchRequestAction),
      switchMap(({ options }) => {
        reportToGA(GoogleAnalyticsEvent.FILTER, {
          type: options.type,
          byText: !!options.text,
          useFrom: !!options.from,
          useTo: !!options.to,
          byTypeId: !!options.typeId,
        });

        return this.apiService.call(() =>
          this.apiClient.getSearch({
            ...options,
          }),
        );
      }),
      map(response =>
        getFilterSearchSuccessAction({
          response,
        }),
      ),
      catchError(error =>
        of(
          getFilterSearchFailureAction({
            errors: [error],
          }),
        ),
      ),
    ),
  );

  prepareFilterSearchRequestOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setFilterSearchOption),
      map(({ options }) => options),
      withLatestFrom(this.store.pipe(select($filterSearchOptions))),
      map(([updated, previous]) => ({ ...previous, ...updated })),
      map(
        (x: FilterSearchFormModel): FilterSearchRequestModel =>
          Object.entries(x)
            .filter(([, value]) => !!value && value !== TEMPLATE_NULL)
            .reduce(
              (acc, [key, value]) =>
                key === FILTER_FORM_CONTROLS.FROM
                  ? {
                      ...acc,
                      from: moment(value).unix(),
                    }
                  : key === FILTER_FORM_CONTROLS.TO
                    ? {
                        ...acc,
                        to: moment(value)
                          .add(ALMOST_ONE_DAY_HOURS, 'hours')
                          .add(ALMOST_ONE_HOUR_MINUTES, 'minutes')
                          .unix(),
                      }
                    : {
                        ...acc,
                        [key]: value,
                      },
              {},
            ),
      ),
      map(options => getFilterSearchRequestAction({ options })),
    ),
  );

  getFilterSearchTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFilterSearchTagsRequestAction),
      switchMap(() =>
        this.apiService.call(() =>
          this.apiClient.getAnnotationTypes({ shared: true }),
        ),
      ),
      map(tags =>
        getFilterSearchTagsSuccessAction({
          tags,
        }),
      ),
      catchError(error =>
        of(
          getFilterSearchTagsFailureAction({
            errors: [error],
          }),
        ),
      ),
    ),
  );

  navigateToResults$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getFilterSearchSuccessAction, getFilterSearchFailureAction),
        withLatestFrom(this.store.pipe(select($searchBarVisible))),
        filter(([, visible]) => visible),
        map(() => this.router.navigate([RoutePath.Platform, RoutePath.Filter])),
      ),
    { dispatch: false },
  );

  navigateBackFromSearching$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(resetSearchingAction),
        map(() =>
          this.router.navigate([RoutePath.Platform, RoutePath.Recordings]),
        ),
      ),
    { dispatch: false },
  );

  hideSearchBarOnRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(storeRouteAction),
      withLatestFrom(this.store.pipe(select($searchBarVisible))),
      filter(
        ([
          {
            route: { path },
          },
          visible,
        ]) =>
          !path.includes(`${RoutePath.Platform}/${RoutePath.Filter}`) &&
          visible,
      ),
      map(() => closeSearchingAfterRedirectAction()),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly apiClient: APIClient,
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly apiService: ApiService,
  ) {}
}
