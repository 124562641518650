import { Injectable } from '@angular/core';
import moment from 'moment';
import { catchError, defer, EMPTY, Observable, take } from 'rxjs';
import {
  API_SERVICE_UNTIL_DELAY,
  API_SERVICE_UNTIL_MAX_TRY,
} from '../app.constants';
import { until } from '../app.utils';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private timeToRefresh: moment.Moment;

  call<Type>(func: () => Observable<Type>): Observable<Type | never> {
    const p = new Promise((resolve: (value: Type) => any, reject) => {
      until(
        () => this.isTokenValid,
        API_SERVICE_UNTIL_DELAY,
        API_SERVICE_UNTIL_MAX_TRY,
      )
        .then(() => {
          func()
            .pipe(
              take(1),
              catchError(error => {
                reject(error);
                return EMPTY;
              }),
            )
            .subscribe(d => resolve(d));
        })
        .catch(() =>
          reject({
            status: 401,
            error: {
              msg: 'Token is not valid!',
            },
          }),
        );
    });

    return defer(() => p);
  }

  setTokenValidity(val: moment.Moment) {
    this.timeToRefresh = val;
  }

  private get isTokenValid() {
    return this.timeToRefresh?.isAfter();
  }
}
