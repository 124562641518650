import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Annotation, Video } from 'src/api/models';
import { QueryParamNames, RoutePath } from '../../../app.constants';
import { TAGS_ANIMATION } from '../../../app.utils';
import { AppState } from '../../../shared/models/app.state';
import {
  deleteAnnotationRequestAction,
  editAnnotationNameRequestAction,
  prepareCurrentAnnotationTrimmingRequestAction,
  prepareLiveMatchAnnotationPostRequestAction,
} from '../../../shared/store/actions/annotation.actions';
import {
  $canDownloadAnnotationList,
  $isAnnotationListRendering,
  $shareableList,
} from '../../../shared/store/selectors/annotations-lists.selectors';
import {
  $currentRecording,
  $currentRecordingAnnotations,
  $currentSignedUrl,
  $isStreamTagging,
  $trimmingDisabled,
  $currentRecordingVideos,
  $currentVideo,
  $currentVideoLoading,
  $currentAnnotation,
  $currentAnnotationOffset,
  $currentAnnotationValue,
} from '../../../shared/store/selectors/current-selections.selectors';
import {
  changeCurrentVideoAction,
  changeCurrentVideoDurationAction,
} from '../../../shared/store/actions/current-selections.actions';
import { $liveMatchViewLoading } from '../../../shared/store/selectors/loading-components.selectors';
import { $largeDeviceTagging } from '../../../shared/store/selectors/responsivity.selectors';
import { $isSharingEnabled } from '../../../shared/store/selectors/sharing.selectors';
import { CurrentVideoModel } from 'src/app/shared/models/current-video.model';
import { VideoPlayerWrapperComponent } from 'src/app/shared/components/video-player-wrapper/video-player-wrapper.component';
import {
  BehaviorSubject,
  Observable,
  filter,
  first,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs';
import {
  $currentRecordingId,
  $isTrimming,
} from 'src/app/shared/store/selectors/route.selectors';
import { OffsetModel } from 'src/app/shared/models/offset.model';
import { SEEK_TO } from 'src/app/shared/components/trimming-panel/trimming-panel.component';
import { TrimmingInputModel } from 'src/app/shared/models/trimming-input.model';

const TRIM_ANNOTATION_TIMEOUT = 250;
@Component({
  selector: 'cmv-live-tagging-view',
  templateUrl: './live-match-view.component.html',
  styleUrls: ['./live-match-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: TAGS_ANIMATION,
})
export class LiveMatchViewComponent implements OnInit {
  @ViewChild('videoPlayer')
  videoPlayer: VideoPlayerWrapperComponent;

  boundedCreateTag: Function;
  shouldAutoplay: boolean;
  stopInfo: { at: number; ignore: number } | null;

  readonly annotations$ = this.store.pipe(select($currentRecordingAnnotations));
  readonly shareableList$ = this.store.pipe(select($shareableList));
  readonly isSharingEnabled$ = this.store.pipe(select($isSharingEnabled));
  readonly recordingVideos$ = this.store.pipe(select($currentRecordingVideos));
  readonly isStreamTagging$ = this.store.pipe(select($isStreamTagging));
  readonly largeDeviceTagging$ = this.store.pipe(select($largeDeviceTagging));

  readonly currentVideo$ = this.store.pipe(select($currentVideo));
  readonly currentVideoUrl$ = this.store.pipe(select($currentSignedUrl));
  readonly currentVideoLoading$ = this.store.pipe(select($currentVideoLoading));

  readonly trimmingDisabled$ = this.store.pipe(select($trimmingDisabled));
  readonly match$ = this.store.pipe(select($currentRecording));
  readonly loadingResources$ = this.store.pipe(select($liveMatchViewLoading));
  readonly isTrimming$ = this.store.pipe(select($isTrimming));
  readonly currentAnnotation$ = this.store.select($currentAnnotation);
  readonly currentRecordingId$ = this.store.select($currentRecordingId);

  readonly canDownloadAnnotationList$ = this.store.pipe(
    select($canDownloadAnnotationList),
  );

  readonly isAnnotationListRendering$ = this.store.pipe(
    select($isAnnotationListRendering),
  );

  jumpToStart = true;
  jumpToEnd = false;

  newCurrentOffset$: BehaviorSubject<OffsetModel> = new BehaviorSubject({
    jumpToStart: this.jumpToStart,
    jumpToEnd: this.jumpToEnd,
  });

  currentOffset$: Observable<OffsetModel | null> = this.store.pipe(
    select($currentAnnotationOffset),
    withLatestFrom(this.store.select($isTrimming)),
    map(([currVidOffset, isTrimming]) =>
      currVidOffset === undefined || !isTrimming
        ? null
        : {
            ...currVidOffset,
            jumpToStart: this.jumpToStart,
            jumpToEnd: this.jumpToEnd,
          },
    ),
  );

  calcAnnotationValue$ = this.store.pipe(select($currentAnnotationValue));

  playerOffset$: Observable<OffsetModel | null> = this.currentOffset$.pipe(
    switchMap(currentOffset =>
      this.newCurrentOffset$
        .asObservable()
        .pipe(
          map(newCurrentOffset =>
            newCurrentOffset.start != null && newCurrentOffset.end != null
              ? newCurrentOffset
              : currentOffset,
          ),
        ),
    ),
  );

  playerOffsetIsValid$: Observable<boolean> = this.playerOffset$.pipe(
    switchMap(offset =>
      this.store.select($currentVideo).pipe(
        map(video => {
          if (offset === null) {
            return true;
          }

          if (offset?.start === undefined || offset?.end === undefined) {
            return false;
          }

          const start = Number(offset.start);
          const end = Number(offset.end);

          if (end < 0 || start > video.trueDuration || end - start <= 0) {
            return false;
          }

          return true;
        }),
      ),
    ),
  );

  trimmingOffset$: Observable<TrimmingInputModel> = this.newCurrentOffset$.pipe(
    switchMap((newCurrentOffset: OffsetModel) =>
      this.currentOffset$.pipe(
        map((currentOffset: OffsetModel) => [newCurrentOffset, currentOffset]),
      ),
    ),
    map(([newCurrentOffset, currentOffset]: [OffsetModel, OffsetModel]) =>
      newCurrentOffset.start != null && newCurrentOffset.end != null
        ? newCurrentOffset
        : currentOffset,
    ),
    switchMap(offset =>
      this.calcAnnotationValue$.pipe(
        filter(
          (calcAnnotationValue): calcAnnotationValue is TrimmingInputModel =>
            !!calcAnnotationValue,
        ),
        map(
          (calcAnnotationValue): TrimmingInputModel => ({
            ...calcAnnotationValue,
            tagStart: offset.start!,
            tagEnd: offset.end!,
          }),
        ),
      ),
    ),
  );

  constructor(
    private readonly router: Router,
    private readonly store: Store<AppState>,
  ) {
    this.shouldAutoplay = true;
  }

  ngOnInit(): void {
    this.boundedCreateTag = this.createTag.bind(this);
  }

  onPlayerReady(): void {
    this.currentVideo$
      .pipe(
        withLatestFrom(this.currentAnnotation$),
        filter(
          ([video, annotation]) =>
            !!video &&
            !!annotation &&
            !!this.videoPlayer?.player?.api.getDefaultMedia(),
        ),
        take(1),
      )
      .subscribe(([currentVideo, currentAnnotation]) => {
        if (currentAnnotation) {
          const annotationStart =
            currentAnnotation.timeStamp -
            currentVideo.startAt +
            currentAnnotation.from;
          this.videoPlayer.autoplay = false;
          this.videoPlayer?.player?.api.pause();
          this.videoPlayer.setCurrentTime(annotationStart);
          this.stopInfo = {
            at: annotationStart + currentAnnotation.to,
            ignore: annotationStart,
          };
        }
      });
  }

  videoCurrentTimeChange(currentTime: number): void {
    if (this.stopInfo) {
      if (Math.floor(currentTime / 1000) === this.stopInfo.at) {
        this.videoPlayer.player.api.pause();
      }
    }
  }

  videoSeeked(time: number) {
    if (this.stopInfo?.ignore !== Math.floor(time / 1000)) {
      this.stopInfo = null;
    }
  }

  createTag(annotation: Partial<Annotation>, inOverlay: boolean = false): void {
    const currentTime = this.videoPlayer
      ? Math.floor(this.videoPlayer.player.getCurrentTime() * 1000) / 1000
      : 0;

    this.store.dispatch(
      prepareLiveMatchAnnotationPostRequestAction({
        annotation,
        videoPlayer: this.videoPlayer != null,
        currentTime,
        inOverlay,
      }),
    );
  }

  deleteTag(annotation: Annotation): void {
    this.store.dispatch(deleteAnnotationRequestAction({ annotation }));
  }

  selectTag(annotation: Annotation): void {
    this.isTrimming$.pipe(take(1)).subscribe(isTrimming => {
      if (isTrimming) {
        this.newCurrentOffset$.next({
          jumpToStart: true,
          jumpToEnd: false,
        });
        const duration = this.videoPlayer.player.api.duration;
        this.store.dispatch(changeCurrentVideoDurationAction({ duration }));
        this.router.navigate(
          [RoutePath.Platform, RoutePath.Recordings, RoutePath.LiveMatch],
          {
            queryParams: {
              [QueryParamNames.AnnotationId]: annotation.id,
              [QueryParamNames.RecordingId]: annotation.recordingId,
              [QueryParamNames.Trimming]: true,
            },
          },
        );
      } else {
        if (!this.videoPlayer) {
          return;
        }

        this.currentVideo$.pipe(first()).subscribe(currentVideo => {
          const annotationStart =
            annotation.timeStamp - currentVideo.startAt + annotation.from;
          this.videoPlayer.setCurrentTime(annotationStart);
          this.stopInfo = {
            at: annotationStart + annotation.to,
            ignore: annotationStart,
          };
        });
      }
    });
  }

  editAnnotation(annotation: Annotation): void {
    this.store.dispatch(
      editAnnotationNameRequestAction({
        id: annotation.id,
        name: annotation.name,
        description: annotation.description,
      }),
    );
  }

  changeSrc(
    {
      currentVideo,
      video,
    }: {
      currentVideo: CurrentVideoModel;
      video: Video;
    },
    inFullscreen = false,
  ): void {
    if (currentVideo.id === video.id) {
      return;
    }

    const prevVideoTime = this.videoPlayer
      ? this.videoPlayer.player.getCurrentTime()
      : 0;

    if (this.videoPlayer) {
      this.shouldAutoplay = this.videoPlayer.player.api
        .getDefaultMedia()
        .state.includes('playing');
    }
    this.store.dispatch(changeCurrentVideoAction({ video, inFullscreen }));

    if (this.videoPlayer) {
      this.videoPlayer.player.setCurrentTime(
        prevVideoTime + currentVideo.startAt - video.startAt,
      );
    }
  }

  trimAnnotation(newValues: { from: number; to: number }): void {
    this.currentAnnotation$.pipe(take(1)).subscribe(annotation => {
      this.store.dispatch(
        prepareCurrentAnnotationTrimmingRequestAction({ newValues }),
      );
      this.router.navigate(
        [RoutePath.Platform, RoutePath.Recordings, RoutePath.LiveMatch],
        {
          queryParams: {
            [QueryParamNames.RecordingId]: annotation!.recordingId,
          },
        },
      );
      this.newCurrentOffset$.next({
        jumpToStart: false,
        jumpToEnd: false,
      });

      setTimeout(() => {
        this.videoPlayer.setCurrentTime(newValues.from);
      }, TRIM_ANNOTATION_TIMEOUT);
    });
  }

  cancelTrim() {
    this.currentRecordingId$.pipe(take(1)).subscribe(recordingId => {
      this.router.navigate(
        [RoutePath.Platform, RoutePath.Recordings, RoutePath.LiveMatch],
        {
          queryParams: {
            [QueryParamNames.RecordingId]: recordingId,
          },
        },
      );
    });
    this.newCurrentOffset$.next({
      jumpToStart: true,
      jumpToEnd: false,
    });
  }

  recalculateTag(start: number, end: number): void {
    if (this.newCurrentOffset$.value) {
      this.newCurrentOffset$.next({
        ...this.newCurrentOffset$.value,
        end,
        start,
      });
    }
  }

  seekWithHandleMove(seekTo: SEEK_TO): void {
    if (seekTo === SEEK_TO.START) {
      this.jumpToStart = true;
      this.jumpToEnd = !this.jumpToStart;
    } else {
      this.jumpToEnd = true;
      this.jumpToStart = !this.jumpToEnd;
    }
  }

  redirectToTrimming(id: string, recordingId: string): void {
    this.newCurrentOffset$.next({
      jumpToStart: true,
      jumpToEnd: false,
    });

    const duration = this.videoPlayer.player.api.duration;
    this.store.dispatch(changeCurrentVideoDurationAction({ duration }));

    this.router.navigate(
      [RoutePath.Platform, RoutePath.Recordings, RoutePath.LiveMatch],
      {
        queryParams: {
          [QueryParamNames.AnnotationId]: id,
          [QueryParamNames.RecordingId]: recordingId,
          [QueryParamNames.Trimming]: true,
        },
      },
    );
  }
}
