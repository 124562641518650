import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dateFormat',
})
export class DateFormat implements PipeTransform {
  constructor() {}
  transform(value: number): string {
    return moment.unix(value).format('D MMMM YYYY');
  }
}
