import { TeamModel } from '../../models/manual-scheduling.model';
import { ErrorModel } from '../../models/app.state';
import { createAction, props } from '@ngrx/store';

export const getTeamsRequestAction = createAction(
  'GetTeamsRequest',
  props<{ id: string; forceSearch: boolean }>(),
);
export const getTeamsSuccessAction = createAction(
  'GetTeamsSuccess',
  props<{ data: TeamModel[]; clubId: string }>(),
);
export const getTeamsFailureAction = createAction(
  'GetTeamsFailure',
  props<{ errors: ErrorModel[] }>(),
);
