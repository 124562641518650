import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationModel } from '../../models/notification.model';

@Component({
  selector: 'cmv-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationModalComponent {
  constructor(
    private readonly dialogRef: MatDialogRef<NotificationModalComponent>,
    @Inject(MAT_DIALOG_DATA) readonly notificationData: NotificationModel,
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
