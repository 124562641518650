import {
  AnnotationType,
  AnnotationTypePatchRequest,
  AnnotationTypeRequest,
} from '../../../../api/models';
import { ErrorModel } from '../../models/app.state';
import { createAction, props } from '@ngrx/store';

export const getAnnotationTypesRequestAction = createAction(
  'GetAnnotationTypesRequest',
);
export const getAnnotationTypesSuccessAction = createAction(
  'GetAnnotationTypesSuccess',
  props<{ annotationTypes: AnnotationType[] }>(),
);
export const getAnnotationTypesFailureAction = createAction(
  'GetAnnotationTypesFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const deleteAnnotationTypeRequestAction = createAction(
  'DeleteAnnotationTypeRequest',
  props<{ id: string }>(),
);
export const deleteAnnotationTypeSuccessAction = createAction(
  'DeleteAnnotationTypeSuccess',
  props<{ id: string }>(),
);
export const deleteAnnotationTypeFailureAction = createAction(
  'DeleteAnnotationTypeFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const postAnnotationTypeRequestAction = createAction(
  'PostAnnotationTypeRequest',
  props<{ request: AnnotationTypeRequest }>(),
);
export const postAnnotationTypeSuccessAction = createAction(
  'PostAnnotationTypeSuccess',
  props<{ annotationType: AnnotationType }>(),
);
export const postAnnotationTypeFailureAction = createAction(
  'PostAnnotationTypeFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const patchAnnotationTypeRequestAction = createAction(
  'PatchAnnotationTypeRequest',
  props<{ request: AnnotationTypePatchRequest }>(),
);
export const patchAnnotationTypeSuccessAction = createAction(
  'PatchAnnotationTypeSuccess',
  props<{ annotationType: AnnotationType }>(),
);
export const patchAnnotationTypeFailureAction = createAction(
  'PatchAnnotationTypeFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const patchAnnotationTypeOrderRequestAction = createAction(
  'PatchAnnotationTypeOrderRequest',
  props<{ id: string; newOrder: number; oldOrder: number }>(),
);
export const patchAnnotationTypeOrderSuccessAction = createAction(
  'PatchAnnotationTypeOrderSuccess',
  props<{
    response: AnnotationType;
    newOrder: number;
    oldOrder: number;
  }>(),
);
export const patchAnnotationTypeOrderFailureAction = createAction(
  'PatchAnnotationTypeOrderFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const changeATPageAction = createAction(
  'ChangeATPage',
  props<{ page: number }>(),
);
export const nextATPageAction = createAction('nextATPageAction');
export const prevATPageAction = createAction('prevATPageAction');

export const changeATPageLeftAction = createAction(
  'ChangeATPageLeft',
  props<{ page: number }>(),
);
export const nextATPageLeftAction = createAction('NextATPageLeft');
export const prevATPageLeftAction = createAction('PrevATPageLeft');
export const changeATPageRightAction = createAction(
  'ChangeATPageRight',
  props<{ page: number }>(),
);
export const nextATPageRightAction = createAction('NextATPageRight');
export const prevATPageRightAction = createAction('PrevATPageRight');
