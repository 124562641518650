<div *ngIf="!upgrade" class="match-day-container">
  <cmv-separator class="separator" [date]="matchesDate"></cmv-separator>
  <div class="matches-container">
    <div
      class="matches-today grid-container matches-list-grid"
      *ngIf="isToday; else notToday"
    >
      <div class="grid-container matches-grid" *ngIf="matches.length > 0">
        <div class="match" *ngFor="let match of matches">
          <cmv-match-widget
            [hideScore]="hideScore"
            [navigation]="isNavigationEnabled && true"
            [match]="match"
            [isInUserTrash]="isInUserTrash"
          ></cmv-match-widget>
        </div>
      </div>
    </div>
    <ng-template #notToday>
      <div class="matches-other">
        <div class="grid-container matches-list-grid">
          <div class="grid-container matches-grid" *ngIf="matches.length > 0">
            <div class="match" *ngFor="let match of matches">
              <cmv-match-widget
                [hideScore]="hideScore"
                [navigation]="isNavigationEnabled && true"
                [match]="match"
                [isInUserTrash]="isInUserTrash"
              ></cmv-match-widget>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<div *ngIf="upgrade" class="upgrade-container match-day-container">
  <cmv-separator
    class="separator"
    [date]="nearestPremiumMatchDate$ | async"
  ></cmv-separator>
  <div class="matches-container">
    <div class="matches-other">
      <div class="grid-container matches-list-grid">
        <div class="grid-container matches-grid">
          <div class="match upgrade-content-container">
            <div class="match upgrade-opacity">
              <cmv-match-widget></cmv-match-widget>
            </div>
            <div class="upgrade-content">
              <div class="upgrade-text cmv-h2">
                {{ 'components.recordingList.upgradeText' | translate }}
              </div>
              <div
                class="upgrade-accept-button cmv-accept-button cmv-h4"
                (click)="onGetPremium()"
              >
                {{ 'components.recordingList.upgradeButton' | translate }}
              </div>
            </div>
          </div>
          <div class="match upgrade-opacity">
            <cmv-match-widget></cmv-match-widget>
          </div>
          <div class="match upgrade-opacity">
            <cmv-match-widget></cmv-match-widget>
          </div>
          <div class="match upgrade-opacity">
            <cmv-match-widget></cmv-match-widget>
          </div>
          <div class="match upgrade-opacity">
            <cmv-match-widget></cmv-match-widget>
          </div>
          <div class="match upgrade-opacity">
            <cmv-match-widget></cmv-match-widget>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
