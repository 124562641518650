import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { Video } from 'src/api/models';
import { ChangeSrcModel } from '../../models/change-src.model';

@Component({
  selector: 'cmv-video-control-panel',
  templateUrl: './video-control-panel.component.html',
  styleUrls: ['./video-control-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoControlPanelComponent {
  @Input() title: string;
  @Input() description: string;

  @Input() isLoadingVisible: boolean = false;
  @Input() showChangeVideoSource: boolean = false;
  @Input() currentVideo: Video;
  @Input() videoSources: Video[];

  @Input() downloadEnabled: boolean = false;
  @Input() downloadVideos: Video[];
  @Input() liveVideos: Video[];

  @Input() infoEnabled: boolean = false;

  @Output() changeSrc = new EventEmitter<ChangeSrcModel>();

  constructor() {}
}
