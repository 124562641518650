import { Action, createReducer, on } from '@ngrx/store';
import { PtzState } from '../../models/app.state';
import {
  hidePtzRequestAction,
  setPtzViewSuccessAction,
  showPtzRequestAction,
  showPtzSuccessAction,
} from '../actions/ptz.actions';

const initialState: PtzState = {
  overlayVisible: false,
};

const reducer = createReducer(
  initialState,
  on(showPtzRequestAction, state => ({ ...state, overlayVisible: false })),
  on(showPtzSuccessAction, (state, { manual, view }) => ({
    ...state,
    overlayVisible: true,
    manual,
    view,
    setAt: undefined,
    changeAt: undefined,
  })),
  on(setPtzViewSuccessAction, (state, { manual, view, changeAt, setAt }) => ({
    ...state,
    manual,
    view,
    setAt,
    changeAt,
  })),
  on(hidePtzRequestAction, state => ({
    ...state,
    manual: false,
    view: undefined,
    overlayVisible: false,
    setAt: undefined,
    changeAt: undefined,
  })),
);

export function ptztReducer(state: PtzState, action: Action): PtzState {
  return reducer(state, action);
}
