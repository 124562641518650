<div class="video-control-panel--wrapper">
  <div class="video-control-panel">
    <div class="cmv-h4">
      {{ title }}
    </div>
    <div class="controls-wrapper">
      <cmv-change-src-menu
        *ngIf="showChangeVideoSource"
        [currentVideo]="currentVideo"
        [videoSources]="videoSources"
        (changeSrc)="changeSrc.emit($event)"
      ></cmv-change-src-menu>
      <cmv-download-video-menu
        *ngIf="downloadEnabled"
        [downloadVideos]="downloadVideos"
        [liveVideos]="liveVideos"
      ></cmv-download-video-menu>
      <cmv-recording-info *ngIf="infoEnabled"></cmv-recording-info>
    </div>
  </div>
  <div class="description cmv-body" *ngIf="description">
    {{ description }}
  </div>
  <cmv-loading-bar *ngIf="isLoadingVisible"></cmv-loading-bar>
</div>
