import { CustomerState } from '../../models/app.state';
import { Action, INIT } from '@ngrx/store';
import { CUSTOMERS } from '../../models/customer';

const initialState: CustomerState = CUSTOMERS.default;

export function customerReducer(
  state: CustomerState = initialState,
  action: Action,
): CustomerState {
  switch (action.type) {
    case INIT:
      return {
        ...state,
      };

    default:
      return state;
  }
}
