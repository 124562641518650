import { createAction, props } from '@ngrx/store';

export const showErrorOverlay = createAction('ShowErrorOverlay');

export const hideErrorOverlay = createAction('HideErrorOverlay');

export const toggleSearchBarAction = createAction('ToggleSearchBar');
export const setSearchBarVisibilityAction = createAction(
  'SetSearchBarVisibility',
  props<{ visible: boolean; route: string }>(),
);

export const toggleSearchFiltersUiAction = createAction(
  'ToggleSearchFiltersUi',
);
export const hideSearchFiltersUiAction = createAction('HideSearchFiltersUi');

export const setDrawingOverlayVisibilityAction = createAction(
  'SetDrawingOverlayVisibility',
  props<{ visible: boolean }>(),
);

export const toggleVideoTaggingPanelAction = createAction(
  'ToggleVideoTaggingPanel',
);

export const setVideoVisibleAction = createAction(
  'SetVideoVisibleAction',
  props<{ videoVisible: boolean }>(),
);

export const closeIOSPWAFullscreenWarningAction = createAction(
  'CloseIOSPWAFullscreenWarning',
);

export const installPwaAction = createAction('InstallPwa');

export const setClosedPWAButtonAction = createAction(
  'SetClosedPWAButton',
  props<{ value: boolean }>(),
);

export const setPWAAction = createAction('SetPWA', props<{ value: boolean }>());

export const toggleMoreSubmenuAction = createAction('ToggleSubmenu');

export const closeMoreSubmenuAction = createAction('CloseSubmenu');
export const toggleMenuCollapse = createAction('toggleMenuCollapse');
