import { AnnotationType } from '../../../../api/models';
import {
  changeATPageAction,
  changeATPageLeftAction,
  changeATPageRightAction,
  deleteAnnotationTypeFailureAction,
  deleteAnnotationTypeRequestAction,
  deleteAnnotationTypeSuccessAction,
  getAnnotationTypesFailureAction,
  getAnnotationTypesRequestAction,
  getAnnotationTypesSuccessAction,
  patchAnnotationTypeFailureAction,
  patchAnnotationTypeOrderFailureAction,
  patchAnnotationTypeOrderRequestAction,
  patchAnnotationTypeOrderSuccessAction,
  patchAnnotationTypeRequestAction,
  patchAnnotationTypeSuccessAction,
  postAnnotationTypeFailureAction,
  postAnnotationTypeRequestAction,
  postAnnotationTypeSuccessAction,
  prevATPageAction,
  prevATPageLeftAction,
  prevATPageRightAction,
} from '../actions/annotation-types.actions';
import { AnnotationTypesState } from '../../models/app.state';
import { Action, createReducer, on } from '@ngrx/store';

const initialState: AnnotationTypesState = {
  errors: [],
  loading: false,
  loaded: false,
  metadata: {
    currentPage: 0,
    currentPageLeft: 0,
    currentPageRight: 0,
  },
};

const reducer = createReducer(
  initialState,
  on(
    getAnnotationTypesRequestAction,
    deleteAnnotationTypeRequestAction,
    postAnnotationTypeRequestAction,
    patchAnnotationTypeRequestAction,
    patchAnnotationTypeOrderRequestAction,
    state => ({ ...state, loading: true }),
  ),
  on(getAnnotationTypesSuccessAction, (state, { annotationTypes }) => ({
    ...state,
    entity: annotationTypes,
    loading: false,
    loaded: true,
    metadata: {
      ...state.metadata,
      currentPage: 0,
      currentPageLeft: 0,
      currentPageRight: 0,
    },
    errors: [],
  })),
  on(deleteAnnotationTypeSuccessAction, (state, { id }) => {
    const deletedOrder = state.entity!.find(e => e.id === id)!;
    const entity = state
      .entity!.filter(e => e.id !== id)
      .map(newEnt =>
        newEnt.order <= deletedOrder.order
          ? newEnt
          : { ...newEnt, order: newEnt.order - 1 },
      );
    return {
      ...state,
      entity,
      loading: false,
      loaded: true,
      errors: [],
      metadata: {
        ...state.metadata,
        currentPage: 0,
        currentPageLeft: 0,
        currentPageRight: 0,
      },
    };
  }),
  on(postAnnotationTypeSuccessAction, (state, { annotationType }) => {
    const entity = [...(state.entity || []), annotationType];
    return {
      ...state,
      entity,
      loading: false,
      loaded: true,
      errors: [],
      metadata: {
        ...state.metadata,
        currentPage: 0,
        currentPageLeft: 0,
        currentPageRight: 0,
      },
    };
  }),
  on(patchAnnotationTypeSuccessAction, (state, { annotationType }) => {
    const entity =
      state.entity != null
        ? state.entity.map(e =>
            e.id === annotationType.id ? annotationType : e,
          )
        : [annotationType];
    return {
      ...state,
      entity,
      loading: false,
      loaded: true,
      metadata: {
        ...state.metadata,
        currentPage: 0,
        currentPageLeft: 0,
        currentPageRight: 0,
      },
      errors: [],
    };
  }),
  on(patchAnnotationTypeOrderSuccessAction, (state, { oldOrder, newOrder }) => {
    const entity: AnnotationType[] = (state.entity || []).map(ent => {
      if (
        oldOrder < newOrder &&
        ent.order >= oldOrder + 1 &&
        ent.order <= newOrder
      ) {
        return { ...ent, order: ent.order - 1 };
      } else if (
        oldOrder > newOrder &&
        ent.order >= newOrder &&
        ent.order <= oldOrder - 1
      ) {
        return { ...ent, order: ent.order + 1 };
      } else if (ent.order === oldOrder) {
        return { ...ent, order: newOrder };
      } else {
        return { ...ent };
      }
    });
    return { ...state, entity, loading: false, loaded: true, errors: [] };
  }),
  on(changeATPageAction, (state, { page }) => ({
    ...state,
    metadata: {
      ...state.metadata!,
      currentPage: page,
    },
  })),
  on(prevATPageAction, state => ({
    ...state,
    metadata: {
      ...state.metadata!,
      currentPage:
        state.metadata!.currentPage > 0 ? state.metadata!.currentPage - 1 : 0,
    },
  })),
  on(changeATPageLeftAction, (state, { page }) => ({
    ...state,
    metadata: { ...state.metadata!, currentPageLeft: page },
  })),
  on(prevATPageLeftAction, state => ({
    ...state,
    metadata: {
      ...state.metadata!,
      currentPageLeft:
        state.metadata!.currentPageLeft > 0
          ? state.metadata!.currentPageLeft - 1
          : 0,
    },
  })),
  on(changeATPageRightAction, (state, { page }) => ({
    ...state,
    metadata: { ...state.metadata!, currentPageRight: page },
  })),
  on(prevATPageRightAction, state => ({
    ...state,
    metadata: {
      ...state.metadata!,
      currentPageRight:
        state.metadata!.currentPageRight > 0
          ? state.metadata!.currentPageRight - 1
          : 0,
    },
  })),
  on(
    getAnnotationTypesFailureAction,
    deleteAnnotationTypeFailureAction,
    postAnnotationTypeFailureAction,
    patchAnnotationTypeFailureAction,
    patchAnnotationTypeOrderFailureAction,
    (state, { errors }) => ({
      ...state,
      loading: false,
      errors,
    }),
  ),
);

export function annotationTypesReducer(
  state: AnnotationTypesState,
  action: Action,
): AnnotationTypesState {
  return reducer(state, action);
}
