<div class="cmv-modal-outer-card notification-mobile">
  <div class="cmv-modal-inner-card inner-card-notification">
    <div class="cmv-modal-close-button" (click)="closeDialog()">
      <mat-icon
        class="modal-icon-closing cmv-dim-gray"
        svgIcon="cmv-closing"
      ></mat-icon>
    </div>

    <div class="main-info">
      <div class="icon-wrapper icon-wrapper-notification">
        <mat-icon
          class="modal-icon cmv-gray-icon"
          [svgIcon]="notificationData.icon"
        ></mat-icon>
      </div>
      <div class="cmv-h1 notification-title">
        {{
          notificationData.title.translate
            ? (notificationData.title.text | translate)
            : notificationData.title.text
        }}
      </div>
      <h2
        class="cmv-h2 notification-subtitle"
        *ngIf="notificationData.subTitle"
      >
        {{
          notificationData.subTitle?.translate
            ? (notificationData.subTitle?.text | translate)
            : notificationData.subTitle?.text
        }}
      </h2>
      <div class="cmv-body notification-text-container">
        {{
          notificationData.text.translate
            ? (notificationData.text.text | translate)
            : notificationData.text.text
        }}
      </div>
    </div>
    <div class="buttons buttons-first-time-login">
      <div class="cmv-accept-button" (click)="closeDialog()">
        <div class="cmv-h4">{{ 'basic.ok' | translate }}</div>
      </div>
    </div>
  </div>
</div>
