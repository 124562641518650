import { Injectable } from '@angular/core';
import { PwaInstallGuideModalComponent } from '../shared/components/modal/pwa-install-guide-modal/pwa-install-guide-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  promptEvent: any;

  constructor(private readonly dialog: MatDialog) {
    window.addEventListener('beforeinstallprompt', (event: any) => {
      event.preventDefault();
      this.promptEvent = event;
    });
  }

  public initPwaPrompt(): void {
    if (!!this.promptEvent) {
      this.promptEvent.prompt();
    } else {
      this.dialog.open(PwaInstallGuideModalComponent);
    }
  }
}
