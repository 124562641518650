import { Club, Team } from '../../../../api/models';
import { TeamModel } from '../../models/manual-scheduling.model';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { FormControl, ValidationErrors } from '@angular/forms';
import { AppState } from '../../models/app.state';
import { select, Store } from '@ngrx/store';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import {
  $clubLogo,
  $clubName,
  $clubs,
} from '../../store/selectors/clubs.selectors';
import {
  $loadingClub,
  $teamNameById,
  $teamsGroupByClub,
} from '../../store/selectors/teams.selectors';
import { MatOptionSelectionChange } from '@angular/material/core';
import { getTeamsRequestAction } from '../../store/actions/teams.actions';
import { getClubTeamName } from 'src/app/app.utils';

const MAX_ITEMS = 30;

interface ClubInfo extends Club {
  teams?: Team[];
}

@Component({
  selector: 'cmv-team-search',
  templateUrl: './team-search.component.html',
  styleUrls: ['./team-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamSearchComponent implements OnDestroy, AfterViewInit {
  @Input() placeholder = 'components.manualScheduling.club';
  @Input() label = 'components.manualScheduling.searchTeam';
  @Input() error: string;
  @Input() showImage = false;

  clubLogo$: Observable<string>;

  clubData$ = this.store.pipe(select($clubs));
  teamData$ = this.store.pipe(select($teamsGroupByClub));

  changed$ = new ReplaySubject<boolean>();

  logoLoaded = false;
  selectedTeamName: string;
  teamName$ = new ReplaySubject<string>();

  canShowLogo$: Observable<boolean>;

  public clubFilterCtrl: FormControl = new FormControl();
  public filteredClubs$: Observable<ClubInfo[]> = this.clubData$;

  protected unsubscribe$ = new Subject<void>();
  private storedTeamCtrl: FormControl;

  get teamCtrl(): FormControl {
    return this.storedTeamCtrl;
  }

  @Input() set teamCtrl(control: FormControl) {
    this.storedTeamCtrl = control;

    if (control.value != null) {
      combineLatest([
        this.store.pipe(select($clubName(control.value.clubId))),
        this.store.pipe(select($teamNameById(control.value.teamId))),
      ])
        .pipe(
          filter(
            ([clubName, teamName]: [string, string]) =>
              clubName !== '' && teamName !== '',
          ),
          map(([clubName, teamName]) => getClubTeamName(clubName, teamName)),
          take(1),
          takeUntil(this.unsubscribe$),
        )
        .subscribe(clubTeamName => {
          this.selectedTeamName = clubTeamName;
          this.clubFilterCtrl.setValue(clubTeamName);
          this.changed$.next(false);
          this.teamName$.next(clubTeamName);
        });
    }

    this.clubLogo$ = control.valueChanges.pipe(
      startWith(control.value),
      filter(value => value != null),
      distinctUntilChanged(
        (prev, current) => prev && current && prev.clubId === current.clubId,
      ),
      switchMap(value => this.store.pipe(select($clubLogo(value.clubId)))),
      shareReplay(1),
    );

    this.canShowLogo$ = combineLatest([
      this.clubLogo$,
      this.changed$.asObservable(),
    ]).pipe(
      map(([logo, changed]) => logo != null && !changed),
      takeUntil(this.unsubscribe$),
    );

    control.registerOnDisabledChange(val => {
      if (val) {
        this.clubFilterCtrl.disable();
      }
    });
  }

  constructor(private readonly store: Store<AppState>) {
    this.filteredClubs$ = combineLatest([
      this.clubFilterCtrl.valueChanges.pipe(
        distinctUntilChanged((prev, next) => prev === next),
        switchMap(search => {
          const changed = typeof search === 'string';

          this.changed$.next(changed);

          if (changed && search !== this.selectedTeamName) {
            this.teamCtrl.setValue(null);
          }

          if (this.clubFilterCtrl.value !== '' && changed) {
            this.clubFilterCtrl.setErrors({
              ...this.clubFilterCtrl.errors,
              invalidTeam: 'Invalid team',
            } as ValidationErrors);
          }

          if (!this.teamCtrl.value) {
            this.clubFilterCtrl.setErrors({
              ...this.clubFilterCtrl.errors,
              required: 'Required field',
            } as ValidationErrors);
          }

          return this.clubData$.pipe(
            map(clubs =>
              clubs
                .filter(club =>
                  club.name
                    .toLowerCase()
                    .includes(
                      typeof search === 'string'
                        ? search.toLowerCase()
                        : this.selectedTeamName,
                    ),
                )
                .slice(0, MAX_ITEMS),
            ),
          );
        }),
      ),
      this.teamData$,
    ]).pipe(
      map(([clubs, teams]: [Club[], { [index: string]: TeamModel }]) =>
        clubs.map(club => ({
          ...club,
          teams: Object.values(teams[club.id] || {}),
        })),
      ) as any,
    );
  }

  ngAfterViewInit(): void {
    if (!this.teamCtrl.value) {
      this.clubFilterCtrl.setValue('');
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadTeams(_: Event, id: string): void {
    this.store.dispatch(getTeamsRequestAction({ id, forceSearch: false }));
  }

  getFirstError(): string {
    const errors = this.clubFilterCtrl.errors;
    return errors ? Object.keys(errors)[0] : '';
  }

  isTeamSelected = (id: string): boolean =>
    this.teamCtrl.value && this.teamCtrl.value.teamId === id;

  clubLoading(id: string): Observable<boolean> {
    return this.store.pipe(take(1), select($loadingClub(id)));
  }

  logoImageLoaded(): void {
    this.logoLoaded = true;
  }

  setTeam($event: MatOptionSelectionChange): void {
    const value = $event.source.value;
    const clubTeamName = getClubTeamName(value.club.name, value.team.name);
    this.teamCtrl.setValue({ clubId: value.club.id, teamId: value.team.id });
    this.selectedTeamName = clubTeamName;
    this.clubFilterCtrl.setValue(clubTeamName);
    this.changed$.next(false);
    this.teamName$.next(clubTeamName);
    this.logoLoaded = false;
  }

  getClubTeamsOptions(club: ClubInfo) {
    return club.teams
      ?.map(t => ({
        name: t.name === club.name ? 'A' : t.name,
        id: t.id,
        value: t,
      }))
      .sort((a, b) => {
        if (a.value.name === club.name) {
          return -1;
        } else if (b.value.name === club.name) {
          return 1;
        }

        return a.name.localeCompare(b.name);
      });
  }

  trackBy = (_: number, item: { id: string }) => item.id;
}
