import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cmv-default-terms-of-use',
  templateUrl: './default-terms-of-use.component.html',
  styleUrls: ['./default-terms-of-use.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultTermsOfUseComponent {
  @Input() modalVersion = false;
}
