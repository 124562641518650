import {
  hideSnackbarAction,
  scheduleSnackbarAction,
} from '../actions/snackbar.actions';
import { SnackbarState } from '../../models/app.state';
import { Action, createReducer, on } from '@ngrx/store';

const initialState: SnackbarState = {
  infoMessage: '',
  willHideAt: null,
};

const reducer = createReducer(
  initialState,
  on(scheduleSnackbarAction, (_, { snackbarState }) => ({ ...snackbarState })),
  on(hideSnackbarAction, state => ({ ...state, willHideAt: null })),
);

export function snackbarReducer(
  state: SnackbarState,
  action: Action,
): SnackbarState {
  return reducer(state, action);
}
