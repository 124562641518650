<div
  class="slider-container"
  [style.width.px]="canvasWidth"
  [style.height.px]="canvasHeight"
>
  <canvas
    #myCanvas
    class="slider-canvas"
    [width]="canvasWidth"
    [height]="canvasHeight"
  ></canvas>
  <div class="slider" (click)="clickHandle($event)">
    <div
      class="knob"
      #knob
      (pan)="swipeHandle($event)"
      [style.width.px]="knobSize"
      [style.height.px]="knobSize"
      [style.left.px]="knobLeft"
      [style.transform]="transformY"
      [style.transition]="transition"
      [style.backgroundColor]="bgColor"
      [style.bottom.px]="bottom"
    ></div>
  </div>
</div>
