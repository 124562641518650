import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { screenResizeAction } from '../actions/responsivity.actions';

const SCREEN_RESIZE_DEBOUNCE = 200;

@Injectable()
export class ResponsivityEffects {
  private readonly screenWidth$ = new Observable<number>(subscriber => {
    window.addEventListener('resize', () => subscriber.next(window.innerWidth));
    window.addEventListener('orientationchange', () =>
      subscriber.next(window.innerWidth),
    );
  });

  screenResize$ = createEffect(() =>
    this.screenWidth$.pipe(
      debounceTime(SCREEN_RESIZE_DEBOUNCE),
      map(screenWidth => screenResizeAction({ screenWidth })),
    ),
  );
}
