import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { CurrentVideoModel } from '../../models/current-video.model';
import { Video } from 'src/api/models';
import { ChangeSrcModel } from '../../models/change-src.model';

@Component({
  selector: 'cmv-change-src-menu',
  templateUrl: './change-src-menu.component.html',
  styleUrls: ['./change-src-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeSrcMenuComponent {
  @Input() currentVideo: CurrentVideoModel;
  @Input() videoSources: Video[];

  @Output() changeSrc = new EventEmitter<ChangeSrcModel>();

  videoClick(video: Video): void {
    this.changeSrc.emit({ currentVideo: this.currentVideo, video });
  }
}
