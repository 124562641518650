import {
  INPUT_TYPE,
  RemoveModalComponent,
} from '../modal/remove-modal/remove-modal.component';
import { Router } from '@angular/router';
import { RecordingRequest } from '../../../../api/models';
import { getClubsRequestAction } from '../../store/actions/clubs.actions';
import { TeamValidators } from '../../validators/team-validators';
import {
  ManualSchedulingInputTypes,
  ManualSchedulingModel,
} from '../../models/manual-scheduling.model';
import { DateValidators } from '../../validators/date-time-validators';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { AppState } from '../../models/app.state';
import { $recorders } from '../../store/selectors/recorders.selectors';
import { RecordingType } from 'src/api/models';
import { Subject, timer } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { RoutePath } from '../../../app.constants';
import { OnlyRequired } from '../../../app.utils';
import { Languages } from '../../models/customer';
import { getTeamsRequestAction } from '../../store/actions/teams.actions';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  deleteRecordingRequestAction,
  postRecordingRequestAction,
  putRecordingRequestAction,
  stopRecordingRequestAction,
} from '../../store/actions/recording.actions';
import {
  getRecorderCameraRequestAction,
  resetRecorderCamerasAction,
} from '../../store/actions/camera.actions';
import { $nonDuplicateCameras } from '../../store/selectors/camera.selectors';
import { $recordingsLoading } from '../../store/selectors/recording-list.selectors';
import { $clubsLoaded } from '../../store/selectors/clubs.selectors';

const DEFAULT_DURATION = 7200;
const NOW_ADD_MINUTES = 15;
const SECONDS = 60;
const MIN_DURATION = 5;
const MAX_DURATION = 180;

@Component({
  selector: 'cmv-manual-scheduling-form',
  templateUrl: './manual-scheduling-form.component.html',
  styleUrls: ['./manual-scheduling-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManualSchedulingFormComponent implements OnInit, OnDestroy {
  @Input() data: ManualSchedulingModel = {
    id: '',
    date: moment().add(NOW_ADD_MINUTES, 'minutes').unix(),
    duration: DEFAULT_DURATION,
    recorderId: '',
    manualExports: [],
    type: RecordingType.MATCH,
  };

  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store<AppState>,
    private readonly router: Router,
    public readonly dialog: MatDialog,
    private readonly dateAdapter: DateAdapter<Date>,
  ) {
    this.store.dispatch(getClubsRequestAction());
  }

  alreadyRunning: string;
  manualSchedulingInputTypes = ManualSchedulingInputTypes;
  recordingType = RecordingType;

  form: FormGroup;
  recorders$ = this.store.pipe(select($recorders));
  recordingsLoading$ = this.store.pipe(select($recordingsLoading));
  clubsLoaded$ = this.store.pipe(select($clubsLoaded));

  protected unsubscribe$ = new Subject<void>();

  static buildBaseFormOutput(
    formData: ManualSchedulingModel & { time: string },
  ): OnlyRequired<RecordingRequest> {
    const { time, recorderId, duration, type, manualExports } = formData;
    const [hours, minutes] = time.split(':');
    const dateTime = moment(formData.date)
      .hours(Number(hours))
      .minutes(Number(minutes))
      .seconds(0)
      .unix();

    return {
      recorderId,
      duration: duration * SECONDS,
      type,
      manualExports,
      dateTime,
    };
  }

  static buildMatchFormOutput(
    formData: ManualSchedulingModel & { time: string },
  ): RecordingRequest {
    return {
      ...ManualSchedulingFormComponent.buildBaseFormOutput(formData),
      teamHome: {
        teamId: formData.team1!.teamId,
      },
      teamAway: {
        teamId: formData!.team2!.teamId,
      },
    };
  }

  static buildTrainingFormOutput(
    formData: ManualSchedulingModel & { time: string },
  ): RecordingRequest {
    return {
      ...ManualSchedulingFormComponent.buildBaseFormOutput(formData),
      teamHome: {
        teamId: formData.team1!.teamId,
      },
    };
  }

  static buildOtherFormOutput(
    formData: ManualSchedulingModel & { time: string },
  ): RecordingRequest {
    return {
      ...ManualSchedulingFormComponent.buildBaseFormOutput(formData),
      name: formData.title || '',
    };
  }

  get now(): Date {
    return moment().toDate();
  }

  get nextWeek(): Date {
    return moment().add('week', 1).toDate();
  }

  get showTime(): boolean {
    if (this.data && this.form) {
      const date = (
        this.form.get(ManualSchedulingInputTypes.DATE) as FormControl
      ).value;
      const duration =
        (this.form.get(ManualSchedulingInputTypes.DURATION) as FormControl)
          .value * 60;
      return moment().isBetween(
        moment(date),
        moment(date).add(duration, 'seconds'),
      );
    }
    return false;
  }

  cameras$ = (currentCameras: string[]) =>
    this.store.select($nonDuplicateCameras(currentCameras));

  ngOnInit(): void {
    this.dateAdapter.setLocale(Languages.ENGLISH);

    if (this.data.team1) {
      this.store.dispatch(
        getTeamsRequestAction({
          id: this.data.team1.clubId,
          forceSearch: true,
        }),
      );
    }

    if (this.data.team2) {
      this.store.dispatch(
        getTeamsRequestAction({
          id: this.data.team2.clubId,
          forceSearch: true,
        }),
      );
    }

    const date = moment.unix(this.data.date);
    this.form = this.fb.group({
      [ManualSchedulingInputTypes.DATE]: [
        date.toDate(),
        {
          validators: [
            Validators.required,
            DateValidators.dateMinimum(this.now, 'day'),
            DateValidators.dateMaximum(this.nextWeek, 'day'),
          ],
        },
      ],
      [ManualSchedulingInputTypes.TIME]: [
        date.format('HH:mm'),
        {
          validators: [Validators.required, DateValidators.laterTodayValidator],
        },
      ],
      [ManualSchedulingInputTypes.DURATION]: [
        this.data.duration / SECONDS,
        {
          validators: [
            Validators.required,
            Validators.min(MIN_DURATION),
            Validators.max(MAX_DURATION),
          ],
        },
      ],
      [ManualSchedulingInputTypes.MANUAL_EXPORTS]: [
        this.data.manualExports,
        {
          validators: [Validators.required],
        },
      ],
      [ManualSchedulingInputTypes.RECORDER_ID]: [
        this.data.recorderId,
        {
          validators: [Validators.required],
        },
      ],
      [ManualSchedulingInputTypes.TYPE]: [
        this.data.type,
        {
          validators: [Validators.required],
        },
      ],
      [ManualSchedulingInputTypes.TEAM1]: [
        this.data.team1,
        {
          validators: [Validators.required],
        },
      ],
      [ManualSchedulingInputTypes.TEAM2]: [
        this.data.team2,
        {
          validators: [Validators.required, TeamValidators.notSameTeam],
        },
      ],
      [ManualSchedulingInputTypes.TITLE]: [
        this.data.title,
        { validators: [Validators.required] },
      ],
    });

    if (this.data.recorderId) {
      this.store.dispatch(
        getRecorderCameraRequestAction({ id: this.data.recorderId }),
      );
    }

    this.form.controls[ManualSchedulingInputTypes.RECORDER_ID].valueChanges
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe(id => {
        this.form.controls[ManualSchedulingInputTypes.MANUAL_EXPORTS].reset();

        if (
          this.data.manualExports &&
          this.data.recorderId &&
          this.form.controls[ManualSchedulingInputTypes.RECORDER_ID].value ===
            this.data.recorderId
        ) {
          this.form.controls[
            ManualSchedulingInputTypes.MANUAL_EXPORTS
          ].setValue(this.data.manualExports);
        }

        this.store.dispatch(getRecorderCameraRequestAction({ id }));
      });

    this.handleScheduleTypeChange(this.data.type);

    timer(0, 1000)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        const now = moment();
        const diff = moment.duration(now.diff(date));
        if (date.isBefore(now)) {
          this.form.disable();
          this.alreadyRunning = `${this.padTimeValue(
            diff.hours() * 60 + diff.minutes(),
          )}:${this.padTimeValue(diff.seconds())}`;
        }
      });

    this.form
      .get(ManualSchedulingInputTypes.TYPE)!
      .valueChanges.subscribe(val => {
        this.handleScheduleTypeChange(val);
      });
  }

  handleScheduleTypeChange(val: string): void {
    const team1Control = this.form.get('team1')!;
    const team2Control = this.form.get('team2')!;
    const titleControl = this.form.get('title')!;

    switch (val) {
      case RecordingType.MATCH:
        team1Control.setValidators([Validators.required]);
        team2Control.setValidators([
          Validators.required,
          TeamValidators.notSameTeam,
        ]);
        titleControl.setValidators([]);
        break;

      case RecordingType.TRAINING:
        team1Control.setValidators([Validators.required]);
        team2Control.setValidators([]);
        titleControl.setValidators([]);
        break;

      case RecordingType.OTHER:
        team1Control.setValidators([]);
        team2Control.setValidators([]);
        titleControl.setValidators([Validators.required]);
        break;

      default:
        break;
    }

    team1Control.updateValueAndValidity();
    team2Control.updateValueAndValidity();
    titleControl.updateValueAndValidity();
  }

  getFirstError(key: ManualSchedulingInputTypes): string {
    if (!this.form || !this.form.get(key)) {
      return '';
    }

    const errors = this.form.get(key)!.errors;
    return errors ? Object.keys(errors)[0] : '';
  }

  hasValue(
    key: ManualSchedulingInputTypes,
    value: ManualSchedulingModel[keyof ManualSchedulingModel],
  ): boolean {
    if (!this.form || !this.form.get(key)) {
      return false;
    }

    const formValue = this.form.get(key)!.value;
    return formValue && formValue === value;
  }

  delete(): void {
    this.recordingsLoading$.pipe(take(1)).subscribe(loading => {
      if (loading) {
        return;
      }

      const dialogInstance = this.dialog.open(RemoveModalComponent, {
        data: {
          title: this.data.title,
          type: INPUT_TYPE.RECORDING,
        },
      });

      if (dialogInstance != null) {
        dialogInstance
          .afterClosed()
          .pipe(take(1))
          .subscribe(value => {
            if (value) {
              this.store.dispatch(
                deleteRecordingRequestAction({ id: this.data.id }),
              );
              this.router.navigate([RoutePath.Platform, RoutePath.Recordings]);
            }
          });
      }
    });
  }

  cancel(): void {
    this.router.navigate([RoutePath.Platform, RoutePath.Recordings]);
  }

  stopRecording(): void {
    this.store.dispatch(stopRecordingRequestAction({ id: this.data.id }));
  }

  updateLength(): void {
    const durationCtrl = this.form.get('duration');
    durationCtrl!.setValue(durationCtrl!.value + NOW_ADD_MINUTES);
    this.submit();
  }

  submit(): void {
    if (this.form.invalid && this.form.enabled) {
      return;
    }

    let manualSchedulingForm;
    switch (this.form.value[ManualSchedulingInputTypes.TYPE]) {
      case RecordingType.MATCH:
        manualSchedulingForm =
          ManualSchedulingFormComponent.buildMatchFormOutput(this.form.value);
        break;
      case RecordingType.TRAINING:
        manualSchedulingForm =
          ManualSchedulingFormComponent.buildTrainingFormOutput(
            this.form.value,
          );
        break;
      case RecordingType.OTHER:
        manualSchedulingForm =
          ManualSchedulingFormComponent.buildOtherFormOutput(this.form.value);
        break;

      default:
        return;
    }

    if (this.data.id) {
      this.store.dispatch(
        putRecordingRequestAction({
          recordingUpdate: { id: this.data.id, ...manualSchedulingForm },
        }),
      );
    } else {
      this.store.dispatch(
        postRecordingRequestAction({ recordingRequest: manualSchedulingForm }),
      );
    }
  }

  padTimeValue(
    value: number,
    maxLength: number = 2,
    fillString: string = '0',
  ): string {
    return value.toString().padStart(maxLength, fillString);
  }

  ngOnDestroy(): void {
    this.store.dispatch(resetRecorderCamerasAction());
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
