import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { RecordingsComponent } from './recordings.component';
import { CreateRecordingViewComponent } from './create-recording-view/create-recording-view.component';
import { EditRecordingViewComponent } from './edit-recording-view/edit-recording-view.component';

@NgModule({
  declarations: [
    RecordingsComponent,
    CreateRecordingViewComponent,
    EditRecordingViewComponent,
  ],
  imports: [SharedModule],
})
export class RecordingsModule {}
