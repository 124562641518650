<div tabindex="0" #elementToFocus class="cmv-modal-outer-card">
  <div class="cmv-modal-inner-card login-failure-modal-wrapper">
    <div class="cmv-modal-close-button" (click)="closeModal()">
      <mat-icon
        class="modal-icon-closing cmv-dim-gray"
        svgIcon="cmv-closing"
      ></mat-icon>
    </div>

    <div class="main-info">
      <div class="icon-wrapper">
        <mat-icon class="modal-icon cmv-gray" svgIcon="cmv-player"></mat-icon>
      </div>
      <div class="cmv-h1 title">
        {{ 'components.modals.loginFailureModal.title' | translate }}
      </div>
      <div
        class="cmv-body subtitle"
        *ngFor="let authError of authErrors$ | async"
      >
        {{ authError.message }}
      </div>
    </div>
    <div class="buttons">
      <div class="cmv-accept-button" (click)="closeModal()">
        <div class="cmv-h4">
          {{ 'components.modals.loginFailureModal.ok' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
