import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import moment from 'moment';
import { Recording } from '../../../../api/models';
import { $nearestPremiumMatchDate } from '../../store/selectors/recording-list.selectors';
import { Subject } from 'rxjs';
import { AppState } from '../../models/app.state';
import { select, Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { PremiumFreeComponent } from '../modal/premium-free/premium-free.component';

@Component({
  selector: 'cmv-match-day',
  templateUrl: './match-day.component.html',
  styleUrls: ['./match-day.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchDayComponent implements OnDestroy {
  @Input() upgrade = false;
  @Input() hideScore = false;
  @Input() isInUserTrash = false;
  @Input() isNavigationEnabled = true;

  matches: Recording[] = [];
  nearestPremiumMatchDate$ = this.store.pipe(select($nearestPremiumMatchDate));
  private readonly unsubscribe$ = new Subject<void>();

  @Input() set matchDay(data: Recording[]) {
    this.matches = data;
  }

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialog: MatDialog,
  ) {}

  get matchesDate(): number {
    return this.matches.length > 0 ? this.matches[0].dateTime : 0;
  }

  get isToday(): boolean {
    return moment.unix(this.matchesDate).isSame(moment(), 'day');
  }

  onGetPremium(): void {
    this.dialog.open(PremiumFreeComponent);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
