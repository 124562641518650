<div
  class="video-tagging-wrapper"
  *ngIf="match$ | async as match"
  [@openClose]="
    (taggingPanelRolledUp$ | async)
      ? openingAnimationStates.OPEN
      : openingAnimationStates.CLOSED
  "
>
  <button class="video-tagging-toggler" (click)="toggleTaggingPanel()">
    <span class="arrow" [ngClass]="{ active: taggingPanelRolledUp$ | async }">
      <span></span>
      <span></span>
    </span>
  </button>
  <div class="video-tagging-panel-wrapper">
    <div
      class="header-video-tagging"
      [ngClass]="{
        'header-video-tagging-closed':
          (taggingPanelRolledUp$ | async) === false,
      }"
      (click)="toggleTaggingPanel()"
    >
      <div
        [style.background-image]="
          'url(' + (firstTaggingTeamIcon$ | async) + ')'
        "
        class="club-logo"
        [class.hidden]="(firstTaggingTeamIcon$ | async) == null"
      ></div>
      <div class="cmv-h2 header-title">
        {{ 'components.taggingPanel.addTags' | translate }}
      </div>
      <div
        [style.background-image]="
          'url(' + (secondTaggingTeamIcon$ | async) + ')'
        "
        class="club-logo"
        [class.hidden]="(secondTaggingTeamIcon$ | async) == null"
      ></div>
      <cmv-tagging-team-menu
        *ngIf="taggingPanelRolledUp$ | async"
        [inVideoPlayer]="true"
      ></cmv-tagging-team-menu>
    </div>

    <div
      class="panel-wrapper"
      [ngClass]="{
        'loading-annotation': annotationIsLoading$ | async,
        bothPannels: (currentTaggingTeam$ | async) === TaggingTeam.BOTH,
      }"
      [@fadeInOut]="
        (taggingPanelRolledUp$ | async)
          ? fadeAnimationStates.DISPLAYED
          : fadeAnimationStates.FADED
      "
    >
      <div
        *ngIf="(currentTaggingTeam$ | async) === TaggingTeam.BOTH"
        class="outer-circle"
      >
        <div class="inner-circle"></div>
      </div>
      <div
        *ngIf="(currentTaggingTeam$ | async) === TaggingTeam.BOTH"
        class="separator"
      ></div>
      <div *ngIf="showHomeTaggingTab$ | async" class="tagging-panel-paged">
        <div class="tagging-panel">
          <button
            class="paging-arrow-icon-wrapper"
            (click)="selectPreviousLeftPage()"
            [disabled]="prevPageLeftDisabled$ | async"
          >
            <mat-icon
              class="cmv-white-icon paging-arrow-icon"
              svgIcon="cmv-arrow-left"
            ></mat-icon>
          </button>
          <mat-tab-group
            class="left-side"
            animationDuration="0ms"
            [selectedIndex]="currentPageLeft$ | async"
            (swipeleft)="selectNextLeftPage()"
            (swiperight)="selectPreviousLeftPage()"
          >
            <mat-tab
              *ngFor="
                let page of annotationTypesPaged$ | async;
                let pageIndex = index;
                trackBy: trackByFn
              "
            >
              <div class="tag-buttons">
                <div
                  class="tag-button cmv-h5"
                  *ngFor="let tag of page; let tagIndex = index"
                  (touchend)="onCreateTag(tag, annotationTeam.HOME)"
                  (click)="onCreateTag(tag, annotationTeam.HOME)"
                >
                  <div class="tag-button-text">
                    {{ tag.name }}
                  </div>
                  <ng-container
                    *ngIf="
                      pageIndex * (tagsPerPage$ | async) +
                      tagIndex +
                      1 as result
                    "
                  >
                    <div class="tag-button-shortcut" *ngIf="result - 1 < 10">
                      ({{ result - 1 }})
                    </div>
                  </ng-container>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
          <button
            class="paging-arrow-icon-wrapper"
            (click)="selectNextLeftPage()"
            [disabled]="nextPageLeftDisabled$ | async"
          >
            <mat-icon
              class="cmv-white-icon paging-arrow-icon"
              svgIcon="cmv-arrow-right"
            ></mat-icon>
          </button>
        </div>
        <div class="page-mark-wrap">
          <div
            class="page-mark"
            *ngFor="let page of totalPages$ | async; let i = index"
            [ngClass]="{ 'page-mark-active': i === (currentPageLeft$ | async) }"
            (click)="setPageLeft(i)"
          ></div>
        </div>
      </div>
      <div *ngIf="showAwayTaggingTab$ | async" class="tagging-panel-paged">
        <div class="tagging-panel">
          <button
            class="paging-arrow-icon-wrapper"
            (click)="selectPreviousRightPage()"
            [disabled]="prevPageRightDisabled$ | async"
          >
            <mat-icon
              class="cmv-white-icon paging-arrow-icon"
              svgIcon="cmv-arrow-left"
            ></mat-icon>
          </button>
          <mat-tab-group
            class="right-side"
            animationDuration="0ms"
            [selectedIndex]="currentPageRight$ | async"
            (swipeleft)="selectNextRightPage()"
            (swiperight)="selectPreviousRightPage()"
          >
            <ng-container *ngIf="match.type !== recordingType.TRAINING">
              <mat-tab
                *ngFor="
                  let page of annotationTypesPaged$ | async;
                  let pageIndex = index;
                  trackBy: trackByFn
                "
              >
                <div class="tag-buttons">
                  <div
                    class="tag-button dark cmv-h5"
                    *ngFor="let tag of page; let tagIndex = index"
                    (click)="onCreateTag(tag, annotationTeam.AWAY)"
                    (touchend)="onCreateTag(tag, annotationTeam.AWAY)"
                  >
                    <div class="tag-button-text">
                      {{ tag.name }}
                    </div>
                    <ng-container
                      *ngIf="
                        pageIndex * (tagsPerPage$ | async) +
                        tagIndex +
                        1 as result
                      "
                    >
                      <div class="tag-button-shortcut" *ngIf="result - 1 < 10">
                        (Ctrl + {{ result - 1 }})
                      </div>
                    </ng-container>
                  </div>
                </div>
              </mat-tab>
            </ng-container>
          </mat-tab-group>
          <button
            class="paging-arrow-icon-wrapper"
            (click)="selectNextRightPage()"
            [disabled]="nextPageRightDisabled$ | async"
          >
            <mat-icon
              class="cmv-white-icon paging-arrow-icon"
              svgIcon="cmv-arrow-right"
            ></mat-icon>
          </button>
        </div>
        <div class="page-mark-wrap">
          <div
            class="page-mark"
            *ngFor="let page of totalPages$ | async; let i = index"
            [ngClass]="{
              'page-mark-active': i === (currentPageRight$ | async),
            }"
            (click)="setPageRight(i)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
