import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../models/app.state';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import {
  $isMenuCollapsed,
  $moreSubmenuOpen,
} from '../selectors/ui-flags.selectors';
import {
  closeIOSPWAFullscreenWarningAction,
  closeMoreSubmenuAction,
  installPwaAction,
  setClosedPWAButtonAction,
  setPWAAction,
  toggleMenuCollapse,
  toggleMoreSubmenuAction,
} from '../actions/ui-flags.actions';
import { PwaService } from '../../../services/pwa.service';
import { NavigationEnd, Router } from '@angular/router';
import { $xlBreakPoint } from '../selectors/responsivity.selectors';
import { fromEvent } from 'rxjs';
import { GoogleAnalyticsEvent } from 'src/app/app.constants';
import { reportToGA } from 'src/app/app.utils';
import { Platform } from '@angular/cdk/platform';

@Injectable()
export class UiFlagsEffects {
  closePwaButton$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setClosedPWAButtonAction),
        map(({ value }) =>
          localStorage.setItem('closedPWAButton', value ? 'true' : 'false'),
        ),
      ),
    { dispatch: false },
  );

  closedIOSPWAFullscreenWarning$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(closeIOSPWAFullscreenWarningAction),
        map(() =>
          localStorage.setItem('closedIOSPWAFullscreenWarning', 'true'),
        ),
      ),
    { dispatch: false },
  );

  installPwa$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(installPwaAction),
        map(() => this.pwa.initPwaPrompt()),
        tap(() => {
          reportToGA(GoogleAnalyticsEvent.INSTALL_PWA, {
            isIOS: this.platform.IOS,
            isAndroid: this.platform.ANDROID,
            userAgent: navigator.userAgent,
            maxTouchPoints: navigator.maxTouchPoints,
          });
        }),
      ),
    { dispatch: false },
  );

  changePwa$ = createEffect(() =>
    fromEvent(window.matchMedia('(display-mode: browser)'), 'change').pipe(
      map((value: MediaQueryListEvent) =>
        setPWAAction({ value: !value.matches }),
      ),
    ),
  );

  navigate$ = createEffect(() =>
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      withLatestFrom(this.store.pipe(select($xlBreakPoint))),
      filter(([, xl]) => xl),
      map(() => closeMoreSubmenuAction()),
    ),
  );

  openMoreMenu$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(toggleMoreSubmenuAction),
        withLatestFrom(this.store.select($moreSubmenuOpen)),
        filter(([_, isOpen]) => isOpen),
        tap(() => {
          reportToGA(GoogleAnalyticsEvent.MORE_MENU);
        }),
      ),
    { dispatch: false },
  );

  toggleMenuCollapse$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(toggleMenuCollapse),
        withLatestFrom(this.store.select($isMenuCollapsed)),
        tap(([_, menuCollapsed]) => {
          sessionStorage.setItem('isMenuCollapsed', String(menuCollapsed));
          reportToGA(GoogleAnalyticsEvent.TOGGLE_MENU, {
            collapsed: menuCollapsed,
          });
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly actions$: Actions,
    private readonly pwa: PwaService,
    private readonly router: Router,
    private readonly platform: Platform,
  ) {}
}
