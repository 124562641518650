import { AppState, CameraState } from '../../models/app.state';
import { createSelector } from '@ngrx/store';

const $cameraState = (state: AppState) => state.camera;

export const $cameras = createSelector(
  $cameraState,
  (state: CameraState): string[] => state.entity || [],
);

export const $nonDuplicateCameras = (exports: string[]) =>
  createSelector($cameras, (cameras): string[] => {
    const nonDuplicate: string[] = exports.reduce(
      (acc: string[], curr) =>
        !!cameras.find(c => c === curr) ? acc : [...acc, curr],
      [],
    );
    return [...cameras, ...nonDuplicate];
  });
