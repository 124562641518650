<div
  [ngClass]="{
    'fake-video-fullscreen': isFakeFullscreen$ | async,
  }"
  class="video-stream cmv-16-9-ratio-container"
  *ngIf="!isMissingVideo; else noVideo"
>
  <div class="video-player--inner" [hidden]="notInRange">
    <cmv-video-player
      [offset]="offset"
      [videoUrl]="videoUrl"
      [loading]="loading"
      [autoplay]="autoplay"
      [createTag]="createTag"
      [enableChangeVideoSource]="enableChangeVideoSource"
      [currentVideo]="currentVideo"
      [videoSources]="videoSources"
      (clipEnded)="clipEnded.emit($event)"
      (changeSrc)="changeSrc.emit($event)"
      (seeked)="seeked.emit($event)"
      (videoCurrentTime)="videoCurrentTime.emit($event)"
      (playerReady)="playerReady.emit()"
      #videoPlayer
    ></cmv-video-player>
  </div>
  <div class="video-player--inner" *ngIf="notInRange">
    <ng-container *ngTemplateOutlet="noVideo"></ng-container>
  </div>
</div>

<ng-template #noVideo>
  <div class="cmv-16-9-ratio-container">
    <div class="cmv-not-in-range" *ngIf="isMissingVideo">
      <mat-icon class="cmv-white-icon" svgIcon="cmv-play"></mat-icon>

      <ng-container>
        <!-- Video not found -->
        <div class="cmv-h1 cmv-not-in-range-title">
          {{ videoMissingTitle | translate }}
        </div>
        <div class="cmv-h5">
          {{ videoMissingSubTitle | translate }}
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
