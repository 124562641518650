import {
  getMoreRecordingsFailureAction,
  getMoreRecordingsRequestAction,
  getMoreRecordingsSuccessAction,
  getRecordingsMetaFailureAction,
  getRecordingsMetaRequestAction,
  getRecordingsMetaSuccessAction,
  refreshRecordingsFailureAction,
  refreshRecordingsRequestAction,
  refreshRecordingsSuccessAction,
  refreshSingleRecordingFailureAction,
  refreshSingleRecordingSuccessAction,
} from '../actions/recording-list.actions';
import {
  deleteRecordingFailureAction,
  deleteRecordingRequestAction,
  deleteRecordingSuccessAction,
  deleteRecordingsSuccessAction,
  postRecordingFailureAction,
  postRecordingRequestAction,
  postRecordingSuccessAction,
  putRecordingFailureAction,
  putRecordingRequestAction,
  putRecordingSuccessAction,
  stopRecordingFailureAction,
  stopRecordingRequestAction,
  stopRecordingSuccessAction,
} from '../actions/recording.actions';
import { RecordingListState } from '../../models/app.state';
import { constructRecordingsQuery } from 'src/app/app.utils';
import { Action, createReducer, on } from '@ngrx/store';
import { restoreFromTrashSuccessAction } from '../actions/trash.actions';
import { Recording } from 'src/api/models';

const initialState: RecordingListState = {
  entities: {},
  errors: [],
  loading: false,
  loaded: false,
};

const reducer = createReducer(
  initialState,
  on(
    getRecordingsMetaRequestAction,
    getMoreRecordingsRequestAction,
    postRecordingRequestAction,
    refreshRecordingsRequestAction,
    putRecordingRequestAction,
    deleteRecordingRequestAction,
    stopRecordingRequestAction,
    state => ({
      ...state,
      loading: true,
    }),
  ),
  on(getRecordingsMetaSuccessAction, (state, { metaRecording }) => {
    const { from, to } = constructRecordingsQuery(metaRecording);
    return {
      ...state,
      metadata: {
        days: metaRecording,
        from,
        to,
      },
      loading: false,
    };
  }),
  on(refreshRecordingsSuccessAction, (state, { recordings }) => ({
    ...state,
    entities: recordings.reduce(
      (acc, recording) => ({ ...acc, [recording.id]: recording }),
      {},
    ),
    loading: false,
    loaded: true,
    errors: [],
  })),
  on(
    postRecordingSuccessAction,
    putRecordingSuccessAction,
    stopRecordingSuccessAction,
    (state, { recording }) => ({
      ...state,
      entities: {
        ...state.entities,
        [recording.id]: recording,
      },
      loading: false,
      loaded: true,
      errors: [],
    }),
  ),
  on(getMoreRecordingsSuccessAction, (state, { recordings }) => {
    const from = recordings.reduce(
      (min, curr) => (curr.dateTime < min ? curr.dateTime : min),
      recordings.length ? recordings[0].dateTime : Number.MAX_SAFE_INTEGER,
    );
    const entities = recordings.reduce(
      (acc, recording) => ({ ...acc, [recording.id]: recording }),
      { ...state.entities },
    );
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
      errors: [],
      metadata: {
        ...state.metadata!,
        from,
      },
    };
  }),
  on(deleteRecordingSuccessAction, (state, { id }) => {
    const { [id]: removed, ...entities } = state.entities;
    return { ...state, entities, loading: false, loaded: true, errors: [] };
  }),
  on(
    refreshSingleRecordingSuccessAction,
    restoreFromTrashSuccessAction,
    (state, { recording }) => ({
      ...state,
      entities: {
        ...state.entities,
        [recording.id]: recording,
      },
    }),
  ),
  on(deleteRecordingsSuccessAction, (state, { ids }) => {
    const entities = Object.keys(state.entities)
      .filter(id => !ids.includes(id))
      .reduce((e: { [key: string]: Recording }, c: string) => {
        e[c] = state.entities[c];
        return e;
      }, {});
    return { ...state, entities, loading: false, loaded: true, errors: [] };
  }),
  on(
    getRecordingsMetaFailureAction,
    getMoreRecordingsFailureAction,
    postRecordingFailureAction,
    refreshRecordingsFailureAction,
    putRecordingFailureAction,
    deleteRecordingFailureAction,
    stopRecordingFailureAction,
    refreshSingleRecordingFailureAction,
    (state, { errors }) => ({
      ...state,
      loading: false,
      errors,
    }),
  ),
);

export function recordingListReducer(
  state: RecordingListState,
  action: Action,
): RecordingListState {
  return reducer(state, action);
}
