import { AppState } from '../../models/app.state';
import { createSelector } from '@ngrx/store';
import { $routeWithPlayer } from './route.selectors';
import { $currentSignedUrl } from './current-selections.selectors';

export const $videoPlayerState = (state: AppState) => state.videoPlayer;

export const $videoPlayerPlaying = createSelector(
  $videoPlayerState,
  ({ playing }) => playing,
);

export const $getVideoPlayerActualTime = createSelector(
  $videoPlayerState,
  ({ actualTime }) => actualTime,
);

export const $getVideoPlayerSpeed = createSelector(
  $videoPlayerState,
  ({ playerSpeed }) => playerSpeed,
);

export const $videoFullScreen = createSelector(
  $videoPlayerState,
  ({ videoFullscreen }) => videoFullscreen,
);

export const $isEnabledNativeFullscreen = createSelector(
  $videoPlayerState,
  ({ enabledNativeFullscreen }) => enabledNativeFullscreen,
);

export const $isFakeFullscreenActive = createSelector(
  $isEnabledNativeFullscreen,
  $videoFullScreen,
  $routeWithPlayer,
  $currentSignedUrl,
  (
    isEnabledNativeFullscreen,
    videoFullScreen,
    routeWithPlayer,
    currentSignedUrl,
  ) =>
    !isEnabledNativeFullscreen &&
    videoFullScreen &&
    routeWithPlayer &&
    currentSignedUrl !== '',
);
