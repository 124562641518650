import { AnnotationTypesEffects } from './annotation-types.effects';
import { AnnotationEffects } from './annotation.effects';
import { AnnotationsListsEffects } from './annotations-lists.effects';
import { AuthEffect } from './auth.effect';
import { ClubsEffects } from './clubs.effects';
import { CookiesEffect } from './cookies.effect';
import { CustomerEffects } from './customer.effects';
import { ErrorEffects } from './error.effects';
import { FilterSearchEffects } from './filter-search.effects';
import { InitEffects } from './init.effects';
import { PlaylistListEffects } from './playlist-list.effects';
import { RecordersEffects } from './recorders.effects';
import { RecordingListEffects } from './recording-list.effects';
import { RecordingEffects } from './recording.effects';
import { ResponsivityEffects } from './responsivity.effects';
import { RouteEffects } from './route.effects';
import { SharingEffects } from './sharing.effects';
import { SnackbarEffects } from './snackbar.effects';
import { TeamsEffects } from './teams.effects';
import { UserEffects } from './user.effects';
import { VideoDetailEffects } from './video-detail.effects';
import { VideoEffects } from './video.effects';
import { WsEffects } from './ws.effects';
import { CameraEffects } from './camera.effects';
import { CurrentSelectionEffects } from './current-selection.effects';
import { UiFlagsEffects } from './ui-flags.effects';
import { LogEffects } from './log.effects';
import { SharingRecordingEffects } from './sharing-recording.effects';
import { TrashEffects } from './trash.effects';
import { PtzEffects } from './ptz.effects';

export const platformEffects = [
  RecordingEffects,
  RecordingListEffects,
  SharingRecordingEffects,
  AnnotationTypesEffects,
  AnnotationEffects,
  PlaylistListEffects,
  AuthEffect,
  SnackbarEffects,
  ResponsivityEffects,
  VideoEffects,
  RouteEffects,
  CustomerEffects,
  UserEffects,
  VideoDetailEffects,
  SharingEffects,
  CookiesEffect,
  AnnotationsListsEffects,
  TeamsEffects,
  ClubsEffects,
  RecordersEffects,
  CameraEffects,
  WsEffects,
  ErrorEffects,
  FilterSearchEffects,
  InitEffects,
  CurrentSelectionEffects,
  LogEffects,
  UiFlagsEffects,
  TrashEffects,
  PtzEffects,
];
