import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Inject,
  OnInit,
  AfterContentInit,
  HostListener,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState } from 'src/app/shared/models/app.state';
import {
  $isRecordingSharingLoaded,
  $recordingSharingUsers,
} from 'src/app/shared/store/selectors/sharing-recording.selectors';
import {
  getRecordingSharingsRequestAction,
  postSharingRecordingRequestAction,
  postSharingRecordingSuccessAction,
  removeSharingRecordingRequestAction,
} from 'src/app/shared/store/actions/sharing-recording.actions';
import { ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';

const TRANSLATE_PREFIX = 'components.modals.shareRecordingModal';

@Component({
  selector: 'cmv-share-recording-modal',
  templateUrl: './share-recording-modal.component.html',
  styleUrls: ['./share-recording-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareRecordingModalComponent
  implements OnDestroy, AfterViewInit, OnInit, AfterContentInit
{
  readonly unsubscribe$ = new Subject<void>();

  readonly isRecordingSharingLoaded$ = this.store.pipe(
    select($isRecordingSharingLoaded),
  );

  readonly recordingSharingUsers$ = this.store.pipe(
    select($recordingSharingUsers),
  );

  get sharingIsEnabled(): boolean {
    return !this.email.invalid && this.email.value !== '';
  }

  @ViewChild('elementToFocus')
  elementToFocus: ElementRef;
  email = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    private readonly actionsListener$: ActionsSubject,
    private readonly dialog: MatDialog,
    private readonly translate: TranslateService,
    private readonly store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { id: string },
  ) {}

  ngOnInit(): void {
    this.actionsListener$
      .pipe(ofType(postSharingRecordingSuccessAction))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.email.setValue('');
        this.email.setErrors([]);
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elementToFocus.nativeElement.focus();
    }, 0);
  }

  ngAfterContentInit(): void {
    this.store.dispatch(
      getRecordingSharingsRequestAction({ id: this.data.id }),
    );
  }

  addSharing(): void {
    if (this.sharingIsEnabled) {
      this.store.dispatch(
        postSharingRecordingRequestAction({
          id: this.data.id,
          add: {
            userEmail: this.email.value ?? undefined,
          },
        }),
      );
    }
  }

  removeSharing(userId: string): void {
    this.store.dispatch(
      removeSharingRecordingRequestAction({
        id: this.data.id,
        del: {
          userId,
        },
      }),
    );
  }

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return this.translate.instant(`${TRANSLATE_PREFIX}.required`);
    }

    if (this.email.hasError('email')) {
      return this.translate.instant(`${TRANSLATE_PREFIX}.emailNotValid`);
    }

    return '';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
  }

  closeModal(): void {
    this.dialog.closeAll();
  }
}
