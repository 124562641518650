<div
  tabindex="0"
  #elementToFocus
  class="cmv-modal-outer-card edit-modal-wrapper"
>
  <div class="cmv-modal-inner-card cmv-modal-edit">
    <div class="cmv-modal-close-button" (click)="closeModal(false)">
      <mat-icon
        class="modal-icon-closing cmv-dim-gray"
        svgIcon="cmv-closing"
      ></mat-icon>
    </div>

    <div class="main-info">
      <div class="main-info-wrapper">
        <div class="cmv-h1 edit-modal-title">
          {{ 'components.modals.editTag.' + type + 'Title' | translate }}
        </div>
        <div class="cmv-body subtitle">
          {{ 'components.modals.editTag.' + type + 'Subtitle' | translate }}
        </div>
      </div>

      <form class="edit-form" [formGroup]="tagForm">
        <mat-form-field
          class="cmv-input modal-input"
          [hideRequiredMarker]="true"
        >
          <mat-label>{{ 'basic.title' | translate }}</mat-label>
          <input #elementToFocus matInput formControlName="name" />
          <mat-error *ngIf="invalidName">
            {{ 'components.modals.editTag.errors.required' | translate }}
          </mat-error>
        </mat-form-field>

        <div class="offset-input-container">
          <div class="offset-input-wrap">
            <button
              class="offset-button"
              matRipple
              matRippleCentered="true"
              matRippleUnbounded="true"
              [disabled]="subPrerollDisabled"
              (click)="patchOffsetValue(tagFormControls.PREROLL, 'sub')"
            >
              <mat-icon
                class="cmv-dim-gray-icon"
                svgIcon="cmv-arrow-left"
              ></mat-icon>
            </button>
            <mat-form-field
              class="cmv-input modal-input offset-input"
              [hideRequiredMarker]="true"
            >
              <mat-label>
                {{ 'components.modals.editTag.preroll' | translate }}
              </mat-label>
              <input matInput formControlName="preroll" type="number" />
              <mat-error *ngIf="invalidPreroll">
                {{
                  'components.modals.editTag.errors.' + prerollErrorMessage
                    | translate
                }}
              </mat-error>
            </mat-form-field>
            <button
              class="offset-button"
              matRipple
              matRippleCentered="true"
              matRippleUnbounded="true"
              [disabled]="addPrerollDisabled"
              (click)="patchOffsetValue(tagFormControls.PREROLL, 'add')"
            >
              <mat-icon
                class="cmv-dim-gray-icon"
                svgIcon="cmv-arrow-right"
              ></mat-icon>
            </button>
          </div>
          <div class="offset-input-wrap">
            <button
              class="offset-button"
              matRipple
              matRippleCentered="true"
              matRippleUnbounded="true"
              [disabled]="subDurationDisabled"
              (click)="patchOffsetValue(tagFormControls.DURATION, 'sub')"
            >
              <mat-icon
                class="cmv-dim-gray-icon"
                svgIcon="cmv-arrow-left"
              ></mat-icon>
            </button>
            <mat-form-field
              class="cmv-input modal-input offset-input"
              [hideRequiredMarker]="true"
            >
              <mat-label>
                {{ 'components.modals.editTag.duration' | translate }}
              </mat-label>
              <input matInput formControlName="duration" type="number" />
              <mat-error *ngIf="invalidDuration">
                {{
                  'components.modals.editTag.errors.' + durationErrorMessage
                    | translate
                }}
              </mat-error>
            </mat-form-field>
            <button
              class="offset-button"
              matRipple
              matRippleCentered="true"
              matRippleUnbounded="true"
              [disabled]="addDurationDisabled"
              (click)="patchOffsetValue(tagFormControls.DURATION, 'add')"
            >
              <mat-icon
                class="cmv-dim-gray-icon"
                svgIcon="cmv-arrow-right"
              ></mat-icon>
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="buttons tag-modal-buttons">
      <button
        [disabled]="!tagForm.valid"
        class="cmv-accept-button edit-tag-accept-button"
        (click)="closeModal(true)"
      >
        <div class="cmv-h4">{{ 'basic.ok' | translate }}</div>
      </button>
      <div class="cmv-decline-button" (click)="closeModal(false)">
        <div class="cmv-h4">
          {{ 'basic.cancel' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
