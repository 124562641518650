import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/shared/models/app.state';
import {
  $currentTaggingTeam,
  $firstTaggingTeamIcon,
  $secondTaggingTeamIcon,
} from 'src/app/shared/store/selectors/current-selections.selectors';

@Component({
  selector: 'cmv-tagging-panel-header',
  templateUrl: './tagging-panel-header.component.html',
  styleUrls: ['./tagging-panel-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaggingPanelHeaderComponent {
  readonly currentTaggingTeam$ = this.store.pipe(select($currentTaggingTeam));
  readonly firstTaggingTeamIcon$ = this.store.pipe(
    select($firstTaggingTeamIcon),
  );
  readonly secondTaggingTeamIcon$ = this.store.pipe(
    select($secondTaggingTeamIcon),
  );

  constructor(private readonly store: Store<AppState>) {}
}
