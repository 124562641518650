import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationModalComponent } from './notification-modal.component';

@NgModule({
  declarations: [NotificationModalComponent],
  imports: [CommonModule, TranslateModule, MatIconModule],
  exports: [NotificationModalComponent],
})
export class NotificationModalModule {}
