import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FilterResultModule } from './filter-result/filter-result.module';
import { MatchesModule } from './matches/matches.module';
import { PlatformComponent } from './platform.component';
import { PlaylistsModule } from './playlists/playlists.module';
import { RecordingsModule } from './recordings/recordings.module';
import { TagPanelViewComponent } from './tag-panel-view/tag-panel-view.component';
import { TrimPageComponent } from './trim-page/trim-page.component';
import { VideoDetailPlaylistComponent } from './video-detail-playlist/video-detail-playlist.component';
import { MoreModule } from './more/more.module';

@NgModule({
  imports: [
    SharedModule,
    MatchesModule,
    RecordingsModule,
    PlaylistsModule,
    FilterResultModule,
    MoreModule,
  ],
  declarations: [
    PlatformComponent,
    TrimPageComponent,
    VideoDetailPlaylistComponent,
    TagPanelViewComponent,
  ],
})
export class PlatformModule {}
