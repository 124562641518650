import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  hideSnackbarAction,
  scheduleSnackbarAction,
  showSnackbarAction,
} from '../actions/snackbar.actions';
import { mapTo, switchMap } from 'rxjs/operators';
import { merge, of, timer } from 'rxjs';

const SNACKBAR_LIFETIME = 3500;

@Injectable()
export class SnackbarEffects {
  closeSnackbar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showSnackbarAction),
      switchMap(payload =>
        merge(
          of(
            scheduleSnackbarAction({
              snackbarState: {
                ...payload,
                willHideAt: Date.now() + SNACKBAR_LIFETIME,
              },
            }),
          ),
          timer(SNACKBAR_LIFETIME).pipe(mapTo(hideSnackbarAction())),
        ),
      ),
    ),
  );

  constructor(private readonly actions$: Actions) {}
}
