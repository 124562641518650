import { Video } from '../../../../api/models';
import {
  LiveTaggingModes,
  TaggingTeam,
} from '../../models/current-selections.model';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../models/app.state';

export const changeCurrentVideoAction = createAction(
  'ChangeCurrentVideo',
  props<{ video: Video; inFullscreen: boolean }>(),
);
export const setCurrentVideoAction = createAction(
  'SetCurrentVideo',
  props<{ videos: Video[] }>(),
);
export const setCurrentLiveVideoAction = createAction(
  'setCurrentLiveVideoAction',
  props<{ videos: Video[] }>(),
);

export const changeCurrentVideoDurationAction = createAction(
  'ChangeCurrentVideoDuration',
  props<{ duration: number }>(),
);

export const changeLiveTaggingModeAction = createAction(
  'ChangeLiveTaggingMode',
  props<{ mode: LiveTaggingModes }>(),
);
export const setLiveTaggingModeAction = createAction(
  'SetLiveTaggingMode',
  props<{ mode: LiveTaggingModes }>(),
);

export const getCurrentSignedVideoRequestAction = createAction(
  'GetCurrentSignedVideoRequestAction',
);
export const getCurrentSignedVideoSuccessAction = createAction(
  'GetCurrentSignedVideoSuccessAction',
  props<{ stream: string }>(),
);
export const getCurrentSignedVideoFailureAction = createAction(
  'GetCurrentSignedVideoFailureAction',
  props<{ errors: ErrorModel[] }>(),
);

export const noVideoToSignAction = createAction('NoVideoToSignAction');

export const clearVideoUrlAction = createAction('clearVideoUrlAction');

export const reactOnRemuxWsAction = createAction(
  'reactOnRemuxWs',
  props<{ status?: string; userId?: string; videoId?: string }>(),
);

export const addRemuxVideoId = createAction(
  'addRemuxVideoId',
  props<{ videoId?: string }>(),
);

export const removeRemuxVideoId = createAction(
  'removeRemuxVideoId',
  props<{ videoId?: string }>(),
);

export const matchesTabIndexChanged = createAction(
  'matchesTabIndexChanged',
  props<{ index: number }>(),
);

export const setScrollPosition = createAction(
  'setScrollPosition',
  props<{ index: number; scrollPosition: number }>(),
);

export const removeDownloadPlaylistId = createAction(
  'RemoveDownloadPlaylistId',
  props<{ playlistId: string }>(),
);

export const removeDownloadAnnotation = createAction(
  'RemoveDownloadAnnotationId',
  props<{ annotationId: string }>(),
);

export const removeDownloadAnnotationListId = createAction(
  'RemoveDownloadAnnotationId',
  props<{ annotationListId: string }>(),
);

export const setCurrentTaggingTeamAction = createAction(
  'setCurrentTaggingTeamAction',
  props<{ team: TaggingTeam }>(),
);
