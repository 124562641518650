import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { Playlist, PlaylistStatus } from '../../../../api/models';
import { EditModalModel } from '../../models/edit-modal.model';
import {
  EditModalComponent,
  EditModalTypes,
} from '../modal/edit-modal/edit-modal.component';
import {
  INPUT_TYPE,
  RemoveModalComponent,
} from '../modal/remove-modal/remove-modal.component';
import { QueryParamNames, RoutePath } from 'src/app/app.constants';

@Component({
  selector: 'cmv-playlist-title',
  templateUrl: './playlist-title.component.html',
  styleUrls: ['./playlist-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaylistTitleComponent {
  @Input() playlist: Playlist;
  @Input() navigation = true;

  @Output() removePlaylist: EventEmitter<Playlist> =
    new EventEmitter<Playlist>();
  @Output() editPlaylist: EventEmitter<Playlist> = new EventEmitter<Playlist>();
  @Output() renderPlaylist: EventEmitter<string> = new EventEmitter<string>();

  PlaylistStatus = PlaylistStatus;

  constructor(public dialog: MatDialog) {}

  renderPlaylistHandler(id: string): void {
    this.renderPlaylist.emit(id);
  }

  openModal(type: string): void {
    let dialog;
    let handleFunction;
    switch (type) {
      case 'EditModal':
        dialog = this.dialog.open(EditModalComponent, {
          data: {
            title: this.playlist.name,
            type: EditModalTypes.PLAYLIST_EDIT,
          },
        });
        handleFunction = (value: EditModalModel) => {
          if (value != null && this.playlist.name !== value.title) {
            this.editPlaylist.emit({ ...this.playlist, name: value.title });
          }
        };
        break;
      case 'RemoveModal':
        dialog = this.dialog.open(RemoveModalComponent, {
          data: {
            title: this.playlist.name,
            type: INPUT_TYPE.PLAYLIST,
          },
        });
        handleFunction = (value: boolean) => {
          if (value) {
            this.removePlaylist.emit(this.playlist);
          }
        };
        break;
    }
    if (dialog != null) {
      dialog.afterClosed().pipe(take(1)).subscribe(handleFunction);
    }
  }

  menuButtonClickHandler(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  get routerLink() {
    if (!this.navigation) {
      return null;
    }

    return this.playlist.annotations?.filter(a => a.show).length > 0
      ? ['/', RoutePath.Platform, RoutePath.Playlists, RoutePath.Clip]
      : null;
  }

  get routerQueryParameters() {
    if (!this.navigation) {
      return null;
    }

    const filteredAnnotations = this.playlist.annotations?.filter(a => a.show);
    return filteredAnnotations?.length > 0
      ? {
          [QueryParamNames.PlaylistId]: this.playlist.id,
          [QueryParamNames.AnnotationId]: filteredAnnotations[0].id,
        }
      : null;
  }

  get canDownload(): boolean {
    return (
      this.playlist.annotations &&
      this.playlist.annotations.filter(a => a.show).length > 0 &&
      this.playlist.url === '' &&
      this.playlist.canDownload &&
      this.playlist.status !== PlaylistStatus.RENDERING
    );
  }

  get playlistLength(): number {
    return (this.playlist.annotations ?? []).filter(a => a.show).length;
  }
}
