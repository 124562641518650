import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'cmv-how-it-works-view',
  templateUrl: './how-it-works-view.component.html',
  styleUrls: ['./how-it-works-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HowItWorksViewComponent {
  constructor() {}
}
