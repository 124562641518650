import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { APIClient } from 'src/api';
import {
  GoogleAnalyticsEvent,
  SHARE_RECORDING_ERROR_CODES,
} from 'src/app/app.constants';
import { ApiService } from 'src/app/services/api.service';
import { ErrorTypes } from '../../components/error/error.component';
import { handleErrorResponseAction } from '../actions/error.actions';
import {
  getRecordingSharingsRequestAction,
  getRecordingSharingsSuccessAction,
  postSharingRecordingFailureAction,
  postSharingRecordingRequestAction,
  postSharingRecordingSuccessAction,
  removeSharingRecordingFailureAction,
  removeSharingRecordingRequestAction,
  removeSharingRecordingSuccessAction,
} from '../actions/sharing-recording.actions';
import { getSharingTeamsFailureAction } from '../actions/sharing.actions';
import { showSnackbarAction } from '../actions/snackbar.actions';
import { reportToGA } from 'src/app/app.utils';

@Injectable()
export class SharingRecordingEffects {
  getRecordingSharings = createEffect(() =>
    this.actions$.pipe(
      ofType(getRecordingSharingsRequestAction),
      switchMap(({ id }) =>
        this.apiService
          .call(() => this.apiClient.getSharingRecording({ id }))
          .pipe(
            map(sharings => getRecordingSharingsSuccessAction({ sharings })),
            catchError(error => {
              const parsedError = {
                type: error.status.toString(),
                message: error.error.msg,
              };

              return of(
                getSharingTeamsFailureAction({
                  errors: [parsedError],
                }),
                handleErrorResponseAction({
                  errorType: parsedError.type,
                }),
              );
            }),
          ),
      ),
    ),
  );

  postSharingRecording = createEffect(() =>
    this.actions$.pipe(
      ofType(postSharingRecordingRequestAction),
      switchMap(({ id, add }) =>
        this.apiService
          .call(() =>
            this.apiClient.postSharingRecording({
              body: {
                id,
                add: [add],
              },
            }),
          )
          .pipe(
            map(sharings => postSharingRecordingSuccessAction({ sharings })),
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.RECORDING_ADD_SHARE);
            }),
            catchError(error => {
              const parsedError = {
                type: error.status.toString(),
                message: error.error.msg,
              };

              return of(
                postSharingRecordingFailureAction({
                  error: parsedError,
                }),
              );
            }),
          ),
      ),
    ),
  );

  removeSharingRecording = createEffect(() =>
    this.actions$.pipe(
      ofType(removeSharingRecordingRequestAction),
      switchMap(({ id, del }) =>
        this.apiService
          .call(() =>
            this.apiClient.postSharingRecording({ body: { id, del: [del] } }),
          )
          .pipe(
            map(sharings => removeSharingRecordingSuccessAction({ sharings })),
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.RECORDING_REMOVE_SHARE);
            }),
            catchError(error => {
              const parsedError = {
                type: error.status.toString(),
                message: error.error.msg,
              };

              return of(
                removeSharingRecordingFailureAction({
                  error: parsedError,
                }),
              );
            }),
          ),
      ),
    ),
  );

  errorSharingRecording = createEffect(() =>
    this.actions$.pipe(
      ofType(
        postSharingRecordingFailureAction,
        removeSharingRecordingFailureAction,
      ),
      switchMap(({ error }) => {
        if (SHARE_RECORDING_ERROR_CODES.includes(parseInt(error.type))) {
          return of(
            showSnackbarAction({
              infoMessage: `shareRecording.${error.type}`,
            }),
          );
        } else {
          return of(
            handleErrorResponseAction({
              errorType: ErrorTypes.DEFAULT,
            }),
          );
        }
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly apiClient: APIClient,
    private readonly apiService: ApiService,
  ) {}
}
