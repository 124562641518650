<div class="content-wrapper">
  <div class="top-half">
    <img src="../../assets/images/Error-page-goal.svg" class="goal" />
  </div>

  <div class="bottom-half">
    <div class="titles-wrapper">
      <div class="cmv-h1 title">
        {{ 'components.error404.title' | translate }}
      </div>

      <div class="cmv-body subtitle">
        {{ 'components.error404.subtitle' | translate }}
      </div>
    </div>

    <div class="back-to-homepage-wrapper">
      <a
        class="back-to-homepage cmv-h4 cmv-accept-button"
        [routerLink]="routerLink"
      >
        {{ 'components.error404.button' | translate }}
      </a>
    </div>
  </div>
</div>
