import {
  Component,
  ChangeDetectionStrategy,
  HostListener,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cmv-premium-free',
  templateUrl: './premium-free.component.html',
  styleUrls: ['./premium-free.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PremiumFreeComponent implements AfterViewInit {
  @ViewChild('elementToFocus')
  elementToFocus: ElementRef;

  constructor(private readonly dialogRef: MatDialogRef<PremiumFreeComponent>) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elementToFocus.nativeElement.focus();
    }, 0);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
  }

  closeModal(output: boolean): void {
    this.dialogRef.close(output);
  }
}
