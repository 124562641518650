<div class="error-comp-wrapper" *ngIf="errorOverlayVisible$ | async">
  <h1 class="cmv-h1 error-comp-title">
    {{ 'components.error.title' | translate }}
  </h1>
  <h5 class="cmv-h2 error-comp-subtitle">
    {{ 'components.error.subTitle' | translate }}
  </h5>
  <div class="error-comb-buttons">
    <button class="cmv-h4 error-comp-button" (click)="reloadPage()">
      {{ 'components.error.refreshButton' | translate }}
    </button>
    <button class="cmv-h4 error-comp-button" (click)="continueWithErrors()">
      {{ 'components.error.continueButton' | translate }}
    </button>
  </div>
</div>
