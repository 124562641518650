import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  $premiumMatchesExist,
  $currentOnlyRecordingDays,
  $recordingsLoaded,
  $upcomingOnlyRecordingDays,
} from '../../../shared/store/selectors/recording-list.selectors';
import { AppState } from '../../../shared/models/app.state';
import { $searchBarVisible } from '../../../shared/store/selectors/ui-flags.selectors';
import {
  $getScrollPosition,
  $matchesTabIndex,
} from 'src/app/shared/store/selectors/current-selections.selectors';
import {
  matchesTabIndexChanged,
  setScrollPosition,
} from 'src/app/shared/store/actions/current-selections.actions';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { filter, take } from 'rxjs';
import { MatchListComponent } from 'src/app/shared/components/match-list/match-list.component';
import {
  GoogleAnalyticsEvent,
  MatchList,
  RoutePath,
} from 'src/app/app.constants';
import { Router } from '@angular/router';
import { $hasAnyRecorder } from 'src/app/shared/store/selectors/recorders.selectors';
import { reportToGA } from 'src/app/app.utils';

interface MatchListInfo {
  init: boolean;
  component: MatchListComponent | null;
}

@Component({
  selector: 'cmv-match-list-view',
  templateUrl: './match-list-view.component.html',
  styleUrls: ['./match-list-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchListViewComponent implements AfterViewInit {
  @ViewChild('recordedMatchList') recordedMatchList: MatchListComponent;
  @ViewChild('upcomingMatchList') upcomingMatchList: MatchListComponent;

  MatchList = MatchList;
  matchLists: {
    [x: number]: MatchListInfo;
  } = {
    [MatchList.RECORDED]: {
      init: false,
      component: null,
    },
    [MatchList.UPCOMING]: {
      init: false,
      component: null,
    },
  };

  readonly upcomingMatchDay$ = this.store.pipe(
    select($upcomingOnlyRecordingDays),
  );
  readonly currentMatchDay$ = this.store.pipe(
    select($currentOnlyRecordingDays),
  );

  readonly recordingsLoaded$ = this.store.pipe(select($recordingsLoaded));
  readonly premiumMatchesExist$ = this.store.pipe(select($premiumMatchesExist));

  readonly searchBarVisible$ = this.store.pipe(select($searchBarVisible));
  readonly matchesTabIndex$ = this.store.pipe(select($matchesTabIndex));
  readonly hasAnyRecorder$ = this.store.pipe(select($hasAnyRecorder));

  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
  ) {}

  createRecording(): void {
    this.router.navigate([
      RoutePath.Platform,
      RoutePath.Recordings,
      RoutePath.Create,
    ]);
  }

  labelClick(index: number): void {
    this.matchesTabIndex$.pipe(take(1)).subscribe(currentIndex => {
      if (index === currentIndex) {
        this.matchLists[index].component?.setScrollPosition(0, true);
      }
    });
  }

  ngAfterViewInit(): void {
    this.recordingsLoaded$.pipe(filter(loaded => !!loaded)).subscribe(() => {
      this.matchesTabIndex$.pipe(take(1)).subscribe(index => {
        this.matchLists[index].init = true;

        setTimeout(() => {
          this.matchLists[MatchList.RECORDED].component =
            this.recordedMatchList;
          this.matchLists[MatchList.UPCOMING].component =
            this.upcomingMatchList;

          this.store
            .pipe(select($getScrollPosition(index)), take(1))
            .subscribe(position => {
              this.matchLists[index].component?.setScrollPosition(position);
            });
        }, 0);
      });
    });
  }

  focusChange({ index }: MatTabChangeEvent) {
    const previousListIndex =
      index === MatchList.UPCOMING ? MatchList.RECORDED : MatchList.UPCOMING;
    const scrollPosition =
      this.matchLists[previousListIndex].component?.scrollPosition ?? 0;

    this.store.dispatch(matchesTabIndexChanged({ index }));
    this.store.dispatch(
      setScrollPosition({ index: previousListIndex, scrollPosition }),
    );
  }

  tabIndexChange({ index }: MatTabChangeEvent): void {
    if (!this.matchLists[index].init) {
      this.matchLists[index].init = true;

      this.store
        .select($getScrollPosition(index))
        .pipe(take(1))
        .subscribe(position => {
          this.matchLists[index].component?.setScrollPosition(position);
        });
    }

    reportToGA(GoogleAnalyticsEvent.CHANGE_RECORDINGS_TAB, { index });
  }

  onMatchListDestroy({
    index,
    scrollPosition,
  }: {
    index: number;
    scrollPosition: number;
  }) {
    this.matchesTabIndex$.pipe(take(1)).subscribe(currentIndex => {
      if (index === currentIndex) {
        this.store.dispatch(setScrollPosition({ index, scrollPosition }));
      }
    });
  }
}
