import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, from, of } from 'rxjs';
import {
  catchError,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { APIClient } from '../../../../api';
import { AppState } from '../../models/app.state';
import {
  downloadAnnotationsListFailureAction,
  downloadAnnotationsListRequestAction,
  downloadAnnotationsListSuccessAction,
  getRecordingsAnnotationListsFailureAction,
  getRecordingsAnnotationListsRequestAction,
  getRecordingsAnnotationListsSuccessAction,
  refreshAnnotationListRequestAction,
  refreshRecordingsAnnotationListRequestAction,
} from '../actions/annotations-lists.actions';
import { handleErrorResponseAction } from '../actions/error.actions';
import {
  $currentRecording,
  $userDownloadingAnnotationListIds,
} from '../selectors/current-selections.selectors';
import { ApiService } from 'src/app/services/api.service';
import { $shareableList } from '../selectors/annotations-lists.selectors';
import { reportToGA } from 'src/app/app.utils';
import { GoogleAnalyticsEvent } from 'src/app/app.constants';
import { showSnackbarAction } from '../actions/snackbar.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { RenderStatus } from 'src/api/models';
import { removeDownloadAnnotationListId } from '../actions/current-selections.actions';

@Injectable()
export class AnnotationsListsEffects {
  getRecordingsAnnotationLists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRecordingsAnnotationListsRequestAction),
      withLatestFrom(this.store.select($userDownloadingAnnotationListIds)),
      switchMap(
        ([
          { recordingId, status, annListId },
          userDownloadingAnnotationListIds,
        ]) =>
          this.apiService
            .call(() => this.apiClient.getAnnotationListId({ id: recordingId }))
            .pipe(
              switchMap(annotationLists => {
                if (status === RenderStatus.DONE && annListId) {
                  const annList = annotationLists.find(l => l.id === annListId);
                  if (
                    annList &&
                    annList.url &&
                    userDownloadingAnnotationListIds.includes(annListId)
                  ) {
                    window.open(annList.url, '_blank');
                  }
                }
                return from([
                  getRecordingsAnnotationListsSuccessAction({
                    annotationLists,
                  }),
                  ...(annListId &&
                  (status === RenderStatus.DONE ||
                    status === RenderStatus.FAILED)
                    ? [
                        removeDownloadAnnotationListId({
                          annotationListId: annListId,
                        }),
                      ]
                    : []),
                ]);
              }),
              catchError(error => {
                const parsedError = {
                  type: error.status.toString(),
                  message: error.error.msg,
                };

                return of(
                  getRecordingsAnnotationListsFailureAction({
                    errors: [parsedError],
                  }),
                  handleErrorResponseAction({
                    errorType: parsedError.type,
                  }),
                );
              }),
            ),
      ),
    ),
  );

  refreshRecordingAnnotationList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshRecordingsAnnotationListRequestAction),
      switchMap(({ recordingId, annListId, status }) =>
        this.store.pipe(
          select($currentRecording),
          take(1),
          switchMap(recording =>
            recording?.id === recordingId
              ? of(
                  getRecordingsAnnotationListsRequestAction({
                    recordingId,
                    annListId,
                    status,
                  }),
                )
              : EMPTY,
          ),
        ),
      ),
    ),
  );

  refreshAnnotationList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshAnnotationListRequestAction),
      switchMap(({ annotationListId, status }) =>
        this.store.pipe(
          select($shareableList),
          take(1),
          switchMap(annotationList =>
            annotationList.id === annotationListId
              ? of(
                  refreshRecordingsAnnotationListRequestAction({
                    recordingId: annotationList.recordingId,
                    annListId: annotationListId,
                    status,
                  }),
                )
              : EMPTY,
          ),
        ),
      ),
    ),
  );

  downloadAnnotationsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(downloadAnnotationsListRequestAction),
      switchMap(({ id }) =>
        this.apiService
          .call(() => this.apiClient.getAnnotationListDownloadId({ id }))
          .pipe(
            switchMap(annotationsList =>
              of(
                downloadAnnotationsListSuccessAction({ annotationsList }),
                showSnackbarAction({
                  infoMessage: 'success.annotationListDownload.start',
                }),
              ),
            ),
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.DOWNLOAD_TAGS);
            }),
            catchError((err: HttpErrorResponse) =>
              of(
                downloadAnnotationsListFailureAction(),
                showSnackbarAction({
                  infoMessage: `error.playlistDownload.${err.status}`,
                  messageParameters: { ...err.error },
                }),
              ),
            ),
          ),
      ),
    ),
  );
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly apiClient: APIClient,
    private readonly apiService: ApiService,
  ) {}
}
