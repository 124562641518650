<div class="matches-list" #wrapper>
  <cmv-match-day
    *ngFor="let matchDay of matchDays; trackBy: nameTrackFn"
    class="match-day"
    [hideScore]="createRecordings || hideScore"
    [matchDay]="matchDay"
  ></cmv-match-day>
  <div
    class="cmv-accept-button cmv-button-with-spinner load-more-button"
    [class.block-interaction]="updating$ | async"
    (click)="loadMore()"
    *ngIf="showMoreButton && (moreMatchesToLoad$ | async)"
  >
    <div class="cmv-h4">
      {{ 'basic.loadMore' | translate }}
    </div>
    <ng-container>
      <mat-spinner
        class="button-spinner"
        [class.button-spinner-show]="updating$ | async"
      ></mat-spinner>
    </ng-container>
  </div>
  <cmv-match-day *ngIf="showUpgrade" [upgrade]="true"></cmv-match-day>
</div>
