export const environment = {
  production: true,
  wsUrl: 'wss://ws2.panoris.com/test?token=',
  apiUrl: `https://api2.panoris.com/test`,
  cognito: {
    clientId: '7k37jmgih6ak0oucq5rg3rbghs',
    appWebDomain: 'bat-devel.auth.eu-central-1.amazoncognito.com',
    userPoolId: 'eu-central-1_BU60kvUuD',
  },
  ga: {
    enable: false,
    id: null,
  },
};
