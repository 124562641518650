import { Recorder } from '../../../../api/models';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../models/app.state';

export const getRecorderCameraRequestAction = createAction(
  'GetRecorderCameraRequestAction',
  props<{ id: string }>(),
);
export const getRecorderCameraSuccessAction = createAction(
  'GetRecorderCameraSuccessAction',
  props<{ recorder: Recorder }>(),
);
export const getRecorderCameraFailureAction = createAction(
  'GetRecorderCameraFailureAction',
  props<{ errors: ErrorModel[] }>(),
);

export const resetRecorderCamerasAction = createAction(
  'ResetRecorderCamerasAction',
);
