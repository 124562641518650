<div class="empty-taglist" *ngIf="showEmptyList && annotations.length === 0">
  <div class="empty-taglist-image">
    <img src="assets/images/empty_state.svg" />
  </div>
  <div class="empty-taglist-title cmv-h2">
    {{ 'components.liveMatchTagging.emptyTaglistTitle' | translate }}
  </div>
  <div class="empty-taglist-subtitle cmv-body">
    {{ 'components.liveMatchTagging.emptyTaglistSubtitle' | translate }}
  </div>
</div>
<div
  *ngFor="let annotation of annotations; trackBy: idTrackFn"
  class="cmv-video-widget-container"
  [@tagsAnimation]="'created'"
>
  <cmv-video-widget
    [id]="annotation.id"
    [widgetData]="annotation"
    [trimmingDisabled]="trimmingDisabled$ | async"
    (selectedWidgetIdChange)="selectedWidgetIdChange.emit($event)"
    (editAnnotation)="editAnnotation.emit($event)"
    (trimVideo)="trimVideo.emit($event)"
    (deleteTag)="deleteTag.emit($event)"
    [isSelected]="annotation.id === currentAnnotation?.id"
    [fromPlaylistId]="fromPlaylistId"
    [hideDownload]="hideDownloadTag"
    [trimmingDisabled]="trimmingDisabled"
  ></cmv-video-widget>
</div>
