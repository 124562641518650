import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { APIClient } from '../../../../api';
import { SharingRequestAnn } from '../../../../api/models';
import { SharingTypes } from '../../components/tree/tree.component';
import { AppState } from '../../models/app.state';
import { ShareableEntityType } from '../../models/sharing.model';
import { handleErrorResponseAction } from '../actions/error.actions';
import {
  changeSharingMethodFailureAction,
  changeSharingMethodRequestAction,
  changeSharingMethodSuccessAction,
  getSharingTeamsFailureAction,
  getSharingTeamsRequestAction,
  getSharingTeamsSuccessAction,
  toggleUserFailureAction,
  toggleUserRequestAction,
  toggleUserSuccessAction,
} from '../actions/sharing.actions';
import { showSnackbarAction } from '../actions/snackbar.actions';
import { $shareableEntity } from '../selectors/sharing.selectors';
import { reportToGA } from 'src/app/app.utils';
import { GoogleAnalyticsEvent } from 'src/app/app.constants';

@Injectable()
export class SharingEffects {
  getSharingTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSharingTeamsRequestAction),
      switchMap(({ shareable }) =>
        this.apiService
          .call(() => this.apiClient.getSharingTeams())
          .pipe(
            map(sharingTeams =>
              getSharingTeamsSuccessAction({
                shareable,
                sharingTeams,
              }),
            ),
            catchError(error => {
              const parsedError = {
                type: error.status.toString(),
                message: error.error.msg,
              };

              return of(
                getSharingTeamsFailureAction({
                  errors: [parsedError],
                }),
                handleErrorResponseAction({
                  errorType: parsedError.type,
                }),
              );
            }),
          ),
      ),
    ),
  );

  toggleShareWithUser = createEffect(() =>
    this.actions$.pipe(
      ofType(toggleUserRequestAction),
      withLatestFrom(
        this.store.pipe(
          select($shareableEntity),
          filter(shareable => shareable != null),
        ),
      ),
      switchMap(([{ user }, shareable]) => {
        const request: SharingRequestAnn = {
          [user.selected ? 'del' : 'add']: [{ userId: user.id }],
          id: shareable ? shareable.id : '',
        };

        const sharings$ =
          shareable && shareable.type === ShareableEntityType.ANNO
            ? this.apiService.call(() =>
                this.apiClient.postSharingAnnotation({ body: request }),
              )
            : this.apiService.call(() =>
                this.apiClient.postSharingAnnotationList({ body: request }),
              );

        reportToGA(
          user.selected
            ? GoogleAnalyticsEvent.REMOVE_SHARE_WITH_USER
            : GoogleAnalyticsEvent.SHARE_WITH_USER,
          {
            type: shareable?.type,
          },
        );

        return sharings$.pipe(
          switchMap(sharings =>
            of(
              toggleUserSuccessAction({ sharings }),
              showSnackbarAction({
                infoMessage: `success.shareTag.user${
                  user.selected ? 'Del' : 'Add'
                }`,
                entryEnd: user.label,
                icon: 'share',
              }),
            ),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              showSnackbarAction({
                infoMessage: `error.shareTag.user${
                  user.selected ? 'Del' : 'Add'
                }`,
              }),
              toggleUserFailureAction({
                errors: [
                  {
                    type: error.status.toString(),
                    message: error.error.msg,
                  },
                ],
              }),
            ),
          ),
        );
      }),
    ),
  );

  toggleShareMode = createEffect(() =>
    this.actions$.pipe(
      ofType(changeSharingMethodRequestAction),
      withLatestFrom(this.store.pipe(select($shareableEntity))),
      switchMap(([{ team, sharingType }, shareable]) => {
        const teamMembers = team.users ? team.users : [];
        const isSharing = isTeamSharing(sharingType);
        const body: SharingRequestAnn = isSharing
          ? {
              add: [
                {
                  teamId: team.id,
                },
              ],
              del: teamMembers
                ? teamMembers.map(teamMember => ({ userId: teamMember.id }))
                : [],
              id: shareable ? shareable.id : '',
            }
          : {
              del: [
                {
                  teamId: team.id,
                },
              ],
              id: shareable ? shareable.id : '',
            };

        const sharings$ =
          shareable && shareable.type === ShareableEntityType.ANNO
            ? this.apiService.call(() =>
                this.apiClient.postSharingAnnotation({ body }),
              )
            : this.apiService.call(() =>
                this.apiClient.postSharingAnnotationList({ body }),
              );

        reportToGA(
          isSharing
            ? GoogleAnalyticsEvent.SHARE_WITH_TEAM
            : GoogleAnalyticsEvent.REMOVE_SHARE_WITH_TEAM,
          {
            type: shareable?.type,
          },
        );
        return sharings$.pipe(
          switchMap(sharings =>
            of(
              changeSharingMethodSuccessAction({ sharings }),
              showSnackbarAction({
                infoMessage: `success.shareTag.changeMode${
                  isTeamSharing(sharingType) ? 'Team' : 'Single'
                }`,
                entryEnd: team.label,
                icon: 'share',
              }),
            ),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              showSnackbarAction({
                infoMessage: 'error.shareTag.changeMode',
                icon: 'share',
              }),
              changeSharingMethodFailureAction({
                errors: [
                  {
                    type: error.status.toString(),
                    message: error.error.msg,
                  },
                ],
              }),
            ),
          ),
        );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly apiClient: APIClient,
    private readonly apiService: ApiService,
  ) {}
}

const isTeamSharing = (method: SharingTypes): boolean =>
  method === SharingTypes.TEAM;
