import { createSelector, createFeatureSelector } from '@ngrx/store';
import { teamsAdapter } from '../reducers/teams.reducer';
import { TeamModel } from '../../models/manual-scheduling.model';
import { TeamsState, StateStatus } from '../../models/app.state';

const { selectAll: selectTeams } = teamsAdapter.getSelectors();

export const $teamsState = createFeatureSelector<TeamsState>('teams');

export const $teams = createSelector($teamsState, selectTeams);

export const $teamsGroupByClub = createSelector($teams, teams =>
  teams.reduce(
    (acc: { [key: string]: TeamModel[] }, curr) => ({
      ...acc,
      [curr.clubId]: acc[curr.clubId]
        ? { ...acc[curr.clubId], [curr.id]: curr }
        : { [curr.id]: curr },
    }),
    {},
  ),
);

export const $teamById = (id: string) =>
  createSelector($teamsState, teams =>
    teams.entities ? teams.entities[id] : null,
  );

export const $teamNameById = (id: string) =>
  createSelector($teamById(id), team => (team ? team.name : ''));

export const $loadingClub = (id: string) =>
  createSelector(
    $teamsState,
    teams =>
      teams.status === StateStatus.UPDATING &&
      !teams.alreadyLoadedIds.includes(id),
  );

export const $teamsLoaded = (id: string) =>
  createSelector($teamsState, teams => teams.alreadyLoadedIds.includes(id));

export const $isClubEmpty = (id: string) =>
  createSelector($teams, $teamsLoaded(id), (teams, teamsLoaded) => {
    const filteredTeams = teams.filter(team => team.clubId === id);
    return filteredTeams.length === 0 && teamsLoaded;
  });

export const $returnIdIfUnloaded = (id: string) =>
  createSelector($teamsLoaded(id), teamsLoaded => (!teamsLoaded ? id : null));
