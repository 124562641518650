<form
  class="manual-scheduling-form"
  [formGroup]="form"
  (submit)="submit()"
  *ngIf="clubsLoaded$ | async; else loadingState"
>
  <div class="input-hint-wrapper">
    <mat-form-field class="cmv-input" [hideRequiredMarker]="true">
      <mat-label>
        {{ 'components.manualScheduling.location' | translate }}
      </mat-label>
      <mat-select
        formControlName="recorderId"
        [hideSingleSelectionIndicator]="true"
      >
        <mat-option
          *ngFor="let recorder of recorders$ | async"
          [value]="recorder.id"
        >
          {{ recorder.description ? recorder.description : recorder.name }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{
          'components.manualScheduling.errors.' +
            getFirstError(manualSchedulingInputTypes.RECORDER_ID) | translate
        }}
      </mat-error>
    </mat-form-field>

    <mat-form-field
      class="cmv-input datetime-input"
      floatLabel="always"
      [hideRequiredMarker]="true"
    >
      <mat-label>
        {{ 'components.manualScheduling.date' | translate }}
      </mat-label>
      <input
        class="lowercase"
        matInput
        [min]="now"
        [max]="nextWeek"
        [matDatepicker]="picker"
        formControlName="date"
      />
      <mat-datepicker-toggle
        class="input-right-icon"
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error>
        {{
          'components.manualScheduling.errors.' +
            getFirstError(manualSchedulingInputTypes.DATE) | translate
        }}
      </mat-error>
    </mat-form-field>

    <mat-form-field
      class="cmv-input datetime-input"
      floatLabel="always"
      [hideRequiredMarker]="true"
    >
      <mat-label>
        {{ 'components.manualScheduling.time' | translate }}
      </mat-label>
      <input matInput type="time" formControlName="time" />

      <mat-icon
        class="cmv-gray-icon input-right-icon"
        svgIcon="cmv-clock"
      ></mat-icon>
      <mat-error>
        {{
          'components.manualScheduling.errors.' +
            getFirstError(manualSchedulingInputTypes.TIME) | translate
        }}
      </mat-error>
    </mat-form-field>

    <mat-form-field
      class="cmv-input datetime-input"
      [hideRequiredMarker]="true"
    >
      <mat-label>
        {{ 'components.manualScheduling.duration' | translate }}
      </mat-label>
      <input
        matInput
        type="nubmer"
        class="cmv-number-input-without-controls"
        min="MIN_DURATION"
        max="MAX_DURATION"
        formControlName="duration"
        pattern="^[0-9]*$"
        cmvFluidInput
        autocomplete="off"
      />
      <span class="cmv-input-suffix">
        {{ 'components.manualScheduling.minutes' | translate }}
      </span>
      <mat-icon
        class="cmv-gray-icon input-right-icon"
        svgIcon="cmv-stopwatch"
      ></mat-icon>
      <mat-error>
        {{
          'components.manualScheduling.errors.' +
            getFirstError(manualSchedulingInputTypes.DURATION) | translate
        }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="cmv-input lg-half-input" [hideRequiredMarker]="true">
      <mat-label>
        {{ 'components.manualScheduling.camera' | translate }}
      </mat-label>
      <mat-select multiple formControlName="manualExports">
        <mat-option
          *ngFor="let camera of cameras$(data.manualExports) | async"
          [value]="camera"
        >
          {{ camera | cameraTranslate }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{
          'components.manualScheduling.errors.' +
            getFirstError(manualSchedulingInputTypes.MANUAL_EXPORTS) | translate
        }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="cmv-input lg-half-input" [hideRequiredMarker]="true">
      <mat-label>
        {{ 'components.manualScheduling.scheduleType' | translate }}
      </mat-label>
      <mat-select formControlName="type" [hideSingleSelectionIndicator]="true">
        <mat-option [value]="recordingType.MATCH">
          {{ 'components.manualScheduling.match' | translate }}
        </mat-option>
        <mat-option [value]="recordingType.TRAINING">
          {{ 'components.manualScheduling.training' | translate }}
        </mat-option>
        <mat-option [value]="recordingType.OTHER">
          {{ 'components.manualScheduling.other' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <cmv-team-search
      class="lg-half-input"
      [teamCtrl]="form.get('team1')"
      [showImage]="true"
      error="{{
        'components.manualScheduling.errors.' +
          getFirstError(manualSchedulingInputTypes.TEAM1) | translate
      }}"
      label="{{ 'components.manualScheduling.team1' | translate }}"
      placeholder="{{ 'components.manualScheduling.searchTeam' | translate }}"
      *ngIf="!hasValue(manualSchedulingInputTypes.TYPE, recordingType.OTHER)"
    ></cmv-team-search>

    <cmv-team-search
      class="lg-half-input"
      [teamCtrl]="form.get('team2')"
      [showImage]="true"
      error="{{
        'components.manualScheduling.errors.' +
          getFirstError(manualSchedulingInputTypes.TEAM2) | translate
      }}"
      label="{{ 'components.manualScheduling.team2' | translate }}"
      placeholder="{{ 'components.manualScheduling.searchTeam' | translate }}"
      *ngIf="hasValue(manualSchedulingInputTypes.TYPE, recordingType.MATCH)"
    ></cmv-team-search>

    <mat-form-field
      class="cmv-input lg-half-input cmv-input-without-label"
      *ngIf="hasValue(manualSchedulingInputTypes.TYPE, recordingType.OTHER)"
    >
      <mat-label>
        {{ 'components.manualScheduling.title' | translate }}
      </mat-label>
      <input matInput formControlName="title" />
      <mat-error>
        {{
          'components.manualScheduling.errors.' +
            getFirstError(manualSchedulingInputTypes.TITLE) | translate
        }}
      </mat-error>
    </mat-form-field>
  </div>

  <div class="buttons">
    <button
      type="submit"
      [disabled]="form.invalid || (recordingsLoading$ | async)"
      class="button cmv-accept-button"
      *ngIf="!showTime"
    >
      <div class="cmv-h4">{{ 'basic.save' | translate }}</div>
    </button>
    <div
      *ngIf="data.id && !showTime"
      class="button cmv-decline-button cmv-delete-button"
      (click)="delete()"
      role="button"
    >
      <mat-icon
        class="button-icon cmv-gray-icon"
        svgIcon="cmv-delete"
      ></mat-icon>
    </div>
    <button
      *ngIf="!data.id"
      class="button cmv-decline-button"
      (click)="cancel()"
      [disabled]="recordingsLoading$ | async"
      type="button"
    >
      <div class="cmv-h4">{{ 'basic.cancel' | translate }}</div>
    </button>

    <span *ngIf="showTime && data.id" class="running-time cmv-h5">
      {{ alreadyRunning }}
    </span>
    <button
      *ngIf="showTime && data.id"
      class="button cmv-decline-button update-length-button"
      (click)="updateLength()"
      type="button"
      [disabled]="recordingsLoading$ | async"
    >
      <div class="cmv-h4">
        {{ 'components.manualScheduling.updateLength' | translate }}
      </div>
    </button>
    <button
      type="button"
      class="button cmv-accept-button stop-recording-button"
      *ngIf="showTime && data.id"
      (click)="stopRecording()"
      [disabled]="recordingsLoading$ | async"
    >
      <div class="cmv-h4">
        {{ 'components.manualScheduling.stopRecording' | translate }}
      </div>
    </button>
  </div>
</form>

<ng-template #loadingState>
  <!-- Loading overlay -->
  <cmv-loading-state [overlay]="true"></cmv-loading-state>
</ng-template>
