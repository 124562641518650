import { createSelector } from '@ngrx/store';
import { Recording, RecordingStatus } from '../../../../api/models';
import { AppState } from '../../models/app.state';
import { $currentRecordingId, $isTrimming } from './route.selectors';

export const $recordingState = (state: AppState) => state.recording;

export const $recordingEntities = createSelector(
  $recordingState,
  state => state.entities,
);

export const $recording = (id: string) =>
  createSelector($recordingEntities, entities => entities[id] || null);

export const $recordingVideos = (id: string) =>
  createSelector($recording(id), (recording: Recording) =>
    recording !== null && recording.videos ? recording.videos : [],
  );

export const $recordingFirstHalfTime = (id: string) =>
  createSelector($recording(id), (recording: Recording) =>
    recording !== null ? recording.firstHalf : null,
  );

export const $recordingSecondHalfTime = (id: string) =>
  createSelector($recording(id), (recording: Recording) =>
    recording !== null ? recording.secondHalf : null,
  );

export const $taggingModeSwitchDisabled = createSelector(
  $currentRecordingId,
  $recordingEntities,
  $isTrimming,
  (id, entities, isTrimming) =>
    isTrimming ||
    !(
      entities[id] &&
      entities[id].videos &&
      entities[id].videos.find(video => video.live)
    ),
);

export const $canNavigate = (id: string) =>
  createSelector(
    $recording(id),
    (recording: Recording) =>
      recording &&
      recording.videos &&
      (recording.status !== RecordingStatus.LIVE ||
        recording.videos.filter(video => video.live)),
  );

export const $recordingEntityWithLoading = (id: string) =>
  createSelector($recordingState, state => ({
    recording: state.entities[id],
    loading: state.loading,
  }));
