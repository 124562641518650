export const enum ThemeName {
  DEFAULT = 'default',
}

export const enum Provider {
  AUTH0 = 'CamvisionAuth0',
}

export enum CustomerID {
  DEFAULT = 'default',
}

export enum Languages {
  ENGLISH = 'en',
  DUTCH = 'nl',
}

export enum Favicons {
  DEFAULT = 'assets/favicons/favicon_panoris.ico',
}

export enum PageTitles {
  DEFAULT = 'Panoris App',
}

export enum CurrentTosVersion {
  DEFAULT = '2.0',
}

export interface Customer {
  customerId: CustomerID;
  themeName: ThemeName;
  authProvider: Provider;
  defaultLanguage: string;
  faviconUrl: string;
  pageTitle: string;
  currentTosVersion: CurrentTosVersion;
}

export const CUSTOMERS: { [key: string]: Customer } = {
  default: {
    customerId: CustomerID.DEFAULT,
    themeName: ThemeName.DEFAULT,
    authProvider: Provider.AUTH0,
    defaultLanguage: Languages.ENGLISH,
    faviconUrl: Favicons.DEFAULT,
    pageTitle: PageTitles.DEFAULT,
    currentTosVersion: CurrentTosVersion.DEFAULT,
  },
};
