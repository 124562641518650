import { createAction, props } from '@ngrx/store';
import { User } from '../../../../api/models';
import { ErrorModel } from '../../models/app.state';

export const getUserRequestAction = createAction('GetUserRequest');
export const getUserSuccessAction = createAction(
  'GetUserSuccess',
  props<{ user: User }>(),
);
export const getUserFailureAction = createAction(
  'GetUserFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const openTOSModalAction = createAction(
  'OpenTOSModal',
  props<{ version: string }>(),
);

export const postUserTOSRequestAction = createAction(
  'PostUserTOSRequest',
  props<{ version: string }>(),
);
export const postUserConfirmAppNewsRequestAction = createAction(
  'PostUserConfirmAppNewsAction',
  props<{ version: string }>(),
);

export const postUserTOSSuccessAction = createAction(
  'PostUserTOSSuccess',
  props<{ user: User }>(),
);
export const postUserTOSFailureAction = createAction(
  'PostUserTOSFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const postUserConfirmAppNewsSuccessAction = createAction(
  'PostUserTOSSuccess',
  props<{ user: User }>(),
);
export const postUserConfirmAppNewsFailureAction = createAction(
  'PostUserTOSFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const openNotificationModalAction = createAction(
  'OpenNotificationModalAction',
);
export const updateNotificationFlagAction = createAction(
  'UpdateNotificationFlag',
);
