<div class="header-content-wrapper" *ngIf="!searchBarVisible">
  <div class="header">
    <div class="header-text-container">
      <div class="cmv-h1 title">{{ title }}</div>
    </div>
    <div class="search-button-container">
      <div
        class="search-button"
        (click)="activateSearchBar()"
        *ngIf="searchButtonEnabled"
      >
        <mat-icon
          class="cmv-white-icon search-icon"
          svgIcon="cmv-search-left"
        ></mat-icon>
      </div>
      <div
        class="search-button"
        *ngIf="actionButtonEnabled"
        (click)="emitActionButton()"
      >
        <mat-icon
          class="cmv-white-icon plus-icon"
          [svgIcon]="actionButtonIcon"
        ></mat-icon>
      </div>
    </div>
  </div>
</div>
<cmv-search *ngIf="searchButtonEnabled && searchBarVisible"></cmv-search>
