import {
  Component,
  ElementRef,
  Input,
  HostListener,
  OnInit,
  ViewEncapsulation,
  HostBinding,
  OnDestroy,
} from "@angular/core";
import { Subscription } from "rxjs";
import {
  VgControlsHiddenService,
  VgApiService,
  VgStates,
} from "@videogular/ngx-videogular/core";
import { Key } from "ts-key-enum";

@Component({
  selector: "vg-scrub-bar",
  encapsulation: ViewEncapsulation.None,
  template: `
    <div
      class="scrubBar"
      tabindex="0"
      role="slider"
      aria-label="scrub bar"
      aria-level="polite"
      [attr.aria-valuenow]="getPercentage()"
      aria-valuemin="0"
      aria-valuemax="100"
      [attr.aria-valuetext]="getPercentage()"
    >
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      vg-scrub-bar {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        position: absolute;
        width: 100%;
        height: 5px;
        bottom: 50px;
        margin: 0;
        cursor: pointer;
        align-items: center;
        background: rgba(0, 0, 0, 0.75);
        z-index: 250;
        -webkit-transition: bottom 1s, opacity 0.5s;
        -khtml-transition: bottom 1s, opacity 0.5s;
        -moz-transition: bottom 1s, opacity 0.5s;
        -ms-transition: bottom 1s, opacity 0.5s;
        transition: bottom 1s, opacity 0.5s;
      }
      vg-scrub-bar .scrubBar {
        position: relative;
        display: flex;
        flex-grow: 1;
        align-items: center;
        height: 100%;
      }
      vg-controls vg-scrub-bar {
        position: relative;
        bottom: 0;
        background: transparent;
        height: 50px;
        flex-grow: 1;
        flex-basis: 0;
        margin: 0 10px;
        -webkit-transition: initial;
        -khtml-transition: initial;
        -moz-transition: initial;
        -ms-transition: initial;
        transition: initial;
      }
      vg-scrub-bar.hide {
        bottom: 0;
        opacity: 0;
      }
      vg-controls vg-scrub-bar.hide {
        bottom: initial;
        opacity: initial;
      }
    `,
  ],
})
export class VgScrubBarComponent implements OnInit, OnDestroy {
  @HostBinding("class.hide") hideScrubBar = false;

  @Input() vgFor: string;
  @Input() vgSlider = true;

  elem: HTMLElement;
  target: any;
  isSeeking = false;
  wasPlaying = false;
  allowSeekInLive = true;

  subscriptions: Subscription[] = [];

  constructor(
    ref: ElementRef,
    public API: VgApiService,
    vgControlsHiddenState: VgControlsHiddenService
  ) {
    this.elem = ref.nativeElement;
    this.subscriptions.push(
      vgControlsHiddenState.isHidden.subscribe((hide) =>
        this.onHideScrubBar(hide)
      )
    );
  }

  ngOnInit() {
    if (this.API.isPlayerReady) {
      this.onPlayerReady();
    } else {
      this.subscriptions.push(
        this.API.playerReadyEvent.subscribe(() => this.onPlayerReady())
      );
    }
  }

  onPlayerReady() {
    this.target = this.API.getMediaById(this.vgFor);
  }

  protected seekStart() {
    if (this.target.canPlay) {
      this.isSeeking = true;
      if (this.target.state === VgStates.VG_PLAYING) {
        this.wasPlaying = true;
      }
      this.target.pause();
    }
  }

  protected seekMove(offset: number) {
    if (this.isSeeking) {
      const percentage = Math.max(
        Math.min((offset * 100) / this.elem.scrollWidth, 99.9),
        0
      );
      this.target.time.current = (percentage * this.target.time.total) / 100;
      this.target.seekTime(percentage, true);
    }
  }

  protected seekEnd(offset: number | false) {
    this.isSeeking = false;
    if (this.target.canPlay) {
      if (offset !== false) {
        const percentage = Math.max(
          Math.min((offset * 100) / this.elem.scrollWidth, 99.9),
          0
        );
        this.target.seekTime(percentage, true);
      }
      if (this.wasPlaying) {
        this.wasPlaying = false;
        this.target.play();
      }
    }
  }

  protected touchEnd() {
    this.isSeeking = false;
    if (this.wasPlaying) {
      this.wasPlaying = false;
      this.target.play();
    }
  }

  protected getTouchOffset(event: any) {
    let offsetLeft = 0;
    let element: any = event.target;

    while (element) {
      if (this.isTopLayer(element)) {
        break;
      }

      offsetLeft += element.offsetLeft;
      element = element.offsetParent;
    }

    return event.touches[0].pageX - offsetLeft;
  }

  protected getMouseOffset(event: any) {
    let offsetLeft = 0;
    let element: any = this.elem;

    while (element) {
      if (this.isTopLayer(element)) {
        break;
      }

      offsetLeft += element.offsetLeft;
      element = element.offsetParent;
    }

    return event.clientX - offsetLeft;
  }

  isTopLayer(element: HTMLElement) {
    return element.classList.contains("native-fullscreen");
  }

  @HostListener("mousedown", ["$event"])
  onMouseDownScrubBar($event: any) {
    if (this.target) {
      if (!this.target.isLive || this.allowSeekInLive) {
        if (!this.vgSlider) {
          this.seekEnd(this.getMouseOffset($event));
        } else {
          this.seekStart();
        }
      }
    }
  }

  @HostListener("document:mousemove", ["$event"])
  onMouseMoveScrubBar($event: any) {
    if (this.target) {
      if (
        (!this.target.isLive || this.allowSeekInLive) &&
        this.vgSlider &&
        this.isSeeking
      ) {
        this.seekMove(this.getMouseOffset($event));
      }
    }
  }

  @HostListener("document:mouseup", ["$event"])
  onMouseUpScrubBar($event: any) {
    if (this.target) {
      if (
        (!this.target.isLive || this.allowSeekInLive) &&
        this.vgSlider &&
        this.isSeeking
      ) {
        this.seekEnd(this.getMouseOffset($event));
      }
    }
  }

  @HostListener("touchstart", ["$event"])
  onTouchStartScrubBar(_$event: any) {
    if (this.target) {
      if (!this.target.isLive || this.allowSeekInLive) {
        if (!this.vgSlider) {
          this.seekEnd(false);
        } else {
          this.seekStart();
        }
      }
    }
  }

  @HostListener("document:touchmove", ["$event"])
  onTouchMoveScrubBar($event: any) {
    if (this.target) {
      if (
        (!this.target.isLive || this.allowSeekInLive) &&
        this.vgSlider &&
        this.isSeeking
      ) {
        this.seekMove(this.getTouchOffset($event));
      }
    }
  }
  // @ts-ignore
  @HostListener("document:touchcancel", ["$event"]) onTouchCancelScrubBar(
    _$event: any
  ) {
    if (this.target) {
      if (
        (!this.target.isLive || this.allowSeekInLive) &&
        this.vgSlider &&
        this.isSeeking
      ) {
        this.touchEnd();
      }
    }
  }
  // @ts-ignore
  @HostListener("document:touchend", ["$event"]) onTouchEndScrubBar(
    _$event: any
  ) {
    if (this.target) {
      if (
        (!this.target.isLive || this.allowSeekInLive) &&
        this.vgSlider &&
        this.isSeeking
      ) {
        this.touchEnd();
      }
    }
  }

  @HostListener("document:keydown", ["$event"])
  arrowSeekTime(event: KeyboardEvent) {
    if (this.API.shortcutsDisabled) {
      return;
    }

    if (this.target) {
      if (event.key === Key.ArrowRight) {
        event.preventDefault();
        this.target.seekTime(this.target.currentTime + 10, false);
      } else if (event.key === Key.ArrowLeft) {
        event.preventDefault();
        this.target.seekTime(this.target.currentTime - 10, false);
      } else if (event.key === Key.Home) {
        event.preventDefault();
        this.target.seekTime(0, true);
      } else if (event.key === Key.End) {
        event.preventDefault();
        if (this.API.isLive) {
          this.API.jumpToLive();
        } else {
          this.target.seekTime(99.99, true);
        }
      }
    }
  }

  getPercentage() {
    return this.target
      ? Math.round((this.target.time.current * 100) / this.target.time.total) +
          "%"
      : "0%";
  }

  onHideScrubBar(hide: boolean) {
    this.hideScrubBar = hide;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
