<a
  class="match-widget-container"
  [routerLink]="matchRouterLink"
  [queryParams]="queryParams"
  [class.canNavigate]="
    navigation === true && match.status !== matchStatus.UPCOMING
  "
  *ngIf="match; else loading"
  [attr.data-live]="match.status === matchStatus.LIVE"
  [id]="match.id"
>
  <div class="cmv-h5 match-date">
    {{ matchDate }}
    <span class="match-time" *ngIf="matchTime">{{ matchTime }}</span>
  </div>
  <ng-container class="widget-detail" [ngSwitch]="match.type">
    <ng-container *ngSwitchCase="recordingType.MATCH">
      <div class="teams-container">
        <cmv-match-widget-team [team]="match.teamHome"></cmv-match-widget-team>
        <cmv-match-widget-team [team]="match.teamAway"></cmv-match-widget-team>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="recordingType.TRAINING">
      <div class="teams-container other-recording-container">
        <cmv-match-widget-team [team]="match.teamHome"></cmv-match-widget-team>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="recordingType.OTHER">
      <div class="teams-container other-recording-container">
        <div class="team">
          <div class="cmv-h5 team-name">
            {{ match.name }}
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div class="match-status">
    <div class="menu-wrap">
      <button
        mat-icon-button
        (click)="menuWrapClick($event)"
        [matMenuTriggerFor]="detailMenu"
      >
        <img src="assets/icons/more_icon_ver.svg" />
      </button>

      <mat-menu class="cmv-menu-container" #detailMenu="matMenu">
        <div (keydown)="$event.stopPropagation()">
          <div *ngIf="!isInUserTrash">
            <button
              class="cmv-menu-item"
              mat-menu-item
              (click)="editRecording()"
              *ngIf="match.manual && match.status !== matchStatus.PLAYED"
              [disabled]="!isEditable(match.recorderId, recorders$ | async)"
            >
              <span class="cmv-h4">
                {{ 'basic.editRecording' | translate }}
              </span>
              <span>
                <mat-icon
                  class="modal-icon cmv-gray-icon"
                  svgIcon="cmv-edit"
                ></mat-icon>
              </span>
            </button>
            <button
              class="cmv-menu-item"
              mat-menu-item
              (click)="openShareModal()"
              [disabled]="!isEditable(match.recorderId, recorders$ | async)"
            >
              <span class="cmv-h4">
                {{ 'basic.shareRecording' | translate }}
              </span>
              <span>
                <mat-icon
                  class="modal-icon cmv-gray-icon"
                  svgIcon="cmv-share"
                ></mat-icon>
              </span>
            </button>
            <button
              class="cmv-menu-item"
              mat-menu-item
              *ngIf="
                match.status === matchStatus.PLAYED ||
                (match.manual && match.status === matchStatus.UPCOMING)
              "
              (click)="deleteRecording(match)"
              [disabled]="!isEditable(match.recorderId, recorders$ | async)"
            >
              <span class="cmv-h4">
                {{ 'basic.removeRecording' | translate }}
              </span>
              <span>
                <mat-icon class="cmv-gray-icon" svgIcon="cmv-delete"></mat-icon>
              </span>
            </button>
          </div>
          <div *ngIf="isInUserTrash">
            <button
              class="cmv-menu-item"
              mat-menu-item
              (click)="restoreRecording(match)"
              [disabled]="!isEditable(match.recorderId, recorders$ | async)"
            >
              <span class="cmv-h4">
                {{ 'basic.restoreRecording' | translate }}
              </span>
              <span>
                <mat-icon
                  class="cmv-gray-icon"
                  svgIcon="cmv-restore"
                ></mat-icon>
              </span>
            </button>
          </div>
        </div>
      </mat-menu>
    </div>
  </div>
</a>

<ng-template #loading>
  <div class="match-widget-container-loading">
    <div class="match-date"></div>
    <div class="teams-container">
      <div class="team-placeholder">
        <img src="assets/icons/shield_icon.svg" />
        <div class="placeholder"></div>
      </div>
      <div class="team-placeholder">
        <img src="assets/icons/shield_icon.svg" />
        <div class="placeholder"></div>
      </div>
    </div>
  </div>
</ng-template>
