import { AppState } from '../../models/app.state';
import { createSelector } from '@ngrx/store';
import { SharingStateEntityModel } from '../../models/sharing.model';
import { $route } from './route.selectors';
import { RoutePath } from '../../../app.constants';

export const $sharingTeamsState = (state: AppState) =>
  state.sharing.entity ? state.sharing.entity.tree : null;
export const $sharingTeamsEntity = (state: AppState) => state.sharing.entity;
export const $sharingExpand = (state: AppState) =>
  state.sharing.entity ? state.sharing.entity.expanded : null;
export const $isSharingLoaded = (state: AppState) => state.sharing.loaded;
export const $treeExists = (state: AppState) =>
  state.sharing.entity && state.sharing.entity.tree.length;
export const $sharingTeamsStateMappedExpand = createSelector(
  $sharingTeamsEntity,
  (sharingTeamsEntity: SharingStateEntityModel) => {
    if (sharingTeamsEntity) {
      return {
        tree: sharingTeamsEntity.tree
          .map(x => ({ ...Object.values(x)[0] }))
          .sort((a, b) =>
            `${Object.keys(a)[0]}`.localeCompare(Object.keys(b)[0]),
          ),
        expanded: sharingTeamsEntity.expanded
          ? sharingTeamsEntity.expanded
          : [],
      };
    }
    return [];
  },
);
export const $shareableEntity = (state: AppState) =>
  state.sharing.entity ? state.sharing.entity.shareable : undefined;

export const $isSharingEnabled = createSelector(
  $route,
  route => !route.path.includes(RoutePath.Playlists),
);
