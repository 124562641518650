import { Team } from './../../../api/models/team.model';
import { RecordingType } from 'src/api/models';
export enum ManualSchedulingInputTypes {
  DATE = 'date',
  TIME = 'time',
  DURATION = 'duration',
  RECORDER_ID = 'recorderId',
  MANUAL_EXPORTS = 'manualExports',
  TYPE = 'type',
  TEAM1 = 'team1',
  TEAM2 = 'team2',
  TITLE = 'title',
}

export interface ManualSchedulingModel {
  id: string;
  date: number;
  duration: number;
  recorderId: string;
  manualExports: string[];
  type: RecordingType;
  team1?: { teamId: string; clubId: string };
  team2?: { teamId: string; clubId: string };
  title?: string;
}

export interface TeamModel extends Team {
  clubId: string;
}
