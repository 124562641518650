import { ChangeDetectionStrategy, Component } from '@angular/core';
import { APP_NEWS_VERSIONS } from 'src/app/app.constants';

@Component({
  selector: 'cmv-changelog-view',
  templateUrl: './changelog-view.component.html',
  styleUrls: ['./changelog-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangelogViewComponent {
  versions: Array<{ version: string; loaded: boolean }>;
  loaded = false;

  constructor() {
    this.versions = [...APP_NEWS_VERSIONS].reverse().map(v => ({
      version: v,
      loaded: false,
    }));
    this.loaded = false;
  }

  onLoad(version: string) {
    let index = this.versions.findIndex(v => v.version === version);
    this.versions[index].loaded = true;
    this.loaded = this.versions.filter(v => v.loaded === false).length === 0;
  }
}
