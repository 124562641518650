import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export enum INPUT_TYPE {
  PLAYLIST = 'playlist',
  ANNOTATION = 'annotation',
  TAG = 'tag',
  RECORDING = 'recording',
}

@Component({
  selector: 'cmv-restore-modal',
  templateUrl: './restore-modal.component.html',
  styleUrls: ['./restore-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestoreModalComponent implements AfterViewInit {
  title = '';

  @ViewChild('elementToFocus')
  elementToFocus: ElementRef;

  constructor(
    private readonly dialogRef: MatDialogRef<RestoreModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: { title: string },
  ) {
    this.title = data.title;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elementToFocus.nativeElement.focus();
    }, 0);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
  }

  closeModal(remove: boolean): void {
    this.dialogRef.close(remove);
  }
}
