<div>
  <div class="cmv-document-main-heading">PERSONAL DATA PROTECTION RULES</div>
  <p>
    The purpose of this document is to provide you with information about
    personal data processing in the company Panoris s.r.o. and about your rights
    related to your personal data and their processing.
  </p>
  <p>
    The processing of personal data is being conducted in accordance with the
    Regulation (EU) 2016/679 of the European Parliament and of the Council of 27
    April 2016 on the protection of natural persons with regard to the
    processing of personal data and on the free movement of such data, and
    repealing Directive 95/46/EC (hereinafter referred to as the “GDPR”).
  </p>
  <p>
    <span>
      These Personal Data Protection Rules (hereinafter referred to as the
      “Rules”) will be updated by the Panoris s.r.o. if necessary. The current
      updated version of the Personal Data Protection Rules will always be
      available at the websites app.panoris.com,
    </span>
    <a href="https://app.panoris.com">control.panoris.com</a>
    <span>or</span>
    <a href="http://www.panoris.com">www.panoris.com</a>
    <span>
      and at the register office of the company Panoris s.r.o. If a significant
      change occurs in these Rules in regard to the manners of handling of
      personal data, we will inform you about it on the websites
      app.panoris.com,
    </span>
    <a href="https://app.panoris.com">control.panoris.com</a>
    <span>or</span>
    <a href="http://www.panoris.com">www.panoris.com</a>
    <span>.</span>
  </p>
  <p>&nbsp;</p>
  <p>
    Due to the nature of provided services, Panoris s.r.o. performs both in
    positions of personal data controller as well as personal data processor.
  </p>
  <p>&nbsp;</p>
  <p class="cmv-h1 cmv-document-section">IDENTIFICATION DATA</p>
  <p>
    <b>Panoris s.r.o.</b>
    <span>
      , identification number (IČ)&nbsp;27820050, with registered office at
      Zadní 424/13, Bohunice, 625 00 Brno, a company registered in the
      Commercial Register with the Regional Court in Brno, section C, file 69494
      (hereinafter also referred to as „PANORIS“).
    </span>
  </p>
  <p>
    <span>Contact e-mail:</span>
    <span>info&#64;panoris.com.</span>
  </p>
  <p>
    <span>PANORIS</span>
    <b></b>
    <span>
      has not appointed a data protection officer, since it does not fit within
      the obligation set out in Art. 37 of the GDPR.
    </span>
  </p>
  <p>&nbsp;</p>
  <p class="cmv-h1 cmv-document-section">PANORIS AS PERSONAL DATA CONTROLLER</p>
  <p>
    <span>
      The PANORIS acts in the position of a personal data controller
      particularly in relation to the personal data of customers and of natural
      persons that visit the website
    </span>
    <span></span>
    <a href="https://app.panoris.com">
      <span></span>
      <span>app.panoris.com</span>
      <span></span>
    </a>
    <span>, control.panoris.com or www.panoris.com.</span>
  </p>
  <p>
    <b>Purpose of processing:</b>
    <span>
      For the purpose of fulfilling an agreement (registration to the
      application, communication) or fulfilling legal obligations (invoicing),
      the PANORIS processes: name, surname, business name, identification
      number, residence/registered address, telephone, email.
    </span>
  </p>
  <p>
    <span>
      The PANORIS also processes data that it obtains from the customers and
      from other natural persons through their visit to the websites
      app.panoris.com,
    </span>
    <a href="https://app.panoris.com">control.panoris.com</a>
    <span>or</span>
    <a href="http://www.panoris.com">www.panoris.com</a>
    <span>: IP address, or other online identifiers.</span>
  </p>
  <p>
    If the PANORIS intends to process other personal data than as stated in this
    article, or for other purposes, it can do so only on the basis of a validly
    granted consent to the processing of personal data.
  </p>
  <p>
    <b>Duration of data processing:</b>
    <span>
      The personal data are processed by the PANORIS for the duration of the
      contractual relationship and subsequently for a maximum period of 1 year
      from the termination of the contractual relationship. Personal data being
      processed in order to fulfill obligations arising from special legal
      regulations are processed by the PANORIS for the duration of time as set
      out in such legal regulations. If it is necessary to use the personal data
      for the protection of the PANORIS’s legitimate interests, the PANORIS
      processes these for the duration of time necessary in order to exercise
      such rights.
    </span>
  </p>
  <p>&nbsp;</p>
  <p class="cmv-h1 cmv-document-section">PANORIS AS PERSONAL DATA PROCESSOR</p>
  <p>
    <span>
      The PANORIS provides its partners with data space for the purposes of
      storing data operated within the scope of the service Panoris. The
      partner’s data may also include personal data of natural persons.
    </span>
    <b>
      In relation to the personal data that the partners or their customers
      store on the PANORIS’s servers, or within a hosting center, the PANORIS
      acts in the position of a personal data processor. The controller of such
      personal data is the PANORIS’s partner.
    </b>
  </p>
  <p>
    The PANORIS is not liable for personal data protection principles or
    security procedures used by the partner, which may differ from these
    Personal Data Protection Rules.
  </p>
  <p>
    <b>Purpose of processing and handling of data</b>
    <span>
      : The PANORIS does not carry out any operations upon the partners’ data
      and data of their customers and in particular, it does not interfere in
      them, does not modify them, does not disclose or transfer them to third
      parties unless the contracting parties agree otherwise.
    </span>
  </p>
  <p>
    <b>Type of personal data being processed:</b>
    <span>Name, surname</span>
    <b></b>
    <span>
      and contact information such as e-mail, video recordings of people. The
      PANORIS does not process personal data pertaining to judgments in criminal
      matters and criminal acts. The PANORIS does not process any personal data
      of a special category according to Art. 9 of the GDPR
    </span>
  </p>
  <p>
    <b>Categories of data subjects whose personal data will be processed</b>
    <span>
      : Natural persons, which will be recorded on video in relation with sports
      matches, customers of the service PANORIS.
    </span>
  </p>
  <p>
    <b>Duration of processing of personal data:</b>
    <span>
      The PANORIS processes personal data for the duration of the Agreement.
      After the elapse of 30 days from the termination of the Agreement, the
      PANORIS will erase (delete) all of the customers’ data stored on the
      PANORIS’s servers unless the contracting parties agree otherwise.
    </span>
  </p>
  <p>&nbsp;</p>
  <p class="cmv-h1 cmv-document-section">RECIPIENTS OF PERSONAL DATA</p>
  <p>The PANORIS does not transfer personal data to any other controllers.</p>
  <p class="cmv-document-section">Processors of personal data are:</p>
  <div class="cmv-document-table">
    <table>
      <tbody>
        <tr>
          <td>
            <p>Area of cooperation</p>
          </td>
          <td>
            <p>Processor Identification</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <b>
                Google
                <br />
              </b>
              <span>Emailing: Incoming and outgoing communication</span>
            </p>
            <p>GSuite</p>
          </td>
          <td>
            <p>
              Google Ireland Ltd, Gordon House, Barrow Street, Dublin 4,
              Ireland.
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Tomáš Winkler</p>
            <p>Application development</p>
          </td>
          <td>
            <p>Tomáš Winkler, IČO 6141129, Vinařického 3552/9, Brno 615 00</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>David Endrych</p>
            <p>Application development</p>
          </td>
          <td>
            <p>David Endrych, IČO 19622805, Lideřovská 28, Vnorovy 696 61</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>AWS Amazon</p>
            <p>Servers</p>
          </td>
          <td>
            <p>Amazon.com, Inc., P.O. Box 81226, Seattle, WA 98108-1226</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Auth0</p>
            <p>Authentication provider</p>
          </td>
          <td>
            <p>Auth0, 10800 NE 8th Street, Suite 700, Bellevue, WA 98004</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>mongoDB Cloud</p>
            <p>Servers</p>
          </td>
          <td>
            <p>
              mongoDB Cloud, 3 Shelbourne Building, 3rd Floor, Crampton Avenue,
              Ballsbridge, Dublin 4, Ireland.
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Marta Buršíková</p>
            <p>Tax advisor</p>
          </td>
          <td>
            <p>
              Marta Buršíková, daňový poradce č. 3112, Dvořákova 2881/77, 750 02
              Přerov
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Buršíková a Vojtášková, s.r.o.</p>
            <p>accountancy</p>
          </td>
          <td>
            <p>Oldřichov 36, office in Přerov, Dvořákova 77,</p>
            <p>IČO 04699874</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <p>&nbsp;</p>
  <p>
    Processing of personal data may be conducted for the PANORIS by processors
    exclusively on the basis of a personal data processing agreement, i.e. with
    guarantees of the organizational and technical security of such data with a
    definition of the purpose of processing, whereby processors cannot use the
    data for other purposes.
  </p>
  <p>
    Personal data may under certain conditions be disclosed to government
    authorities or the PANORIS may disclose them directly to other entities
    within the scope as set out in a special law.
  </p>
  <p>
    <span>
      PANORIS does not have intention to transfer personal data to third
      countries or to international organizations within the meaning of Art. 44
      and following of the GDPR.
    </span>
    <b></b>
    <span>
      In the case that Amazon's servers would be located outside the European
      Union, the processor will ensure an adequate protection under the GDPR
      rules (https://aws.amazon.com/compliance/gdpr-center/).
    </span>
  </p>
  <p>&nbsp;</p>
  <p class="cmv-h1 cmv-document-section">TECHNICAL SECURITY OF DATA</p>
  <p>
    For the purpose of the security of the data the PANORIS applies reasonable
    and appropriate technical and organizational measures that are continuously
    updated. For the purpose of maximum protection, the PANORIS uses encryption.
    Organizational measures are a set of rules of behavior for the PANORIS’s
    employees and are a part of the PANORIS’s internal rules, and are considered
    by the PANORIS to be confidential on grounds of security. If the PANORIS’s
    servers are located in a data center operated by a third party, the PANORIS
    takes care to ensure that the technical and organizational measures are
    implemented within such a PANORIS as well.
  </p>
  <p>
    The PANORIS places all data only on servers located within the European
    Union or in countries ensuring personal data protection in a manner
    equivalent to the protection ensured by the legal regulations of the Czech
    Republic.
  </p>
  <p>&nbsp;</p>
  <p class="cmv-h1 cmv-document-section">RIGHTS OF DATA SUBJECTS</p>
  <p class="cmv-document-header">The right to access to personal data</p>
  <p>
    The data subject has the right to obtain a confirmation from the PANORIS as
    to whether personal data pertaining to the data subject are or are not being
    processed, and if so, the data subject has the right to obtain access to
    such personal data and to the following information: a) the purpose of
    processing; b) the category of affected personal data; c) the recipients to
    which personal data have been or will be disclosed; d) the planned time
    period for which personal data will be stored; e) the existence of the right
    to require the correction or erasure of personal data from the controller or
    a restriction of the processing thereof, or to raise an objection to such
    processing; f) the right to lodge a complaint with supervisory authority; g)
    all available information on the source of the personal data, if they are
    not obtained from the data subject; h) the fact that automated
    decision-making is occurring, including profiling. The data subject also has
    the right to obtain a copy of the personal data being processed.
  </p>
  <p class="cmv-document-header">
    The right to the correction of personal data
  </p>
  <p>
    The data subject has the right for the PANORIS to correct inaccurate
    personal data pertaining to the data subject without undue delay, or to
    supplement incomplete personal data.
  </p>
  <p class="cmv-document-header">The right to the erasure of personal data</p>
  <p>
    The data subject has the right for the PANORIS to erase the data subject’s
    personal data pertaining to him/her without undue delay, in the event that:
    a) the personal data are no longer necessary for the purposes for which they
    were collected or otherwise processed; b) the data subject withdraws the
    consent on the basis of which the data were processed, and there is no other
    legal reason for processing; c) the data subject raises objections to
    processing and there are no overriding legitimate reasons for processing; d)
    the personal data were processed unlawfully; e) the personal data must be
    erased in order to fulfill a legal obligation set out within the law of the
    Union or of the Czech Republic; f) the personal data were collected in
    connection with an offer of information society services. The right to
    erasure shall not apply if the processing is necessary in order to fulfill
    legal obligations, for the establishment, exercise or defense of legal
    claims, and in other cases as set out within the GDPR.
  </p>
  <p class="cmv-document-header">The right to the restriction of processing</p>
  <p>
    The data subject has the right for the PANORIS to restrict processing, in
    any of the following cases: a) the data subject contests the accuracy of the
    personal data, for the time necessary for the PANORIS to verify the accuracy
    of the personal data; b) processing is unlawful and the data subject opposes
    the erasure of the personal data and, instead, requests a restriction of
    their use; c) the PANORIS no longer needs the personal data for the purposes
    of processing, but the data subject requires them for the establishment,
    exercise or defense of legal claims; d) the data subject has raised an
    objection to processing, until it is verified whether the PANORIS’s
    legitimate reasons override the legitimate reasons of the data subject.
  </p>
  <p class="cmv-document-header">The right to object to processing</p>
  <p>
    The data subject has, on grounds pertaining to the data subject’s specific
    situation, the right to raise an objection at any time to the processing of
    personal data pertaining to him/her and which the PANORIS is processing on
    grounds of its legitimate interest. In such a case, the PANORIS does not
    process the personal data further, unless it proves serious legitimate
    reasons for processing that override the interests or rights and freedoms of
    the data subject, or for the establishment, exercise or defense of legal
    claims.
  </p>
  <p class="cmv-document-header">The right to data portability</p>
  <p>
    The data subject has the right to obtain personal data pertaining to him/her
    that the data subject has provided to the PANORIS, in a structured, commonly
    used and machine-readable format, and the right to transfer such data to
    another controller, without the PANORIS preventing it, in the event that: a)
    processing is based upon consent and b) processing is being conducted by
    automated means. When exercising his/her right to data portability, the data
    subject has the right for personal data to be transferred directly by one
    controller to another controller, if this is technically feasible.
  </p>
  <p class="cmv-document-header">
    The right to lodge a complaint with a supervisory authority
  </p>
  <p>
    If the data subject believes that the PANORIS is not processing his/her
    personal data in a lawful manner, the data subject has the right to lodge a
    complaint with a supervisory authority The supervisory authority is the
    Office for Personal Data Protection with registered office at Pplk. Sochora
    27, 170 00 Praha 7, e-mail: posta&#64;uoou.cz, tel.: 234 665&nbsp;125.
  </p>
  <p class="cmv-document-header">
    The right to information regarding the correction or erasure of personal
    data or a restriction of processing
  </p>
  <p>
    The PANORIS is obligated to notify individual recipients to whom personal
    data have been disclosed of all corrections or erasures of personal data or
    restrictions on processing, with the exception of cases where this is found
    to be impossible or it requires a disproportionate effort. If the data
    subject requests it, the PANORIS informs the data subject of such
    recipients.
  </p>
  <p class="cmv-document-header">
    The right to be informed in the event of a breach of personal data security
  </p>
  <p>
    If it is likely that a certain case of personal data security breach will
    result in a high risk to the rights and freedoms of natural persons, the
    PANORIS shall notify the data subject of such breach without undue delay.
  </p>
  <p class="cmv-document-header">
    The right to withdraw consent to the processing of personal data
  </p>
  <p>
    If the PANORIS processes any personal data on the basis of consent, the data
    subject has the right to withdraw its consent to the processing of personal
    data at any time in writing, by sending a non‑consent to the processing of
    personal data to the email address info&#64;panoris.com.
  </p>
  <p class="cmv-document-header">
    The right not to be a subject to any decision based solely on automated
    individual decision-making, including profiling.
  </p>
  <p>
    We do not conduct automated individual decision-making or profiling during
    the processing of personal data within the meaning of the Art. 22 of the
    GDPR.
  </p>
  <p>&nbsp;</p>
  <p class="cmv-h1 cmv-document-section">COOKIE FILES</p>
  <p>
    The PANORIS uses cookie files, small text files that identify the user of
    the websites app.panoris.com, control.panoris.com or www.panoris.com and
    record the user’s user activities. The text of a cookie file often consists
    of a series of numbers and letters that uniquely identify the user’s
    computer, but do not provide any specific personal data regarding the user.
  </p>
  <p>
    <span>The website app.panoris.com</span>
    <span>,</span>
    <a href="https://app.panoris.com">control.panoris.com</a>
    <span>
      or www.panoris.com automatically identifies the user’s IP address. The IP
      address is the number automatically assigned to the user’s computer upon
      connecting to the internet. All such information is recorded in the
      activity file by the server, which enables the subsequent processing of
      data.
    </span>
  </p>
  <p>
    <b>Purpose of using cookie files:</b>
    <span>
      The PANORIS uses cookie files and similar technologies for several
      purposes, which include:
    </span>
  </p>
  <p class="cmv-document-section">Webpage: www.panoris.com</p>
  <div class="cmv-document-table">
    <table>
      <tbody>
        <tr>
          <td><p>Name of Cookies</p></td>
          <td>
            <p>Purpose and Expiration</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Google Analytics service</p>
          </td>
          <td>
            <p>
              Google Analytics - To register visitor flows, sources and
              pageviews.
            </p>
            <p>Expiration: 2 years</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <p>&nbsp;</p>
  <p>Webpage: app.panoris.com and control.panoris.com</p>
  <div class="cmv-document-table">
    <table>
      <tbody>
        <tr>
          <td><p>Name of Cookies</p></td>
          <td>
            <p>Purpose and Expiration</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Google Analytics service</p>
          </td>
          <td>
            <p>
              Google Analytics - To register visitor flows, sources and
              pageviews.
            </p>
            <p>Expiration: 2 years</p>
          </td>
        </tr>
        <tr>
          <td><p>Auth0</p></td>
          <td>
            <p>Authentication&nbsp;service</p>
            <p>Expiration: 6 years</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <p>&nbsp;</p>
  <p>
    <b>Cookie setting:</b>
    <span>
      The majority of web browsers accept cookie files automatically. However,
      they provide controls that enable them to be blocked or removed. Users of
      the
    </span>
    <span>
      websites app.panoris.com, control.panoris.com or www.panoris.com
    </span>
    <span>
      are thus entitled to set their browser in such a way so that the use of
      cookie files on their computer is prevented. Instructions for blocking or
      removing cookie files in browsers may usually be found in the user
      documentation of individual browsers.
    </span>
  </p>
</div>
