<div class="created-tags-more">
  <div class="buttons-wrapper">
    <a
      *ngIf="isDownloadPrepared"
      [href]="shareableList.url"
      class="annotation-download--container"
    >
      <div (click)="$event.stopPropagation()" class="annotation-download">
        <mat-icon
          class="annotation-download--icon"
          svgIcon="cmv-download"
        ></mat-icon>
      </div>
    </a>
    <button
      class="cmv-more-button"
      mat-icon-button
      (click)="$event.stopPropagation()"
      [matMenuTriggerFor]="detailMenu"
    >
      <img src="assets/icons/more_icon_ver.svg" />
    </button>
  </div>
  <mat-menu class="cmv-menu-container" #detailMenu="matMenu">
    <div (keydown)="$event.stopPropagation()">
      <button
        class="cmv-menu-item"
        (click)="shareAnnotationList(shareableList)"
        mat-menu-item
      >
        <span class="cmv-h4">
          {{ 'basic.shareList' | translate }}
        </span>
        <span class="menu-icon--wrapper">
          <mat-icon
            class="modal-icon cmv-gray-icon"
            svgIcon="cmv-share"
          ></mat-icon>
        </span>
      </button>
      <button
        *ngIf="
          !annotationsEmpty &&
          (currentRecordingHasVideo$ | async) &&
          (!shareableList?.status ||
            shareableList?.status === renderStatus.NONE ||
            shareableList.status === renderStatus.FAILED)
        "
        class="cmv-menu-item"
        (click)="downloadAnnotationList(shareableList)"
        mat-menu-item
      >
        <span class="cmv-h4">
          {{ 'basic.downloadList' | translate }}
        </span>
        <span class="menu-icon--wrapper">
          <mat-icon
            class="modal-icon cmv-gray-icon"
            [svgIcon]="
              shareableList?.status === renderStatus.FAILED
                ? 'cmv-retry'
                : 'cmv-download'
            "
          ></mat-icon>
        </span>
      </button>
    </div>
  </mat-menu>
</div>
