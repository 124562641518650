import { createAction, props } from '@ngrx/store';
import { AnnotationType, SearchResponse } from '../../../../api/models';
import { ErrorModel } from '../../models/app.state';
import { FilterSearchFormModel } from '../../models/filter-search-form.model';
import { FilterSearchRequestModel } from '../../models/filter-search-request.model';

export const getFilterSearchRequestAction = createAction(
  'GetFilterSearchRequest',
  props<{ options: FilterSearchRequestModel }>(),
);
export const getFilterSearchSuccessAction = createAction(
  'GetFilterSearchSuccess',
  props<{ response: SearchResponse }>(),
);
export const getFilterSearchFailureAction = createAction(
  'GetFilterSearchFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const setFilterSearchOption = createAction(
  'SetFilterSearchOptions',
  props<{ options: Partial<FilterSearchFormModel> }>(),
);

export const getFilterSearchTagsRequestAction = createAction(
  'GetFilterSearchTagsRequest',
);
export const getFilterSearchTagsSuccessAction = createAction(
  'GetFilterSearchTagsSuccess',
  props<{ tags: AnnotationType[] }>(),
);
export const getFilterSearchTagsFailureAction = createAction(
  'GetFilterSearchTagsFailure',
  props<{ errors: ErrorModel[] }>(),
);
export const resetSearchingAction = createAction('ResetSearching');

export const closeSearchingAfterRedirectAction = createAction(
  'CloseSearchingAfterRedirect',
);
