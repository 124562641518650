<cmv-page-layout
  [showHeader]="showHeader"
  [title]="title"
  [subtitle]="subtitle"
  [loadingResources]="loadingResources"
  [hideHeaderOnMobile]="true"
>
  <div class="grid-container">
    <div class="sub-content-left">
      <ng-content select="[left-column]"></ng-content>
    </div>
    <div class="sub-content-right">
      <ng-content select="[right-column]"></ng-content>
    </div>
  </div>
</cmv-page-layout>
