<div
  [ngClass]="{
    'modal-tos': modalVersion,
  }"
>
  <div class="cmv-document-main-heading">TERMS OF USE OF THE PANORIS APP</div>
  <div class="cmv-body">valid and effective from: 2020-03-01</div>
  <div class="cmv-body">version no.: 1.0</div>
  <ol>
    <li class="cmv-h1 cmv-document-section"><b>DEFINITIONS</b></li>
  </ol>
  <div>
    <div class="cmv-document-header">
      1.1.&nbsp;
      <span>“Provider”</span>
      <span>
        is the company Panoris&nbsp;s.r.o., with registered office at Zadní
        424/13, Bohunice, 625 00 Brno, identification number 27820050, VAT no.
        CZ27820050, incorporated in the Commercial Register maintained by the
        Regional Court in Brno, section C, file 69494.
      </span>
    </div>
    <div class="cmv-document-header">
      1.2.&nbsp;
      <span>“Panoris App”</span>
      <span>
        shall mean software provided as a service as described in paragraph 2.1.
      </span>
    </div>
    <div class="cmv-document-header">
      1.3.&nbsp;
      <span>“Contracting Party</span>
      <span></span>
      <span>”</span>
      <span>
        shall mean the party to the Purchase Agreement concluded between that
        party and the Provider, based on which the Provider provides the
        Contracting Party inter alia access to the Panoris App.
      </span>
    </div>
    <div class="cmv-document-header">
      1.4.&nbsp;
      <span>“User”</span>
      <span>
        shall mean an individual natural person appointed by the Contracting
        Party to use and manage an Account.
      </span>
    </div>
    <div class="cmv-document-header">
      1.5.&nbsp;
      <span>“User content”</span>
      <span></span>
      <span></span>
      <span>
        shall mean namely uploaded, submitted, stored or received content,
        primarily from Panoris Recorder, regardless of its form, by the User to
        his/her Account within the Panoris App.
      </span>
    </div>
    <div class="cmv-document-header">
      1.6.&nbsp;
      <span>“</span>
      <span>Account</span>
      <span>”</span>
      <span></span>
      <span>
        shall mean an individual user interface for using the Panoris App,
        available at app.panoris.com after logging in.
      </span>
    </div>
    <div class="cmv-document-header">
      1.7.&nbsp;
      <span>To</span>
      <span>“use content”</span>
      <i></i>
      <span>
        shall mean to upload, download, store, edit, view, share or in any way
        operate with User content.
      </span>
    </div>
    <div class="cmv-document-header">
      1.8.&nbsp;
      <span>“IP Rights”</span>
      <span>
        shall mean all existing and future intellectual property rights,
        including but not limited to patent rights, copyrights, neighboring
        rights, trademark rights, trade names, domain names, goodwill, design
        rights and database rights.
      </span>
    </div>
    <div class="cmv-document-header">
      1.9.&nbsp;
      <span>“Personal Data”</span>
      <span>
        shall mean any data concerning an identified or identifiable natural
        person within the meaning of Article 4 of the General Data Protection
        Regulation.
      </span>
    </div>
    <div class="cmv-document-header">
      1.10.&nbsp;
      <span>“Privacy Policy”</span>
      <span>
        shall mean the Provider’s policy regarding Personal Data protection.
        This policy governs all services provided by the Provider and is
        available at app.panoris.com/privacy.
      </span>
    </div>
  </div>
  <ol>
    <li value="2" class="cmv-h1 cmv-document-section">
      <span>INITIAL PROVISIONS</span>
    </li>
  </ol>
  <div>
    <div class="cmv-document-header">
      2.1.&nbsp;
      <span>
        The Provider operates a web-running application Panoris App available on
        app.panoris.com, which allows its users inter alia to store videos
        recorded with use of the Provider’s products, to edit and share such
        videos within organization, to create lists of the recordings, video
        highlights and tags, to plan recordings of selected sport events, to
        watch live broadcasts and is more closely specified on the Provider’s
        website app.panoris.com.
      </span>
    </div>
    <div class="cmv-document-header">
      2.2.&nbsp;
      <span>
        The scope of these Terms of Use is to provide binding rules for the use
        of the Panoris App, for the access of the Contracting Party and a User
        to the Panoris App and for using the content within the Panoris App. For
        data protection provisions and information please refer to our Privacy
        Policy, available at app.panoris.com/privacy. These Terms of Use are an
        integral part of a set of contractual documentation between the Provider
        and the Contracting Party, along with General Terms and Conditions,
        Privacy Policy and Purchase Agreement. Terms of Use shall be interpreted
        with respect to these documents.
      </span>
    </div>
    <div class="cmv-document-header">
      2.3.&nbsp;
      <span>
        Subject matter not specifically regulated hereto (i.e. term,
        termination, price, payment terms) shall be subject to provisions set
        out in the Purchase Agreement.
      </span>
    </div>
    <div class="cmv-document-header">
      2.4.&nbsp;
      <span>
        Where Terms of Use impose an obligation to the Contracting Party, the
        same obligation is adequately imposed to a User.
      </span>
    </div>
  </div>
  <ol>
    <li value="3" class="cmv-h1 cmv-document-section">
      <span>account establishment</span>
    </li>
  </ol>
  <div>
    <div class="cmv-document-header">
      3.1.&nbsp;
      <span>
        Depending on the Purchase Agreement, Accounts may be established:
      </span>
    </div>
  </div>
  <ol>
    <li>
      <span>
        The Contracting Party shall appoint a number of Users not exceeding the
        number of Users agreed upon in the Purchase Agreement. The Contracting
        Party is obliged to provide the Provider with a list of the appointed
        Users, containing name, surname, job position and email address of each
        User. After receiving such list, the Provider shall create Accounts for
        the designated Users and send login details (generated username and
        password) to the Users’ email addresses.
      </span>
    </li>
    <li>
      <span>
        The Contracting Party shall appoint a number of Users not exceeding the
        number of Users agreed upon in the Purchase Agreement and provide
        Provider with a list of appointed Users. Every such User shall create
        his own Account, by entering Provider´s webpage app.panoris.com and
        filling particularly his/her name, position, organization, e-mail and
        after creation of the password to the Account.
      </span>
    </li>
  </ol>
  <div>
    <div class="cmv-document-header">
      3.2.&nbsp;
      <span>
        The Contracting Party shall ensure that the information provided to the
        Provider according to this paragraph is up to date and is obliged to
        announce any changes in it without delay.
      </span>
    </div>
    <div class="cmv-document-header">
      3.3.&nbsp;
      <span>
        Each Account shall be used solely by its User. Users are responsible for
        ensuring safety of their Accounts, namely for safekeeping and not
        sharing login details. For that purpose, each User shall change password
        to the Account, if assigned by the Provider. The Provider reserves the
        right to block any Account in case of a reasonable doubt about
        compliance with these obligations or on demand of the Contracting Party.
      </span>
    </div>
    <div class="cmv-document-header">
      3.4.&nbsp;
      <span>Forgotten password may be restored on demand of a User.</span>
    </div>
  </div>
  <ol>
    <li value="4" class="cmv-h1 cmv-document-section">
      <span>use of the panoris app</span>
    </li>
  </ol>
  <div>
    <div class="cmv-document-header">
      4.1.&nbsp;
      <span>A consent of a User with these Terms</span>
      <span></span>
      <span></span>
      <span>
        of Use is an essential condition for using of an account by such User.
        Refusing to accept these Terms of Use is a reason for making the account
        inaccessible to a User.
      </span>
    </div>
    <div class="cmv-document-header">
      4.2.&nbsp;
      <span>
        The Contracting Party is responsible for conduct of its Users, and every
        breach of these Terms of Use by a User can be attributed to the
        Contracting Party.
      </span>
    </div>
    <div class="cmv-document-header">
      4.3.&nbsp;
      <span>
        The Panoris App shall be used only for purposes for which it was
        designed and provided to the Contracting Party for, i.e. to store videos
        of sport events, to edit and share such videos within Contracting
        Party’s organization, to create lists of the recordings, to plan
        recordings of selected sport events and to watch live broadcasts. Users
        are namely forbidden to use the Panoris App for any kind of unlawful
        conduct, including but not limited to:
      </span>
    </div>
  </div>
  <ol>
    <li>
      <span>
        using content breaching rights of third parties, namely their IP Rights
        and Personal Data protection rights;
      </span>
    </li>
    <li>
      <span>
        using content forbidden by the law or considered by the law as unlawful;
      </span>
    </li>
    <li>
      <span>
        using offensive content, which assessment is solely on Provider’s
        discretion (incl. racist, discriminatory, sexist);
      </span>
    </li>
    <li>
      <span>
        introducing or spreading (computer) viruses to and through Panoris App
        or take other actions that could affect any functionality of the Panoris
        App and/or infringe security of the Panoris App.
      </span>
    </li>
  </ol>
  <div>
    <div class="cmv-document-header">
      4.4.&nbsp;
      <span>
        Accounts of Users created on basis of Purchase Agreement with
        Contracting Party
      </span>
      <span></span>
      <span>
        shall have aggregate storage capacity agreed upon in the Purchase
        Agreement. In case the storage capacity reaches its limit, depending on
        the Purchase Agreement:
      </span>
    </div>
  </div>
  <ol>
    <li>
      <span>
        the oldest content shall be shortened, edited, changed or deleted to
        free storage capacity for the newest content. A User and the Contracting
        Party hereby renounce any loses/damages etc. arising from such deletion;
      </span>
    </li>
    <li>
      <span>
        a User shall be notified of the lack of capacity and asked to delete
        saved content or to increase storage capacity for respective fee,
        otherwise a User will not be able to save newest content. The capacity
        shall be increased only after the respective fee is fully paid to the
        Provider.
      </span>
    </li>
  </ol>
  <div>
    <div class="cmv-document-header">
      4.5.&nbsp;
      <span>
        The Contracting Party and Users shall at all times take into account
        rights, good name and reputation of the Provider and ensure that the use
        of the Panoris App in no way harms rights, good name and reputation of
        the Provider.
      </span>
    </div>
    <div class="cmv-document-header">
      4.6.&nbsp;
      <span>
        After termination of the Purchase Agreement, the content of the
        Contracting Party or a User shall be deleted by the Provider, unless
        agreed otherwise.
      </span>
    </div>
    <div class="cmv-document-header">
      4.7.&nbsp;
      <span>
        In case of change of a User, content of previous User shall be backed up
        for 30 days. Unless the Contracting Party asks the Provider to place
        such content to a storage of other Account, the content shall be
        deleted.
      </span>
      <span></span>
    </div>
  </div>
  <ol>
    <li value="5" class="cmv-h1 cmv-document-section">
      <span>licensing and ip rights</span>
    </li>
  </ol>
  <div>
    <div class="cmv-document-header">
      5.1.&nbsp;
      <span>
        The Provider hereby grants the Contracting Party a license, i.e. the
        entitlement to exercise the right to use the Panoris App, under the
        terms as set out below:
      </span>
    </div>
  </div>
  <ol>
    <li>
      <span>The license is stipulated as being a non-exclusive license;</span>
    </li>
    <li>
      <span>
        The license is granted for the term of the Purchase Agreement, whereby
        the license is conditional upon compliance with the terms of the
        license, as well as upon compliance with the Contracting Party’s
        obligations under these Terms of Use;
      </span>
    </li>
    <li>
      <span>Territorial scope of the license is unrestricted.</span>
    </li>
    <li>
      <span>
        The quantitative scope of the license (number of licenses) is restricted
        by the number of the Contracting Party’s appointed Users. The
        Contracting Party is entitled to change the scope of the license under
        the terms of the Purchase Agreement.
      </span>
    </li>
    <li>
      <span>
        The Provider is not obliged to provide any reproduction of the Panoris
        App to the Contracting Party. The Contracting Party is not entitled to
        the Panoris App’s source codes.
      </span>
    </li>
    <li>
      <span>
        The Contracting Party is not entitled, without the express written
        consent of the Provider, to grant a sublicense, assign rights to the
        Panoris App or to otherwise make it accessible in any manner other than
        under through normal use.
      </span>
    </li>
    <li>
      <span>
        The Contracting Party is not entitled to perform decompilation of the
        Panoris App or of individual programs contained within the Application,
        or any other processing, translation, reproduction or change of the
        Application or any decompilation or disassembly.
      </span>
    </li>
  </ol>
  <div>
    <div class="cmv-document-header">
      5.2.&nbsp;
      <span>
        Panoris App and all of its parts, such as texts, codes, design, user
        interface, applications, web pages or information architecture, are the
        exclusive ownership of the Provider and cannot be utilized, copied,
        misused, resold or reproduced without the express written consent of the
        Provider. These rules also pertain to other copyright works provided to
        the Contracting Party for use or created on the basis of the Purchase
        Agreement (user documentation, processing or modifications to the
        Panoris App, the creation of other software). By using the Panoris App,
        neither Contracting Party nor a User obtain any form of IP Right in the
        Panoris App.
      </span>
    </div>
    <div class="cmv-document-header">
      5.3.&nbsp;
      <span>
        Owner of the User content is either the Contracting party or a User,
        depending on their mutual agreement and/or statutory provisions. The
        Provider does not claim any rights to the User content, except rights
        hereto reserved and rights resulting from breaching of Terms of Use.
      </span>
    </div>
  </div>
  <ol>
    <li value="6" class="cmv-h1 cmv-document-section">
      <span>liability</span>
    </li>
  </ol>
  <div>
    <div class="cmv-document-header">
      6.1.&nbsp;
      <span>
        The Provider does not bear any liability for any direct or indirect harm
        occurring in connection with the use of the Panoris App, or for any harm
        occurring due to the temporary partial and/or complete unavailability of
        the Panoris App to the extent in which this is allowed by the valid
        legal regulations and service level agreement. The Contracting Party is
        not entitled to demand any compensation of damage from the Provider, nor
        is the Contracting Party entitled to any refund of the price or any
        proportionate part thereof for a period in which the provision of the
        Panoris App is limited or suspended. The Provider is not liable for
        damage caused namely by
      </span>
    </div>
  </div>
  <ol>
    <li>
      <span>
        the input of incorrect data into the Panoris App by the Contracting
        Party, an erroneous course of action on the part of the Contracting
        Party when inputting information or files into the Application;
      </span>
    </li>
    <li>
      <span>
        a failure to fulfill minimum systems or other requirements in order to
        run the Application;
      </span>
    </li>
    <li>
      <span>
        the infection of the Contracting Party’s local network or its computers
        with computer viruses (spyware, malware etc.), or through an attack by
        hackers or through another similar attack from outside;
      </span>
    </li>
    <li>
      <span>
        damage caused by incorrect functioning of the Contracting Party’s
        technical equipment, operating system or network;
      </span>
    </li>
    <li>
      <span>
        a violation of legal regulations regulating the handling of personal
        data, the manner of exerting control over employees, and other legal
        regulations pertaining to the use of the Panoris App, on the part of the
        Contracting Party;
      </span>
    </li>
    <li>
      <span>
        a leak of access passwords to third parties caused by the Contracting
        Party;
      </span>
    </li>
    <li>
      <span>
        the unavailability of data and the suspension of access to the Panoris
        App as a result of a delay on the part of the Contracting Party in
        fulfilling financial obligations or in other cases stipulated within
        these Terms of Use.
      </span>
    </li>
  </ol>
  <div>
    <div class="cmv-document-header">
      6.2.&nbsp;
      <span>
        The Contracting Party acknowledges that the Panoris App is provided as
        is. Its provision is dependent upon the availability of a number of
        third party services. If an outage of the Panoris App occurs due to the
        non-functionality of third party services, such a situation will be
        considered to be force majeure. It is not within the power of the
        Provider to assume liability for such outages.
      </span>
    </div>
    <div class="cmv-document-header">
      6.3.&nbsp;
      <span>
        The Contracting Party acknowledges that the Provider does not backup
        User content and is therefore not liable for its loss in any case.
      </span>
    </div>
    <div class="cmv-document-header">
      6.4.&nbsp;
      <span>
        The Contracting Party and the Provider declare that the Provider does
        not exercise direct or indirect decisive influence over the Users’
        activities.
      </span>
    </div>
    <div class="cmv-document-header">
      6.5.&nbsp;
      <span>
        The Contracting Party and Users are obliged to report any unlawful and
        inappropriate content with regards to par. 4.2. to the Provider. The
        Provider reserves the right to temporarily restrict access to such
        content and to delete such content once it is found that it breaches
        these Terms of Use. A User and the Contracting Party hereby renounce any
        loses/damages etc. arising from such deletion.
      </span>
    </div>
    <div class="cmv-document-header">
      6.6.&nbsp;
      <span>
        The Provider reserves the right to erase any potentially illegal User
        content without prior notice and without any compensation for a User and
        Contracting Party.
      </span>
    </div>
    <div class="cmv-document-header">
      6.7.&nbsp;
      <span>
        The Contracting Party and Users proclaim they are aware of the fact that
        the Provider may collect statistical data and logs in encrypted form in
        order to improve Panoris App and its safety.
      </span>
    </div>
  </div>
  <ol>
    <li value="7" class="cmv-h1 cmv-document-section">
      <span>Availability of the panoris app</span>
      <span></span>
    </li>
  </ol>
  <div>
    <div class="cmv-document-header">
      7.1.&nbsp;
      <span>
        The Provider guarantees availability of the Panoris App under the
        following service level guarantee:
      </span>
    </div>
  </div>
  <ol>
    <li>
      <span>
        <span>
          Panoris App shall be available to Users for at least 90 % of time each
          calendar month.
        </span>
      </span>
    </li>
    <li>
      <span>
        The time of persisting force majeure or an obstacle on the part of
        Contracting Party or User shall be deducted from the month’s overall
        time.
      </span>
    </li>
    <li>
      <span>
        The Provider is entitled to perform maintenance of the Panoris App
        without prior notice, during which the Panoris App will not be
        available, for up to 2 hours per month.
      </span>
    </li>
  </ol>
  <div>
    <div class="cmv-document-header">
      7.2.&nbsp;
      <span>
        Helpdesk support is a service intended for dealing with all operating
        incidents reported by the Contracting Party and its Users. The
        communication between the Contracting party and the Provider will be
        conducted through email support&#64;panoris.com.
      </span>
    </div>
    <div class="cmv-document-header">
      7.3.&nbsp;
      <span>
        The Contracting party acknowledges that all operating incidents or other
        demands must be dealt with through Helpdesk support only. In the event
        of a dispute between the Contracting party and the Provider, the
        communication within Helpdesk support shall be decisive; in view of its
        operating capacities, the Provider cannot take into consideration any
        other communication (by telephone, fax ...).
      </span>
    </div>
    <div class="cmv-document-header">
      7.4.&nbsp;
      <span>
        The scope of user support for each Contracting Party is defined in the
        Purchase agreement.
      </span>
    </div>
  </div>
  <ol>
    <li value="8" class="cmv-h1 cmv-document-section">
      <span>final provisions</span>
    </li>
  </ol>
  <div>
    <div class="cmv-document-header">
      8.1.&nbsp;
      <span>
        The Terms of Use shall be governed by the law prorogated in the Purchase
        Agreement. If no law is prorogated thereto, the Terms of Use shall be
        governed by Czech law, primarily by the provisions of Act No. 89/2012
        Coll., the Civil Code, as amended, and Act No. 121/2000 Coll., the
        Copyright Act, as amended, regardless of conflict of laws.
      </span>
    </div>
    <div class="cmv-document-header">
      8.2.&nbsp;
      <span>
        All disputes arising from these Terms of Use will be settled by a Czech
        court, whose local jurisdiction will be established according to the
        Provider’s registered address. The parties are obliged to seek amicable
        resolution prior to commencing a legal dispute.
      </span>
    </div>
    <div class="cmv-document-header">
      8.3.&nbsp;
      <span>The Provider is entitled to unilaterally change th</span>
      <span>e</span>
      <span>Terms of Use</span>
      <span>to a reasonable extent.</span>
      <span>The Provider is obligated to give notice of the fact that the</span>
      <span>Terms of Use</span>
      <span></span>
      <span>have been chan</span>
      <span>ged by way of an e-mail to the Contracting Party</span>
      <span>
        ’s e-mail address, at least 30 days prior to the effective date of the
        new version of the
      </span>
      <span>Terms of Use</span>
      <span>. If the</span>
      <span>Contracting Party</span>
      <span></span>
      <span>
        does not reject the changes to the Terms that it has been duly notified
        of, the relationship under the
      </span>
      <span>Purchase Agreement</span>
      <span>will be governed by the new version of the</span>
      <span>Terms of Use</span>
      <span></span>
      <span>as per the text as notified to the</span>
      <span>Contracting Party</span>
      <span>.</span>
    </div>
  </div>
</div>
