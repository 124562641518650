import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../models/app.state';
import { setSearchBarVisibilityAction } from '../../store/actions/ui-flags.actions';

@Component({
  selector: 'cmv-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() actionButtonEnabled = false;
  @Input() actionButtonIcon = 'cmv-plus';
  @Input() searchButtonEnabled = false;
  @Input() searchBarVisible = false;

  @Output() actionButtonClicked = new EventEmitter<void>();

  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
  ) {}

  activateSearchBar(): void {
    this.store.dispatch(
      setSearchBarVisibilityAction({ visible: true, route: this.router.url }),
    );
  }

  emitActionButton(): void {
    this.actionButtonClicked.emit();
  }
}
