import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'cmv-version-info',
  templateUrl: './version-info.component.html',
  styleUrls: ['./version-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionInfoComponent implements OnInit {
  @Input() version: string;
  @Output() loadInfo = new EventEmitter<string>();

  url: string;

  ngOnInit(): void {
    this.url = `../../../../assets/changelogs/en/${this.version}.md`;
  }

  onLoad() {
    this.loadInfo.emit(this.version);
  }
}
