import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import moment from 'moment';

export class DateValidators {
  static dateMinimum(
    date: Date,
    granularity?: moment.unitOfTime.StartOf,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      const controlDate = moment(control.value);

      if (!controlDate.isValid()) {
        return null;
      }

      const validationDate = moment(date);

      return controlDate.isSameOrAfter(validationDate, granularity)
        ? null
        : {
            minDate: {
              minDate: validationDate.format(),
              actual: controlDate.format(),
            },
          };
    };
  }

  static dateMaximum(
    date: Date,
    granularity?: moment.unitOfTime.StartOf,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      const controlDate = moment(control.value);

      if (!controlDate.isValid()) {
        return null;
      }

      const validationDate = moment(date);

      return controlDate.isSameOrBefore(validationDate, granularity)
        ? null
        : {
            maxDate: {
              maxDate: validationDate.format(),
              actual: controlDate.format(),
            },
          };
    };
  }

  static laterTodayValidator(
    control: AbstractControl,
  ): ValidationErrors | null {
    if (
      !control.parent ||
      !control.parent.get('date') ||
      !control.parent.get('time')
    ) {
      return null;
    }

    const dateVal = control.parent.get('date')!.value;
    const timeVal = control.parent.get('time')!.value;
    const [hours, minutes] = timeVal.split(':');

    if (!hours || !minutes) {
      return null;
    }

    const dateTime = moment(dateVal).hours(hours).minutes(minutes);

    const res = dateTime.isBefore(moment());
    return res ? { minDate: res } : null;
  }
}
