<div [formGroup]="tagForm">
  <div class="search">
    <div class="search-input-grid">
      <div class="search-input-wrap">
        <div class="back-button" (click)="resetFilters()">
          <mat-icon
            class="cmv-white-icon search-filter-icon"
            svgIcon="cmv-arrow-back"
          ></mat-icon>
        </div>
        <div class="search-input">
          <mat-form-field class="search-input-field" floatLabel="never">
            <input
              matInput
              [formControlName]="filterFormControls.TEXT"
              type="search"
              placeholder="{{ 'components.search.search' | translate }}"
            />
          </mat-form-field>
          <div class="filters-button" (click)="toggleFilters()">
            <mat-icon
              class="search-filter-icon"
              [ngClass]="{
                'filter-icon-active': searchFiltersVisible$ | async,
                'filter-icon-inactive':
                  (searchFiltersVisible$ | async) === false,
              }"
              svgIcon="cmv-filters"
            ></mat-icon>
          </div>
          <div class="search-icon">
            <mat-icon
              class="cmv-white-icon search-filter-icon"
              svgIcon="cmv-search-left"
            ></mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div
      class="grid-container search-filters-grid"
      *ngIf="searchFiltersVisible$ | async"
    >
      <div
        class="search-filters-wrap"
        *ngIf="isLargeDevice$ | async; else mobileFilters"
      >
        <div class="filter-field-wrapper">
          <mat-form-field
            floatLabel="never"
            class="date-input"
            (click)="picker.open()"
          >
            <div class="filter-label-font filter-label">
              {{ 'components.search.date' | translate }}
            </div>
            <div class="mat-date-range-input--container">
              <mat-date-range-input
                [rangePicker]="picker"
                [min]="minDate"
                [max]="maxDate"
              >
                <input
                  matStartDate
                  [formControlName]="filterFormControls.FROM"
                  placeholder="{{ 'components.search.from' | translate }}"
                  readonly="true"
                />
                <input
                  matEndDate
                  [formControlName]="filterFormControls.TO"
                  placeholder="{{ 'components.search.to' | translate }}"
                  readonly="true"
                />
              </mat-date-range-input>
              <mat-icon
                *ngIf="this.fromFormControl!.value !== null"
                matDatepickerToggleIcon
                class="clearDateIcon"
                (click)="clearDate($event)"
              >
                clear
              </mat-icon>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </div>
          </mat-form-field>
        </div>
        <div class="filter-field-wrapper">
          <mat-form-field floatLabel="never">
            <div class="filter-label-font filter-label">
              {{ 'components.search.contentType' | translate }}
            </div>
            <mat-select
              panelClass="select-panel"
              disableOptionCentering="true"
              [formControlName]="filterFormControls.TYPE"
            >
              <mat-option
                *ngFor="let contentType of contentTypesArray"
                [value]="contentType"
              >
                {{
                  'components.search.contentTypes.' + contentType | translate
                }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          class="filter-field-wrapper"
          *ngIf="this.typeFormControl!.value !== searchType.RECORDINGS"
        >
          <mat-form-field floatLabel="never">
            <div class="filter-label-font filter-label">
              {{ 'components.search.tags' | translate }}
            </div>
            <mat-select
              panelClass="select-panel"
              disableOptionCentering="true"
              [formControlName]="filterFormControls.TYPE_ID"
            >
              <mat-option [value]="templateNull">
                {{ 'components.search.all' | translate }}
              </mat-option>
              <mat-option
                *ngFor="
                  let filterTag of filterSearchTags$ | async;
                  trackBy: tagsTrackBy
                "
                [value]="filterTag.id"
              >
                {{ filterTag.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <ng-template #mobileFilters>
        <div class="search-filters-wrap-mobile">
          <div class="filter-field-wrapper-mobile">
            <mat-form-field
              floatLabel="never"
              class="date-input date-input-mobile"
              (click)="picker.open()"
            >
              <div class="filter-label-font">
                {{ 'components.search.date' | translate }}
              </div>
              <div class="mat-date-range-input--container">
                <mat-date-range-input
                  [rangePicker]="picker"
                  [min]="minDate"
                  [max]="maxDate"
                >
                  <input
                    matStartDate
                    [formControlName]="filterFormControls.FROM"
                    placeholder="{{ 'components.search.from' | translate }}"
                    readonly="true"
                  />
                  <input
                    matEndDate
                    [formControlName]="filterFormControls.TO"
                    placeholder="{{ 'components.search.to' | translate }}"
                    readonly="true"
                  />
                </mat-date-range-input>
                <mat-icon
                  *ngIf="this.fromFormControl!.value !== null"
                  matDatepickerToggleIcon
                  class="clearDateIcon"
                  (click)="clearDate($event)"
                >
                  clear
                </mat-icon>
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </div>
            </mat-form-field>
          </div>
          <mat-accordion multi="true" displayMode="flat">
            <mat-expansion-panel
              class="filter-expansion-panel"
              #contentTypeExpansion
            >
              <mat-expansion-panel-header class="filter-expansion-panel-header">
                <span class="filter-label-font">
                  {{ 'components.search.contentType' | translate }}
                </span>
                {{
                  'components.search.contentTypes.' + selectedContentType
                    | translate
                }}
              </mat-expansion-panel-header>
              <div
                class="filter-expansion-panel-option"
                *ngFor="let contentType of contentTypesArray"
                (click)="
                  patchContentType(contentType); contentTypeExpansion.close()
                "
              >
                {{
                  'components.search.contentTypes.' + contentType | translate
                }}
              </div>
            </mat-expansion-panel>
            <div *ngIf="this.typeFormControl!.value !== searchType.RECORDINGS">
              <mat-expansion-panel
                class="filter-expansion-panel"
                *ngIf="filterSearchTags$ | async as filterSearchTags"
                #tagsExpansion
              >
                <mat-expansion-panel-header
                  class="filter-expansion-panel-header"
                >
                  <span class="filter-label-font">
                    {{ 'components.search.tags' | translate }}
                  </span>
                  {{ selectedTag(filterSearchTags) }}
                </mat-expansion-panel-header>
                <div
                  class="filter-expansion-panel-option"
                  (click)="patchTag(templateNull); tagsExpansion.close()"
                >
                  {{ 'components.search.all' | translate }}
                </div>
                <div
                  class="filter-expansion-panel-option"
                  *ngFor="
                    let filterTag of filterSearchTags;
                    trackBy: tagsTrackBy
                  "
                  (click)="patchTag(filterTag.id); tagsExpansion.close()"
                >
                  {{ filterTag.name }}
                </div>
              </mat-expansion-panel>
            </div>
          </mat-accordion>
          <div
            class="cmv-accept-button apply-filters-button"
            (click)="updateFilters()"
          >
            {{ 'components.search.applyFilters' | translate }}
          </div>
        </div>
      </ng-template>
    </div>
    <div
      *ngIf="searchFiltersOverlayVisible$ | async"
      class="search-filter-overlay"
      (click)="hideSearchFilter()"
    ></div>
  </div>
</div>
