import { Action, createReducer, on } from '@ngrx/store';
import { CameraState } from '../../models/app.state';
import {
  getRecorderCameraFailureAction,
  getRecorderCameraRequestAction,
  getRecorderCameraSuccessAction,
  resetRecorderCamerasAction,
} from '../actions/camera.actions';

const initialState: CameraState = {
  entity: undefined,
  errors: [],
  loading: false,
  loaded: false,
};

const reducer = createReducer(
  initialState,
  on(getRecorderCameraRequestAction, (state, {}) => ({
    ...state,
    loading: true,
  })),
  on(getRecorderCameraSuccessAction, (state, { recorder }) => ({
    ...state,
    entity: recorder.sources || [],
    loaded: true,
    loading: false,
    errors: [],
  })),
  on(getRecorderCameraFailureAction, (state, { errors }) => ({
    ...state,
    loading: false,
    errors,
  })),
  on(resetRecorderCamerasAction, state => ({ ...state, entity: [] })),
);

export function cameraReducer(state: CameraState, action: Action): CameraState {
  return reducer(state, action);
}
