import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'cmv-loading-state',
  templateUrl: './loading-state.component.html',
  styleUrls: ['./loading-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingStateComponent {
  @Input() overlay = false;
  @Input() extraClass = '';

  get componentStyleClass(): string {
    return `loading-state${this.overlay ? '-overlay' : ''} ${this.extraClass}`;
  }
}
