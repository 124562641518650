import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Video, VideoStatus } from 'src/api/models';
import { RemuxVideoModalComponent } from '../modal/remux-video-modal/remux-video-modal.component';
import { logVideoDownloadAction } from '../../store/actions/log.actions';
import { AppState } from '../../models/app.state';
import { Store } from '@ngrx/store';
import { reportToGA } from 'src/app/app.utils';
import { GoogleAnalyticsEvent } from 'src/app/app.constants';

@Component({
  selector: 'cmv-download-video-menu',
  templateUrl: './download-video-menu.component.html',
  styleUrls: ['./download-video-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadVideoMenuComponent {
  @Input() downloadVideos: Video[];
  @Input() liveVideos: Video[];

  VideoStatus = VideoStatus;
  constructor(
    public readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
  ) {}

  onRenderVideo(video: Video): void {
    if (video.status === VideoStatus.DONE && video.remuxVideoLink) {
      this.downloadVideo(video.remuxVideoLink, video.id, video.recordingId);
    } else if (video.status !== VideoStatus.REMUXING) {
      this.dialog.open(RemuxVideoModalComponent, {
        data: {
          videoId: video.id,
        },
      });
    }
  }

  downloadVideo(downloadUrl: string, videoId: string, recordingId: string) {
    reportToGA(GoogleAnalyticsEvent.DOWNLOAD_VIDEO);
    this.store.dispatch(
      logVideoDownloadAction({
        videoId,
        recordingId,
      }),
    );
    window.open(downloadUrl, '_blank');
  }

  getVideoRemuxTranslateString(video: Video) {
    return `components.playedMatchTagging.${
      video.status === VideoStatus.REMUXING ? 'renderingFeed' : 'downloadFeed'
    }`;
  }
}
