import { createSelector } from '@ngrx/store';
import moment from 'moment';
import {
  Annotation,
  AnnotationTeam,
  Playlist,
  RecordingStatus,
  RecordingType,
  VideoStatus,
} from '../../../../api/models';
import { calculateTagDuration, isOlderThan } from '../../../app.utils';
import { AppState, PlaylistListState } from '../../models/app.state';
import {
  LiveTaggingModes,
  TaggingTeam,
} from '../../models/current-selections.model';
import {
  $annotationEntities,
  $annotationsLoaded,
} from './annotation.selectors';
import { $premiumUser } from './auth.selectors';
import { $recording } from './loading-components.selectors';
import { $recordingEntities } from './recording.selectors';
import {
  $currentRecordingId,
  $queryParameters,
  $route,
} from './route.selectors';

export const PREMIUM_CONTENT_DAY_LIMIT = 14;

export const $currentSelections = (state: AppState) => state.currentSelections;
export const $playlistList = (state: AppState) => state.playlistList;

export const $playlistEntities = createSelector(
  $playlistList,
  (state: PlaylistListState) => (state.entity ? state.entity : []),
);

export const $currentPlaylist = createSelector(
  $playlistEntities,
  $queryParameters,
  (state: Playlist[], { playlistId }) =>
    state.find(item => item.id === playlistId) || null,
);

export const $currentRecording = createSelector(
  $currentRecordingId,
  $recordingEntities,
  (id, entities) => entities[id] || null,
);

export const $currentRecordingVideos = createSelector(
  $currentRecording,
  recording => (recording && recording.videos ? recording.videos : []),
);

export const $currentRecordingHasNoVideo = createSelector(
  $currentRecordingVideos,
  recordingVideos => recordingVideos.length === 0,
);

export const $currentRecordingHasVideo = createSelector(
  $currentRecordingHasNoVideo,
  noVideo => !noVideo,
);

export const $userRemuxingVideoIds = createSelector(
  $currentSelections,
  currentSelections => currentSelections.remuxingVideoIds,
);

export const $userDownloadingPlaylistIds = createSelector(
  $currentSelections,
  currentSelections => currentSelections.downloadingPlaylistIds,
);

export const $userDownloadingAnnotation = createSelector(
  $currentSelections,
  currentSelections => currentSelections.downloadingAnnotations,
);

export const $userDownloadingAnnotationListIds = createSelector(
  $currentSelections,
  currentSelections => currentSelections.downloadingAnnotationListIds,
);

export const $currentRecordingLiveVideos = createSelector(
  $currentRecordingVideos,
  recordingVideos => recordingVideos.filter(v => v.live),
);

export const $isAnyCurrentRecordingVideoUserRemuxing = createSelector(
  $currentRecordingLiveVideos,
  $userRemuxingVideoIds,
  (videos, userRemuxingVideos) =>
    !!videos.find(
      v =>
        v.status === VideoStatus.REMUXING &&
        userRemuxingVideos.find(id => id === v.id),
    ),
);

export const $currentVideo = createSelector(
  $currentSelections,
  currentSelections => currentSelections.currentVideo,
);

export const $currentVideoLoading = createSelector(
  $currentVideo,
  video => video.loaded === false,
);

export const $currentVideoStream = createSelector(
  $currentVideo,
  ({ stream }) => stream,
);

export const $isCurrentVideoLive = createSelector(
  $currentVideo,
  currentVideo => currentVideo.live,
);

export const $currentVideoUrl = createSelector(
  $currentVideo,
  currentVideo => currentVideo.stream,
);

export const $currentAnnotation = createSelector(
  $route,
  $annotationEntities,
  ({ params }, annotations) => {
    if (params && params.annotationId) {
      return annotations[params.annotationId] || undefined;
    }
    return undefined;
  },
);

export const $currentAnnotationRecordingId = createSelector(
  $currentAnnotation,
  annotation => (annotation ? annotation.recordingId : undefined),
);

export const $currentAnnotationListVideoDetail = createSelector(
  $currentAnnotationRecordingId,
  $annotationEntities,
  (recordingId, annotations) =>
    recordingId
      ? Object.values(annotations)
          .filter(
            (annotation: Annotation) => annotation.recordingId === recordingId,
          )
          .sort((a, b) => a.timeStamp - b.timeStamp)
      : [],
);

export const $currentAnnotationsRecording = createSelector(
  $currentAnnotationRecordingId,
  $recordingEntities,
  (id, recordings) => (id ? recordings[id] : undefined),
);

export const $currentAnnotationsRecordingHasNoVideo = createSelector(
  $currentAnnotationsRecording,
  recording => !(recording && recording.videos && recording.videos.length),
);

export const $currentPlaylistAnotations = createSelector(
  $currentPlaylist,
  (playlist: Playlist) =>
    (playlist && playlist.annotations ? playlist.annotations : []).filter(
      p => p.show,
    ),
);
export const $currentPlaylistsAnnotation = createSelector(
  $currentPlaylistAnotations,
  $queryParameters,
  (playlistsAnnotations: Annotation[], { annotationId }) =>
    playlistsAnnotations.find(
      (annotation: Annotation) => annotation.id === annotationId,
    ) || undefined,
);
export const $currentPlaylistsRecording = createSelector(
  $currentPlaylistsAnnotation,
  $recording,
  (annotation, { entities }) =>
    !!annotation ? entities[annotation.recordingId] : null,
);

export const $currentPlaylistAnnotationsRecordingHasNoVideo = createSelector(
  $currentPlaylistsRecording,
  recording => !(recording && recording.videos && recording.videos.length),
);

export const $currentRecordingIsLive = createSelector(
  $currentRecording,
  recording => recording?.status === RecordingStatus.LIVE,
);

export const $currentAnnotationRecordingIsLive = createSelector(
  $currentAnnotationsRecording,
  recording => recording?.status === RecordingStatus.LIVE,
);

export const $trimmingDisabled = createSelector(
  $currentRecordingHasNoVideo,
  $currentAnnotationsRecordingHasNoVideo,
  $currentPlaylistAnnotationsRecordingHasNoVideo,
  (
    currentRecordingHasNoVideo,
    currentAnnotationsRecordingHasNoVideo,
    currentPlaylistAnnotationsRecordingHasNoVideo,
  ) =>
    currentRecordingHasNoVideo &&
    currentAnnotationsRecordingHasNoVideo &&
    currentPlaylistAnnotationsRecordingHasNoVideo,
);

export const $currentAnnotationsRecordingMissingVideoMessage = createSelector(
  $currentAnnotationsRecording,
  recording =>
    recording && isOlderThan(recording.dateTime + recording.duration, 1, 'd')
      ? {
          title: 'components.videoPlayer.notFoundTitle',
          subtitle: 'components.videoPlayer.notFound',
        }
      : {
          title: 'components.videoPlayer.willBeUploadedTitle',
          subtitle: 'components.videoPlayer.willBeUploaded',
        },
);

export const $currentAnnotationsRecordingVideos = createSelector(
  $currentAnnotationsRecording,
  currentAnnRecording =>
    currentAnnRecording ? currentAnnRecording.videos : [],
);

export const $currentRecordingAnnotations = createSelector(
  $currentRecordingId,
  $annotationEntities,
  (recordingId, annotations) =>
    recordingId
      ? Object.values(annotations)
          .filter(
            (annotation: Annotation) => annotation.recordingId === recordingId,
          )
          .sort((a, b) => a.timeStamp - b.timeStamp)
      : [],
);

export const $currentRecordingTacticalVideos = createSelector(
  $currentRecordingVideos,
  videos => videos.filter(video => video.name === 'Tactical'),
);

export const $currentRecordingDownloadVideos = createSelector(
  $currentRecordingVideos,
  recordingVideos => recordingVideos.filter(video => !video.live),
);

export const $currentPlaylistAnnotation = createSelector(
  $route,
  $playlistEntities,
  ({ params }, playlists) => {
    if (params && params.playlistId && params.annotationId) {
      const currentPlaylist = playlists.find(
        playlist => playlist.id === params.playlistId,
      );
      return (
        currentPlaylist &&
        currentPlaylist.annotations?.find(
          annotation => annotation.id === params.annotationId,
        )
      );
    }
    return undefined;
  },
);

export const $currentPlaylistAnnotationOffsets = createSelector(
  $currentPlaylistAnnotation,
  $currentVideo,
  (annotation, video) => {
    const offset = calculateTagDuration(annotation, video);

    return offset
      ? {
          start: offset.tagStart,
          end: offset.tagEnd,
        }
      : undefined;
  },
);

export const $currentPlaylistsRecordingVideos = createSelector(
  $currentPlaylistsRecording,
  recording => (!!recording ? recording.videos : []),
);
export const $canPlayCurrentPlaylistsAnnotation = createSelector(
  $currentPlaylistsAnnotation,
  $premiumUser,
  (annotation, premiumUser) =>
    annotation !== undefined &&
    (premiumUser ||
      moment
        .unix(annotation.recordingDate)
        .isSameOrAfter(moment().subtract(PREMIUM_CONTENT_DAY_LIMIT, 'days'))),
);

export const $currentPlaylistAnnotationValue = createSelector(
  $currentPlaylistsAnnotation,
  $currentVideo,
  (annotation, video) =>
    (!!annotation && calculateTagDuration(annotation, video)) || null,
);

export const $currentAnnotationValue = createSelector(
  $currentAnnotation,
  $currentVideo,
  (annotation, video) =>
    (!!annotation && calculateTagDuration(annotation, video)) || null,
);

export const $currentAnnotationOffset = createSelector(
  $currentAnnotation,
  $annotationsLoaded,
  $currentVideo,
  (annotation, loaded, video) => {
    const offset = calculateTagDuration(annotation, video);
    return offset && loaded
      ? {
          start: offset.tagStart,
          end: offset.tagEnd,
        }
      : undefined;
  },
);

export const $currentAnnotationCanBeCalculated = createSelector(
  $currentAnnotation,
  $currentVideo,
  (annotation, video) =>
    !video.loaded ||
    (video.loaded && !!calculateTagDuration(annotation, video)),
);

export const $currentPlaylistAnnotationCanBeCalculated = createSelector(
  $currentPlaylistsAnnotation,
  $currentVideo,
  (annotation, video) =>
    !video.loaded ||
    (video.loaded && !!calculateTagDuration(annotation, video)),
);

export const $currentSignedUrl = createSelector(
  $currentSelections,
  ({ signedVideoUrl }): string => {
    if (!signedVideoUrl) {
      return '';
    }

    return signedVideoUrl;
  },
);

export const $isCurrentPlaylistsAnnotationVideoValid = createSelector(
  $canPlayCurrentPlaylistsAnnotation,
  $currentPlaylistAnnotationCanBeCalculated,
  $currentSignedUrl,
  $currentVideo,
  (canPlay, canCalculate, signedUrl, video) =>
    video && !!signedUrl && canPlay && canCalculate,
);

export const $currentLiveTaggingMode = createSelector(
  $currentSelections,
  currentSelections => currentSelections.currentLiveTaggingMode,
);

export const $isStreamTagging = createSelector(
  $currentLiveTaggingMode,
  currLiveTaggingMode => currLiveTaggingMode === LiveTaggingModes.STREAM,
);

export const $currentVideoLoaded = createSelector(
  $currentVideo,
  ({ loaded }) => loaded,
);

export const $currentRecordingUniversal = createSelector(
  $currentRecording,
  $currentAnnotationsRecording,
  $currentPlaylistsRecording,
  (a, b, c) => a || b || c,
);

export const $matchesTabIndex = createSelector(
  $currentSelections,
  ({ matchesTabIndex }) => matchesTabIndex,
);

export const $getScrollPositions = createSelector(
  $currentSelections,
  ({ scrollPositions }) => scrollPositions,
);

export const $getScrollPosition = (index: number) =>
  createSelector(
    $getScrollPositions,
    scrollPositions => scrollPositions[index],
  );

export const $isTeamTaggingChangeEnabled = createSelector(
  $currentRecording,
  recording => recording?.type !== RecordingType.TRAINING,
);

export const $currentTaggingTeam = createSelector(
  $currentSelections,
  $isTeamTaggingChangeEnabled,
  ({ taggingTeam }, isTeamTaggingChangeEnabled) =>
    isTeamTaggingChangeEnabled ? taggingTeam : AnnotationTeam.HOME,
);

export const $showHomeTaggingTab = createSelector(
  $currentTaggingTeam,
  currentTaggingTeam =>
    currentTaggingTeam === TaggingTeam.HOME ||
    currentTaggingTeam === TaggingTeam.BOTH,
);

export const $showAwayTaggingTab = createSelector(
  $currentTaggingTeam,
  currentTaggingTeam =>
    currentTaggingTeam === TaggingTeam.AWAY ||
    currentTaggingTeam === TaggingTeam.BOTH,
);

export const $firstTaggingTeamIcon = createSelector(
  $currentRecording,
  $currentTaggingTeam,
  (recording, taggingTeam) =>
    taggingTeam === TaggingTeam.AWAY
      ? recording?.teamAway?.logo
      : recording?.teamHome?.logo,
);

export const $secondTaggingTeamIcon = createSelector(
  $currentRecording,
  $currentTaggingTeam,
  (recording, taggingTeam) =>
    taggingTeam === TaggingTeam.BOTH ? recording?.teamAway?.logo : null,
);
