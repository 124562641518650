import { Action, createReducer, on } from '@ngrx/store';
import { TrashState } from '../../models/app.state';
import {
  emptyTrashFailureAction,
  emptyTrashRequestAction,
  emptyTrashSuccessAction,
  moveToTrashFailureAction,
  moveToTrashRequestAction,
  moveToTrashSuccessAction,
  refreshTrashFailureAction,
  refreshTrashRequestAction,
  refreshTrashSuccessAction,
  restoreFromTrashFailureAction,
  restoreFromTrashRequestAction,
  restoreFromTrashSuccessAction,
} from '../actions/trash.actions';

const initialState: TrashState = {
  entities: {},
  errors: [],
  loading: false,
  loaded: false,
};

const reducer = createReducer(
  initialState,
  on(
    refreshTrashRequestAction,
    emptyTrashRequestAction,
    moveToTrashRequestAction,
    restoreFromTrashRequestAction,
    state => ({
      ...state,
      loading: true,
    }),
  ),
  on(refreshTrashSuccessAction, (state, { recordings }) => ({
    ...state,
    entities: recordings.reduce(
      (acc, recording) => ({ ...acc, [recording.id]: recording }),
      {},
    ),
    loaded: true,
    loading: false,
    errors: [],
  })),
  on(moveToTrashSuccessAction, (state, { recording }) => ({
    ...state,
    entities: {
      ...state.entities,
      [recording.id]: recording,
    },
    loading: false,
    errors: [],
  })),
  on(restoreFromTrashSuccessAction, (state, { recording }) => {
    const { [recording.id]: removed, ...entities } = state.entities;
    return { ...state, entities: entities, loading: false, errors: [] };
  }),
  on(emptyTrashSuccessAction, state => ({
    ...state,
    entities: {},
    loading: false,
    errors: [],
  })),
  on(
    emptyTrashFailureAction,
    refreshTrashFailureAction,
    moveToTrashFailureAction,
    restoreFromTrashFailureAction,
    (state, { errors }) => ({
      ...state,
      loading: false,
      errors: errors,
    }),
  ),
);

export function trashReducer(state: TrashState, action: Action): TrashState {
  return reducer(state, action);
}
