import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../models/app.state';
import { PtzView } from 'src/api/models';

export const showPtzRequestAction = createAction(
  'ShowPtzRequestAction',
  props<{ recordingId: string; source: string }>(),
);

export const showPtzSuccessAction = createAction(
  'ShowPtzSuccessAction',
  props<{
    manual: boolean;
    view?: PtzView;
  }>(),
);
export const showPtzFailureAction = createAction(
  'ShowPtzFailureAction',
  props<{ error: ErrorModel[] }>(),
);
export const hidePtzRequestAction = createAction('HidePtzRequestAction');

export const setPtzViewRequestAction = createAction(
  'SetPtzViewRequestAction',
  props<{
    recordingId: string;
    source: string;
    manual: boolean;
    view?: PtzView;
  }>(),
);
export const setPtzViewSuccessAction = createAction(
  'SetPtzViewSuccessAction',
  props<{
    manual: boolean;
    view?: PtzView;
    setAt: number;
    changeAt: number;
  }>(),
);
export const setPtzViewFailureAction = createAction(
  'SetPtzViewFailureAction',
  props<{ error: ErrorModel[] }>(),
);

export const setPtzAutomaticRequestAction = createAction(
  'SetPtzAutomaticRequestAction',
);
export const setPtzAutomaticSuccessAction = createAction(
  'SetPtzAutomaticSuccessAction',
);
export const setPtzAutomaticFailureAction = createAction(
  'SetPtzAutomaticFailureAction',
  props<{ error: ErrorModel[] }>(),
);
