<a
  [routerLink]="routerLink"
  [queryParams]="routerQueryParameters"
  [class.canNavigate]="navigation === true"
  class="playlist-title-container"
  *ngIf="playlist"
  [ngClass]="{
    'empty-playlist': playlistLength === 0,
  }"
>
  <div class="cmv-h2 playlist-name">{{ playlist.name }}</div>
  <div
    class="cmv-h2 playlist-rendering"
    *ngIf="
      playlist.status === PlaylistStatus.RENDERING ||
      playlist.status === PlaylistStatus.ZIPPING
    "
  >
    {{ 'components.playlistCollection.rendering' | translate }}
  </div>
  <div
    class="cmv-h2 playlist-download-failure"
    *ngIf="playlist.status === PlaylistStatus.FAILED"
  >
    {{ 'components.playlistCollection.failure' | translate }}
  </div>
  <a [href]="playlist?.url" class="playlist-download-container">
    <div
      (click)="$event.stopPropagation()"
      class="cmv-h2 playlist-download"
      *ngIf="playlist.status === PlaylistStatus.DONE && playlist.url !== ''"
    >
      <mat-icon class="cmv-gray-icon" svgIcon="cmv-download"></mat-icon>
      {{ 'components.playlistCollection.download' | translate }}
    </div>
  </a>

  <div class="cmv-h2 playlist-count">
    {{ playlistLength }}
    {{
      playlistLength === 1
        ? ('components.playlistDetail.video' | translate)
        : ('components.playlistDetail.videos' | translate)
    }}
  </div>
  <button
    (click)="menuButtonClickHandler($event)"
    class="cmv-more-button"
    mat-icon-button
    [matMenuTriggerFor]="detailMenu"
  >
    <img src="assets/icons/more_icon_ver.svg" />
  </button>

  <mat-menu class="cmv-menu-container" #detailMenu="matMenu">
    <div (keydown)="$event.stopPropagation()">
      <button
        class="cmv-menu-item"
        (click)="openModal('EditModal')"
        mat-menu-item
      >
        <div class="cmv-h4">{{ 'basic.editPlaylist' | translate }}</div>
        <span>
          <mat-icon
            class="modal-icon cmv-gray-icon"
            svgIcon="cmv-edit"
          ></mat-icon>
        </span>
      </button>
      <button
        class="cmv-menu-item"
        mat-menu-item
        (click)="renderPlaylistHandler(playlist.id)"
        *ngIf="canDownload"
      >
        <div class="cmv-h4">
          {{ 'basic.downloadPlaylist' | translate }}
        </div>
        <span>
          <mat-icon
            class="modal-icon cmv-gray-icon"
            svgIcon="cmv-download"
          ></mat-icon>
        </span>
      </button>
      <button
        class="cmv-menu-item"
        (click)="openModal('RemoveModal')"
        mat-menu-item
      >
        <div class="cmv-h4">
          {{ 'basic.removePlaylist' | translate }}
        </div>
        <span>
          <mat-icon
            class="modal-icon cmv-gray-icon"
            svgIcon="cmv-delete"
          ></mat-icon>
        </span>
      </button>
    </div>
  </mat-menu>
</a>
<cmv-loading-bar
  *ngIf="
    playlist?.status === PlaylistStatus.RENDERING ||
    playlist?.status === PlaylistStatus.ZIPPING
  "
></cmv-loading-bar>
