import { ActionReducerMap } from '@ngrx/store';
import { AppState } from '../../models/app.state';
import { annotationTypesReducer } from './annotation-types.reducer';
import { annotationReducer } from './annotation.reducer';
import { annotationListReducer } from './annotations-lists.reducer';
import { authReducer } from './auth.reducer';
import { clubsReducer } from './clubs.reducer';
import { currentSelectionsReducer } from './current-selections.reducer';
import { customerReducer } from './customer.reducer';
import { filterSearchReducer } from './filter-search.reducer';
import { playlistListReducer } from './playlist-list.reducer';
import { recordersReducer } from './recorders.reducer';
// Reducers
import { recordingListReducer } from './recording-list.reducer';
import { recordingReducer } from './recording.reducer';
import { responsivityReducer } from './responsivity.reducer';
import { routeReducer } from './route.reducer';
import { sharingReducer } from './sharing.reducer';
import { snackbarReducer } from './snackbar.reducer';
import { teamsReducer } from './teams.reducer';
import { uiFlagsReducer } from './ui-flags.reducer';
import { userReducer } from './user.reducer';
import { wsReducer } from './ws.reducer';
import { videoReducer } from './video.reducer';
import { cameraReducer } from './camera.reducer';
import { sharingRecordingReducer } from './sharing-recording.reducer';
import { trashReducer } from './trash.reducer';
import { ptztReducer } from './ptz.reducer';

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  annotationTypes: annotationTypesReducer,
  annotations: annotationReducer,
  recording: recordingReducer,
  recordingList: recordingListReducer,
  playlistList: playlistListReducer,
  snackbar: snackbarReducer,
  responsivity: responsivityReducer,
  customer: customerReducer,
  route: routeReducer,
  user: userReducer,
  currentSelections: currentSelectionsReducer,
  sharing: sharingReducer,
  sharingRecording: sharingRecordingReducer,
  annotationLists: annotationListReducer,
  clubs: clubsReducer,
  teams: teamsReducer,
  recorders: recordersReducer,
  camera: cameraReducer,
  ws: wsReducer,
  uiFlags: uiFlagsReducer,
  filterSearch: filterSearchReducer,
  videoPlayer: videoReducer,
  trash: trashReducer,
  ptz: ptztReducer,
};
