import { AppState } from '../../models/app.state';
import { createSelector } from '@ngrx/store';

export const $customer = ({ customer }: AppState) => customer;

export const $authProvider = createSelector(
  $customer,
  ({ authProvider }) => authProvider,
);

export const $customerId = createSelector(
  $customer,
  ({ customerId }) => customerId,
);

export const $customerCurrentTos = createSelector(
  $customer,
  ({ currentTosVersion }) => currentTosVersion,
);
