import {
  Recording,
  RecordingRequest,
  RecordingUpdateRequest,
} from '../../../../api/models';
import { ErrorModel } from '../../models/app.state';
import { createAction, props } from '@ngrx/store';

export const getRecordingRequestAction = createAction(
  'GetRecordingRequest',
  props<{ id: string }>(),
);
export const getRecordingSuccessAction = createAction(
  'GetRecordingSuccess',
  props<{ recording: Recording }>(),
);
export const getRecordingFailureAction = createAction(
  'GetRecordingFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const postRecordingRequestAction = createAction(
  'PostRecordingRequest',
  props<{ recordingRequest: RecordingRequest }>(),
);
export const postRecordingSuccessAction = createAction(
  'PostRecordingSuccess',
  props<{ recording: Recording }>(),
);
export const postRecordingFailureAction = createAction(
  'PostRecordingFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const putRecordingRequestAction = createAction(
  'PutRecordingRequest',
  props<{ recordingUpdate: RecordingUpdateRequest }>(),
);
export const putRecordingSuccessAction = createAction(
  'PutRecordingSuccess',
  props<{ recording: Recording }>(),
);
export const putRecordingFailureAction = createAction(
  'PutRecordingFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const deleteRecordingRequestAction = createAction(
  'DeleteRecordingRequest',
  props<{ id: string }>(),
);
export const deleteRecordingSuccessAction = createAction(
  'DeleteRecordingSuccess',
  props<{ id: string }>(),
);
export const deleteRecordingsSuccessAction = createAction(
  'DeleteRecordingsSuccess',
  props<{ ids: string[] }>(),
);
export const deleteRecordingFailureAction = createAction(
  'DeleteRecordingFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const stopRecordingRequestAction = createAction(
  'StopRecordingRequest',
  props<{ id: string }>(),
);
export const stopRecordingSuccessAction = createAction(
  'StopRecordingSuccess',
  props<{ recording: Recording }>(),
);
export const stopRecordingFailureAction = createAction(
  'StopRecordingFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const deletedRecordingRequestAction = createAction(
  'DeletedRecordingRequestAction',
  props<{ id: string }>(),
);

export const deletedRecordingsRequestAction = createAction(
  'DeletedRecordingsRequestAction',
  props<{ ids: string[] }>(),
);

export const remuxRecordingVideoRequestAction = createAction(
  'RemuxRecordingVideoRequest',
  props<{ videoId: string }>(),
);

export const remuxRecordingVideoSuccessAction = createAction(
  'RenderRecordingVideoSuccess',
  props<{ recording: Recording }>(),
);

export const remuxRecordingVideoFailureAction = createAction(
  'RenderRecordingVideoSuccess',
  props<{ errors: ErrorModel[] }>(),
);

export const restoreRecordingRequestAction = createAction(
  'RestoreRecordingRequest',
  props<{ id: string }>(),
);
export const restoreRecordingSuccessAction = createAction(
  'RestoreRecordingSuccess',
  props<{ id: string }>(),
);
export const restoreRecordingFailureAction = createAction(
  'RestoreRecordingFailure',
  props<{ errors: ErrorModel[] }>(),
);
