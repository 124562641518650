<div
  tabindex="0"
  #elementToFocus
  class="cmv-modal-outer-card playlist-add-wrapper"
>
  <div class="cmv-modal-inner-card cmv-modal-add">
    <div class="cmv-modal-close-button" (click)="closeModal()">
      <mat-icon
        class="modal-icon-closing cmv-dim-gray"
        svgIcon="cmv-closing"
      ></mat-icon>
    </div>

    <div class="main-info main-info-playlist">
      <div class="icon-wrapper border">
        <div class="plus-icon-wrapper">
          <mat-icon
            class="modal-icon plus-icon cmv-gray-icon"
            svgIcon="cmv-plus"
          ></mat-icon>
        </div>
      </div>
      <div class="cmv-h1">{{ 'basic.addToPlaylist' | translate }}</div>
      <div class="cmv-body subtitle">
        {{
          'components.modals.addToPlaylistModal.subtitle'
            | translate: { name: title }
        }}
      </div>
    </div>
    <div class="checkboxes">
      <div class="checkboxes-list">
        <cmv-checkbox
          *ngFor="let playlist of playlists"
          [checked]="playlist.member"
          (checkedChange)="togglePlaylist(playlist)"
          [label]="playlist.playlist.name"
          class="checkbox"
        ></cmv-checkbox>
      </div>
    </div>
    <div class="buttons buttons-add-playlist">
      <div class="cmv-accept-button" (click)="openCreatePlaylistModal()">
        <div class="cmv-h4">{{ 'basic.createNewPlaylist' | translate }}</div>
      </div>
    </div>
  </div>
</div>
