import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs';
import { Annotation } from 'src/api/models';
import { AddToPlaylistModalComponent } from '../modal/add-to-playlist-modal/add-to-playlist-modal.component';
import {
  EditModalComponent,
  EditModalTypes,
} from '../modal/edit-modal/edit-modal.component';
import {
  INPUT_TYPE,
  RemoveModalComponent,
} from '../modal/remove-modal/remove-modal.component';
import { EditModalModel } from '../../../shared/models/edit-modal.model';

@Component({
  selector: 'cmv-annotation-controls',
  templateUrl: './annotation-controls.component.html',
  styleUrls: ['./annotation-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnotationControlsComponent {
  @Input() annotation: Annotation;
  @Input() fromPlaylistId: string | undefined;

  @Output() editedAnnotation = new EventEmitter<Annotation>();
  @Output() deletedAnnotation = new EventEmitter<Annotation>();

  constructor(private readonly dialog: MatDialog) {}

  openModal(annotation: Annotation, type: string): void {
    if (!annotation) {
      return;
    }
    let handleFunction;
    let dialog;
    switch (type) {
      case 'EditModal':
        dialog = this.dialog.open(EditModalComponent, {
          autoFocus: false,
          data: {
            title: annotation.name,
            description: annotation.description,
            type: EditModalTypes.TAG_EDIT,
          },
        });
        handleFunction = (value: EditModalModel) => {
          if (
            value != null &&
            (annotation.name !== value.title ||
              annotation.description !== value.description)
          ) {
            this.editedAnnotation.emit({
              ...annotation,
              name: value.title,
              description: value.description as string,
            });
          }
        };
        break;
      case 'AddToPlaylistModal':
        this.dialog.open(AddToPlaylistModalComponent, {
          data: { annotation, fromPlaylistId: this.fromPlaylistId },
        });
        break;
      case 'RemoveModal':
        dialog = this.dialog.open(RemoveModalComponent, {
          data: {
            title: annotation.name,
            type: INPUT_TYPE.ANNOTATION,
          },
        });
        handleFunction = (value: boolean) => {
          if (value) {
            this.deletedAnnotation.emit(annotation);
          }
        };
        break;
      default:
        return;
    }
    if (dialog != null) {
      dialog.afterClosed().pipe(take(1)).subscribe(handleFunction);
    }
  }
}
