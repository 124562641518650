import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import moment from 'moment';
import {
  HandlePositionModel,
  TrimmingInputModel,
} from '../../models/trimming-input.model';

const SECONDS = 60;
const SEPARATOR_COUNT = 11;
const SKIP_DURATION = 5;

export enum SEEK_TO {
  START = 'START',
  END = 'END',
}

@Component({
  selector: 'cmv-trimming-panel',
  templateUrl: './trimming-panel.component.html',
  styleUrls: ['./trimming-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrimmingPanelComponent implements OnInit {
  @ViewChild('baseline') baseline: ElementRef;

  panBreakpoint: number;
  skipDuration = SKIP_DURATION;
  baselineSeparatorCount: number[];
  handlePositions: HandlePositionModel;
  trimmingData: TrimmingInputModel = {
    tagStart: 0,
    tagEnd: 0,
    minValue: 0,
    maxValue: 180,
  };
  dragToValueOffset = 0;

  @Output() valueRangeChange = new EventEmitter<{ from: number; to: number }>();
  @Output() submitChange = new EventEmitter<{ from: number; to: number }>();
  @Output() seekTo = new EventEmitter<SEEK_TO>();
  @Output() cancel = new EventEmitter();

  @Input()
  videoStartAt: number;

  @Input()
  set trimmingValues(data: TrimmingInputModel) {
    if (data) {
      const newHandlePositions: HandlePositionModel = {
        leftHandle: data.tagStart,
        rightHandle: data.tagEnd,
      };
      this.trimmingData = data;
      this.handlePositions = newHandlePositions;
    }
  }

  constructor() {
    this.baselineSeparatorCount = Array(SEPARATOR_COUNT).fill(0);
  }

  ngOnInit(): void {
    this.handlePositions = {
      leftHandle: this.trimmingData.tagStart,
      rightHandle: this.trimmingData.tagEnd,
    };
  }

  onSaveChanges(): void {
    this.seekTo.emit(SEEK_TO.START);
    this.submitChange.emit({
      from: this.handlePositions.leftHandle,
      to: this.handlePositions.rightHandle,
    });
  }

  onBaselineTap(event: HammerInput): void {
    const tappedValue = this.eventToValue(event);
    if (
      tappedValue - this.handlePositions.leftHandle <
      this.handlePositions.rightHandle - tappedValue
    ) {
      this.handlePositions.leftHandle = tappedValue;
      this.seekTo.emit(SEEK_TO.START);
    } else {
      this.handlePositions.rightHandle = tappedValue;
      this.seekTo.emit(SEEK_TO.END);
    }
    this.valueRangeChange.emit({
      from: this.handlePositions.leftHandle,
      to: this.handlePositions.rightHandle,
    });
  }

  onHandlePanStart(whichHandle: 'left' | 'right'): void {
    this.panBreakpoint =
      whichHandle === 'left'
        ? this.handlePositions.rightHandle
        : this.handlePositions.leftHandle;
  }

  onDragStart(e: HammerInput): void {
    const dragTo = this.eventToValue(e);
    this.dragToValueOffset = dragTo - this.handlePositions.leftHandle;
  }

  onDrag(e: HammerInput): void {
    const dragTo = this.eventToValue(e);
    const tagDuration = Math.abs(
      this.handlePositions.rightHandle - this.handlePositions.leftHandle,
    );
    const newLeftHandleValue = dragTo - this.dragToValueOffset;
    const newRightHandleValue = newLeftHandleValue + tagDuration;

    if (
      newLeftHandleValue >= this.trimmingData.minValue &&
      newRightHandleValue <= this.trimmingData.maxValue
    ) {
      this.handlePositions.leftHandle = newLeftHandleValue;
      this.handlePositions.rightHandle = newRightHandleValue;
    }
    if (newRightHandleValue >= this.trimmingData.maxValue) {
      this.handlePositions.rightHandle = this.trimmingData.maxValue;
      this.handlePositions.leftHandle =
        this.handlePositions.rightHandle - tagDuration;
    }
    if (newLeftHandleValue <= this.trimmingData.minValue) {
      this.handlePositions.leftHandle = this.trimmingData.minValue;
      this.handlePositions.rightHandle =
        this.handlePositions.leftHandle + tagDuration;
    }
    this.seekTo.emit(SEEK_TO.START);
    this.valueRangeChange.emit({
      from: this.handlePositions.leftHandle,
      to: this.handlePositions.rightHandle,
    });
  }

  onHandlePanMove(event: HammerInput): void {
    const pannedValue = this.eventToValue(event);
    if (pannedValue > this.panBreakpoint) {
      this.handlePositions.rightHandle = pannedValue;
      this.seekTo.emit(SEEK_TO.END);
    } else {
      this.handlePositions.leftHandle = pannedValue;
      this.seekTo.emit(SEEK_TO.START);
    }
    this.valueRangeChange.emit({
      from: this.handlePositions.leftHandle,
      to: this.handlePositions.rightHandle,
    });
  }

  getFormatedTimeFromVideoPosition(from: number): string {
    return moment.unix(this.videoStartAt).add(from, 's').format('HH:mm:ss');
  }

  getDuration(duration: number): string {
    return `${this.formatDuration(
      Math.floor(duration / SECONDS),
    )}:${this.formatDuration(Math.floor(duration % SECONDS))}`;
  }

  formatDuration(duration: number): string {
    return `${duration < 10 ? '0' : ''}${duration}`;
  }

  changeFromValue(value: number): void {
    if (
      this.handlePositions.leftHandle + value <
      this.handlePositions.rightHandle
    ) {
      if (
        this.handlePositions.leftHandle + value >
        this.trimmingData.minValue
      ) {
        this.handlePositions.leftHandle =
          this.handlePositions.leftHandle + value;
      } else {
        this.handlePositions.leftHandle = this.trimmingData.minValue;
      }
    } else {
      this.handlePositions.leftHandle = this.handlePositions.rightHandle;
    }
    this.seekTo.emit(SEEK_TO.START);
    this.valueRangeChange.emit({
      from: this.handlePositions.leftHandle,
      to: this.handlePositions.rightHandle,
    });
  }

  changeToValue(value: number): void {
    if (
      this.handlePositions.rightHandle + value >
      this.handlePositions.leftHandle
    ) {
      if (
        this.handlePositions.rightHandle + value <
        this.trimmingData.maxValue
      ) {
        this.handlePositions.rightHandle =
          this.handlePositions.rightHandle + value;
      } else {
        this.handlePositions.rightHandle = this.trimmingData.maxValue;
      }
    } else {
      this.handlePositions.rightHandle = this.handlePositions.leftHandle;
    }
    this.seekTo.emit(SEEK_TO.END);
    this.valueRangeChange.emit({
      from: this.handlePositions.leftHandle,
      to: this.handlePositions.rightHandle,
    });
  }

  get rangeWidth(): string {
    const lowerPercent = this.valueToPercent(
      Math.max(this.handlePositions.leftHandle, this.trimmingData.minValue),
    );
    const upperPercent = this.valueToPercent(
      Math.min(this.handlePositions.rightHandle, this.trimmingData.maxValue),
    );
    return `${upperPercent - lowerPercent}%`;
  }

  get leftHandlePosition(): string {
    const lowerValue = Math.max(
      this.handlePositions.leftHandle,
      this.trimmingData.minValue,
    );
    return `${this.valueToPercent(lowerValue)}%`;
  }

  get rightHandlePosition(): string {
    const upperValue = Math.min(
      this.handlePositions.rightHandle,
      this.trimmingData.maxValue,
    );
    return `${this.valueToPercent(upperValue)}%`;
  }

  private valueToPercent(value: number): number {
    return (
      ((value - this.trimmingData.minValue) /
        (this.trimmingData.maxValue - this.trimmingData.minValue)) *
      100
    );
  }

  private eventToValue(event: HammerInput): number {
    const baselineRect = this.baseline.nativeElement.getBoundingClientRect();
    const rawValue =
      ((event.center.x - baselineRect.left) / baselineRect.width) *
        (this.trimmingData.maxValue - this.trimmingData.minValue) +
      this.trimmingData.minValue;
    return Math.min(
      Math.max(Math.round(rawValue), this.trimmingData.minValue),
      this.trimmingData.maxValue,
    );
  }

  onCancel() {
    this.cancel.emit();
  }
}
