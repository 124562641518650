import { SharingState } from '../../models/app.state';
import {
  changeSharingMethodFailureAction,
  changeSharingMethodRequestAction,
  changeSharingMethodSuccessAction,
  getSharingTeamsFailureAction,
  getSharingTeamsRequestAction,
  getSharingTeamsSuccessAction,
  toggleTreeExpandedAction,
  toggleUserFailureAction,
  toggleUserRequestAction,
  toggleUserSuccessAction,
} from '../actions/sharing.actions';
import { SharingTypes } from '../../components/tree/tree.component';
import {
  SharingExpandIndex,
  SharingTeamIndexedModel,
} from '../../models/sharing.model';
import { Sharing, SharingUserTeam } from '../../../../api/models';
import { Action, createReducer, on } from '@ngrx/store';

const initialState: SharingState = {
  entity: null,
  errors: [],
  loading: false,
  loaded: false,
};

const reducer = createReducer(
  initialState,
  on(
    getSharingTeamsRequestAction,
    changeSharingMethodRequestAction,
    toggleUserRequestAction,
    state => ({
      ...state,
      loading: true,
    }),
  ),
  on(getSharingTeamsSuccessAction, (state, { shareable, sharingTeams }) => ({
    ...state,
    entity:
      state.entity && shareable.id === state.entity.shareable.id
        ? state.entity
        : {
            tree: createTree(sharingTeams, shareable?.sharings || []),
            expanded: sharingTeams.map(sharingTeam => ({
              [sharingTeam.id]: {
                expanded: false,
              },
            })),
            shareable,
            sharingTeams,
          },
    loading: false,
    loaded: true,
    errors: [],
  })),
  on(
    getSharingTeamsFailureAction,
    changeSharingMethodFailureAction,
    toggleUserFailureAction,
    (state, { errors }) => ({
      ...state,
      loading: false,
      errors,
    }),
  ),
  on(toggleTreeExpandedAction, (state, { teamId }) => ({
    ...state,
    entity: state.entity
      ? {
          ...state.entity,
          expanded: state.entity
            ? state.entity.expanded.map((expandIndex: SharingExpandIndex) => {
                if (expandIndex[teamId]) {
                  return {
                    [teamId]: { expanded: !expandIndex[teamId].expanded },
                  };
                }
                return expandIndex;
              })
            : [],
        }
      : null,
  })),
  on(changeSharingMethodSuccessAction, (state, { sharings }) => ({
    ...state,
    entity: state.entity
      ? {
          ...state.entity,
          tree: createTree(state.entity.sharingTeams, sharings),
        }
      : null,
    loading: false,
    loaded: true,
  })),
  on(toggleUserSuccessAction, (state, { sharings }) => ({
    ...state,
    entity: state.entity
      ? {
          ...state.entity,
          shareable: {
            ...state.entity.shareable,
            sharings: [...sharings],
          },
          tree: createTree(state.entity.sharingTeams, sharings),
        }
      : null,
    loading: false,
    loaded: true,
  })),
);

const createTree = (
  sharingTeams: SharingUserTeam[],
  annotationSharings: Sharing[],
): SharingTeamIndexedModel[] => {
  if (!sharingTeams) {
    return [];
  }
  const iterableSharings = annotationSharings ? annotationSharings : [];
  return sharingTeams.map(team => {
    const users = team.users.map(user => ({
      id: user.id,
      label: user.name.length ? user.name : user.email,
      selected: iterableSharings.some(
        sharing => !!sharing.userId && sharing.userId === user.id,
      ),
    }));
    const sharingMode = iterableSharings.find(
      sharing => sharing.teamId === team.id,
    )
      ? SharingTypes.TEAM
      : SharingTypes.SINGLE;
    return {
      [team.id]: {
        id: team.id,
        label: team.name,
        sharingMode,
        users,
      },
    };
  });
};

export function sharingReducer(
  state: SharingState,
  action: Action,
): SharingState {
  return reducer(state, action);
}
