import { Action, createReducer, on } from '@ngrx/store';
import { insertNewPlaylist, sortPlaylists } from 'src/app/app.utils';
import { PlaylistListState } from '../../models/app.state';
import {
  addToPlaylistFailureAction,
  addToPlaylistRequestAction,
  addToPlaylistSuccessAction,
  createdPlaylistFailureAction,
  createdPlaylistSuccessAction,
  deletePlaylistFailureAction,
  deletePlaylistRequestAction,
  deletePlaylistSuccessAction,
  getPlaylistListFailureAction,
  getPlaylistListRequestAction,
  getPlaylistListSuccessAction,
  postPlaylistFailureAction,
  postPlaylistRequestAction,
  postPlaylistSuccessAction,
  putPlaylistFailureAction,
  putPlaylistRequestAction,
  putPlaylistSuccessAction,
  refreshPlaylistAnnotationThumbnailAction,
  refreshPlaylistSuccessAction,
  removeFromPlaylistFailureAction,
  removeFromPlaylistRequestAction,
  removeFromPlaylistSuccessAction,
  renderPlaylistSuccessAction,
} from '../actions/playlist-list.actions';
import {
  getPlaylistsRecordingsFailureAction,
  getPlaylistsRecordingsRequestAction,
  getPlaylistsRecordingsSuccessAction,
} from '../actions/video-detail.actions';

const initialState: PlaylistListState = {
  entity: [],
  errors: [],
  loading: false,
  loaded: false,
};

const reducer = createReducer(
  initialState,
  on(
    getPlaylistListRequestAction,
    postPlaylistRequestAction,
    putPlaylistRequestAction,
    deletePlaylistRequestAction,
    getPlaylistsRecordingsRequestAction,
    addToPlaylistRequestAction,
    removeFromPlaylistRequestAction,
    state => ({
      ...state,
      loading: true,
    }),
  ),
  on(getPlaylistListSuccessAction, (state, { playlists }) => ({
    ...state,
    entity: playlists ? sortPlaylists([...playlists]) : [],
    loading: false,
    loaded: true,
    errors: [],
  })),
  on(
    refreshPlaylistSuccessAction,
    renderPlaylistSuccessAction,
    (state, { playlist }) => ({
      ...state,
      entity: (state.entity || []).map(p =>
        p.id === playlist.id ? { ...playlist } : p,
      ),
      loading: false,
      loaded: true,
      errors: [],
    }),
  ),
  on(
    createdPlaylistSuccessAction,
    postPlaylistSuccessAction,
    (state, { playlist }) => ({
      ...state,
      entity: insertNewPlaylist(state.entity, playlist),
      loading: false,
      loaded: true,
      errors: [],
    }),
  ),
  on(putPlaylistSuccessAction, (state, { playlist }) => ({
    ...state,
    entity: sortPlaylists(
      (state.entity || []).map(item =>
        item.id === playlist.id ? playlist : item,
      ),
    ),
    loading: false,
    loaded: true,
    errors: [],
  })),
  on(deletePlaylistSuccessAction, (state, { id }) => ({
    ...state,
    entity: (state.entity || []).filter(item => item.id !== id),
    loading: false,
    loaded: true,
    errors: [],
  })),
  on(
    getPlaylistsRecordingsSuccessAction,
    (state, { response: { playlist } }) => ({
      ...state,
      entity: sortPlaylists([
        ...(state.entity || []).filter(item => item.id !== playlist.id),
        playlist,
      ]),
      loading: false,
      loaded: true,
      errors: [],
    }),
  ),
  on(
    addToPlaylistSuccessAction,
    removeFromPlaylistSuccessAction,
    (state, { playlist }) => ({
      ...state,
      entity: sortPlaylists(
        (state.entity || []).map(item =>
          item.id === playlist.id ? { ...playlist, url: '' } : item,
        ),
      ),
      loading: false,
      loaded: true,
      errors: [],
    }),
  ),
  on(
    getPlaylistListFailureAction,
    postPlaylistFailureAction,
    putPlaylistFailureAction,
    deletePlaylistFailureAction,
    getPlaylistsRecordingsFailureAction,
    addToPlaylistFailureAction,
    removeFromPlaylistFailureAction,
    createdPlaylistFailureAction,
    (state, { errors }) => ({
      ...state,
      errors,
      loading: false,
    }),
  ),
  on(
    refreshPlaylistAnnotationThumbnailAction,
    (state, { annotationId, playlistId, url }) => ({
      ...state,
      entity: state.entity?.map(playlist =>
        playlist.id === playlistId
          ? {
              ...playlist,
              annotations: playlist.annotations.map(a =>
                a.id === annotationId
                  ? {
                      ...a,
                      thumbnail: url,
                    }
                  : a,
              ),
            }
          : playlist,
      ),
    }),
  ),
);

export function playlistListReducer(
  state: PlaylistListState,
  action: Action,
): PlaylistListState {
  return reducer(state, action);
}
