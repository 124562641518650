<cmv-video-page-layout
  [title]="'components.videoDetail.title' | translate"
  [subtitle]="'components.videoDetail.subtitle' | translate"
  [loadingResources]="loadingResources$ | async"
>
  <ng-container left-column>
    <cmv-video-player-wrapper
      class="video-player"
      [isMissingVideo]="(currentVideo$ | async) === null"
      [loading]="
        (currentVideoLoading$ | async) ||
        (currentAnnotation$ | async)?.loaded === false
      "
      [notInRange]="(playerOffsetIsValid$ | async) === false"
      [videoUrl]="currentVideoStream$ | async"
      [autoplay]="shouldAutoplay"
      (clipEnded)="shouldPlayNext($event)"
      [offset]="playerOffset$ | async"
      [currentVideo]="currentVideo$ | async"
      [videoSources]="recordingVideos$ | async"
      (changeSrc)="changeSrc($event, true)"
      #videoPlayer
    ></cmv-video-player-wrapper>
    <ng-container *ngIf="currentAnnotation$ | async as annotation">
      <cmv-video-control-panel
        [title]="annotation.name"
        [description]="annotation.description"
        [showChangeVideoSource]="true"
        [currentVideo]="currentVideo$ | async"
        [videoSources]="recordingVideos$ | async"
        (changeSrc)="changeSrc($event)"
      ></cmv-video-control-panel>
    </ng-container>
    <cmv-annotation-controls
      [annotation]="currentAnnotation$ | async"
      (editedAnnotation)="editAnnotation($event)"
      (deletedAnnotation)="deleteTag($event)"
      [fromPlaylistId]="(playlist$ | async)?.id"
    ></cmv-annotation-controls>
    <div class="trimming-panel-wrapper" *ngIf="isTrimming$ | async">
      <cmv-trimming-panel
        [videoStartAt]="(currentVideo$ | async).startAt"
        [trimmingValues]="trimmingOffset$ | async"
        (submitChange)="trimAnnotation($event)"
        (seekTo)="seekWithHandleMove($event)"
        (valueRangeChange)="recalculateTag($event.from, $event.to)"
        (cancel)="cancelTrim()"
      ></cmv-trimming-panel>
    </div>
  </ng-container>

  <ng-container right-column>
    <div class="widget-wrapper" *ngIf="playlist$ | async">
      <cmv-playlist-title
        [navigation]="false"
        (editPlaylist)="editPlaylist($event)"
        (removePlaylist)="removePlaylist($event)"
        (renderPlaylist)="renderPlaylist($event)"
        [playlist]="playlist$ | async"
      ></cmv-playlist-title>
    </div>
    <div class="created-tags">
      <div class="cmv-section-header">
        <div class="cmv-h4">{{ 'components.createdTags' | translate }}</div>
      </div>
      <cmv-created-tags
        [hideDownloadTag]="true"
        [annotations]="annotations$ | async"
        [currentAnnotation]="currentAnnotation$ | async"
        (deleteTag)="deleteTag($event)"
        (trimVideo)="redirectToTrimming($event.id)"
        (editAnnotation)="editAnnotation($event)"
        (selectedWidgetIdChange)="selectTag($event.id)"
        [fromPlaylistId]="(playlist$ | async)?.id"
      ></cmv-created-tags>
    </div>
  </ng-container>
</cmv-video-page-layout>
