import { EntityState } from '@ngrx/entity';
import {
  Annotation,
  AnnotationList,
  AnnotationType,
  Club,
  MetaRecording,
  Playlist,
  Recorder,
  Recording,
  SearchResponse,
  User,
} from '../../../api/models';
import { AnnotationTypesPagingModel } from './annotation-types-paging.model';
import { AuthModel } from './auth.model';
import { CurrentSelectionsModel } from './current-selections.model';
import { Customer } from './customer';
import { FilterSearchMetadataModel } from './filter-search-metadata.model';
import { TeamModel } from './manual-scheduling.model';
import { RecordingListQuery } from './recording-list-query.model';
import { ResponsivityInfoModel } from './responsivity-info.model';
import { RouteModel } from './route.model';
import { SharingStateEntityModel } from './sharing.model';
import { SnackbarStateModel } from './snackbar-state.model';
import { UiFlagsModel } from './ui-flags.model';
import { WsStateModel } from './ws.models';
import { VideoPlayerStateModel } from './video.model';
import { SharingRecordingStateEntityModel } from './sharing-recording.model';
import { PtzStateModel } from './ptz-state.model';

export type AuthState = StoreStateSingle<AuthModel | null>;
export type RecordingState = StoreState<Recording>; // eslint-disable-line no-redeclare
export type RecordingListState = StoreState<
  Recording,
  RecordingListQuery & { days: MetaRecording[] }
>;
export type AnnotationTypesState = StoreStateSingle<
  AnnotationType[] | null,
  AnnotationTypesPagingModel
>;
export type TrashState = StoreState<Recording>;
export type AnnotationState = StoreState<Annotation>;
export type PlaylistListState = StoreStateSingle<Playlist[]>;
export type PtzState = PtzStateModel;
export type SnackbarState = SnackbarStateModel;
export type ResponsivityState = ResponsivityInfoModel;
export type CustomerState = Customer;
export type RouteState = RouteModel;
export type UserState = StoreStateSingle<User | null>;
export type CurrentSelectionsState = CurrentSelectionsModel;
export type SharingState = StoreStateSingle<SharingStateEntityModel | null>;
export type SharingRecordingState =
  StoreStateSingle<SharingRecordingStateEntityModel>;
export type AnnotationListsState = StoreState<AnnotationList>;
export type TeamsState = EntityStateModel<TeamModel> & {
  alreadyLoadedIds: string[];
};
export type ClubsState = EntityStateModel<Club>;
export type RecorderState = EntityStateModel<Recorder>;
export type CameraState = StoreStateSingle<string[]>;
export type WsState = WsStateModel;
export type UiFlagsState = UiFlagsModel;
export type VideoPlayerState = VideoPlayerStateModel;
export type FilterSearchState = StoreStateSingle<
  SearchResponse,
  FilterSearchMetadataModel
>;

export interface AppState {
  auth: AuthState;
  annotationTypes: AnnotationTypesState;
  annotations: AnnotationState;
  recording: RecordingState;
  recordingList: RecordingListState;
  playlistList: PlaylistListState;
  snackbar: SnackbarState;
  responsivity: ResponsivityState;
  customer: CustomerState;
  route: RouteState;
  user: UserState;
  currentSelections: CurrentSelectionsState;
  sharing: SharingState;
  sharingRecording: SharingRecordingState;
  annotationLists: AnnotationListsState;
  teams: TeamsState;
  clubs: ClubsState;
  recorders: RecorderState;
  camera: CameraState;
  ws: WsState;
  uiFlags: UiFlagsState;
  filterSearch: FilterSearchState;
  videoPlayer: VideoPlayerState;
  trash: TrashState;
  ptz: PtzState;
}

export enum StateStatus {
  START = 'UNITIALIZED',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  LOADED = 'LOADED',
  UPDATING = 'UPDATING',
}

export interface ErrorModel {
  type: string;
  message: string;
}

export const INITIAL_ENTITY_STATE = {
  errors: [] as ErrorModel[],
  status: StateStatus.START,
  ids: [],
  entities: {},
};

interface EntityStateModel<T, U = void> extends EntityState<T> {
  errors: ErrorModel[];
  status: StateStatus;
  metadata?: U;
}

interface BasicStateProps<T> {
  errors: ErrorModel[];
  loading: boolean;
  loaded?: boolean;
  metadata?: T;
}

interface StoreState<T, U = void> extends BasicStateProps<U> {
  entities: { [id: string]: T };
}

interface StoreStateSingle<T, U = void> extends BasicStateProps<U> {
  entity?: T;
}
