<div class="tagging-controls">
  <div class="tagging-mode-switch-wrapper cmv-h5">
    <mat-slide-toggle
      (change)="changeLiveTaggingMode()"
      [disabled]="taggingModeSwitchDisabled$ | async"
      [checked]="isStreamTagging"
      labelPosition="before"
      hideIcon="true"
      color="primary"
    >
      {{ 'components.liveTaggingControls.switchLabel' | translate }}
    </mat-slide-toggle>
  </div>
  <ng-content></ng-content>
</div>
