<div>
  <div
    class="space--bar"
    [matTooltip]="
      'components.freeSpaceVisualizer.tooltip'
        | translate: { used: usedHours, limit: limitHours }
    "
  >
    <div [style.width]="usedStoreInPercent + '%'" class="used-space--bar"></div>
  </div>
</div>
