import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { take } from 'rxjs/operators';
import { AppState } from 'src/app/shared/models/app.state';
import {
  Recorder,
  Recording,
  RecordingStatus,
  RecordingType,
} from '../../../../api/models';
import { QueryParamNames, RoutePath } from '../../../app.constants';
import { deleteRecordingRequestAction } from '../../store/actions/recording.actions';
import { $recorders } from '../../store/selectors/recorders.selectors';
import {
  INPUT_TYPE,
  RemoveModalComponent,
} from '../modal/remove-modal/remove-modal.component';
import { ShareRecordingModalComponent } from '../modal/share-recording-modal/share-recording-modal.component';
import { RestoreModalComponent } from '../modal/restore-modal/restore-modal.component';
import {
  moveToTrashRequestAction,
  restoreFromTrashRequestAction,
} from '../../store/actions/trash.actions';

@Component({
  selector: 'cmv-match-widget',
  templateUrl: './match-widget.component.html',
  styleUrls: ['./match-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchWidgetComponent {
  @Input() match: Recording;
  @Input() navigation = false;
  @Input() hideScore = false;
  @Input() isInUserTrash = false;

  recorders$ = this.store.pipe(select($recorders));

  matchStatus = RecordingStatus;
  recordingType = RecordingType;

  constructor(
    private readonly router: Router,
    public readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
  ) {}

  get matchDate(): string {
    const SHORT_DATE_LENGTH = 3;
    const matchTime = moment.unix(this.match.dateTime);
    if (
      (this.match.status === RecordingStatus.PLAYED ||
        RecordingStatus.UPCOMING) &&
      !moment().isSame(matchTime, 'day')
    ) {
      return matchTime.format('D MMM').endsWith('.')
        ? matchTime.format('D MMM YYYY')
        : matchTime.format('MMMM').length <= SHORT_DATE_LENGTH
          ? matchTime.format('D MMM').concat('  ', matchTime.format('YYYY'))
          : matchTime.format('D MMM').concat('. ', matchTime.format('YYYY'));
    }
    return moment.unix(this.match.dateTime).format('HH:mm');
  }

  get matchTime(): string | void {
    const matchTime = moment.unix(this.match.dateTime);
    if (
      (this.match.status === RecordingStatus.PLAYED ||
        this.match.status === RecordingStatus.UPCOMING) &&
      !moment().isSame(matchTime, 'day')
    ) {
      return moment.unix(this.match.dateTime).format('HH:mm');
    }
  }

  isEditable(recorderId: string, recorders: Recorder[]): boolean {
    return recorders.some(rec => rec.id === recorderId);
  }

  restoreRecording({ id, name }: Recording): void {
    const dialogInstance = this.dialog.open(RestoreModalComponent, {
      data: {
        title: name,
      },
    });

    if (dialogInstance != null) {
      dialogInstance
        .afterClosed()
        .pipe(take(1))
        .subscribe(value => {
          if (value) {
            this.store.dispatch(restoreFromTrashRequestAction({ id }));
          }
        });
    }
  }

  deleteRecording({ id, name, status }: Recording): void {
    const dialogInstance = this.dialog.open(RemoveModalComponent, {
      data: {
        title: name,
        type: INPUT_TYPE.RECORDING,
      },
    });

    if (dialogInstance != null) {
      dialogInstance
        .afterClosed()
        .pipe(take(1))
        .subscribe(value => {
          if (value) {
            if (status === RecordingStatus.UPCOMING) {
              this.store.dispatch(deleteRecordingRequestAction({ id }));
            } else if (status == RecordingStatus.PLAYED) {
              this.store.dispatch(moveToTrashRequestAction({ id }));
            } else {
              throw new Error("Can't delete live recording");
            }
          }
        });
    }
  }

  menuWrapClick(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  editRecording(): void {
    if (this.match.manual) {
      this.router.navigate(
        [RoutePath.Platform, RoutePath.Recordings, RoutePath.Edit],
        { queryParams: { [QueryParamNames.RecordingId]: this.match.id } },
      );
    }
  }

  openShareModal(): void {
    this.dialog.open(ShareRecordingModalComponent, {
      data: {
        id: this.match.id,
      },
    });
  }

  get matchRouterLink() {
    if (!this.navigation) {
      return null;
    }

    if (this.match.status === RecordingStatus.PLAYED) {
      return [
        '/',
        RoutePath.Platform,
        RoutePath.Recordings,
        RoutePath.PlayedMatch,
      ];
    } else if (this.match.status === RecordingStatus.LIVE) {
      return [
        '/',
        RoutePath.Platform,
        RoutePath.Recordings,
        RoutePath.LiveMatch,
      ];
    } else {
      return null;
    }
  }

  get queryParams() {
    return { [QueryParamNames.RecordingId]: this.match.id };
  }
}
