import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { HowItWorksViewComponent } from './how-it-works-view/how-it-works-view.component';
import { PrivacyAndCookiePolicyViewComponent } from './privacy-and-cookie-policy-view/privacy-and-cookie-policy-view.component';
import { TermsOfUseViewComponent } from './terms-of-use-view/terms-of-use-view.component';
import { TrashComponent } from './trash/trash.component';
import { ChangelogViewComponent } from './changelog-view/changelog-view.component';

@NgModule({
  declarations: [
    TrashComponent,
    HowItWorksViewComponent,
    PrivacyAndCookiePolicyViewComponent,
    TermsOfUseViewComponent,
    ChangelogViewComponent,
  ],
  imports: [SharedModule],
})
export class MoreModule {}
