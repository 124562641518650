import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../models/app.state';
import { $searchBarVisible } from '../../store/selectors/ui-flags.selectors';
import { $isFakeFullscreenActive } from '../../store/selectors/video.selectors';

@Component({
  selector: 'cmv-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageLayoutComponent {
  @Input() showHeader: boolean = true;
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() addButtonEnabled: boolean = false;
  @Input() hideHeaderOnMobile: boolean = false;
  @Input() loadingResources: boolean = false;
  @Input() searchButtonEnabled: boolean = false;

  @Output() addButtonClicked = new EventEmitter<void>();

  readonly searchBarVisible$ = this.store.pipe(select($searchBarVisible));
  readonly fakeFullscreenActive$ = this.store.pipe(
    select($isFakeFullscreenActive),
  );

  constructor(private readonly store: Store<AppState>) {}
}
