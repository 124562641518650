import { createSelector } from '@ngrx/store';
import { AppState } from '../../models/app.state';
import {
  BREAKPOINT_L,
  BREAKPOINT_M,
  BREAKPOINT_S,
  BREAKPOINT_XL,
} from 'src/app/app.constants';

export const $screenWidth = ({ responsivity }: AppState) =>
  responsivity.screenWidth;
export const $isLargeDevice = createSelector(
  $screenWidth,
  width => width >= BREAKPOINT_M,
);
export const $isSmallDevice = createSelector(
  $isLargeDevice,
  enabled => !enabled,
);
export const $largeDeviceTagging = createSelector(
  $screenWidth,
  width => width >= BREAKPOINT_L,
);
export const $xlBreakPoint = createSelector(
  $screenWidth,
  width => width < BREAKPOINT_XL,
);
export const $lBreakPoint = createSelector(
  $screenWidth,
  width => width < BREAKPOINT_L,
);
export const $sBreakPoint = createSelector(
  $screenWidth,
  width => width < BREAKPOINT_S,
);
/* eslint-disable no-magic-numbers */
export const $colorPickerDimensions = createSelector(
  $sBreakPoint,
  sBreakPoint => ({
    height: sBreakPoint ? 80 : 120,
    width: sBreakPoint ? 6 : 8,
    knowSize: sBreakPoint ? 12 : 16,
  }),
);
