import { AppState, PlaylistListState } from '../../models/app.state';
import { createSelector } from '@ngrx/store';
import { Playlist } from '../../../../api/models/playlist.model';
import { $route } from './route.selectors';
import {
  $currentPlaylist,
  $currentPlaylistAnnotation,
} from './current-selections.selectors';

export const $playlistList = (state: AppState) => state.playlistList;

export const $playlistEntities = createSelector(
  $playlistList,
  (state: PlaylistListState) => (state.entity ? state.entity : []),
);

export const $playlistId = (id: string) =>
  createSelector(
    $playlistEntities,
    (state: Playlist[]) => state.find(item => item.id === id) || null,
  );

export const $playlistExist = (id: string) =>
  createSelector(
    $playlistEntities,
    $playlistList,
    (state: Playlist[], playList: PlaylistListState) =>
      playList.loading ? true : state.some(item => item.id === id),
  );

export const $playlistWithAnnotationExist = (
  playlistId: string,
  annotationId: string,
) =>
  createSelector($playlistEntities, (state: Playlist[]) => {
    const currentPlaylist = state.find(playlist => playlist.id === playlistId);

    return (
      currentPlaylist &&
      currentPlaylist.annotations.some(
        annotation => annotation.id === annotationId && annotation.show,
      )
    );
  });

export const $nextPlayablePlaylistAnnotationId = createSelector(
  $currentPlaylist,
  $currentPlaylistAnnotation,
  $route,
  (playlist, annotation, route) => {
    if (!route.params.trimming && playlist && annotation) {
      const playlistAnnotations = playlist.annotations.filter(a => a.show);

      return playlistAnnotations.indexOf(annotation) <
        playlistAnnotations.length - 1
        ? playlistAnnotations[playlistAnnotations.indexOf(annotation) + 1].id
        : undefined;
    }
    return undefined;
  },
);
