import { NgModule } from '@angular/core';
import { MatchesComponent } from './matches.component';
import { SharedModule } from '../../shared/shared.module';
import { LiveMatchViewComponent } from './live-match-view/live-match-view.component';
import { VideoTaggingViewComponent } from './video-tagging-view/video-tagging-view.component';
import { MatchListComponent } from '../../shared/components/match-list/match-list.component';
import { MatchListViewComponent } from './match-list-view/match-list-view.component';

@NgModule({
  declarations: [
    MatchesComponent,
    LiveMatchViewComponent,
    VideoTaggingViewComponent,
    MatchListComponent,
    MatchListViewComponent,
  ],
  imports: [SharedModule],
})
export class MatchesModule {}
