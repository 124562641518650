import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { FilterResultComponent } from './filter-result.component';

@NgModule({
  declarations: [FilterResultComponent],
  imports: [CommonModule, SharedModule],
})
export class FilterResultModule {}
