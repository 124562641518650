import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { from, interval, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { RecordingStatus } from 'src/api/models';
import {
  GoogleAnalyticsEvent,
  QueryParamNames,
  RECORDINGS_POLLING_INTERVAL,
  RoutePath,
} from 'src/app/app.constants';
import { ApiService } from 'src/app/services/api.service';
import { APIClient } from '../../../../api';
import {
  manualRecordingErrorCodeToTranslate,
  reportToGA,
} from '../../../app.utils';
import { AppState } from '../../models/app.state';
import { LiveTaggingModes } from '../../models/current-selections.model';
import {
  changeLiveTaggingModeAction,
  setCurrentLiveVideoAction,
  setCurrentVideoAction,
} from '../actions/current-selections.actions';
import { handleErrorResponseAction } from '../actions/error.actions';
import {
  deletedRecordingRequestAction,
  deletedRecordingsRequestAction,
  deleteRecordingFailureAction,
  deleteRecordingRequestAction,
  deleteRecordingsSuccessAction,
  deleteRecordingSuccessAction,
  getRecordingFailureAction,
  getRecordingRequestAction,
  getRecordingSuccessAction,
  postRecordingFailureAction,
  postRecordingRequestAction,
  postRecordingSuccessAction,
  putRecordingFailureAction,
  putRecordingRequestAction,
  putRecordingSuccessAction,
  remuxRecordingVideoRequestAction,
  remuxRecordingVideoSuccessAction,
  restoreRecordingRequestAction,
  stopRecordingFailureAction,
  stopRecordingRequestAction,
  stopRecordingSuccessAction,
} from '../actions/recording.actions';
import { storeRouteAction } from '../actions/route.actions';
import { showSnackbarAction } from '../actions/snackbar.actions';
import {
  $currentRecordingId,
  $subSectionRoute,
} from '../selectors/route.selectors';

@Injectable()
export class RecordingEffects {
  getRecording$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRecordingRequestAction),
      switchMap(({ id }) =>
        this.apiService
          .call(() => this.apiClient.getRecordingId({ id }))
          .pipe(
            withLatestFrom(this.store.pipe(select($subSectionRoute))),
            switchMap(([recording, route]) => {
              if (route === RoutePath.PlayedMatch) {
                return from([
                  getRecordingSuccessAction({ recording }),
                  ...(!!recording.videos
                    ? [setCurrentVideoAction({ videos: recording.videos })]
                    : []),
                ]);
              } else {
                if (recording.status === RecordingStatus.PLAYED) {
                  this.router.navigate(
                    [
                      RoutePath.Platform,
                      RoutePath.Recordings,
                      RoutePath.PlayedMatch,
                    ],
                    {
                      queryParams: {
                        [QueryParamNames.RecordingId]: recording.id,
                      },
                    },
                  );
                }

                return from([
                  getRecordingSuccessAction({ recording }),
                  recording.videos && recording.videos.length > 0
                    ? setCurrentLiveVideoAction({
                        videos: recording.videos,
                      })
                    : changeLiveTaggingModeAction({
                        mode: LiveTaggingModes.PITCH,
                      }),
                ]);
              }
            }),
            catchError(error => {
              const parsedError = {
                type: error.status.toString(),
                message: error.error.msg,
              };

              return of(
                getRecordingFailureAction({
                  errors: [parsedError],
                }),
                handleErrorResponseAction({
                  errorType: parsedError.type,
                }),
              );
            }),
          ),
      ),
    ),
  );

  refreshLiveRecording = createEffect(() =>
    this.actions$.pipe(
      ofType(storeRouteAction),
      filter(({ route }) => route.path.includes(RoutePath.LiveMatch)),
      switchMap(({ route }) =>
        interval(RECORDINGS_POLLING_INTERVAL).pipe(
          map(() =>
            getRecordingRequestAction({
              id: route.params[QueryParamNames.RecordingId],
            }),
          ),
          takeUntil(this.actions$.pipe(ofType(storeRouteAction.type))),
        ),
      ),
    ),
  );

  postRecording$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postRecordingRequestAction),
      switchMap(({ recordingRequest }) =>
        this.apiService
          .call(() => this.apiClient.postRecording({ body: recordingRequest }))
          .pipe(
            switchMap(recording =>
              of(
                postRecordingSuccessAction({ recording }),
                showSnackbarAction({
                  infoMessage: 'success.manualScheduling.create',
                }),
              ),
            ),
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.RECORDING_CREATE);
              this.router.navigate([RoutePath.Platform, RoutePath.Recordings], {
                replaceUrl: true,
              });
            }),
            catchError((error: HttpErrorResponse) =>
              of(
                postRecordingFailureAction({
                  errors: error.error.panorisCode
                    ? []
                    : [
                        {
                          type: error.status.toString(),
                          message: error.error.msg,
                        },
                      ],
                }),
                showSnackbarAction({
                  infoMessage: `${manualRecordingErrorCodeToTranslate(
                    error,
                    'error.manualScheduling.create',
                  )}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  putRecording$ = createEffect(() =>
    this.actions$.pipe(
      ofType(putRecordingRequestAction),
      switchMap(({ recordingUpdate }) =>
        this.apiService
          .call(() => this.apiClient.putRecording({ body: recordingUpdate }))
          .pipe(
            switchMap(recording =>
              of(
                putRecordingSuccessAction({ recording }),
                showSnackbarAction({
                  infoMessage: 'success.manualScheduling.edit',
                  icon: 'edit',
                }),
              ),
            ),
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.RECORDING_EDIT);
              this.router.navigate([RoutePath.Platform, RoutePath.Recordings], {
                replaceUrl: true,
              });
            }),
            catchError((error: HttpErrorResponse) =>
              of(
                putRecordingFailureAction({
                  errors: error.error.panorisCode
                    ? []
                    : [
                        {
                          type: error.status.toString(),
                          message: error.error.msg,
                        },
                      ],
                }),
                showSnackbarAction({
                  infoMessage: `${manualRecordingErrorCodeToTranslate(
                    error,
                    'error.manualScheduling.edit',
                  )}`,
                  icon: 'edit',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  deleteRecording$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteRecordingRequestAction),
      switchMap(({ id }) =>
        this.apiService
          .call(() => this.apiClient.deleteRecordingId({ id }))
          .pipe(
            switchMap(() =>
              of(
                deleteRecordingSuccessAction({ id }),
                showSnackbarAction({
                  infoMessage: 'success.manualScheduling.delete',
                  icon: 'delete',
                }),
              ),
            ),
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.RECORDING_REMOVE);
            }),
            catchError((error: HttpErrorResponse) =>
              of(
                deleteRecordingFailureAction({
                  errors: error.error.panorisCode
                    ? []
                    : [
                        {
                          type: error.status.toString(),
                          message: error.error.msg,
                        },
                      ],
                }),
                showSnackbarAction({
                  infoMessage: `${manualRecordingErrorCodeToTranslate(
                    error,
                    'error.manualScheduling.delete',
                  )}`,
                  icon: 'delete',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  restoreRecording$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(restoreRecordingRequestAction),
        tap(({ id }) => {
          console.log('Restore', id);
        }),
      ),
    { dispatch: false },
  );

  stopRecording$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stopRecordingRequestAction),
      switchMap(({ id }) =>
        this.apiService
          .call(() =>
            this.apiClient.postSchedulerStopRecording({ body: { id } }),
          )
          .pipe(
            switchMap(recording =>
              of(
                stopRecordingSuccessAction({ recording }),
                showSnackbarAction({
                  infoMessage: 'success.manualScheduling.stop',
                  icon: 'edit',
                }),
              ),
            ),
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.RECORDING_STOP);
              this.router.navigate([RoutePath.Platform, RoutePath.Recordings], {
                replaceUrl: true,
              });
            }),
            catchError((error: HttpErrorResponse) =>
              of(
                stopRecordingFailureAction({
                  errors: error.error.panorisCode
                    ? []
                    : [
                        {
                          type: error.status.toString(),
                          message: error.error.msg,
                        },
                      ],
                }),
                showSnackbarAction({
                  infoMessage: `${manualRecordingErrorCodeToTranslate(
                    error,
                    'error.manualScheduling.stop',
                  )}`,
                  icon: 'edit',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  deletedRecording$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletedRecordingRequestAction),
      withLatestFrom(
        this.store.select($subSectionRoute),
        this.store.select($currentRecordingId),
      ),
      switchMap(([{ id }, subsection, currentRecordingId]) => {
        if (
          (subsection === RoutePath.PlayedMatch ||
            subsection === RoutePath.LiveMatch) &&
          currentRecordingId === id
        ) {
          this.router.navigate([RoutePath.Platform, RoutePath.Recordings], {
            replaceUrl: true,
          });
        }

        return of(deleteRecordingSuccessAction({ id }));
      }),
    ),
  );

  deletedRecordings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletedRecordingsRequestAction),
      withLatestFrom(
        this.store.select($subSectionRoute),
        this.store.select($currentRecordingId),
      ),
      switchMap(([{ ids }, subsection, currentRecordingId]) => {
        if (
          (subsection === RoutePath.PlayedMatch ||
            subsection === RoutePath.LiveMatch) &&
          ids.includes(currentRecordingId)
        ) {
          this.router.navigate([RoutePath.Platform, RoutePath.Recordings], {
            replaceUrl: true,
          });
        }

        return of(deleteRecordingsSuccessAction({ ids }));
      }),
    ),
  );

  remuxRecordingVideoRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(remuxRecordingVideoRequestAction),
      switchMap(({ videoId }) =>
        this.apiService
          .call(() => this.apiClient.getVideoRemuxVideoId({ videoId }))
          .pipe(
            switchMap(recording =>
              of(remuxRecordingVideoSuccessAction({ recording })),
            ),
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.REMUX_VIDEO);
            }),
            catchError(() =>
              of(
                showSnackbarAction({
                  infoMessage: 'error.videoRemux',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly apiClient: APIClient,
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly apiService: ApiService,
  ) {}
}
