import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'cmv-terms-of-use-view',
  templateUrl: './terms-of-use-view.component.html',
  styleUrls: ['./terms-of-use-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsOfUseViewComponent {
  constructor() {}
}
