import { UserState } from '../../models/app.state';
import {
  getUserFailureAction,
  getUserRequestAction,
  getUserSuccessAction,
  postUserConfirmAppNewsFailureAction,
  postUserConfirmAppNewsRequestAction,
  postUserConfirmAppNewsSuccessAction,
  postUserTOSFailureAction,
  postUserTOSRequestAction,
  postUserTOSSuccessAction,
} from '../actions/user.actions';
import { Action, createReducer, on } from '@ngrx/store';

const initialState: UserState = {
  errors: [],
  loading: false,
  entity: null,
};

const reducer = createReducer(
  initialState,
  on(
    getUserRequestAction,
    postUserTOSRequestAction,
    postUserConfirmAppNewsRequestAction,
    state => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    getUserSuccessAction,
    postUserTOSSuccessAction,
    postUserConfirmAppNewsSuccessAction,
    (state, { user }) => ({
      ...state,
      entity: user,
      loading: false,
      loaded: true,
      errors: [],
    }),
  ),
  on(
    getUserFailureAction,
    postUserTOSFailureAction,
    postUserConfirmAppNewsFailureAction,
    (state, { errors }) => ({
      ...state,
      errors,
    }),
  ),
);

export function userReducer(state: UserState, action: Action): UserState {
  return reducer(state, action);
}
