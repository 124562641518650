import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

const HEADER_BASIC_PATH = 'components.modals.deleteModal';

export enum INPUT_TYPE {
  PLAYLIST = 'playlist',
  ANNOTATION = 'annotation',
  TAG = 'tag',
  RECORDING = 'recording',
}

@Component({
  selector: 'cmv-remove-modal',
  templateUrl: './remove-modal.component.html',
  styleUrls: ['./remove-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoveModalComponent implements AfterViewInit {
  title = '';
  headerKey = '';
  subtitle = '';

  @ViewChild('elementToFocus')
  elementToFocus: ElementRef;

  constructor(
    private readonly dialogRef: MatDialogRef<RemoveModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: { title: string; type: INPUT_TYPE },
  ) {
    this.title = data.title;
    this.headerKey = `${HEADER_BASIC_PATH}.${data.type}Header`;
    this.subtitle = `${HEADER_BASIC_PATH}.${data.type}Subtitle`;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elementToFocus.nativeElement.focus();
    }, 0);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
  }

  closeModal(remove: boolean): void {
    this.dialogRef.close(remove);
  }
}
