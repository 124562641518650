import { RenderStatus } from 'src/api/models';
import { AppState } from '../../models/app.state';
import { createSelector } from '@ngrx/store';

export const $anotationLists = (state: AppState) => state.annotationLists;

export const $shareableList = createSelector(
  $anotationLists,
  ({ entities }) => entities[Object.keys(entities)[0]],
);

export const $canDownloadAnnotationList = createSelector(
  $shareableList,
  annotationList =>
    annotationList?.status === RenderStatus.DONE && annotationList?.url !== '',
);

export const $isAnnotationListRendering = createSelector(
  $shareableList,
  annotationList =>
    annotationList?.status === RenderStatus.RENDERING ||
    annotationList?.status === RenderStatus.ZIPPING,
);
