<cmv-page-layout
  [title]="'components.tagView.title' | translate"
  [addButtonEnabled]="true"
  (addButtonClicked)="createNewTag()"
  [loadingResources]="loadingResources$ | async"
>
  <div class="tag-wrapper" *ngIf="annotationTypes$ | async as tags">
    <div class="cmv-section-header">
      <div class="cmv-h4">
        {{ tags.length }}
        {{
          tags.length === 1
            ? ('components.tagView.tag' | translate)
            : ('components.tagView.tags' | translate)
        }}
      </div>
    </div>
    <div class="tags">
      <div class="empty-tags" *ngIf="tags.length === 0">
        <div class="empty-tag-image">
          <img src="assets/images/empty_state.svg" />
        </div>
        <div class="empty-tag-title cmv-h1">
          {{ 'components.tagView.emptyTagListTitle' | translate }}
        </div>
        <div class="empty-tag-subtitle cmv-body">
          {{ 'components.tagView.emptyTagListSubtitle' | translate }}
        </div>
        <div
          class="cmv-dashed-button empty-tag-button"
          (click)="createNewTag()"
        >
          <div class="cmv-h4 text">
            {{ 'components.tagView.createNew' | translate }}
          </div>
          <mat-icon
            class="button-icon cmv-dim-gray"
            svgIcon="cmv-add"
          ></mat-icon>
        </div>
      </div>

      <div class="tags-wrap" cdkDropList (cdkDropListDropped)="drop($event)">
        <div
          class="cmv-h2 tag-title-wrap"
          [id]="tag.id"
          cdkDrag
          #ripple
          cdkDragLockAxis="y"
          *ngFor="let tag of tags"
        >
          <div class="tag-title">
            {{ tag.name }}
          </div>
          <div
            class="hover-icon"
            cdkDragHandle
            matRipple
            matRippleCentered="true"
            [matRippleTrigger]="ripple"
          >
            <mat-icon
              class="cmv-dim-gray drag-icon"
              svgIcon="cmv-drag"
            ></mat-icon>
          </div>

          <button
            class="cmv-more-button"
            (click)="$event.stopPropagation()"
            mat-icon-button
            [matMenuTriggerFor]="detailMenu"
          >
            <img src="assets/icons/more_icon_ver.svg" />
          </button>
          <mat-menu
            class="cmv-menu-container"
            #detailMenu="matMenu"
            [overlayPanelClass]="'reverese-item'"
          >
            <button
              class="cmv-menu-item"
              (click)="openModal('EditOffset', tag)"
              mat-menu-item
            >
              <div class="cmv-h4">
                {{ 'components.tagView.editTag' | translate }}
              </div>
              <span>
                <mat-icon
                  class="modal-icon cmv-gray-icon"
                  svgIcon="cmv-edit"
                ></mat-icon>
              </span>
            </button>
            <button
              class="cmv-menu-item"
              (click)="openModal('RemoveTag', tag)"
              mat-menu-item
            >
              <div class="cmv-h4">
                {{ 'components.tagView.deleteTag' | translate }}
              </div>
              <span>
                <mat-icon
                  class="modal-icon cmv-gray-icon"
                  svgIcon="cmv-delete"
                ></mat-icon>
              </span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</cmv-page-layout>
