<ng-container *ngIf="match$ | async as match">
  <cmv-video-page-layout
    [showHeader]="match.status"
    [title]="'components.playedMatchTagging.title' | translate"
    [subtitle]="'components.playedMatchTagging.subtitle' | translate"
    [loadingResources]="loadingResources$ | async"
  >
    <ng-container left-column>
      <cmv-video-player-wrapper
        [isMissingVideo]="!match.videos"
        [notInRange]="(playerOffsetIsValid$ | async) === false"
        [loading]="currentVideoLoading$ | async"
        [videoUrl]="currentVideoUrl$ | async"
        [autoplay]="shouldAutoplay"
        [offset]="playerOffset$ | async"
        [currentVideo]="currentVideo$ | async"
        [videoSources]="recordingVideos$ | async"
        [recordingEnd]="match.dateTime + match.duration"
        [createTag]="boundedCreateTag"
        (changeSrc)="changeSrc($event, true)"
        (seeked)="videoSeeked($event)"
        (videoCurrentTime)="videoCurrentTimeChange($event)"
        (playerReady)="onPlayerReady()"
        #videoPlayer
      ></cmv-video-player-wrapper>
      <cmv-video-control-panel
        [title]="getFullMatchName(match)"
        [showChangeVideoSource]="true"
        [currentVideo]="currentVideo$ | async"
        [offset]="playerOffset$ | async"
        [videoSources]="recordingVideos$ | async"
        (changeSrc)="changeSrc($event)"
        [downloadEnabled]="match.download"
        [downloadVideos]="downloadVideos$ | async"
        [liveVideos]="currentRecordingLiveVideos$ | async"
        [isLoadingVisible]="isAnyCurrentRecordingVideoUserRemuxing$ | async"
        [infoEnabled]="true"
      ></cmv-video-control-panel>
      <div *ngIf="isTrimming$ | async" class="trimming-panel-wrapper">
        <cmv-trimming-panel
          [videoStartAt]="(currentVideo$ | async).startAt"
          [trimmingValues]="trimmingOffset$ | async"
          (submitChange)="trimAnnotation($event)"
          (seekTo)="seekWithHandleMove($event)"
          (valueRangeChange)="recalculateTag($event.from, $event.to)"
          (cancel)="cancelTrim()"
        ></cmv-trimming-panel>
      </div>
    </ng-container>
    <ng-container right-column>
      <div class="match-detail">
        <cmv-match-widget [match]="match"></cmv-match-widget>
      </div>
      <div
        class="large-device-resolution-tagging"
        *ngIf="(xlBreakPoint$ | async) === false"
      >
        <div class="tagging">
          <div class="tagging-panel-wrapper" *ngIf="match.videos">
            <cmv-tagging-panel
              (tagCreated)="createTag($event)"
            ></cmv-tagging-panel>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="createdTags"></ng-container>
      </div>
      <div
        class="small-device-resolution-tagging"
        [class.download-visible]="canDownloadAnnotationList$ | async"
        *ngIf="xlBreakPoint$ | async"
      >
        <mat-tab-group animationDuration="0ms">
          <mat-tab
            *ngIf="match.videos"
            label="{{ 'components.taggingPanel.addTags' | translate }}"
          >
            <div class="tagging">
              <div class="tagging-panel-wrapper">
                <cmv-tagging-panel
                  (tagCreated)="createTag($event)"
                ></cmv-tagging-panel>
              </div>
            </div>
          </mat-tab>
          <mat-tab labelClass="create-tags-label">
            <ng-template mat-tab-label class="mat-tab-label-with-icon">
              <div>
                {{ 'components.createdTags' | translate }}
              </div>
              <div
                class="create-tags-more-wrapper create-tags-more-wrapper-mobile"
              >
                <ng-container
                  *ngTemplateOutlet="createdTagsMoreButton"
                ></ng-container>
              </div>
              <div
                class="created-tags-mobile-loading-bar"
                *ngIf="isAnnotationListRendering$ | async"
              >
                <cmv-loading-bar></cmv-loading-bar>
              </div>
            </ng-template>
            <ng-container *ngTemplateOutlet="createdTags"></ng-container>
          </mat-tab>
        </mat-tab-group>
      </div>
      <ng-template #createdTags>
        <div class="created-tags">
          <div
            class="cmv-section-header with-loading"
            [class.active]="isAnnotationListRendering$ | async"
          >
            <div class="cmv-section-header--inner">
              <div class="cmv-h4">
                {{ 'components.createdTags' | translate }}
              </div>
              <div class="create-tags-more-wrapper">
                <ng-container
                  *ngTemplateOutlet="createdTagsMoreButton"
                ></ng-container>
              </div>
            </div>
            <cmv-loading-bar></cmv-loading-bar>
          </div>
          <cmv-created-tags
            [showEmptyList]="true"
            [annotations]="annotations$ | async"
            (deleteTag)="deleteTag($event)"
            (trimVideo)="redirectToTrimming($event.id, $event.recordingId)"
            (editAnnotation)="editAnnotation($event)"
            (selectedWidgetIdChange)="selectTag($event.annotation)"
            [trimmingDisabled]="trimmingDisabled$ | async"
          ></cmv-created-tags>
        </div>
      </ng-template>

      <ng-template #createdTagsMoreButton>
        <cmv-created-tags-more
          *ngIf="isSharingEnabled$ | async"
          [shareableList]="shareableList$ | async"
          [annotationsEmpty]="(annotations$ | async).length === 0"
          [isDownloadPrepared]="canDownloadAnnotationList$ | async"
        ></cmv-created-tags-more>
      </ng-template>
    </ng-container>
  </cmv-video-page-layout>
</ng-container>
