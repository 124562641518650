import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'cmv-create-recording-view',
  templateUrl: './create-recording-view.component.html',
  styleUrls: ['./create-recording-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateRecordingViewComponent {
  constructor() {}
}
