<div class="sidebar-content">
  <div class="logo-wrapper">
    <a class="sidebar-logo" [routerLink]="routePath.Recordings"></a>
  </div>
  <div class="menu">
    <div class="navigation">
      <div
        class="navigation-inner"
        [ngClass]="{ 'navigation-submenu-open': moreSubmenuOpen$ | async }"
      >
        <cmv-navigation-item
          [customRouterLink]="routePath.Recordings"
          [counter]="liveRecordingsCount$ | async"
          iconName="cmv-recordings"
          iconClass="matches-icon"
        >
          {{ 'components.sideBar.recordings' | translate }}
        </cmv-navigation-item>
        <cmv-navigation-item
          [customRouterLink]="routePath.Playlists"
          iconName="cmv-playlist"
          iconClass="playlists-icon"
        >
          {{ 'components.sideBar.playlists' | translate }}
        </cmv-navigation-item>
        <cmv-navigation-item
          [customRouterLink]="routePath.TagPanel"
          iconName="cmv-tag"
          iconClass="tag-icon"
        >
          {{ 'components.sideBar.tagPanel' | translate }}
        </cmv-navigation-item>
        <cmv-navigation-item
          *ngIf="hasAnyRecorder$ | async"
          customRouterLink="{{ routePath.Trash }}"
          iconName="cmv-trash"
          iconClass="submenu-icon trash-icon"
        >
          {{ 'components.sideBar.trash' | translate }}
        </cmv-navigation-item>
        <cmv-navigation-item
          [submenu]="true"
          iconName="cmv-menu"
          iconClass="more-icon"
        >
          {{ 'components.sideBar.more' | translate }}
          <div class="navigation-submenu">
            <cmv-navigation-item
              *ngIf="showInstallPwa$ | async"
              (click)="installPWA()"
              iconName="cmv-download"
              iconClass="submenu-icon tag-view-icon"
            >
              {{ 'components.sideBar.installPWA' | translate }}
            </cmv-navigation-item>
            <cmv-navigation-item
              customRouterLink="{{ routePath.More }}/{{ routePath.HowItWorks }}"
              iconName="cmv-whistle"
              iconClass="submenu-icon"
            >
              {{ 'components.sideBar.howItWorks' | translate }}
            </cmv-navigation-item>
            <cmv-navigation-item
              customRouterLink="{{ routePath.More }}/{{ routePath.TermsOfUse }}"
              iconName="cmv-text"
              iconClass="submenu-icon"
            >
              {{ 'components.sideBar.termsOfUse' | translate }}
            </cmv-navigation-item>
            <cmv-navigation-item
              customRouterLink="{{ routePath.More }}/{{
                routePath.PrivacyAndCookiePolicy
              }}"
              iconName="cmv-privacy"
              iconClass="submenu-icon"
            >
              {{ 'components.sideBar.privacyAndCookiePolicy' | translate }}
            </cmv-navigation-item>
            <cmv-navigation-item
              class="cmv-logout-submenu"
              (click)="onLogOut()"
              iconName="cmv-logout"
              iconClass="logout-icon"
            >
              {{ 'components.sideBar.logout' | translate }}
            </cmv-navigation-item>
            <a
              class="cmv-paragraph cmv-app-version"
              routerLink="{{ routePath.More }}/{{ routePath.Changelog }}"
            >
              {{ 'basic.version' | translate }}: {{ version }}
            </a>
          </div>
        </cmv-navigation-item>
      </div>
    </div>

    <cmv-navigation-item
      class="cmv-logout-button"
      (click)="onLogOut()"
      iconName="cmv-logout"
      iconClass="logout-icon"
    >
      {{ 'components.sideBar.logout' | translate }}
    </cmv-navigation-item>
  </div>
  <div class="collapse-icon" (click)="toggleCollapseMenu()">
    <mat-icon [svgIcon]="getCollapseIcon(isMenuCollapsed$ | async)"></mat-icon>
  </div>
</div>
