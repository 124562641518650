import {
  Component,
  ChangeDetectionStrategy,
  HostListener,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cmv-not-authorized-modal',
  templateUrl: './not-authorized-modal.component.html',
  styleUrls: ['./not-authorized-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotAuthorizedModalComponent implements AfterViewInit {
  @ViewChild('elementToFocus')
  elementToFocus: ElementRef;

  constructor(
    private readonly dialogRef: MatDialogRef<NotAuthorizedModalComponent>,
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elementToFocus.nativeElement.focus();
    }, 0);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
