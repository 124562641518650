import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppState } from '../shared/models/app.state';
import { Store, select } from '@ngrx/store';
import {
  handleLoginAction,
  loginAction,
} from '../shared/store/actions/auth.actions';
import { $authLoading } from '../shared/store/selectors/auth.selectors';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

enum Swipe {
  LEFT = 'left',
  RIGHT = 'right',
}

interface CarouselImage {
  link: string;
  state: 'shown' | 'hidden';
}

@Component({
  selector: 'cmv-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('carousel', [
      state(
        'hidden',
        style({
          opacity: 0,
        }),
      ),
      state(
        'shown',
        style({
          opacity: 1,
        }),
      ),
      transition('hidden <=> shown', animate('500ms ease-out')),
    ]),
  ],
})
export class LandingComponent {
  images: CarouselImage[] = [
    {
      link: 'assets/images/tagging-panel.png',
      state: 'shown',
    },
    {
      link: 'assets/images/trimming.png',
      state: 'hidden',
    },
    {
      link: 'assets/images/random-btn.png',
      state: 'hidden',
    },
  ];
  readonly authLoading$ = this.store.pipe(select($authLoading));

  constructor(
    private readonly store: Store<AppState>,
    private readonly route: ActivatedRoute,
  ) {
    const { fragment, queryParams } = this.route.snapshot;
    if (fragment && fragment.indexOf('access_token=') !== -1) {
      this.store.dispatch(handleLoginAction());
    }
    if (queryParams && queryParams.code) {
      this.store.dispatch(handleLoginAction());
    }
  }

  logIn(): void {
    this.store.dispatch(loginAction());
  }

  swipe(swipeDirection: string, index: number): void {
    if (swipeDirection === Swipe.RIGHT && index > 0) {
      this.images = this.images.map(image => ({
        ...image,
        state: (this.images[index - 1] === image ? 'shown' : 'hidden') as
          | 'shown'
          | 'hidden',
      }));
    }

    if (swipeDirection === Swipe.LEFT && index < this.images.length - 1) {
      this.images = this.images.map(image => ({
        ...image,
        state: (this.images[index + 1] === image ? 'shown' : 'hidden') as
          | 'shown'
          | 'hidden',
      }));
    }
  }

  changeImage(changedImage: CarouselImage): void {
    this.images = this.images.map(image => ({
      ...image,
      state: (image.link === changedImage.link ? 'shown' : 'hidden') as
        | 'shown'
        | 'hidden',
    }));
  }

  identifyImage(index: number): number {
    return index;
  }
}
