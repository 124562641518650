import { createSelector } from '@ngrx/store';
import { AppState, UiFlagsState } from '../../models/app.state';
import {
  $isLargeDevice,
  $screenWidth,
  $xlBreakPoint,
} from './responsivity.selectors';
import { BREAKPOINT_L } from 'src/app/app.constants';
import { $ptzOverlayVisible } from './ptz.selectors';
import { $isFakeFullscreenActive, $videoFullScreen } from './video.selectors';

export const $uiFlagsEntity = ({ uiFlags }: AppState) => uiFlags;
export const $errorOverlayVisible = createSelector(
  $uiFlagsEntity,
  ({ errorOverlayVisible }: UiFlagsState) => errorOverlayVisible,
);
export const $searchBarVisible = createSelector(
  $uiFlagsEntity,
  ({ searchBarVisible }) => searchBarVisible,
);
export const $searchFiltersVisible = createSelector(
  $uiFlagsEntity,
  ({ searchFiltersVisible }) => searchFiltersVisible,
);
export const $drawingOverlayVisible = createSelector(
  $uiFlagsEntity,
  ({ drawingOverlayVisible }) => drawingOverlayVisible,
);
export const $videoTaggingPanelRolledUp = createSelector(
  $uiFlagsEntity,
  ({ videoTaggingPanelRolledUp }) => videoTaggingPanelRolledUp,
);
export const $videoVisible = createSelector(
  $uiFlagsEntity,
  ({ videoVisible }) => videoVisible,
);
export const $showRotateDeviceMessage = createSelector(
  $videoFullScreen,
  $videoVisible,
  (fullscreen, visible) => fullscreen || visible,
);
export const $showIOSPWAWarning = createSelector(
  $uiFlagsEntity,
  $isFakeFullscreenActive,
  (
    { closedIOSPWAFullscreenWarning, closedPWAButton },
    isFakeFullscreenActive,
  ) =>
    isFakeFullscreenActive && !closedIOSPWAFullscreenWarning && closedPWAButton,
);
export const $closedPWAButton = createSelector(
  $uiFlagsEntity,
  ({ closedPWAButton }) => closedPWAButton,
);
export const $isPWA = createSelector($uiFlagsEntity, ({ isPWA }) => isPWA);
export const $showPWAButton = createSelector(
  $uiFlagsEntity,
  ({ closedPWAButton, isPWA }) => !closedPWAButton && !isPWA,
);

export const $moreSubmenuOpen = createSelector(
  $uiFlagsEntity,
  ({ moreSubmenuOpen }) => moreSubmenuOpen,
);

export const $isMenuCollapsed = createSelector(
  $uiFlagsEntity,
  $xlBreakPoint,
  ({ isMenuCollapsed }, xlBreakPoint) => isMenuCollapsed && !xlBreakPoint,
);

export const $videoTaggingEnabled = createSelector(
  $screenWidth,
  $drawingOverlayVisible,
  $ptzOverlayVisible,
  (width, drawingVisible, ptzVisible) =>
    width >= BREAKPOINT_L && !drawingVisible && !ptzVisible,
);

/* eslint-enable no-magic-numbers */
export const $searchFiltersOverlayVisible = createSelector(
  $searchFiltersVisible,
  $isLargeDevice,
  (filterVisible, isLarge) => filterVisible && isLarge,
);
