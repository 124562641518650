import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { from, interval, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  switchMapTo,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { APIClient } from '../../../../api';
import {
  QueryParamNames,
  RECORDINGS_POLLING_INTERVAL,
  RoutePath,
} from '../../../app.constants';
import { constructRecordingsQuery } from '../../../app.utils';
import { AppState } from '../../models/app.state';
import { handleErrorResponseAction } from '../actions/error.actions';
import {
  getMoreRecordingsFailureAction,
  getMoreRecordingsRequestAction,
  getMoreRecordingsSuccessAction,
  getRecordingsMetaFailureAction,
  getRecordingsMetaRequestAction,
  getRecordingsMetaSuccessAction,
  refreshRecordingsFailureAction,
  refreshRecordingsMetadataRequestAction,
  refreshRecordingsRequestAction,
  refreshRecordingsSuccessAction,
  refreshSingleRecordingFailureAction,
  refreshSingleRecordingRequestAction,
  refreshSingleRecordingSuccessAction,
} from '../actions/recording-list.actions';
import { $recordingsMetadata } from '../selectors/recording-list.selectors';
import {
  $subSectionRoute,
  $currentRecordingId,
} from '../selectors/route.selectors';
import { setCurrentVideoAction } from '../actions/current-selections.actions';
import { Router } from '@angular/router';
import { RecordingStatus } from 'src/api/models';
import { deleteRecordingSuccessAction } from '../actions/recording.actions';
import { ErrorTypes } from '../../components/error/error.component';
import { ApiService } from 'src/app/services/api.service';

@Injectable()
export class RecordingListEffects {
  getRecordingsMeta$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRecordingsMetaRequestAction),
      switchMap(({ query }) =>
        this.apiService
          .call(() => this.apiClient.getMetaRecording(query))
          .pipe(
            map(metaRecording =>
              getRecordingsMetaSuccessAction({ metaRecording }),
            ),
            catchError(error => {
              const parsedError = {
                type: error.status.toString(),
                message: error.error.msg,
              };

              return of(
                getRecordingsMetaFailureAction({
                  errors: [parsedError],
                }),
                handleErrorResponseAction({
                  errorType: parsedError.type,
                }),
              );
            }),
          ),
      ),
    ),
  );

  getRecordingsMetaSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRecordingsMetaSuccessAction),
      map(({ metaRecording }) => constructRecordingsQuery(metaRecording)),
      switchMap(query =>
        this.apiService
          .call(() => this.apiClient.getRecording(query))
          .pipe(
            map(recordings => getMoreRecordingsSuccessAction({ recordings })),
            catchError(error => {
              const parsedError = {
                type: error.status.toString(),
                message: error.error.msg,
              };

              return of(
                getMoreRecordingsFailureAction({
                  errors: [parsedError],
                }),
                handleErrorResponseAction({
                  errorType: parsedError.type,
                }),
              );
            }),
          ),
      ),
    ),
  );

  getRecordingList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMoreRecordingsRequestAction),
      switchMap(() => this.store.pipe(take(1), select($recordingsMetadata))),
      map(metadata => constructRecordingsQuery(metadata.days, metadata.from)),
      switchMap(query =>
        this.apiService
          .call(() => this.apiClient.getRecording(query))
          .pipe(
            map(recordings => getMoreRecordingsSuccessAction({ recordings })),
            catchError(error => {
              const parsedError = {
                type: error.status.toString(),
                message: error.error.msg,
              };
              return of(
                getMoreRecordingsFailureAction({
                  errors: [parsedError],
                }),
                handleErrorResponseAction({
                  errorType: parsedError.type,
                }),
              );
            }),
          ),
      ),
    ),
  );

  refreshRecordings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshRecordingsRequestAction),
      switchMap(() => this.store.pipe(select($recordingsMetadata))),
      switchMap(query =>
        this.apiService.call(() =>
          this.apiClient.getRecording({ from: query.from, to: query.to }),
        ),
      ),
      map(recordings => refreshRecordingsSuccessAction({ recordings })),
      catchError(error => {
        const parsedError = {
          type: error.status.toString(),
          message: error.error.msg,
        };

        return of(
          refreshRecordingsFailureAction({
            errors: [parsedError],
          }),
          handleErrorResponseAction({
            errorType: parsedError.type,
          }),
        );
      }),
    ),
  );

  refreshSingleRecording$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshSingleRecordingRequestAction),
      withLatestFrom(
        this.store.select($subSectionRoute),
        this.store.select($currentRecordingId),
      ),
      switchMap(([{ id, refreshMetadata }, subsection, currentRecordingId]) =>
        this.apiService
          .call(() => this.apiClient.getRecordingId({ id }))
          .pipe(
            switchMap(recording => {
              if (
                subsection === RoutePath.LiveMatch &&
                recording.status === RecordingStatus.PLAYED &&
                currentRecordingId === recording.id
              ) {
                this.router.navigate(
                  [
                    RoutePath.Platform,
                    RoutePath.Recordings,
                    RoutePath.PlayedMatch,
                  ],
                  {
                    queryParams: {
                      [QueryParamNames.RecordingId]: recording.id,
                    },
                  },
                );
              } else if (
                subsection === RoutePath.PlayedMatch &&
                recording.status === RecordingStatus.LIVE &&
                currentRecordingId === recording.id
              ) {
                this.router.navigate(
                  [
                    RoutePath.Platform,
                    RoutePath.Recordings,
                    RoutePath.LiveMatch,
                  ],
                  {
                    queryParams: {
                      [QueryParamNames.RecordingId]: recording.id,
                    },
                  },
                );
              }

              return from([
                refreshSingleRecordingSuccessAction({ recording }),
                ...(!!recording.videos && recording.id === currentRecordingId
                  ? [setCurrentVideoAction({ videos: recording.videos })]
                  : []),
                ...(refreshMetadata
                  ? [
                      refreshRecordingsMetadataRequestAction({
                        fromDateTime: recording.dateTime,
                      }),
                    ]
                  : []),
              ]);
            }),
            catchError(error => {
              const parsedError = {
                type: error.status.toString(),
                message: error.error.msg,
              };

              if (parsedError.message === ErrorTypes.NO_RIGHTS) {
                if (
                  (subsection === RoutePath.PlayedMatch ||
                    subsection === RoutePath.LiveMatch) &&
                  currentRecordingId === id
                ) {
                  this.router.navigate(
                    [RoutePath.Platform, RoutePath.Recordings],
                    {
                      replaceUrl: true,
                    },
                  );
                }

                return of(deleteRecordingSuccessAction({ id }));
              }

              return of(
                refreshSingleRecordingFailureAction({
                  errors: [parsedError],
                }),
                handleErrorResponseAction({
                  errorType: parsedError.type,
                }),
              );
            }),
          ),
      ),
    ),
  );

  refreshRecordingsInterval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRecordingsMetaSuccessAction),
      switchMapTo(interval(RECORDINGS_POLLING_INTERVAL)),
      map(() => refreshRecordingsRequestAction()),
    ),
  );

  refreshMetadataByRecording$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshRecordingsMetadataRequestAction),
      withLatestFrom(this.store.select($recordingsMetadata)),
      switchMap(([{ fromDateTime }, metadata]) =>
        of(
          getRecordingsMetaRequestAction({
            query: {
              from: Math.min(fromDateTime, metadata.from),
              to: metadata.to,
            },
          }),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly apiClient: APIClient,
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly apiService: ApiService,
  ) {}
}
