import { CurrentVideoModel } from './current-video.model';
import { OffsetModel } from '@videogular/ngx-videogular/core';

export enum LiveTaggingModes {
  PITCH = 'PITCH',
  STREAM = 'STREAM',
}

export enum TaggingTeam {
  BOTH = 'BOTH',
  HOME = 'HOME',
  AWAY = 'AWAY',
}

export interface CurrentSelectionsModel {
  currentVideo: CurrentVideoModel;
  signedVideoUrl: string;
  videoOffsets: OffsetModel | undefined;
  currentLiveTaggingMode: LiveTaggingModes;
  remuxingVideoIds: string[];
  downloadingPlaylistIds: string[];
  downloadingAnnotations: { id: string; recordingId: string }[];
  downloadingAnnotationListIds: string[];
  matchesTabIndex: number;
  scrollPositions: { [x: number]: number };
  taggingTeam: TaggingTeam;
}
