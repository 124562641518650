<div
  tabindex="0"
  #elementToFocus
  class="cmv-modal-outer-card premium-trial-wrapper"
>
  <div class="cmv-modal-inner-card">
    <div class="cmv-modal-close-button" (click)="closeModal(false)">
      <mat-icon
        class="modal-icon-closing cmv-dim-gray"
        svgIcon="cmv-closing"
      ></mat-icon>
    </div>

    <div class="main-info">
      <div class="cmv-h1 premium-modal-title">
        {{ 'components.modals.premiumTrialModal.title' | translate }}
      </div>
      <div class="content-wrapper">
        <div class="info-text-wrapper">
          <div class="cmv-body premium-modal-center">
            {{ 'components.modals.premiumTrialModal.paragraph1' | translate }}
          </div>
          <div class="cmv-body premium-modal-center">
            {{ 'components.modals.premiumTrialModal.paragraph2' | translate }}
          </div>
        </div>
        <div class="premium-pros-text-wrapper">
          <div class="premium-pros">
            <mat-icon
              class="modal-icon-premium"
              svgIcon="cmv-checkmark"
            ></mat-icon>
            <div class="cmv-h2 premium-pros-text">
              {{ 'components.modals.premiumTrialModal.advantage1' | translate }}
            </div>
          </div>
          <div class="premium-pros">
            <mat-icon
              class="modal-icon-premium"
              svgIcon="cmv-checkmark"
            ></mat-icon>
            <div class="cmv-h2 premium-pros-text">
              {{ 'components.modals.premiumTrialModal.advantage2' | translate }}
            </div>
          </div>
          <div class="premium-pros">
            <mat-icon
              class="modal-icon-premium"
              svgIcon="cmv-checkmark"
            ></mat-icon>
            <div class="cmv-h2 premium-pros-text">
              {{ 'components.modals.premiumTrialModal.advantage3' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <div class="cmv-accept-button" (click)="closeModal(true)">
        <div class="cmv-h4">{{ 'basic.yes' | translate }}</div>
      </div>
    </div>
  </div>
</div>
