<ng-container *ngIf="customRouterLink; else nolink">
  <a
    *ngIf="!externalLink; else external"
    [routerLink]="customRouterLink"
    routerLinkActive="navigation-item-active"
    class="navigation-item"
    [ngClass]="{ 'is-open': isSubmenuOpen$ | async }"
  >
    <ng-container *ngTemplateOutlet="inner"></ng-container>
  </a>

  <ng-template #external>
    <a
      [href]="externalLink"
      target="_blank"
      class="navigation-item"
      [ngClass]="{ 'is-open': isSubmenuOpen$ | async }"
    >
      <ng-container *ngTemplateOutlet="inner"></ng-container>
    </a>
  </ng-template>
</ng-container>

<ng-template #nolink>
  <span
    class="navigation-item"
    (click)="switchSubmenu()"
    [ngClass]="{ 'is-open': isSubmenuOpen$ | async }"
  >
    <ng-container *ngTemplateOutlet="inner"></ng-container>
  </span>
</ng-template>

<ng-template #inner>
  <div class="navigation-icon-box">
    <div class="icon-wrapper {{ iconClass }}">
      <div *ngIf="counter > 0" class="live-matches cmv-h3">
        {{ counter }}
      </div>
      <mat-icon
        class="navigation-icon cmv-white-icon"
        [svgIcon]="iconName"
      ></mat-icon>
    </div>
  </div>
  <div class="cmv-h5 navigation-item-title">
    <ng-content></ng-content>
  </div>
</ng-template>

<ng-container *ngIf="submenu">
  <div class="submenu" [ngClass]="{ 'is-open': isSubmenuOpen$ | async }">
    <div class="submenu-inner">
      <div class="logo-wrapper">
        <a
          class="sidebar-logo submenu-logo"
          [routerLink]="routePath.Recordings"
        ></a>
      </div>

      <span class="submenu-back" (click)="switchSubmenu()">
        <div class="submenu-back-icon--wrapper">
          <mat-icon
            class="navigation-icon cmv-white-icon"
            svgIcon="cmv-back"
          ></mat-icon>
        </div>
        <span class="submenu-back-title">
          {{ 'components.submenu.back' | translate }}
        </span>
      </span>
      <ng-content select=".navigation-submenu"></ng-content>
    </div>
  </div>
</ng-container>
